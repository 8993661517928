import { createAction } from '@reduxjs/toolkit';
import type { StepName } from '@lt/api/dist/methods/stats/types';
import { SEND_PAGEVIEW } from './constants';

/**
 * Если на странице нет экшна INIT_APP(либо это чекаут), то будет вызываться этот
 * экшн, в payload передается название страницы
 */
export const sendPageviewAction =
  createAction<StepName[keyof StepName]>(SEND_PAGEVIEW);
