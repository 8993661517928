import { logger } from 'src/error';
import { isSSR } from 'src/helpers/ssr';

import type { MindboxOperationName, MindboxOperationsParams } from './types';
import { isDevelopment } from '../commonUtils';

/**
 * Подключение Mindbox Javascript SDK
 * @see https://developers.mindbox.ru/docs/%D1%82%D1%80%D0%B5%D0%BA%D0%B5%D1%80
 * @see https://docs.google.com/document/d/1rNy-_zEn5AmBgdfb5UkJVg9l4kL6mlgWuRa7Ntd5xxY/edit
 */
export const sendMindboxOperation = (
  operationName: MindboxOperationName,
  data?: MindboxOperationsParams,
) => {
  try {
    if (isSSR) return;

    const mindboxParams = {
      operation: operationName,
      data,
    };

    if (isDevelopment()) {
      // eslint-disable-next-line no-console
      console.log('Отправка операции Mindbox', mindboxParams);
    } else {
      window.mindbox('async', mindboxParams);
    }
  } catch (e) {
    logger({ operationName, data }, 'Ошибка отправки операции Mindbox');
  }
};
