import React from 'react';

import { Burger } from '@lt/components/icons-react';
import styles from './mobileMenu.module.css';

type Props = {
  openSidebar: () => void;
};

const MobileMenu = (props: Props) => {
  const { openSidebar } = props;

  return <Burger className={styles.burger} onClick={openSidebar} />;
};

export default MobileMenu;
