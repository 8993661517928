import { createSlice } from '@reduxjs/toolkit';

import type { PopularMetaregionsOffersState } from './types';

const initialState: PopularMetaregionsOffersState = {
  list: [],
};

export const name = 'popularMetaregionsOffers';

const popularMetaregionsOffersSlice = createSlice({
  name,
  initialState,
  reducers: {},
});

export default popularMetaregionsOffersSlice;
