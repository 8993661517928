import React from 'react';

import { useTranslation } from '@lt/localization';

import classnames from 'classnames';
import type { IContainerHeading } from './types';
import { RELINKING_VARIANTS } from '../../constants';
import styles from './containerHeading.module.css';

export const ContainerHeading = (props: IContainerHeading) => {
  const { variant, isMobile, title } = props;
  const { t } = useTranslation();

  const isHotelsVariant = variant === RELINKING_VARIANTS.HOTELS;
  const isCountriesVariant =
    variant === RELINKING_VARIANTS.DEFAULT_COUNTRIES ||
    variant === RELINKING_VARIANTS.COUNTRIES_WITH_PRICES;

  const headingText =
    title ??
    t('homeRelinking.heading', {
      context: isCountriesVariant ? 'countries' : variant,
    });

  return (
    <>
      <div
        className={classnames(styles.header, {
          [styles.headerMobile]: isMobile,
        })}
      >
        <div
          className={classnames(styles.heading, {
            [styles.headingHotelsVariant]: isHotelsVariant,
            [styles.headingMobile]: isMobile,
          })}
        >
          {headingText}
        </div>
      </div>
    </>
  );
};
