export const CHECKOUT_LAYOUT = {
  HOTEL: 1,
  TOUR: 2,
} as const;

export const PRICE_RANGES = {
  ranges: [
    { category: '<30к', min: 0, max: 30000 },
    { category: '30-59к', min: 30000, max: 60000 },
    { category: '60-89к', min: 60000, max: 90000 },
    { category: '90-119к', min: 90000, max: 120000 },
    { category: '120-159к', min: 120000, max: 160000 },
    { category: '160-219к', min: 160000, max: 220000 },
  ],
  maxValue: '>220к',
};

export const CLEAR_PREVIOUS_PACKAGE_DATA = 'CLEAR_PREVIOUS_PACKAGE_DATA';
export const CREATE_PACKAGE_SUCCESS = 'CREATE_PACKAGE_SUCCESS';
export const UPDATE_PACKAGE_ID = 'UPDATE_PACKAGE_ID';
