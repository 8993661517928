import React from 'react';
import classnames from 'classnames';
import type { IHeaderOrder } from './types';
import styles from './headerOrder.module.css';

const HeaderOrder: React.FC<IHeaderOrder> = (props) => {
  const { className = '', label = '', description = '' } = props;

  return (
    <div className={classnames(styles.headerOrder, className)}>
      <div className={styles.label}>{label}</div>
      <div className={styles.description}>{description}</div>
    </div>
  );
};

export default HeaderOrder;
