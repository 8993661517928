import { createSlice } from '@reduxjs/toolkit';
import type { ILinkedHotel } from './types';

export type LinkedHotelsState = { list: ILinkedHotel[] };

const initialState: LinkedHotelsState = {
  list: [],
};

export const name = 'linkedHotels';

const linkedHotelsSlice = createSlice({
  name,
  initialState,
  reducers: {},
});

export default linkedHotelsSlice;
