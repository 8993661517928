import { isSSR } from '../../helpers/ssr';
import { sendCommonEvents } from '../../helpers/sendCommonEvents';

export const checkGoogleTranslateAttributes = (mutations: MutationRecord[]) => {
  const classMutation = mutations.find((m) => m.attributeName === 'class');
  const langMutation = mutations.find((m) => m.attributeName === 'lang');

  const targetForClass = classMutation?.target as HTMLElement;
  const targetForLang = langMutation?.target as HTMLElement;

  if (
    targetForClass &&
    targetForLang &&
    (targetForClass.classList.contains('translated-ltr') ||
      targetForClass.classList.contains('translated-rtl'))
  )
    sendCommonEvents('google_translate', targetForLang.lang);
};

export const reportGoogleTranslate = () => {
  if (isSSR) {
    return;
  }

  const translateObserver = new MutationObserver(
    checkGoogleTranslateAttributes,
  );
  const target = document.getElementsByTagName('html')[0];

  translateObserver.observe(target, { attributes: true });
};
