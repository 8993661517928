import { MobilePopupBottom, ResponsiveModal } from '@lt/components';
import React, { useEffect, useMemo, useState } from 'react';
import { ChooseRating as ChooseRatingComponent } from './components/ChooseRating';
import { ExpandedForm as ExpandedFormComponent } from './components/ExpandedForm';
import NpsSkeleton from './components/NpsSkeleton';
import NpsError from './components/NpsError';
import { GoodbyeMessage as GoodbyeMessageComponent } from './components/GoodbyeMessage';
import { pushNpsClosed, pushNpsSent } from './analytics';

export enum NpsState {
  ChooseRating,
  ExpandedForm,
  Loading,
  GoodbyeMessage,
  Error,
  Hide,
}

export interface NpsProps {
  isMobile: boolean;
  npsState: Exclude<NpsState, NpsState.Hide>;
  setNpsState: (value: NpsState) => void;
  isAlreadyRated: boolean;
  errorOccured?: boolean;
  submitNps: (values: { score: number; comment: string }) => void;
  onExit: (values: { score: number; comment: string }) => void;
  retry: () => void;
  ratedScore: number | null;
}

const NpsForm = (props: NpsProps) => {
  const {
    isMobile,
    npsState,
    setNpsState,
    isAlreadyRated,
    errorOccured,
    submitNps,
    retry,
    ratedScore,
    onExit,
  } = props;
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');
  // Чтобы форма не сабмитилась второй раз при закрытии попапа
  const [isSubmitted, setIsSubmitted] = useState(false);
  useEffect(() => {
    if (ratedScore) setRating(ratedScore);
  }, [ratedScore]);
  const onRatingSelected = (chosenRating: number) => {
    setNpsState(NpsState.ExpandedForm);
    setRating(chosenRating);
  };
  const onSubmit = (commentFromForm: string) => {
    pushNpsSent();
    submitNps({ score: rating, comment: commentFromForm });
    setIsSubmitted(true);
  };
  const handleClosePopup = () => {
    setNpsState(NpsState.Hide);
    pushNpsClosed();
    // Если пользователь закрывает попап, то отправляем то
    // что пользователь успел ввести
    if (!isSubmitted && rating) onExit({ score: rating, comment });
  };
  const content = useMemo(() => {
    switch (npsState) {
      case NpsState.ChooseRating:
        return (
          <ChooseRatingComponent
            isMobile={isMobile}
            selectRating={onRatingSelected}
          />
        );
      case NpsState.ExpandedForm:
        return (
          <ExpandedFormComponent
            submitForm={onSubmit}
            isMobile={isMobile}
            rating={rating}
            errorOccured={errorOccured}
            setComment={setComment}
          />
        );
      case NpsState.Loading:
        return <NpsSkeleton isMobile={isMobile} />;
      case NpsState.Error:
        return <NpsError isMobile={isMobile} onReload={retry} />;
      case NpsState.GoodbyeMessage:
        return (
          <GoodbyeMessageComponent
            isAlreadyRated={isAlreadyRated}
            isMobile={isMobile}
            rating={rating}
          />
        );

      default: {
        // Чтобы если добавился новый стейт, разработчик
        // сразу же заметил отсутствие соответствующего UI на этапе комплиляции TS
        const check: never = npsState;
        throw new Error(`Unknown nps state: ${check}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorOccured, isAlreadyRated, isMobile, npsState, rating]);
  return (
    <>
      {isMobile ? (
        <MobilePopupBottom
          visible
          options={{ showCloseIcon: npsState !== NpsState.Loading }}
          onClick={handleClosePopup}
        >
          {content}
        </MobilePopupBottom>
      ) : (
        <ResponsiveModal
          center
          open
          onClose={handleClosePopup}
          showCloseIcon={npsState !== NpsState.Loading}
          styles={{ modal: { minWidth: 632, borderRadius: 4 } }}
        >
          {content}
        </ResponsiveModal>
      )}
    </>
  );
};

export default NpsForm;
