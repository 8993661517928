import { captureException } from '@sentry/browser';
import { IS_REPORT_ERROR_TO_SENTRY } from '../config/sentry/runTimeConfig';

export const logErrorToSentry = (err: Error, extra: unknown) => {
  if (IS_REPORT_ERROR_TO_SENTRY) {
    captureException(err, (scope) => {
      scope.setLevel('error');

      scope.setExtra('extra', JSON.stringify(extra));

      return scope;
    });
  } else {
    // eslint-disable-next-line no-console
    console.error(err);
  }
};

export const logErrorToSentryDTOError = (err: unknown) => {
  if (err instanceof Error && err.name === 'DTOError') {
    // @ts-ignore
    // тут надо подумать как типизировать (так как DTOError есть и в open-api)
    const message = error?.resultingValue;
    logErrorToSentry(err, message);
  }
};
