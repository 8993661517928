export const CIGARETTE_DESKTOP_PREVIEW_TEST_ID = 'cigarette-desktop-preview';
export const CIGARETTE_WIZARD_PREVIEW_TEST_ID = 'cigarette-wizard-preview';

export const DESTINATION_PICKER_PREVIEW_TEST_ID =
  'cigarette-destination-picker-value-preview';
export const DATE_PICKER_PREVIEW_TEST_ID =
  'cigarette-date-picker-value-preview';
export const TOURISTS_PICKER_PREVIEW_TEST_ID =
  'cigarette-tourists-picker-value-preview';
export const DEPARTURE_PICKER_PREVIEW_TEST_ID =
  'cigarette-departure-picker-value-preview';
export const SUBMIT_BUTTON_PREVIEW_TEST_ID = 'cigarette-submit-button-preview';

export const CIGARETTE_CONTROL_PREVIEW_TEST_IDS = {
  destination: DESTINATION_PICKER_PREVIEW_TEST_ID,
  datepicker: DATE_PICKER_PREVIEW_TEST_ID,
  tourists: TOURISTS_PICKER_PREVIEW_TEST_ID,
  departure: DEPARTURE_PICKER_PREVIEW_TEST_ID,
  submit: SUBMIT_BUTTON_PREVIEW_TEST_ID,
};

export const CIGARETTE_SKELETON_TEST_ID = 'cigarette-skeleton';
