import React, { useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';

import { Link, Portal } from '@lt/components';
import { Trans, useTranslation } from '@lt/localization';
import { useShowBannerController } from 'src/hooks/useShowBannerController';
import {
  BUTTON_DATA_TEST_ID,
  COOKIE_AGREEMENT,
  DATA_TEST_ID,
  RECOMENDATION_TECHNOLOGIES_AGREEMENT,
  KEY_COOKIE,
} from './constants';

interface CookiesBannerProps {
  isMobile: boolean;
}
export const CookiesBanner = ({
  isMobile,
}: CookiesBannerProps): JSX.Element | null => {
  const { t } = useTranslation();
  const [isShown, setIsShown] = useState(false);
  const [isAnimationGoing, setIsAnimationGoing] = useState(false);

  // Отображаем баннер только через 4 секунды после рендера (просьба продактов)
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (!isShown) setIsShown(true);
    }, 4000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isShown]);

  const { isShowBanner, handleCloseBanner } = useShowBannerController(
    KEY_COOKIE,
    365,
  );

  const closeBanner = () => {
    setTimeout(() => {
      handleCloseBanner();
    }, 300);
    setIsAnimationGoing(true);
  };

  if (!isShowBanner || !isShown) {
    return null;
  }

  return (
    <Portal>
      <Container
        data-testid={DATA_TEST_ID}
        $isMobile={isMobile}
        $isAnimationGoing={isAnimationGoing}
      >
        <Content $isMobile={isMobile}>
          <div>
            <Trans
              i18nKey="cookiesBanner.description"
              components={{
                FirstLink: <StyledLink href={COOKIE_AGREEMENT} />,
                SecondLink: (
                  <StyledLink href={RECOMENDATION_TECHNOLOGIES_AGREEMENT} />
                ),
              }}
            />
          </div>

          <StyledButton onClick={closeBanner} data-testid={BUTTON_DATA_TEST_ID}>
            {t('cookiesBanner.agreeButton')}
          </StyledButton>
        </Content>
      </Container>
    </Portal>
  );
};

const appearance = keyframes`
  from {
    opacity: 0;
    bottom: -80px;
  }
  to {
    opacity: 1;
    bottom: 8px;
  }
`;

const hiding = keyframes`
  from {
    opacity: 1;
    bottom: 8px;
  }
  to {
    opacity: 0;
    bottom: -80px;
  }
`;

const Container = styled.div<{
  $isAnimationGoing: boolean;
  $isMobile: boolean;
}>`
  position: fixed;
  bottom: 8px;
  left: 0;
  ${({ $isMobile }) =>
    $isMobile
      ? `
        left: 4px; 
        right: 4px;
      `
      : `
        width: 100vw;
      `}
  // ниже чем у сиги, выше остального
  z-index: 11;
  animation: ${appearance} 0.3s ease-in-out;
  ${({ $isAnimationGoing }) =>
    $isAnimationGoing &&
    css`
      animation: ${hiding} 0.3s ease-in-out;
    `};
`;

const Content = styled.div<{ $isMobile: boolean }>`
  margin: 0 auto;

  color: #c2c5cc;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;

  padding: 16px;
  border-radius: 8px;
  background: rgba(23, 24, 26, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ $isMobile }) =>
    $isMobile
      ? `
        gap: 8px;
      `
      : `
        max-width: 783px;
        gap: 16px;
    `}
`;

const StyledLink = styled(Link)`
  color: #fff;
  text-decoration-line: underline;
`;

const StyledButton = styled.button`
  border: 4px #007aff;
  background: #007aff;
  outline: none;
  padding: 9px 20px;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
`;
