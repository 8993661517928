import type { Scope } from '@sentry/browser';
import { setContext } from '@sentry/browser';

/** импорты необходимые для конфигурации */
import { isSSR } from 'src/helpers/ssr';
import { getLocalizationValues } from '@lt/localization/dist/utils/getValuesFromBackend';

const getHostname = (): string | undefined => {
  try {
    const url = new URL(window?.location?.href);
    return url?.hostname;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
  return undefined;
};

const getPage = (): string | undefined => {
  try {
    const url = new URL(window?.location?.href);
    // временный костыль, чтобы не встраивать пока в основной код
    return url?.pathname.split('/')[1].includes('-')
      ? url?.pathname.split('/')[2]
      : url?.pathname.split('/')[1];
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
  return undefined;
};

const getUUID = (): string | undefined => {
  try {
    const uuid = localStorage?.getItem('ccx-uuid');
    return uuid || undefined;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
  return undefined;
};

export const saturationScope = (scope: Scope) => {
  /** из-за того что не всегда бэк присылает корректно лучше пошаманить */
  const isMobile = Boolean(window?.is_mobile);
  const isWl = Boolean(window?.is_wl);
  const hostname = getHostname();
  const isWebView = Boolean(window?.is_webview);
  const page = getPage();
  const localization = getLocalizationValues();

  const configuration = {
    isMobile: !isSSR ? isMobile : undefined,
    isWl: !isSSR ? isWl : undefined,
    hostname: !isSSR ? hostname : undefined,
    isWebView: !isSSR ? isWebView : undefined,
    page: !isSSR ? page : undefined,
    country: localization.country,
    language: localization.language,
    currentCode: localization.currencyCode,
    isRussia: localization.country === 'ru',
  };

  /** отправялем контекст для понимания в какой среде падала страница */
  setContext('Configuration', configuration);

  const localStorage = window?.localStorage;
  const sessionStorage = window?.sessionStorage;
  const dataLayer = window?.dataLayer;
  const user = window?.current_client;
  const uuid = getUUID();

  // сбрасываем пользователя
  scope.setUser(null);

  scope.setTag('country', configuration.country);
  scope.setTag('language', configuration.language);
  scope.setTag('currentCode', configuration.currentCode);
  scope.setTag('hostname', configuration.hostname);
  scope.setTag('isMobile', configuration.isMobile);
  scope.setTag('isRussia', configuration.isRussia);
  scope.setTag('isWebView', configuration.isWebView);
  scope.setTag('isWl', configuration.isWl);
  scope.setTag('page', configuration.page);

  if (user) {
    scope.setUser({
      id: user.id ? String(user.id) : undefined,
      email: user.email ? user.email : undefined,
      username: user.name ? user.name : undefined,
    });
  } else if (uuid) {
    scope.setUser({ id: uuid });
  }

  scope.setExtra('localStorage', localStorage);
  scope.setExtra('sessionStorage', sessionStorage);
  scope.setExtra('dataLayer', dataLayer);
};
