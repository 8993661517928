import { connect } from 'react-redux';

import { getIsHideFeedback, menuSelector } from 'src/store/menu/selectors';
import {
  getIsHomepage,
  getIsMobile,
  getIsWhitelabel,
} from 'src/store/view/selectors';

import {
  logOutAction,
  onLoadAction,
  showAuthAction,
} from 'src/store/client/actions';
import HeaderMenu from './HeaderMenu';

const mapStateToProps = (state) => ({
  menu: menuSelector(state),
  isMobile: getIsMobile(state),
  isWhitelabel: getIsWhitelabel(state),
  isHideFeedback: getIsHideFeedback(state),
  isHomepage: getIsHomepage(state),
});

const mapDispatchToProps = {
  onLoad: onLoadAction,
  onShowAuthPopup: showAuthAction,
  onLogOut: logOutAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderMenu);
