import type { ReportDialogOptions, Scope } from '@sentry/browser';
import {
  captureException,
  getClient,
  showReportDialog,
  withScope,
} from '@sentry/browser';
import { isError } from '@sentry/utils';
import React from 'react';
import { saturationScope } from 'src/error/config/sentry/sentryScope.config';
import {
  IS_REPORT_ERROR_TO_SENTRY,
  IS_SHOW_REPORT_DIALOG,
} from '../../config/sentry/runTimeConfig';

type ErrorBoundaryState =
  | {
      componentStack: null;
      error: null;
      eventId: null;
    }
  | {
      componentStack: React.ErrorInfo['componentStack'];
      error: Error;
      eventId: string;
    };

export type FallbackRender = (errorData: {
  error: Error;
  componentStack: string | null;
  eventId: string | null;
  resetError(): void;
}) => React.ReactElement;

export type ErrorBoundaryProps = {
  /** отправлять ли ошибку в sentry */
  isReportErrorToSentry?: boolean;

  children?: React.ReactNode | (() => React.ReactNode);
  /** Показывать ли после ошибки форму репорта */
  showDialog?: boolean;
  /**
   * Настройки для диалогового окна
   * Не сработает если {@link showDialog} принимает значение false.
   */
  dialogOptions?: Omit<ReportDialogOptions, 'eventId'>;
  /**
   * fallback вызывается после того как произошла ошибка
   */
  fallback?: React.ReactElement | FallbackRender;
  /** Вызовется когда errorBoundary обрабатывает ошибку */
  onError?(error: Error, componentStack: string, eventId: string): void;
  /** Вызовется в методе жизненного цикла componentDidMount() */
  onMount?: () => void;
  onReset?: (
    error: Error | null,
    componentStack: string | null,
    eventId: string | null,
  ) => void;
  /** Вызовется в методе жизненного цикл componentWillUnmount() */
  onUnmount?: (
    error: Error | null,
    componentStack: string | null,
    eventId: string | null,
  ) => void;
  /** Вызывается до того, как ошибка будет отправлена в Sentry
   * позволяет добавлять теги или контекст, используя scope
   */
  beforeCapture?: (
    scope: Scope,
    error: Error | null,
    componentStack: string | null,
  ) => void;
};

const INITIAL_STATE = {
  componentStack: null,
  error: null,
  eventId: null,
};

// функция для определения больше ли версия React чем 17
export const isAtLeastReact17 = (version: string): boolean => {
  const major = version.match(/^([^.]+)/);
  return major !== null && parseInt(major[0], 10) >= 17;
};

const setCause = (error: Error & { cause?: Error }, cause: Error): void => {
  const seenErrors = new WeakMap<Error, boolean>();

  function recurse(
    closureError: Error & { cause?: Error },
    closureCause: Error,
  ): void {
    // Если мы уже видели ошибку, то где-то в цепочке причин ошибки есть рекурсивный цикл
    // Давайте просто выйдем из системы, чтобы предотвратить переполнение стека.
    if (seenErrors.has(closureError)) {
      return;
    }
    if (closureError.cause) {
      seenErrors.set(closureError, true);
      recurse(closureError.cause as Error & { cause?: Error }, closureCause);
      return;
    }
    error.cause = cause;
  }

  recurse(error, cause);
};

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  private readonly _openFallbackReportDialog: boolean;

  private _lastEventId?: string;

  public constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = INITIAL_STATE;
    this._openFallbackReportDialog = true;

    try {
      const client = getClient();
      if (client && client.on && props.showDialog) {
        this._openFallbackReportDialog = false;
        client.on('afterSendEvent', (event) => {
          if (!event.type && event.event_id === this._lastEventId) {
            showReportDialog({
              ...props.dialogOptions,
              eventId: this._lastEventId,
            });
          }
        });
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }

  public componentDidMount(): void {
    const { onMount } = this.props;
    if (onMount) {
      onMount();
    }
  }

  public componentDidCatch(
    error: Error & { cause?: Error },
    { componentStack }: React.ErrorInfo,
  ): void {
    const { isReportErrorToSentry = IS_REPORT_ERROR_TO_SENTRY } = this.props;
    if (isReportErrorToSentry) {
      this.sendErrorSentry(error, componentStack || null);
    }
    this.setState({ error: error || null, componentStack, eventId: null });
  }

  public componentWillUnmount(): void {
    const { error, componentStack, eventId } = this.state;
    const { onUnmount } = this.props;
    if (onUnmount) {
      onUnmount(error, componentStack || null, eventId);
    }
  }

  private sendErrorSentry = (
    error: Error & { cause?: Error },
    componentStack: React.ErrorInfo['componentStack'],
  ): void => {
    const {
      beforeCapture = saturationScope,
      onError,
      showDialog = IS_SHOW_REPORT_DIALOG,
      dialogOptions,
    } = this.props;

    withScope((scope) => {
      scope.setLevel('fatal');

      if (isAtLeastReact17(React.version) && isError(error)) {
        const errorBoundaryError = new Error(error.message);
        errorBoundaryError.name = `React ErrorBoundary ${error.name}`;
        if (componentStack) {
          errorBoundaryError.stack = componentStack;
        }

        // Используем `LinkedErrors` чтобы связать ошибки вместе.
        setCause(error, errorBoundaryError);
      }

      if (beforeCapture) {
        beforeCapture(scope, error, componentStack || null);
      }

      const eventId = captureException(error, {
        captureContext: {
          contexts: { react: { componentStack } },
        },
        mechanism: { handled: false },
      });

      if (onError && componentStack) {
        onError(error, componentStack, eventId);
      }

      if (showDialog) {
        this._lastEventId = eventId;
        if (this._openFallbackReportDialog) {
          showReportDialog({ ...dialogOptions, eventId });
        }
      }

      // componentDidCatch используется вместо getDerivedStateFromError
      // так что componentStack доступен через state
      this.setState({ error: error || null, componentStack, eventId });
    });
  };

  public resetErrorBoundary: () => void = () => {
    const { onReset } = this.props;
    const { error, componentStack, eventId } = this.state;
    if (onReset) {
      onReset(error, componentStack || null, eventId);
    }
    this.setState(INITIAL_STATE);
  };

  public render(): React.ReactNode {
    const { fallback, children } = this.props;
    const { error, componentStack, eventId } = this.state;

    if (error) {
      const element =
        typeof fallback === 'function'
          ? fallback({
              error,
              componentStack: String(componentStack),
              resetError: this.resetErrorBoundary,
              eventId,
            })
          : fallback;

      if (React.isValidElement(element)) {
        return element;
      }

      return null;
    }

    if (typeof children === 'function') {
      return (children as () => React.ReactNode)();
    }
    return children;
  }
}
