import { logger } from 'src/error';
import { notEmpty } from '../../utils/notEmpty';

import type {
  PopularMetaregionsOffersState,
  IPopularMetaregionsOffer,
  IPopularRegion,
} from './types';

export const popularMetaregionsOffersDTO = (
  data: unknown,
): PopularMetaregionsOffersState => {
  if (!data || !Array.isArray(data)) return { list: [] };

  return {
    list: data
      .map((offer) => popularMetaregionOfferDTO(offer))
      .filter(notEmpty),
  };
};

const popularMetaregionOfferDTO = (
  data: unknown,
): IPopularMetaregionsOffer | null => {
  if (
    !data ||
    typeof data !== 'object' ||
    !('title' in data) ||
    typeof data.title !== 'string' ||
    !('link' in data) ||
    typeof data.link !== 'string' ||
    !('image_url' in data) ||
    typeof data.image_url !== 'string' ||
    !('regions' in data) ||
    !Array.isArray(data.regions)
  ) {
    logger('popularMetaregionOfferDTO', data);
    return null;
  }

  return {
    title: data.title,
    link: data.link,
    imageUrl: data.image_url,
    regions: data.regions
      .map((region) => popularRegionDTO(region))
      .filter(notEmpty),
  };
};

const popularRegionDTO = (data: unknown): IPopularRegion | null => {
  if (
    !data ||
    typeof data !== 'object' ||
    !('title' in data) ||
    typeof data.title !== 'string' ||
    !('link' in data) ||
    typeof data.link !== 'string'
  ) {
    logger('popularRegionDTO', data);
    return null;
  }

  return {
    title: data.title,
    link: data.link,
  };
};
