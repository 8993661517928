import React from 'react';

import type { IPopularDestinationsOfferWithPrice } from 'src/routes/Home/store/popularDestinationsOffers/types';

import classnames from 'classnames';
import stylesCard from '../../../../styles/card.module.css';
import CountryBackgroundImage from '../../../../CountryBackgroundImage';
import { CardHeadingBlock } from './CardHeadingBlock';
import { CardLabels } from './CardLabels';

import styles from './styles/countryWithPrices.module.css';

interface ICountryWithPrices extends IPopularDestinationsOfferWithPrice {
  isMobile: boolean;
}

export const CountryWithPrices = (props: ICountryWithPrices) => {
  const { isMobile, adults, link, minPrice, nights, place, regions, imageUrl } =
    props;

  if (!minPrice) {
    return null;
  }

  return (
    <div
      className={classnames(
        stylesCard.card,
        stylesCard.cardCountriesWithPricesVariant,
        stylesCard.shadow,
        {
          [stylesCard.desktop]: !isMobile,
          [stylesCard.cardCountriesWithPricesVariantMobile]: isMobile,
        },
      )}
    >
      <a className={styles.link} href={`${link}?ref_content=main_page`}>
        <CountryBackgroundImage src={imageUrl} />
        <div className={styles.countryContentWrapper}>
          <CardHeadingBlock
            place={place}
            minPrice={minPrice}
            nights={nights}
            adults={adults}
          />
          <div className={styles.labelsContainer}>
            <CardLabels regions={regions} />
          </div>
        </div>
      </a>
    </div>
  );
};
