import { createAction } from '@reduxjs/toolkit';

import type { Values } from '@lt/widget/components/Cigarette/context';
import type { SearchType } from 'src/types/search';
import { SUBMIT_CIGARETTE, SWITCH_TAB } from './constants';

export const switchTabAction = createAction<{
  searchType: SearchType;
  url: string;
  cigaretteType: string;
  isMobile: boolean;
}>(SWITCH_TAB);

export const submitCigaretteAction = createAction<{
  values: Values;
  searchType: SearchType;
}>(SUBMIT_CIGARETTE);
