import { connect } from 'react-redux';
import { getShortLink } from 'src/store/appBanners/selectors';
import {
  getDeeplinkAction,
  moveToAppContinuedWeb,
  moveToAppOpenedApp,
  moveToAppShown,
} from 'src/store/appBanners/slice';
import { getIsWhitelabel, getMobilePlatform } from 'src/store/view/selectors';
import { MobileAppPopupContainer } from './MobileAppPopupContainer';

const mapStateToProps = (state) => ({
  isWhiteLabel: getIsWhitelabel(state),
  linkToApp: getShortLink(state),
  platform: getMobilePlatform(state),
});

const mapDispatchToProps = {
  getDeeplink: getDeeplinkAction,
  moveToAppOpenedAppAction: moveToAppOpenedApp,
  moveToAppShownAction: moveToAppShown,
  moveToAppContinuedWeb,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(MobileAppPopupContainer);
