import type { FunctionComponent, ComponentClass } from 'react';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { ServerStyleSheet } from 'styled-components';

export const generateHTML = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: string | FunctionComponent<any> | ComponentClass<any, any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props: any,
): {
  css: string;
  html: string;
} => {
  const sheet = new ServerStyleSheet();
  const element = React.createElement(component, props);
  const html = ReactDOMServer.renderToString(sheet.collectStyles(element));
  const css = sheet.getStyleTags();
  return {
    css,
    html,
  };
};
