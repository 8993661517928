export const DEV_URL_SUFFIX = '/new';

export const COMPONENT_ID = {
  GALLERY: 'lt-hotel-gallery',
  SEARCHER: 'lt-hotel-searcher',
  HOTEL_FACTS: 'lt-hotel-facts',
  MAP: 'lt-hotel-map',
  EXPERIENCE: 'lt-hotel-experience',
  FACILITIES: 'lt-hotel-facilities',
  REVIEWS: 'lt-hotel-reviews',
  TRUST_YOU: 'lt-hotel-trust-you',
};
export const MENU_ITEMS_ID = {
  GALLERY: COMPONENT_ID.GALLERY,
  SEARCHER: COMPONENT_ID.SEARCHER,
  HOTEL_FACTS: COMPONENT_ID.HOTEL_FACTS,
  MAP: COMPONENT_ID.MAP,
  EXPERIENCE: COMPONENT_ID.EXPERIENCE,
  FACILITIES: COMPONENT_ID.FACILITIES,
  REVIEWS: COMPONENT_ID.REVIEWS,
};

export const REVEIW_MAX_HEIGHT = 100;
export const REVEIW_MAX_CONTENT_LENGTH = 600;

export const REVEIW_MAX_HEIGHT_MOBILE = 85;
export const REVEIW_MAX_CONTENT_LENGTH_MOBILE = 300;

export const RECENT_REVEIW_MAX_HEIGHT = 85;
export const RECENT_REVEIW_MAX_CONTENT_LENGTH = 300;

export const HOTEL_MOBILE_PAGES = {
  MAP: 'map',
  REVIEWS: 'reviews',
  SEARCHER: 'searcher',
};

export const HOTEL_SEARCHER_FIXED_BUTTON_HEIGHT = 88;
export const TOP_MENU_OFFSET = 50;
export const HOTEL_SEARCHER_REPEAT_DELAY = (() => {
  const time = 30 * 60 * 1000; // 30 minutes
  try {
    return (
      (localStorage &&
        Number(localStorage.getItem('HOTEL_SEARCHER_REPEAT_DELAY'))) ||
      time
    );
  } catch (error) {
    return time;
  }
})();

export const COUNTRY_RUSSIA = {
  id: 225,
  name_ru: 'Россия',
  name_en: 'Russia',
  iso2: 'RU',
};
export const HINT_ROOM_FACILITIES_NAME = 'hint-room-facilities';
