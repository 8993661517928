export const FacilitiesIcons = {
  internet: [67, 103, 135, 137],
  wifi: [21, 29, 30],
  snowflake: [1, 110, 47],
  fan: [9, 46],
  strongbox: [3, 11, 33],
  tv: [138, 76, 79, 80],
} as const;

export const AVERAGE_TRANSFER_SPEED = 60;
