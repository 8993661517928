import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { getAppName, getIsHomepage } from 'src/store/view/selectors';
import {
  addWishlistItem,
  destroyWishlistAction,
  removeWishlistItem,
} from 'src/store/wishlist/actions';
import { getIsAdmin, getIsLoggedIn } from 'src/store/client/selectors';

import { getRequestId } from 'src/routes/SearchPage/selectors/searchParams';
import { MODULE_NAME } from 'src/constants';
import {
  getWishlistIdSelector,
  getWishlistItemsCounterSelector,
  getWishlistItemsSelector,
  isSearchInWishlistSelector,
} from '../../../../store/wishlist/selectors';

import HeaderWishlist from './HeaderWishlist';

const mapStateToProps = (state) => ({
  items: getWishlistItemsSelector(state),
  isAdmin: getIsAdmin(state),
  wishlistId: getWishlistIdSelector(state),
  counter: getWishlistItemsCounterSelector(state),
  appName: getAppName(state),
  isHomepage: getIsHomepage(state),
  isSearchInWishlist: isSearchInWishlistSelector,
  /**
   * Селектор падает, если нет в сторе enqueue, поэтому проверяем на страницу
   */
  requestId:
    (getAppName(state) === MODULE_NAME.SEARCH && getRequestId(state)) ||
    undefined,
  isLoggedIn: getIsLoggedIn(state),
});

const mapDispatchToProps = {
  addToWishlist: addWishlistItem,
  onDestroyWishlist: destroyWishlistAction,
  onRemoveWishlistItem: removeWishlistItem,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(HeaderWishlist);
