import React from 'react';
import classnames from 'classnames';

import { Loupe, ResentlyBuy, Seaview } from '@lt/components/icons-react';
import DynamicDataBlock from '../DynamicDataBlock';

import type { DynamicDataArrayProps } from './types';
import styles from './dynamicDataArray.module.css';

const DynamicDataArray = ({
  latestOrder,
  hotPriceByCountry,
  foundToursToday,
  isMobile,
  isLoading = false,
  isWhitelabel = false,
}: DynamicDataArrayProps): JSX.Element => (
  <div
    className={classnames(styles.container, {
      [styles.containerDesktop]: !isMobile,
      [styles.containerDesktopWhitelabel]: isWhitelabel,
    })}
  >
    <DynamicDataBlock
      icon={<Loupe className={styles.loupe} />}
      text={`Найдено туров за сутки\n${Number(foundToursToday).toLocaleString(
        'ru',
      )}`}
      isLoading={!foundToursToday && isLoading}
      isMobile={isMobile}
      order={isMobile ? 1 : 3}
    />
    <DynamicDataBlock
      icon={<ResentlyBuy className={styles.resentlyBuy} />}
      text={
        latestOrder ? `${latestOrder.title}\n${latestOrder.descriptions}` : ''
      }
      isLoading={!latestOrder && isLoading}
      isMobile={isMobile}
      order={isMobile ? 2 : 1}
    />
    <DynamicDataBlock
      icon={<Seaview className={styles.seaview} />}
      text={
        hotPriceByCountry
          ? `Самые дешевые туры\n ${hotPriceByCountry.name} от ${Number(
              hotPriceByCountry.price,
            ).toLocaleString('ru')} ₽/чел.`
          : ''
      }
      isLoading={!hotPriceByCountry && isLoading}
      isMobile={isMobile}
      order={isMobile ? 3 : 2}
    />
  </div>
);

export default DynamicDataArray;
