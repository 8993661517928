import { createSlice } from '@reduxjs/toolkit';
import { changeSearchParamsAction } from 'src/routes/SearchPage/actions/search';
import type { HeaderWidgetStateType } from './types';

const initialState: HeaderWidgetStateType = {
  showPopup: false,
};

export const headerWidgetSlice = createSlice({
  name: 'headerWidget',
  initialState,
  reducers: {
    showHeaderWidget(state) {
      state.showPopup = true;
    },
    hideHeaderWidget(state) {
      state.showPopup = false;
    },
  },
  /**
   * Нужно убрать данную сильную связность когда будем разгребать сторы
   */
  extraReducers: (builder) => {
    builder.addCase(changeSearchParamsAction, (state) => ({
      ...state,
      showPopup: false,
    }));
  },
});

export default headerWidgetSlice.reducer;
