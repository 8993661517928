import { createSelector } from '@reduxjs/toolkit';
import { find } from 'lodash';

// Selectors
// eslint-disable-next-line import/no-cycle
import { altExtrasByTourIdSelector } from 'checkout/CheckoutRoutes/PackageCheckout/store/actualize-alternatives';
import { cheapestFlightTourIdSelector } from 'checkout/CheckoutRoutes/PackageCheckout/store/flights/selectors';
import { countTotalExtrasPrice } from '../../utils/orderUtils';
import { getOrderExtras } from './order';

// Constants
import { EXTRA_SCOPES } from '../constants/extras';

// Types
import type { ServicesStateType } from '../services/types';

export const getOriginalPackageExtras = (state): ServicesStateType =>
  state.services;

export const extrasByTourIdSelector = createSelector(
  [
    getOriginalPackageExtras,
    (state, tour_id: string) => altExtrasByTourIdSelector(state, tour_id),
  ],
  (originalPackageExtras, altPackageExtras) =>
    altPackageExtras || originalPackageExtras,
);

export const getMandatoryExtras = createSelector(
  [(state, tour_id: string) => extrasByTourIdSelector(state, tour_id)],
  (extras) => extras.filter((extra) => extra.mandatory && extra.selected),
);

const getExtrasByScope = (scope: string) =>
  createSelector(getOriginalPackageExtras, (extras) =>
    extras.filter((extra) => extra.scope === scope),
  );

export const getLtExtras = getExtrasByScope(EXTRA_SCOPES.LEVEL_TRAVEL);
export const getTourExtras = getExtrasByScope(EXTRA_SCOPES.TOUR);
export const getFlightExtras = getExtrasByScope(EXTRA_SCOPES.FLIGHT);

export const getCheapestMandatoryExtras = createSelector(
  [
    cheapestFlightTourIdSelector,
    (state) => (tour_id: string) => getMandatoryExtras(state, tour_id),
  ],
  (cheapestTourId, getExtrasByTourId) =>
    cheapestTourId
      ? getExtrasByTourId(cheapestTourId)?.filter(
          (extra) => extra.mandatory && extra.selected,
        )
      : [],
);

export const origPackageMandatoryExtrasSelector = createSelector(
  [getOriginalPackageExtras],
  (extras) => extras.filter((extra) => extra.mandatory && extra.selected),
);

export const origPackageMandatoryExtrasPriceSelector = createSelector(
  [origPackageMandatoryExtrasSelector],
  (extras) => extras.reduce((res, extra) => res + (extra.price || 0), 0),
);

// Mandatory extras without LT-extras
export const mandatoryTOExtrasSelector = createSelector(
  getMandatoryExtras,
  (extras) =>
    extras.filter((extra) => extra.scope !== EXTRA_SCOPES.LEVEL_TRAVEL),
);

export const cheapestMandatoryTOExtrasSelector = createSelector(
  getCheapestMandatoryExtras,
  (extras) =>
    extras.filter((extra) => extra.scope !== EXTRA_SCOPES.LEVEL_TRAVEL),
);

export const cheapestTOExtrasTotalPriceSelector = createSelector(
  cheapestMandatoryTOExtrasSelector,
  (extras) => extras?.reduce((res, extra) => res + (extra.price || 0), 0),
);

export const originalSelectedExtrasSelector = createSelector(
  [getOriginalPackageExtras, getOrderExtras],
  (extras, orderExtras) =>
    extras.filter(
      (extra) =>
        extra.selected ||
        find(
          orderExtras,
          (orderExtra) => orderExtra.code_name === extra.code_name,
        ),
    ),
);

export const origPackageTOExtrasPriceSelector = createSelector(
  getOriginalPackageExtras,
  (extras) =>
    countTotalExtrasPrice(
      extras.filter((extra) => extra.scope !== EXTRA_SCOPES.LEVEL_TRAVEL),
    ),
);
