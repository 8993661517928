import { dynamic } from 'src/helpers/dynamic';

const DynamicDesktopAppBanner = dynamic(
  () => import(/* webpackChunkName: "DesktopAppBanner" */ '.'),
  {
    showErrorBoundary: true,
    errorBoundaryProps: {
      showDialog: false,
    },
  },
);

export default DynamicDesktopAppBanner;
