import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { wishlistResponse } from '@lt/api/dist/methods/wishlist/types';
import type { WishlistStore } from './types';

export const initialState: WishlistStore = {
  list: [],
  id: null,
  isWishlistFetched: false,
  filters: { countries: [] },
};
const wishlistSlice = createSlice({
  name: 'wishlist',
  initialState,
  reducers: {
    setWishlistId: (state, action: PayloadAction<string>) => {
      state.id = action.payload;
    },
    updateWishlist: (state, action: PayloadAction<wishlistResponse>) => {
      state.id = action.payload.id;
      state.list = action.payload.wishlistItems;
    },
    updateWishlistWithFilters: (
      state,
      action: PayloadAction<wishlistResponse>,
    ) => {
      state.id = action.payload.id;
      state.list = action.payload.wishlistItems;
      state.filters = action.payload.filters;
    },
    setWishlistFetched: (state) => {
      state.isWishlistFetched = true;
    },
    showWishlistOnboarding: (state, action: PayloadAction<boolean>) => {
      state.showOnboarding = action.payload;
    },
  },
});

export const { name } = wishlistSlice;
export const {
  updateWishlist,
  setWishlistId,
  setWishlistFetched,
  showWishlistOnboarding,
  updateWishlistWithFilters,
} = wishlistSlice.actions;
export default wishlistSlice.reducer;
