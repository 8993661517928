import React from 'react';

import { useTranslation } from '@lt/localization';
import { CurrencyFormat } from '@lt/components';
import {
  pluralizeNights,
  pluralizeAdults,
} from '@lt/components/utils/pluralizeUtils';

import type { IPopularDestinationsOfferWithPrice } from 'src/routes/Home/store/popularDestinationsOffers/types';
import styles from './styles/cardHeadingBlock.module.css';

type ICardHeadingBlock = Omit<
  IPopularDestinationsOfferWithPrice,
  'regions' | 'link' | 'imageUrl'
>;

export const CardHeadingBlock = (props: ICardHeadingBlock) => {
  const { place, minPrice, nights, adults } = props;

  const { t } = useTranslation();

  const secondRowText = `${nights} ${pluralizeNights(
    nights,
  )}, ${adults} ${pluralizeAdults(adults)}`;

  return (
    <div className={styles.contentHeadingContainer}>
      <div className={styles.contentHeadingFirstRow}>
        <div className={styles.countryName}>{place}</div>
        <CurrencyFormat
          value={minPrice}
          prefix={`${t('filters.prices.from')} `}
        />
      </div>
      <div className={styles.contentHeadingSecondRow}>{secondRowText}</div>
    </div>
  );
};
