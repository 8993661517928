import Cookies from 'js-cookie';
import { put, takeLatest, select } from 'redux-saga/effects';
import { api } from 'src/api';
import { addDays, format } from 'date-fns';
import type { SagaReturnType } from 'redux-saga/effects';
import { getIsMobile } from '../view/selectors';

import { GET_DEEPLINK, UTM_KEY_COOKIE } from './constants';
import { setShortLink } from './slice';
import { parseUTMParams, transformUTMKey } from './utils';

function* handleGetDeeplink() {
  try {
    const currentUrlParams = new URLSearchParams(window.location.search);
    const nonMarketingParams = new URLSearchParams();
    // удаляем query-параметры aflt и utm
    [...currentUrlParams.keys()]
      .filter((param) => param !== 'aflt' && !param.includes('utm'))
      .forEach((param) =>
        nonMarketingParams.append(param, currentUrlParams.get(param) || ''),
      );
    const ltDomen = 'https://level.travel';
    const preparingUrl = `${ltDomen}${window.location.pathname}`;
    const newQueryParams = new URLSearchParams();

    // preparingUrl для fallback_url
    const { origin } = window.location;
    const preparingUrlFallback = `${origin}${window.location.pathname}`;

    // добавляем метки маркетинга из Cookies
    const cookieUtmValue = Cookies.get(UTM_KEY_COOKIE);
    if (cookieUtmValue) {
      parseUTMParams(cookieUtmValue)?.forEach((utmParam) => {
        const [key, value] = utmParam.split('=');
        const transformedKey = transformUTMKey(key);
        newQueryParams.append(transformedKey, value);
      });
    }

    // добавляем метку партнера из Cookie
    const afltLink = Cookies.get('last_cookie_link');
    if (afltLink) {
      newQueryParams.append(
        'aflt',
        new URLSearchParams(new URL(afltLink).search).get('aflt') || '',
      );
      newQueryParams.append(
        'aflt_expires_at',
        format(addDays(Date.now(), 30), 'yy-MM-dd'),
      );
    }

    // добавляем признак баннера
    const isMobile: SagaReturnType<typeof getIsMobile> = yield select(
      getIsMobile,
    );
    const bannerType = isMobile ? 'banner_mobweb' : 'banner_desktop';
    newQueryParams.append('utm_referrer', bannerType);

    // Объединяем обычный query-параметры с маркетинговыми
    const url = new URL(
      `${preparingUrl}?${
        nonMarketingParams.entries.length
          ? `${nonMarketingParams.toString()}&`
          : ''
      }${newQueryParams.toString()}`,
    );

    // Объединяем обычный query-параметры с маркетинговыми для fallback_url
    const fallback_url = new URL(
      `${preparingUrlFallback}?${
        nonMarketingParams.entries.length
          ? `${nonMarketingParams.toString()}&`
          : ''
      }${newQueryParams.toString()}`,
    );

    const response = yield api.postDeeplinkGenerator({
      url: url.href,
      fallback_url: fallback_url.href,
      temporary: true,
    });
    const { shortUrl } = response;
    yield put(setShortLink(shortUrl));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
}

export default function* appBannersSaga() {
  yield takeLatest(GET_DEEPLINK, handleGetDeeplink);
}
