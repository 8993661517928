export const WISHLIST_ID = 'WISHLIST_ID';

export const WISHLIST_COUNTER_LIMIT = 99;

/**
 * Actions
 */
export const DESTROY_WISHLIST = 'DESTROY_WISHLIST';
export const COPY_WISHLIST_LINK = 'COPY_WISHLIST_LINK';
export const ADD_ITEM_TO_WISHLIST = 'ADD_ITEM_TO_WISHLIST';
export const REMOVE_ITEM_FROM_WISHLIST = 'REMOVE_ITEM_FROM_WISHLIST';
export const GET_WISHLIST_DATA = 'GET_WISHLIST_DATA';
export const HIDE_WISHLIST_ONBOARDING = 'HIDE_WISHLIST_ONBOARDING';
export const NEED_SHOW_WISHLIST_ONBOARDING = 'NEED_SHOW_WISHLIST_ONBOARDING';
