import { createSelector } from '@reduxjs/toolkit';

import { name } from './slice';
import type { PopularMetaregionsOffersState } from './types';

const popularMetaregionsOffersSelector = (
  state,
): PopularMetaregionsOffersState => state[name];

export const popularMetaregionsOffersListSelector = createSelector(
  popularMetaregionsOffersSelector,
  (offers) => offers.list,
);
