import React, {
  useState,
  useCallback,
  useMemo,
  useRef,
  useEffect,
} from 'react';
import { MobilePopupBottom, ResponsiveModal } from '@lt/components';

import { ITEMS } from '../../utils';
import type { TourGuideModalProps } from '../../types';

import { TourGuideDesktopModal } from './components/TourGuideDesktopModal';
import { TourGuideMobileModal } from './components/TourGuideMobileModal';
import {
  pushBannerClickedAfterPay,
  pushBannerViewed,
  pushBannerViewed2,
} from '../../analytics';

export const TourGuideModal = ({
  mode,
  isMobile,
  isVisible,
  onClose,
  isHotelOnly,
}: TourGuideModalProps) => {
  const items = useMemo(
    () =>
      mode === 'payment' ? ITEMS(isHotelOnly).slice(2) : ITEMS(isHotelOnly),
    [mode, isHotelOnly],
  );
  const [activeItem, setActiveItem] = useState(0);

  const isFirstMobileItemShownRef = useRef(false);

  useEffect(() => {
    if (mode === 'payment') {
      pushBannerClickedAfterPay();
    }
  }, [mode]);

  if (!isVisible && isMobile && isFirstMobileItemShownRef.current) {
    isFirstMobileItemShownRef.current = false;
  }

  if (
    !isFirstMobileItemShownRef.current &&
    isVisible &&
    isMobile &&
    activeItem === 0
  ) {
    pushBannerViewed(mode);

    isFirstMobileItemShownRef.current = true;
  }

  const onCloseClick = useCallback(() => {
    onClose();
    setActiveItem(0);
  }, [onClose]);

  const goToNext = () => {
    const nextItem = activeItem + 1;

    if (nextItem >= items.length) {
      onCloseClick();
      return;
    }

    pushBannerViewed2(mode, nextItem);

    setActiveItem(nextItem);
  };

  return isMobile ? (
    <MobilePopupBottom
      onClick={onCloseClick}
      visible={isVisible}
      options={{
        showCloseIcon: true,
      }}
    >
      <TourGuideMobileModal {...items[activeItem]} onClickButton={goToNext} />
    </MobilePopupBottom>
  ) : (
    <ResponsiveModal
      center
      open={!!isVisible}
      onClose={onCloseClick}
      styles={{ modal: { maxWidth: 700 } }}
    >
      <TourGuideDesktopModal mode={mode} items={items} />
    </ResponsiveModal>
  );
};
