import { useEffect, useState } from 'react';

export const useSearchParams = (names: string | string[]) => {
  const [params, setParams] = useState<any>({});

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    if (Array.isArray(names) && names.length) {
      const paramsList = names.reduce(
        (prev, curr) => ({
          ...prev,
          [curr]: searchParams.get(curr),
        }),
        {},
      );

      setParams(paramsList);
    } else if (typeof names === 'string') {
      const param = searchParams.get(names);
      setParams({ [names]: param });
    } else {
      setParams({});
    }
  }, [names]);

  return params;
};
