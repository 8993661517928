import React from 'react';
import classnames from 'classnames';

import type { IPopularDestinationsOfferDefault } from 'src/routes/Home/store/popularDestinationsOffers/types';

import CountryBackgroundImage from '../../../../CountryBackgroundImage';
import stylesCard from '../../../../styles/card.module.css';
import CardHeading from '../../../../CardHeading';
import CardButton from '../../../../CardButton';

import styles from './popularCountry.module.css';

interface IPopularCountry extends IPopularDestinationsOfferDefault {
  isFirst?: boolean;
  isMobile: boolean;
}

export const PopularCountry = (props: IPopularCountry) => {
  const { place, link, imageUrl, regions, isFirst = false, isMobile } = props;

  return (
    <div
      className={classnames(
        stylesCard.card,
        stylesCard.cardDefaultCountriesVariant,
        styles.countryContainer,
        stylesCard.shadow,
        {
          [stylesCard.desktop]: !isMobile,
          [stylesCard.cardDefaultCountriesVariantMobile]: isMobile,
          [styles.countryContainerFirst]: isFirst && !isMobile,
        },
      )}
    >
      <a className={styles.link} href={`${link}?ref_content=main_page`}>
        <CountryBackgroundImage src={imageUrl} />
        <div className={styles.countryContentWrapper}>
          <CardHeading
            text={place}
            url={link}
            isMobile={isMobile}
            hasMarginTop
          />
          <div className={styles.citiesContainer}>
            {regions.map((region) => (
              <CardButton
                text={region.place}
                url={region.link}
                isMobile={isMobile}
                key={`${place}_region_${region.place}`}
              />
            ))}
          </div>
        </div>
      </a>
    </div>
  );
};
