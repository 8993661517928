import type { StackFrame } from '@sentry/browser';
import { init, Integrations } from '@sentry/browser';

import {
  APPLICATION_VERSION,
  IS_REPORT_ERROR_TO_SENTRY,
  KEY,
  IS_REPORT_ONERROR,
  IS_TRANSACTION_AVAILABLE,
  IS_SESSION_REPLAY_AVAILABLE,
  IS_SESSION_REPLAY_FULL_SESSION,
} from './runTimeConfig';
import type { InitSentryType } from '../../types/sentryTypes';

// сюда пишем сервисы, которые не наши, чтобы не засорять Sentry чужими ошибками
const excludedElements: RegExp[] = [
  /gtm/i,
  /adfox/i,
  /yaCounter/i,
  /mindbox/i,
  /gtm/i,
  /yandex/i,
  /yastatic/i,
  /Tracker/i,
  /mindbox/i,
  /flocktory/i,
];

/**
 * функция, которая инициализирует sentry
 * сделано для быстрого отключения по фиче тк сентря бывает подхватывает лишние ошибки
 * не добавлять ничего лишнего пожалуйста (пусть останется только конфигурация)
 */
export const initSentry = ({
  dsn = KEY,
  release = APPLICATION_VERSION,
  environment = process.env.NODE_ENV,
  isReportOnError = IS_REPORT_ONERROR,
  isTransactionAvailable = IS_TRANSACTION_AVAILABLE,
  isReportErrorToSentry = IS_REPORT_ERROR_TO_SENTRY,
  isSessionReplayAvailable = IS_SESSION_REPLAY_AVAILABLE,
  isSessionReplayFullSession = IS_SESSION_REPLAY_FULL_SESSION,
  transport,
}: InitSentryType): void => {
  init({
    dsn,
    release,
    environment,
    transport,

    /** отправляем 100% ошибок, если хотим частично можно сделать 0.1 это 10%
     ** ДАННОЕ СВОЙСТВО НЕ МЕНЯТЬ ТК НАСТРОЙКА ПРОЦЕНТА ПРОИСХОДИТ ЧЕРЕЗ АДМИНКУ
     * @see https://level.travel/admin/features/12/edit
     */
    sampleRate: isReportErrorToSentry ? 1.0 : 0.0,

    /**
     * по переменной IS_REPORT_ONERROR отключаем дефолтное поведение sentry которое отсылает ошибки при развале любого скрипта
     */
    integrations: !isReportOnError
      ? [
          new Integrations.GlobalHandlers({
            // при развале любого скрипта (сейчас отключаю из-за gtm, mindbox, tracker и тд)
            onerror: false,
            // это пока вообще не из нашей вселенной (там трекеры и все такое)
            onunhandledrejection: false,
          }),
        ]
      : undefined,

    /**
     * Настройка для отправки транзакций. Принимает значение от 0 до 1 (0 это 0%, 1 это 100%)
     ** ДАННОЕ СВОЙСТВО НЕ МЕНЯТЬ ТК НАСТРОЙКА ПРОЦЕНТА ПРОИСХОДИТ ЧЕРЕЗ АДМИНКУ
     */
    tracesSampleRate: isTransactionAvailable ? 1.0 : 0.0,

    /**
     * Данное свойство записывает поведение пользователя при ошибке (если основная запись была отключена)
     * * НЕ ТРОГАТЬ ДАННОЕ ЧИСЛО И НАСТРАИВАТЬ ЧЕРЕЗ АДМИНКУ
     */
    replaysOnErrorSampleRate: isSessionReplayAvailable ? 1.0 : 0.0,

    /**
     * Данное свойство записывает поведение пользователя во время сессии
     * * НЕ ТРОГАТЬ ДАННОЕ ЧИСЛО И НАСТРАИВАТЬ ЧЕРЕЗ АДМИНКУ
     */
    replaysSessionSampleRate: isSessionReplayFullSession ? 1.0 : 0.0,

    /** свойство которое помогает не засорять sentry лишними ошибками
     * отфильтровывает ошибки по типу
     ** СЮДА ДОБАВЛЯТЬ ТОЛЬКО СТОРОННИЕ ОШИБКИ
     * Внутренние ошибки можно настроить в сентри
     * @see https://leveltravel-ib.sentry.io/settings/projects/javascript/filters/data-filters/
     */
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
      // reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
      'Non-Error exception captured',
      'Non-Error promise rejection captured',
      "Failed to execute 'getBattery' on 'Navigator': Illegal invocation",
      'Loading CSS chunk',
    ],

    /**
     * игнорируем url-ы и файлы, которые засоряют sentry, но с которыми ничего сделать не можем
     ** СЮДА ДОБАВЛЯТЬ ТОЛЬКО СТОРОННИЕ СКРИПТЫ.
     ** ДЛЯ ВНУТРЕННИХ МОЖНО ФИЛЬТР НАСТРОИТЬ ТУТ:
     * @see https://leveltravel-ib.sentry.io/settings/projects/javascript/filters/data-filters/
     */
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
    ],

    beforeSend(event) {
      try {
        if (
          // отфильтровываем по именованию ошибки
          event?.exception?.values?.findIndex(({ value }) =>
            excludedElements.reduce(
              (acc, element) => acc || Boolean(value?.match(element)),
              false,
            ),
          ) !== -1 ||
          // отфильтровываем по имени файла
          event?.exception?.values?.[0]?.stacktrace?.frames?.findIndex(
            ({ filename }: StackFrame) =>
              excludedElements.reduce(
                (acc, element) => acc || Boolean(filename?.match(element)),
                false,
              ),
          ) !== -1 ||
          // отфильтровываем по урлу по которому находится файл
          event?.exception?.values?.[0]?.stacktrace?.frames?.findIndex(
            ({ abs_path }: StackFrame) =>
              excludedElements.reduce(
                (acc, element) => acc || Boolean(abs_path?.match(element)),
                false,
              ),
          ) !== -1
        ) {
          return null;
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }

      return event;
    },
  });
};
