import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type {
  HomeDynamicDataState,
  LatestOrderType,
  HotPriceByCountry,
} from './types';

const initialState: HomeDynamicDataState = {
  liveInfoPromoRequest: {
    isError: false,
    isLoading: null,
  },
  latestOrders: [],
  hotPricesByCountry: [],
  foundToursToday: 0,
};

const homeDynamicDataSlice = createSlice({
  name: 'homeDynamicData',
  initialState,
  reducers: {
    liveInfoPromoSetSendingStatus: (state) => {
      state.liveInfoPromoRequest = {
        isError: false,
        isLoading: true,
      };
    },
    liveInfoPromoSetSuccessResponseStatus: (state) => {
      state.liveInfoPromoRequest = {
        isError: false,
        isLoading: false,
      };
    },
    liveInfoPromoSetFailResponseStatus: (state) => {
      state.liveInfoPromoRequest = {
        isError: true,
        isLoading: false,
      };
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    parseLatestOrders: (state, action: PayloadAction<any[]>) => {
      const latestOrders = action.payload.map(
        (value): LatestOrderType => ({
          title: value.title || '',
          descriptions: value.description || '',
        }),
      );
      state.latestOrders = latestOrders;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    parseHotPricesByCountry: (state, action: PayloadAction<any[]>) => {
      const hotPricesByCountry = action.payload.map(
        (value): HotPriceByCountry => ({
          name: value.name || '',
          price: value.price || 0,
        }),
      );
      state.hotPricesByCountry = hotPricesByCountry;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    parseFoundToursToday: (state, action: PayloadAction<any>) => {
      state.foundToursToday = action.payload || 0;
    },
  },
});

export default homeDynamicDataSlice;
