import { connect } from 'react-redux';
import { showAuthAction } from 'src/store/client/actions';
import AuthFromWishlist from './AuthFromWishlist';

const mapDispatchToProps = {
  onShowAuthPopup: showAuthAction,
};

const connector = connect(null, mapDispatchToProps);

export default connector(AuthFromWishlist);
