import { connect } from 'react-redux';

import { Relinking } from './Relinking';

import { getLinkedHotelsList } from '../../store/linkedHotels/selectors';
import { getNarrativeCardsList } from '../../store/narrativeCards/selectors';
import {
  isDefaultPopularDestinationsOffersSelector,
  popularDestinationsOffersListSelector,
} from '../../store/popularDestinationsOffers/selectors';
import { popularMetaregionsOffersListSelector } from '../../store/popularMetaregionsOffers/selectors';

const mapStateToProps = (state) => ({
  isDefaultCountriesLinks: isDefaultPopularDestinationsOffersSelector(state),
  linkedHotels: getLinkedHotelsList(state),
  narrativeCards: getNarrativeCardsList(state),
  popularDestinationsOffers: popularDestinationsOffersListSelector(state),
  popularMetaregionsOffers: popularMetaregionsOffersListSelector(state),
});

const connector = connect(mapStateToProps);
export type PropsFromRedux = ReturnType<typeof mapStateToProps>;

export default connector(Relinking);
