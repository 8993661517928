import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import { getIsMobile } from 'src/store/view/selectors';
import { CookiesBanner } from './CookiesBanner';

const mapStateToProps = (state) => ({
  isMobile: getIsMobile(state),
});

const connector = connect(mapStateToProps);
export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CookiesBanner);
