import { i18n } from '@lt/localization';
import React from 'react';
import styled from 'styled-components';
import type {
  TourGuideItem,
  TourGuideItemImage,
  TourGuideMode,
} from '../../../../types';

interface DesktopItemsProps {
  mode: TourGuideMode;
  items: TourGuideItem[];
}

export const TourGuideDesktopModal = ({ mode, items }: DesktopItemsProps) => (
  <Content>
    <Title>
      {mode === 'default' ? (
        <span>{i18n.t('howBuy.titleItems')}</span>
      ) : (
        `${i18n.t('checkout.payment.whatWillHappenAfter')}?`
      )}
    </Title>

    {items.map(({ image, title, text, desktopStyles }, index) => {
      const key = index;

      return (
        <Item key={key}>
          <ItemImageWrapper align={image.align} style={desktopStyles}>
            <ItemImage src={image.src} />
          </ItemImageWrapper>

          <div>
            <ItemTitle>{title}</ItemTitle>
            <ItemText>{text}</ItemText>
          </div>
        </Item>
      );
    })}
  </Content>
);

const Content = styled.section`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  margin-bottom: 30px;
  white-space: pre-line;
`;

export const Item = styled.div`
  display: flex;
  margin-bottom: 30px;
`;

const ItemImageWrapper = styled.div<{ align: TourGuideItemImage['align'] }>`
  position: relative;
  flex-shrink: 0;
  order: ${(props) => (props.align === 'left' ? -1 : 1)};
  margin-left: ${(props) => (props.align === 'right' ? 20 : 0)}px;
  margin-right: ${(props) => (props.align === 'left' ? 20 : 0)}px;
`;

const ItemImage = styled.img`
  position: absolute;
  width: 100%;
`;

const ItemTitle = styled.div`
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.colors.blackBase};
`;

const ItemText = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.blackLight};
`;
