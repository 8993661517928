export const homoglyphsPairsForBirthCertificate = [
  ['O', 'О'],
  ['M', 'М'],
  ['K', 'К'],
  ['T', 'Т'],
  ['A', 'А'],
  ['X', 'Х'],
  ['H', 'Н'],
  ['B', 'В'],
  ['E', 'Е'],
  ['P', 'Р'],
  ['C', 'С'],
];

export const CHANGING_DAYS_LIMIT = 13;

export const ELDERLY_PERSON_THRESHOLD = 65;

export const countriesWhereRuDocAllowed = ['RU', 'BY', 'AB', 'KZ'];
