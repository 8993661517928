import React from 'react';
import CompanyColumn from './CompanyColumn';
import HelpColumn from './HelpColumn';
import HotelsColumn from './HotelsColumn';
import InstrumentsColumn from './IntrumentsColumn';
import OperatorsColumn from './OperatorsColumn';

import styles from './TopSection.module.css';

const TopSection = () => (
  <div className={styles.topSection}>
    <CompanyColumn />
    <InstrumentsColumn />
    <HelpColumn />
    <HotelsColumn />
    <OperatorsColumn />
  </div>
);

export default TopSection;
