import { getMidnightFromString } from './dateUtils';

export const EMPTY_DATE = {
  date: null,
};

export const getDateCell = (date: string | Date | null) => {
  if (!date) {
    return EMPTY_DATE;
  }

  return {
    date: new Date(getMidnightFromString(date)),
  };
};
