import { ClientType } from '../types';

export function getClientType(
  isLoggedIn,
  isConfirmedClientEmail,
  clientUnconfirmedPhone,
) {
  if (isLoggedIn && !isConfirmedClientEmail && clientUnconfirmedPhone) {
    return ClientType.UnconfEmailUnconfPhone;
  }
  if (isLoggedIn && isConfirmedClientEmail && clientUnconfirmedPhone) {
    return ClientType.ConfEmailUnconfPhone;
  }
  if (isLoggedIn && !isConfirmedClientEmail && !clientUnconfirmedPhone) {
    return ClientType.UnconfEmailConfPhone;
  }
  if (isLoggedIn && isConfirmedClientEmail && !clientUnconfirmedPhone) {
    return ClientType.ConfEmailConfPhone;
  }

  return ClientType.Unauthorized;
}
