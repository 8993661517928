import { isDevelopment } from '../utils/commonUtils';
import { isSSR } from './ssr';

const dataLayer = isSSR ? [] : window.dataLayer || [];

/**
 * Отправляет параметры в GTM безопасно
 */
export const dataLayerPushParams = (param) => dataLayer.push(param);

/**
 * Отправляет событие в GTM безопасно
 */
export function dataLayerPush({ event, ...rest }) {
  try {
    dataLayer.push({
      event,
      eventAction: event,
      ...rest,
    });
  } catch (error) {
    // возможно стоит логгировать в sentry
    if (isDevelopment()) {
      // eslint-disable-next-line no-console
      console.log('GTM dataLayer push failed', error);
    }
  }
}

/**
 * каррированная и типизированная обертка для GTM
 *
 * @example
 *
 * type Events = 'ANY_EVENT'
 * const anyGtmEvent = pushDataLayerEvent<Events, { data: Record<string, number> }>('ANY_EVENT');
 *
 * anyGtmEvent({
 *   data:{
 *     key: 1
 *   }
 * })
 * */
export const pushDataLayerEvent =
  <Event, Params = void>(event: Event) =>
  (params?: Params) => {
    dataLayerPush({
      event,
      ...params,
    });
  };

/**
 * Находит события в gtm по ключу
 */
export const dataLayerEvents = (event: string) => {
  const events = dataLayer.filter((item) => item?.event === event);
  return events;
};
