import { createSelector } from '@reduxjs/toolkit';

import type { RouterState } from 'connected-react-router';
import { getFullPath } from '../../utils/globalUtils';
import { PACKAGE_HOME_PATH, TOURISTS_PATH, PAGES } from '../constants';

const getRouter = (state): RouterState => state.router;

export const getPath = createSelector(
  getRouter,
  (router) => router.location.pathname,
);

export const getIsHomePath = createSelector(
  getPath,
  (currentPath) => getFullPath(PACKAGE_HOME_PATH) === currentPath,
);
export const getIsTouristsPath = createSelector(
  getPath,
  (currentPath) => getFullPath(TOURISTS_PATH) === currentPath,
);
export const getCurrentStep = createSelector(getPath, (currentPath) =>
  PAGES.reduce<number | null>((result, page, pageIndex) => {
    if (!result) {
      return getFullPath(page.path) === currentPath ? pageIndex + 1 : null;
    }
    return result;
  }, null),
);
