import type { WishlistItem } from '@lt/api/dist/methods/wishlist/types';
import { HotelRatingSquare, Image } from '@lt/components';
import WishListButton from '@lt/components/components/WishListButton';
import { NoImage } from '@lt/components/icons-react/userflow';
import { i18n } from '@lt/localization';
import type { MouseEventHandler } from 'react';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { vibrate } from 'src/utils/commonUtils';
import HotelRoom from './components/HotelRoom';
import WishlistInfoBlock from './components/WishlistInfoBlock';
import { WISHLIST_ITEM_TEST_ID } from './constants';
import type { PropsFromRedux } from '../../WishlistPage';
import {
  pushWishlistOpenHotel,
  pushWishlistOpenPackage,
} from '../../analytics';

const wishlistType = {
  package: i18n.t('wishlist.card.type.tour', { ns: 'wishlist' }),
  hotel: i18n.t('wishlist.card.type.hotel', { ns: 'wishlist' }),
};

type WishlistCardProps = Omit<
  PropsFromRedux,
  | 'wishlistItems'
  | 'filterOptions'
  | 'destroyWishlist'
  | 'hideWishlistOnboarding'
  | 'isLoggedIn'
> & {
  wishlistItem: WishlistItem;
  wishlistItemId?: number;
};

const WishlistCard = (props: WishlistCardProps) => {
  const {
    isContainedInWishlist,
    wishlistItem,
    addToWishlist,
    removeFromWishlist,
    wishlistItemId,
    isMobile,
    wishlistId,
  } = props;
  const {
    image,
    rating,
    hotelTitle,
    stars,
    type,
    city,
    country,
    searchType,
    nights,
    date,
    tourists,
    price,
    roomType,
    packageId,
    hotelId,
    link,
  } = wishlistItem;
  const wishlistIdentifier = useMemo(
    () => packageId || hotelId || 0,
    [packageId, hotelId],
  );
  const isSelected = isContainedInWishlist(wishlistIdentifier);
  const onClick: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    if (isSelected) {
      removeFromWishlist(wishlistItemId || 0);
    } else
      addToWishlist({
        wishlistIdentifier,
        type,
      });
    /**
     * Вибрация устройства при добавлении и удалении
     */
    vibrate(200);
  };

  const handleCardClicked = () => {
    window.location.href = window.location.origin + link;
    if (!wishlistId) return;
    if (type === 'hotel' && hotelId)
      pushWishlistOpenHotel({
        wishlistId,
        hotelId,
        searchType,
      });
    if (type === 'package' && packageId)
      pushWishlistOpenPackage({ wishlistId, packageId, searchType });
  };
  return (
    <StyledCardWrapper
      data-testid={WISHLIST_ITEM_TEST_ID}
      onClick={handleCardClicked}
    >
      <StyledTopContainer>
        <StyledCardImageContainer>
          {image ? (
            <StyledImage src={image} fallback="" />
          ) : (
            <StyledNoPhotoContainer>
              <NoImage width={34} height={23} />
              <StyledNoPhotoText>
                {i18n.t('hotel.room.noFoto')}
              </StyledNoPhotoText>
            </StyledNoPhotoContainer>
          )}

          <StyledRating value={rating || 0} count={null} />
        </StyledCardImageContainer>
        <WishlistInfoBlock
          hotelTitle={hotelTitle}
          stars={stars}
          city={city}
          country={country}
          nights={nights}
          date={date}
          tourists={tourists}
          searchType={searchType}
          price={price}
        />
        <StyledRightSideContainer>
          <StyledWishListButton
            onClick={onClick}
            isSelected={isSelected}
            isMobile={isMobile}
          />
          <StyledWishlistType>{wishlistType[searchType]}</StyledWishlistType>
        </StyledRightSideContainer>
      </StyledTopContainer>

      {type === 'package' && <HotelRoom roomType={roomType} />}
    </StyledCardWrapper>
  );
};
const StyledCardWrapper = styled.div`
  min-height: 120px;

  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(210, 208, 197, 0.8);
  overflow: hidden;
`;
const StyledTopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;
const StyledCardImageContainer = styled.div`
  position: relative;
  width: 64px;
  height: 100%;
  overflow: hidden;
  flex: 1 0 auto;
`;
const StyledImage = styled(Image)`
  width: 100%;
  height: 120px;
  object-fit: cover;
`;
const StyledRating = styled(HotelRatingSquare)`
  position: absolute;
  top: 8px;
  left: 8px;
  color: #fff;
  text-align: center;
  font-size: 12px;
  width: 30px;
  height: 20px;
`;
const StyledNoPhotoContainer = styled.div`
  display: flex;
  width: 64px;
  height: 120px;
  padding: 35px 0px 31px 0px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 7px;
  background: #f5f3f2;
`;
const StyledNoPhotoText = styled.div`
  color: #7f8286;

  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px; /* 100% */
`;
const StyledRightSideContainer = styled.div`
  display: flex;
  padding: 8px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  align-self: stretch;
`;
const StyledWishlistType = styled.span`
  color: #919499;
  font-size: 12px;
`;
const StyledWishListButton = styled(WishListButton)`
  margin: 4px;
`;
export default WishlistCard;
