import { createAction } from '@reduxjs/toolkit';
import { NPS_RETRY, SUBMIT_NPS, SUBMIT_NPS_ON_EXIT } from './constants';

export const submitNpsAction = createAction<{
  score: number;
  comment: string;
}>(SUBMIT_NPS);

export const submitNpsOnExitAction = createAction<{
  score: number;
  comment: string;
}>(SUBMIT_NPS_ON_EXIT);

export const retryAction = createAction(NPS_RETRY);
