import React from 'react';

import { Wishlist } from '@lt/components';
import { HeaderMenuWishlist } from '@lt/components/components/HeaderMenu';

import { useTranslation } from '@lt/localization';
import { MODULE_NAME } from 'src/constants';
import {
  pushWishlistOpenHotel,
  pushWishlistOpenPackage,
  pushWishlistShare,
  pushWishlistOpen,
} from 'src/routes/Wishlist/components/WishlistPage/analytics';
import AuthFromWishlist from 'src/components/AuthFromWishlist';
import type { Dispatch, SetStateAction } from 'react';
import type { PropsFromRedux } from '.';
import { isSSR } from '../../../../helpers/ssr';

const HeaderWishlist = ({
  items,
  counter,
  isAdmin,
  onDestroyWishlist,
  onRemoveWishlistItem,
  isCompact,
  wishlistId,
  addToWishlist,
  appName,
  isSearchInWishlist,
  requestId,
  isLoggedIn,
  setShowAuthFromWishlist,
  isHomepage,
}: PropsFromRedux & {
  isCompact?: boolean;
  setShowAuthFromWishlist: Dispatch<SetStateAction<boolean>>;
}) => {
  /**
   * Добавляем поиск в вишлист, только если это страница поиска
   * и в вишлисте нет данного поиска, и если пользователь залогинен
   * с корпоративной почты
   */
  const onSearchWishlistToggle = () => {
    if (!isSearchInWishlist && appName === MODULE_NAME.SEARCH && isAdmin)
      addToWishlist({ type: 'search', requestId });
  };

  const { t } = useTranslation();
  const leadmailerLink = isAdmin && 'https://manager.level.travel/leads/email';
  const onShareWishlist = () => {
    const link = `${window.location.origin}?wish=${wishlistId}`;
    navigator.clipboard.writeText(link);

    if (wishlistId) pushWishlistShare(wishlistId);
  };

  const onHotelClicked = (hotelId: number, searchType: 'package' | 'hotel') => {
    if (wishlistId) pushWishlistOpenHotel({ wishlistId, hotelId, searchType });
  };

  const onPackageClicked = (
    packageId: number,
    searchType: 'package' | 'hotel',
  ) => {
    if (wishlistId)
      pushWishlistOpenPackage({ wishlistId, packageId, searchType });
  };

  const onHoverWishlist = () => {
    pushWishlistOpen();
  };

  return (
    <HeaderMenuWishlist
      slug="wishlist"
      name={t('headerMenu.wishlist')}
      counter={String(counter || '')}
      isCompact={isCompact}
      onToggleWishlist={onSearchWishlistToggle}
      onHoverWishlist={onHoverWishlist}
      isHomepage={isHomepage}
    >
      {!isSSR && (
        <Wishlist
          items={items}
          leadmailerLink={leadmailerLink || undefined}
          onHotelClicked={onHotelClicked}
          onPackageClicked={onPackageClicked}
          onDestroyWishlist={onDestroyWishlist}
          onShareWishlist={onShareWishlist}
          onRemoveWishlistItem={onRemoveWishlistItem}
          isLoggedIn={isLoggedIn}
        >
          <AuthFromWishlist
            isMobile={false}
            setShowAuthFromWishlist={setShowAuthFromWishlist}
          />
        </Wishlist>
      )}
    </HeaderMenuWishlist>
  );
};

export default HeaderWishlist;
