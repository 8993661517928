import React, { useState, useEffect, useRef } from 'react';
import styles from '../../mobileAppPopup.module.css';
import CopyIcon from './components/CopyIcon';
import { PROMOCODE } from '../../constants';
import { handleCopy } from './utils';

const CopyPromoCode: React.FC = () => {
  const [copied, setCopied] = useState(false);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(
    () => () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    },
    [],
  );

  const handleCopyClick = async () => {
    const success = await handleCopy(PROMOCODE);
    if (success) {
      setCopied(true);
      timerRef.current = setTimeout(() => {
        setCopied(false);
        if (timerRef.current) {
          clearTimeout(timerRef.current);
        }
        timerRef.current = null;
      }, 2000);
    }
  };

  return (
    <div className={styles.codeWrapper}>
      <p className={styles.codeText}>{PROMOCODE}</p>
      <CopyIcon copied={copied} onClick={handleCopyClick} />
    </div>
  );
};

export default CopyPromoCode;
