import React from 'react';
import { Link } from '@lt/components';
import { i18n } from '@lt/localization';
import Column from '../Column';

const InstrumentsColumn = () => (
  <Column title={i18n.t('footer.instruments.title')}>
    <li>
      <Link href="https://promo.level.travel/calendar">
        {i18n.t('footer.instruments.calendar')}
      </Link>
    </li>
    <li>
      <Link href="/gift">{i18n.t('footer.instruments.giftCertificates')}</Link>
    </li>
    <li>
      <Link href="https://promo.level.travel/paylate">
        {i18n.t('footer.instruments.installment')}
      </Link>
    </li>
    <li>
      <Link href="https://promo.level.travel/travelpartners">
        {i18n.t('footer.instruments.partnership')}
      </Link>
    </li>
    <li>
      <Link href="https://level.travel/media/index.php">
        {i18n.t('footer.instruments.travelJournal')}
      </Link>
    </li>
  </Column>
);

export default InstrumentsColumn;
