import { createSelector } from '@reduxjs/toolkit';
import { getImmutableCompatible } from 'src/utils/immutableUtils';
import type { RoomPopupProps } from './slice';
import { name } from './slice';

export const getRoomPopup = (state): RoomPopupProps =>
  getImmutableCompatible(state, name);

export const getIsOpenedRoomPopup = createSelector(
  [getRoomPopup],
  (data) => data.isOpened,
);

export const getRoomId = createSelector(
  [getRoomPopup],
  (data) => data.packageId,
);
