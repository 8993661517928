import classnames from 'classnames';
import React from 'react';

import type { SkeletonBoxProps } from './types';
import styles from './skeletonBox.module.css';

const SkeletonBox = ({ className = '' }: SkeletonBoxProps): JSX.Element => (
  <div className={classnames(className, styles.skeletonContainer)} />
);

export default SkeletonBox;
