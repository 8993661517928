import { i18n } from '@lt/localization';

export const DISCOUNT_BONUS = 'bonus';
export const DISCOUNT_COUPON = 'coupon';
export const DISCOUNT_GIFT_CERT = 'certificate';

export const DISCOUNT_TYPES = {
  [DISCOUNT_BONUS]: i18n.t('checkout.discount.bonus'),
  [DISCOUNT_COUPON]: i18n.t('checkout.discount.coupon'),
  [DISCOUNT_GIFT_CERT]: i18n.t('checkout.discount.giftCert'),
} as const;
