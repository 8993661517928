import React, { type ComponentProps, lazy } from 'react';
import ErrorBoundary from 'src/error/components/ErrorBoundary';
import type { ErrorBoundaryProps } from 'src/error/components/ErrorBoundary/ErrorBoundary';

type DynamicImportOptions = {
  fallback?: React.ReactNode;
  showErrorBoundary?: boolean;
  errorBoundaryProps?: ErrorBoundaryProps;
};

export const dynamic = <T extends React.ComponentType<any>>(
  loaderFunction: () => Promise<{ default: T }>,
  {
    fallback = null,
    showErrorBoundary = false,
    errorBoundaryProps = {},
  }: DynamicImportOptions = {},
) => {
  const LazyComponent = lazy(loaderFunction);

  if (showErrorBoundary) {
    return function DynamicComponent(
      props: ComponentProps<typeof LazyComponent>,
    ) {
      return (
        <ErrorBoundary {...errorBoundaryProps}>
          <React.Suspense fallback={fallback}>
            <LazyComponent {...props} />
          </React.Suspense>
        </ErrorBoundary>
      );
    };
  }

  return function DynamicComponent(
    props: ComponentProps<typeof LazyComponent>,
  ) {
    return (
      <React.Suspense fallback={fallback}>
        <LazyComponent {...props} />
      </React.Suspense>
    );
  };
};
