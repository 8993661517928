import type { EffectiveType } from './types';

/**
 * Функция проверяющая есть ли в браузере или на сервере данный api и возвращает объект для взаимодействия с ним
 */
export const getNetworkInformation = (): unknown | undefined => {
  if (window && window.navigator && 'connection' in window.navigator) {
    return window.navigator.connection;
  }
  return undefined;
};

/**
 * Получаем информацию о скорости подключения к интернету
 */
export const getEffectiveType = (): EffectiveType | undefined => {
  const connection = getNetworkInformation();

  if (
    connection &&
    typeof connection === 'object' &&
    'effectiveType' in connection &&
    typeof connection.effectiveType === 'string' &&
    (connection.effectiveType === 'slow-2g' ||
      connection.effectiveType === '2g' ||
      connection.effectiveType === '3g' ||
      connection.effectiveType === '4g')
  ) {
    return connection.effectiveType;
  }
  return undefined;
};

/**
 * Если скорость интернета 4g возвращаем true или если фича в браузере не доступна тоже возвращаем true
 */
export const getIsEffectiveTypeFastOrNotDefined = (): boolean => {
  const networkSpeed = getEffectiveType();
  return networkSpeed === '4g' || !networkSpeed;
};
