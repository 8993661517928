import { select, takeLatest } from 'redux-saga/effects';
import { sendMindboxOperation } from 'src/utils/mindbox';
import { MINDBOX_OPERATION_NAMES } from 'src/utils/mindbox/constants';
import {
  sendMindboxAuthAction,
  sendMindboxAuthOnCheckoutAction,
  sendMindboxFillTouristDataAction,
  sendMindboxPageviewCheckoutAction,
  sendMindboxPageviewExploreAction,
  sendMindboxPageviewHotelAction,
  sendMindboxRegistrationAction,
} from './actions';
import {
  getMindboxAuthData,
  getMindboxAuthOnCheckoutData,
  getMindboxFillTouristData,
  getMindboxPageviewCheckoutData,
  getMindboxPageviewHotelData,
  getMindboxRegistrationData,
} from './selectors';

function* pushMindboxAuth() {
  try {
    const data = yield select(getMindboxAuthData);
    yield sendMindboxOperation(MINDBOX_OPERATION_NAMES.AUTH, data);
  } catch {
    // eslint-disable-next-line no-console
    console.log('Ошибка при отправке аналитики MINDBOX_OPERATION_NAMES.AUTH');
  }
}

function* pushMindboxRegistration() {
  try {
    const data = yield select(getMindboxRegistrationData);
    yield sendMindboxOperation(MINDBOX_OPERATION_NAMES.REGISTRATION, data);
  } catch {
    // eslint-disable-next-line no-console
    console.log(
      'Ошибка при отправке аналитики MINDBOX_OPERATION_NAMES.REGISTRATION',
    );
  }
}

function* pushMindboxAuthOnCheckout() {
  try {
    const data = yield select(getMindboxAuthOnCheckoutData);
    yield sendMindboxOperation(MINDBOX_OPERATION_NAMES.AUTH_ON_CHECKOUT, data);
  } catch {
    // eslint-disable-next-line no-console
    console.log(
      'Ошибка при отправке аналитики MINDBOX_OPERATION_NAMES.AUTH_ON_CHECKOUT',
    );
  }
}

function* pushMindboxFillTouristData() {
  try {
    const data = yield select(getMindboxFillTouristData);
    yield sendMindboxOperation(
      MINDBOX_OPERATION_NAMES.FILL_TOURISTS_DATA,
      data,
    );
  } catch {
    // eslint-disable-next-line no-console
    console.log(
      'Ошибка при отправке аналитики MINDBOX_OPERATION_NAMES.FILL_TOURISTS_DATA',
    );
  }
}

function* pushMindboxPageviewCheckout() {
  try {
    const data = yield select(getMindboxPageviewCheckoutData);
    yield sendMindboxOperation(MINDBOX_OPERATION_NAMES.CHECKOUT_PAGEVIEW, data);
  } catch {
    // eslint-disable-next-line no-console
    console.log(
      'Ошибка при отправке аналитики MINDBOX_OPERATION_NAMES.CHECKOUT_PAGEVIEW',
    );
  }
}

function* pushMindboxPageviewHotel() {
  try {
    const data = yield select(getMindboxPageviewHotelData);
    yield sendMindboxOperation(MINDBOX_OPERATION_NAMES.HOTEL_PAGEVIEW, data);
  } catch {
    // eslint-disable-next-line no-console
    console.log(
      'Ошибка при отправке аналитики MINDBOX_OPERATION_NAMES.HOTEL_PAGEVIEW',
    );
  }
}

function* pushMindboxPageviewExplore() {
  try {
    yield sendMindboxOperation(MINDBOX_OPERATION_NAMES.EXPLORE_PAGEVIEW);
  } catch {
    // eslint-disable-next-line no-console
    console.log(
      'Ошибка при отправке аналитики MINDBOX_OPERATION_NAMES.EXPLORE_PAGEVIEW',
    );
  }
}

export default function* mindboxSaga() {
  yield takeLatest(sendMindboxAuthAction, pushMindboxAuth);
  yield takeLatest(sendMindboxRegistrationAction, pushMindboxRegistration);
  yield takeLatest(sendMindboxAuthOnCheckoutAction, pushMindboxAuthOnCheckout);
  yield takeLatest(
    sendMindboxFillTouristDataAction,
    pushMindboxFillTouristData,
  );
  yield takeLatest(
    sendMindboxPageviewCheckoutAction,
    pushMindboxPageviewCheckout,
  );
  yield takeLatest(sendMindboxPageviewHotelAction, pushMindboxPageviewHotel);
  yield takeLatest(
    sendMindboxPageviewExploreAction,
    pushMindboxPageviewExplore,
  );
}
