import { createSlice } from '@reduxjs/toolkit';
import { initAppAction } from 'src/store/view/actions';
import { getViewInitialState } from 'src/store/view/reducer';
import type { ViewState } from './types';

const initialState: ViewState = {
  isMobile: false,
  isWebview: false,
  availableSearchTypes: undefined,

  // desktop wl
  isWidgetShown: false,
  isFontsBlack: true,
  isTransparent: true,
  isHomepage: true,

  isAppBannerEnabled: false,

  ...getViewInitialState(),
};

const viewSlice = createSlice({
  name: 'view',
  initialState,
  reducers: {
    parseWindow: (state) => {
      if (window.is_mobile === undefined) {
        // eslint-disable-next-line no-console
        console.warn('window.is_mobile is undefined');
      }
      if (window.availableSearchTypes === undefined) {
        // eslint-disable-next-line no-console
        console.warn('window.availableSearchTypes is undefined');
      }
      if (window.is_wl === undefined) {
        // eslint-disable-next-line no-console
        console.warn('window.is_wl is undefined');
      }
      if (window.is_webview === undefined) {
        // eslint-disable-next-line no-console
        console.warn('window.is_webview is undefined');
      }
      state.isMobile = window.is_mobile || false;
      state.availableSearchTypes = window.availableSearchTypes || undefined;
      state.isWhitelabel = window.is_wl || false;
      state.isWebview = window.is_webview || false;

      // desktop wl
      state.isWidgetShown = window.isWidgetShown || false;
      state.isFontsBlack = window.isFontsBlack;
      state.isTransparent = window.isTransparentHeader || true;
      state.isHomepage = window.isHomepage || true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      initAppAction,
      (state, { payload: { appName, platform } }) => ({
        ...state,
        app: appName,
        platform,
      }),
    );
  },
});

export default viewSlice;
