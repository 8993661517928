import { i18n } from '@lt/localization';

export const CHANGE_SELECTED_FLIGHT_CLASS = 'CHANGE_SELECTED_FLIGHT_CLASS';
export const TOGGLE_FLIGHT_CLASS = 'TOGGLE_FLIGHT_CLASS';

export const FLIGHTS_COMPONENT_ID = 'checkout_flights';
export const FLIGHT_ARRIVAL_EARLY_TIME_WARNING_OFFSET = 10;
export const FLIGHT_LATE_DEPARTURE_TIME_WARNING_OFFSET = 17;

export const FLIGHTS_DIRECTIONS = [
  i18n.t('checkout.flight.to'),
  i18n.t('checkout.flight.back'),
];

// ТИПЫ ПЕРЕЛЁТА
export const FLIGHT_TYPES_NAME = {
  REGULAR: 'regular',
  BLOCK: 'block',
  CHARTER: 'charter',
  NA: 'n/a',
};

export const FLIGHT_TYPES = {
  [FLIGHT_TYPES_NAME.REGULAR]: {
    title: i18n.t('checkout.flight.regular.title'),
    pluralTitle: i18n.t('checkout.flight.regular.pluralTitle'),
    tooltipTitle: i18n.t('checkout.flight.regular.tooltip_title'),
    tooltipDescription: i18n.t('checkout.flight.regular.tooltip_description'),
    icon: 'regular',
  },
  [FLIGHT_TYPES_NAME.BLOCK]: {
    title: i18n.t('checkout.flight.block.title'),
    pluralTitle: i18n.t('checkout.flight.block.pluralTitle'),
    tooltipTitle: i18n.t('checkout.flight.block.tooltip_title'),
    tooltipDescription: i18n.t('checkout.flight.block.tooltip_description'),
  },
  [FLIGHT_TYPES_NAME.CHARTER]: {
    title: i18n.t('checkout.flight.charter.title'),
    pluralTitle: i18n.t('checkout.flight.charter.pluralTitle'),
    tooltipTitle: i18n.t('checkout.flight.charter.tooltip_title'),
    tooltipDescription: i18n.t('checkout.flight.charter.tooltip_description'),
  },
  [FLIGHT_TYPES_NAME.NA]: {
    title: i18n.t('checkout.flight.na.title'),
    pluralTitle: i18n.t('checkout.flight.na.pluralTitle'),
    tooltipTitle: i18n.t('checkout.flight.na.tooltip_title'),
    tooltipDescription: i18n.t('checkout.flight.na.tooltip_description'),
  },
};

// КЛАСС ПЕРЕЛЁТА
export const FLIGHT_CLASSES = {
  ECONOMY: 'economy',
  BUSINESS: 'business',
} as const;

/* 
  emptyLoading - при пустой фильтрации и пустом списке рейсов;
  emptyFiltration - когда ни один рейс не подошёл под выбранные фильтры;
  isLoading - когда згружаются любые рейсы: и основные, и альтернативки;
  fetchFinished - когда загрузка рейсов завершена;
 */
export const LOADING_STATE = {
  EMPTY_LOADING: 'emptyLoading',
  EMPTY_FILTRATION: 'emptyFiltration',
  IS_LOADING: 'isLoading',
  FETCH_FINISHED: 'fetchFinished',
} as const;

export const FLIGHT_CLASSES_NAME = {
  [FLIGHT_CLASSES.ECONOMY]: i18n.t('checkout.flight.economy.name'),
  [FLIGHT_CLASSES.BUSINESS]: i18n.t('checkout.flight.business.name'),
};
