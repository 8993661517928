import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { isSSR } from '../../helpers/ssr';

const root = isSSR ? null : document.body;
const baseCls = 'lt-overlay';

class Modal extends React.PureComponent {
  constructor(props) {
    super(props);

    const element = document.createElement('div');
    element.className = baseCls;

    this.el = element;
  }

  componentDidMount() {
    root.appendChild(this.el);
  }

  componentWillUnmount() {
    root.removeChild(this.el);
  }

  render() {
    const { children, shadow } = this.props;
    const modal = (
      <>
        {shadow && <div className={`${baseCls}__shadow`} />}
        {children}
      </>
    );

    return ReactDOM.createPortal(modal, this.el);
  }
}

Modal.defaultProps = {
  shadow: true,
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  shadow: PropTypes.bool,
};

export default Modal;
