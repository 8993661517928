import { connect } from 'react-redux';
import type { ConnectedProps } from 'react-redux';

import { submitCigaretteAction as submitCigarette } from 'src/store/analytics/slice';
import HeaderSearchForm from './HeaderSearchForm';

const mapDispatchToProps = {
  submitCigarette,
};

const connector = connect(null, mapDispatchToProps);
export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(HeaderSearchForm);
