import { get } from 'lodash';
import {
  PAYMENT_GROUPS,
  PAYMENT_METHOD_CARD,
  PAYMENT_METHOD_CUSTOM_PREPAYMENT,
  PAYMENT_METHOD_MIR_CARD,
  PAYMENT_METHOD_MIR_CARD_PARTNER,
  PAYMENT_METHOD_PAYLATE,
  PAYMENT_METHOD_PAYLATE_120,
  PAYMENT_METHOD_PAY_LATER,
  PAYMENT_METHOD_PREPAYMENT,
} from '../../../store/payment/constants';
import type { ExtraItem } from '../types/extras';

// TODO: наверно потом вынесем куда то в общие константы
const labels = {
  tourists: {
    birthday: 'Дата рождения туриста',
    name: 'Имя туриста',
    surname: 'Фамилия туриста',
    passport_number: 'Номер паспорта туриста',
    passport_prefix: 'Префикс паспорта туриста',
  },
};

/**
 * Трансформирует объект ошибок сервера в многострочный текст
 * @param {object} fields
 */
// eslint-disable-next-line import/prefer-default-export
export const transformOrderErrorsToText = (fields) =>
  Object.keys(fields).reduce((accumulator, key) => {
    if (key === 'package_id') {
      const error = `Ошибка оформления заказа: ${fields[key].join(', ')}`;
      return accumulator + error;
    }

    const fieldErrorText = `Ошибка в поле "${get(labels, key)}": ${fields[
      key
    ].join(', ')}\r\n`;
    return accumulator + fieldErrorText;
  }, '');

export const getPaymentGroup = (paymentType) => {
  switch (paymentType) {
    case PAYMENT_METHOD_CARD:
    case PAYMENT_METHOD_PREPAYMENT:
    case PAYMENT_METHOD_CUSTOM_PREPAYMENT:
    case PAYMENT_METHOD_MIR_CARD:
    case PAYMENT_METHOD_MIR_CARD_PARTNER:
      return PAYMENT_GROUPS.CARD;
    case PAYMENT_METHOD_PAYLATE:
    case PAYMENT_METHOD_PAYLATE_120:
      return PAYMENT_GROUPS.PAYLATE;
    case PAYMENT_METHOD_PAY_LATER:
      return PAYMENT_GROUPS.PAY_LATER;
    default:
      return PAYMENT_GROUPS.CARD;
  }
};

export const isPaylate = (paymentType) =>
  getPaymentGroup(paymentType) === PAYMENT_GROUPS.PAYLATE;

export const countTotalExtrasPrice = (extras: ExtraItem[]) =>
  extras?.reduce((sum, extra) => extra && sum + Number(extra.price), 0) || 0;

export const limitClientCashbackDiscount = (
  cashback: number,
  price: number,
) => {
  if (cashback >= price) {
    return price - 1;
  }

  return cashback;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const calculateCashback = (bonuses: any, price: number | undefined) => {
  if (bonuses && price) {
    if (bonuses.promotion_fixed) {
      return bonuses.promotion_value;
    }

    const rawCashbackCount = Math.floor(
      price * (bonuses.promotion_value / 100),
    );

    return Math.min(rawCashbackCount, bonuses.max_value || Infinity);
  }

  return 0;
};
