import type { SagaReturnType } from 'redux-saga/effects';
import { select } from 'redux-saga/effects';
import { dataLayerPush } from 'src/helpers/gtm';
import type {
  AddToWishlistAnalytics,
  RemoveFromWishlistAnalytics,
} from 'src/routes/Wishlist/components/WishlistPage/types';
import { getAppName, searchTypeSelector } from '../view/selectors';

export function* wishlistAddItemAnalytics(
  wishlistId: string,
  item: number,
  isFromMap?: boolean,
) {
  const searchType: SagaReturnType<typeof searchTypeSelector> = yield select(
    searchTypeSelector,
  );
  const page: SagaReturnType<typeof getAppName> = yield select(getAppName);

  const params: AddToWishlistAnalytics = {
    wishlistId,
    item,
    searchType,
    page: isFromMap ? 'map' : page,
  };
  dataLayerPush({ event: 'favourites_add_item', custom_params: params });
}

/**
 * Событие удаления всего вишлиста
 */
export function wishlistDestroyAnalytics(wishlistId) {
  dataLayerPush({
    event: 'favourites_remove_all',
    custom_params: { wishlistId },
  });
}

/**
 * Событие удаления элемента из избранного
 */
export function wishlistRemoveItemAnalytics(
  params: RemoveFromWishlistAnalytics,
) {
  dataLayerPush({ event: 'favourites_remove_item', custom_params: params });
}
