import { createAction } from '@reduxjs/toolkit';
import {
  ADD_ITEM_TO_WISHLIST,
  DESTROY_WISHLIST,
  GET_WISHLIST_DATA,
  HIDE_WISHLIST_ONBOARDING,
  NEED_SHOW_WISHLIST_ONBOARDING,
  REMOVE_ITEM_FROM_WISHLIST,
} from './constants';
import type { AddWishlistPayload } from './types';

export const destroyWishlistAction = createAction(DESTROY_WISHLIST);
export const addWishlistItem =
  createAction<AddWishlistPayload>(ADD_ITEM_TO_WISHLIST);
export const removeWishlistItem = createAction<number>(
  REMOVE_ITEM_FROM_WISHLIST,
);
export const getWishlistDataAction = createAction<string | undefined>(
  GET_WISHLIST_DATA,
);
export const hideWishlistOnboarding = createAction(HIDE_WISHLIST_ONBOARDING);
/**
 * По этому экшну определяется, нужно ли показать онбординг
 * Логика описана в саге /wishlist/sagas.ts
 */
export const needShowWishlistOnboarding = createAction(
  NEED_SHOW_WISHLIST_ONBOARDING,
);
