// import { captureException } from '@sentry/browser';

import { isDevelopment } from 'src/utils/commonUtils';

/**
 * Logger позволяет отправить ошибки в sentry для production сборки
 * В develop сборке ошибки выводятся в консоль
 * @errorData {Error} возникшая ошибка
 * @humanizeMessage {string} читабельное сообщение об ошибке, которое отображается в sentry в виде заголовка ошибки
 */
export function logger(errorData: any, humanizeMessage) {
  if (isDevelopment()) {
    // eslint-disable-next-line no-console
    console.log(humanizeMessage, errorData);
  } else {
    // пока отключаю logger, потому что сложно понимать какие ошибки в сентре важные, а какие нет
    // eslint-disable-next-line no-console
    console.log(humanizeMessage, errorData);
    /**
    captureException(errorData, (scope) => {
      // поскольку отфильтровывание ошибок отсутствует пока сделаю warning, чтобы проще было понимать, что к чему
      // плюс логируются не совсем в некоторых местах ошибки фронта, а скорее бэка
      scope.setLevel('warning');
      scope.setTransactionName(humanizeMessage);
      return scope;
    });
    */
  }
}
