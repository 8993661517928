import React from 'react';
import { connect } from 'react-redux';
import type { ConnectedProps } from 'react-redux';
import { useTranslation } from '@lt/localization';

import { getPartnerPromoTitle } from 'src/store/menu/selectors';
import styles from './wlHeaderDescription.module.css';

const WLHeaderDescription = ({ partnerPromoTitle }) => {
  const { t } = useTranslation();

  return partnerPromoTitle ? (
    <div
      className={styles.customDescription}
      dangerouslySetInnerHTML={{ __html: partnerPromoTitle }}
    />
  ) : (
    <div className={styles.customDescription}>
      <div className={styles.header_promo_title}>
        {t('homeHeader.promoTitle')}
      </div>
      <div className={styles.header_promo_text}>
        {t('homeHeader.promoText')}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  partnerPromoTitle: getPartnerPromoTitle(state),
});

const connector = connect(mapStateToProps);
export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(WLHeaderDescription);
