import React from 'react';
import classnames from 'classnames';
import styles from './dynamicDataBlock.module.css';

import SkeletonBox from '../../../SkeletonBox';

import type { DynamicDataBlockProps } from './types';

const DynamicDataBlock = ({
  icon,
  text,
  isMobile,
  order,
  isLoading = false,
}: DynamicDataBlockProps): JSX.Element =>
  !isLoading ? (
    <div
      className={classnames(styles.container, {
        [styles.containerMobile]: isMobile,
        [styles.containerFirstOrderDesktop]: !isMobile && order === 1,
      })}
      style={{ order }}
    >
      {icon && (
        <div
          className={classnames(styles.iconContainer, {
            [styles.iconContainerMobile]: isMobile,
          })}
        >
          {icon}
        </div>
      )}
      <p className={styles.textContainer}>{text}</p>
    </div>
  ) : (
    <div
      className={classnames(styles.container, {
        [styles.containerMobile]: isMobile,
        [styles.containerFirstOrderDesktop]: !isMobile && order === 1,
      })}
      style={{ order }}
    >
      <SkeletonBox className={styles.skeletonIcon} />
      <div className={styles.skeletonParagraph}>
        <SkeletonBox className={styles.skeletonTextFirst} />
        <SkeletonBox className={styles.skeletonTextSecond} />
      </div>
    </div>
  );

export default DynamicDataBlock;
