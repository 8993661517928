export const WIDGET_ID = 'lt_statistic_widget';

export const WIDGET_CONFIG = {
  place: `#${WIDGET_ID}`,
  height: 180,
  days_count: 55,
  title_size: 15,
  full_month: false,
  countries_list: true,
  departures: true,
  shown_nights: true,
  week_labels: false,
  background: true,
  month_labels: true,
  months_switcher: false,
  tooltip: true,
  best_price: true,
  arrows: true,
  lines: true,
  target: '_blank',
};
