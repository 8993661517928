import React, { lazy, Suspense, useState } from 'react';
import type { FC } from 'react';
import classnames from 'classnames';

import HeaderMenu, {
  HeaderMenuCategory,
} from '@lt/components/components/HeaderMenu';

import { isSSR } from 'src/helpers/ssr';
import HeaderFeedback from '../../HeaderFeedback';
import HeaderWishlist from '../../HeaderWishlist';

import type { IHeaderMenu } from '../types';
import styles from './desktopMenu.module.css';

const HeaderAuth = lazy(
  () =>
    import(
      /* webpackChunkName: "HeaderAuth" */ 'src/components/Header/components/HeaderAuth'
    ),
);

const DesktopMenu: FC<IHeaderMenu> = ({
  services,
  help,
  className,
  isCollapsed,
  showAuthorizationBlock = true,
  showWishlist = true,
  showFeedback,
  isHomepage,
}) => {
  /*
  Стейт showAuthFromWishlist нужен, чтобы при вызове авторизации из избранного  
  отображалась только одна форма авторизации
*/
  const [showAuthFromWishlist, setShowAuthFromWishlist] =
    useState<boolean>(false);
  return (
    <HeaderMenu
      className={classnames(className, { [styles.headerMenu]: isCollapsed })}
      isCompact={isCollapsed}
      isHomepage={isHomepage}
    >
      {/* https://leveltravel.atlassian.net/browse/LT-31772 */}
      {showFeedback && (
        <HeaderFeedback
          isCompact={isCollapsed}
          isHomepage={isHomepage}
          className={classnames({ [styles.homepageFeedback]: isHomepage })}
        />
      )}
      {services && services.items && services.items.length !== 0 && (
        <HeaderMenuCategory
          className={classnames({
            [styles.fullServices]: !isCollapsed,
            [styles.homepageServices]: isHomepage,
            [styles.services]: !isHomepage,
          })}
          isCompact={isCollapsed}
          isHomepage={isHomepage}
          {...services}
        />
      )}
      {showWishlist && (
        <HeaderWishlist
          isCompact={isCollapsed}
          setShowAuthFromWishlist={setShowAuthFromWishlist}
        />
      )}
      {help && help.items && help.items.length !== 0 && (
        <HeaderMenuCategory
          className={classnames({
            [styles.homepageHelp]: isHomepage,
            [styles.help]: !isHomepage,
          })}
          isCompact={isCollapsed}
          isHomepage={isHomepage}
          {...help}
        />
      )}
      {showAuthorizationBlock && !isSSR && (
        <Suspense fallback={<div />}>
          <HeaderAuth
            isCompact={isCollapsed}
            showAuthFromWishlist={showAuthFromWishlist}
          />
        </Suspense>
      )}
    </HeaderMenu>
  );
};
export default DesktopMenu;
