import { createSelector } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { getRoomId } from 'src/store/roomPopup/selectors';
import { getFromImmutableState } from '../../../../utils/immutableUtils';

const _getRooms = (state) => state.get('rooms');

export const getRooms = getFromImmutableState(_getRooms);
export const hasRooms = createSelector(getRooms, (rooms) => !isEmpty(rooms));

export const getCashbackForCheapestRoom = createSelector(
  getRooms,
  (rooms) => rooms?.[0]?.bonus_count || null,
);

export const getCashbackTypeForCheapestRoom = createSelector(
  getRooms,
  (rooms) => rooms?.[0]?.cashback_identifier,
);

export const getRoomPopupData = createSelector(
  [getRoomId, getRooms],
  (roomId, rooms) => {
    const room =
      rooms && roomId
        ? rooms.filter((element) => element.room.id === roomId)[0].room
        : undefined;
    if (!room) return undefined;
    return {
      name_ru: room.name_ru,
      description: room.description,
      facilities: room.facilities,
      images: room.images,
    };
  },
);
