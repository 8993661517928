export const handleCopy = async (promoCode: string): Promise<boolean> => {
  try {
    await navigator.clipboard.writeText(promoCode);
    return true;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Failed to copy code:', error);
    return false;
  }
};
