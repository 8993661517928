import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export type RoomPopupProps = {
  isOpened: boolean;
  packageId: number | null;
};

const initialState: RoomPopupProps = {
  isOpened: false,
  packageId: null,
};

const appBannerSlice = createSlice({
  name: 'roomPopup',
  initialState,
  reducers: {
    openAboutRoomPopup: (
      state: RoomPopupProps,
      action: PayloadAction<RoomPopupProps['packageId']>,
    ) => {
      state.isOpened = true;
      state.packageId = action.payload;
    },
    closeAboutRoomPopup: (state: RoomPopupProps) => {
      state.isOpened = false;
      state.packageId = null;
    },
  },
});

export const { openAboutRoomPopup, closeAboutRoomPopup } =
  appBannerSlice.actions;

const { name, reducer } = appBannerSlice;
export { reducer as default, name };
