import { createAction } from '@reduxjs/toolkit';

import * as VIEW from '../constants/view';

export {
  toggleMap as toggleMapAction,
  routeTransitionStart as routeTransitionStartAction,
  routeTransitionEnd as routeTransitionEndAction,
  expandTouristForm as expandTouristFormAction,
  setTopbarIndex as setTopbarIndexAction,
  toggleTopbar as toggleTopbarAction,
  setScrollPosition as setScrollPositionAction,
  toggleFiltersPanel as toggleFiltersPanelAction,
  setInitialOpenedField,
  setFlightsProgressBarIsSticky,
  setShowSummary as setShowSummaryAction,
  shrinkTouristForms as shrinkTouristFormsAction,
} from '../reducers/view';

export const goToHotelSectionAction = createAction(VIEW.GO_TO_HOTEL);

export const goToPathAction = createAction<string | undefined>(VIEW.GO_TO_PATH);

export const goToTouristsAction = createAction<number | undefined>(
  VIEW.GO_TO_TOURISTS,
);

export const goToTouristsHotelCheckoutAction = createAction<number>(
  VIEW.GO_TO_TOURISTS_HOTEL_CHECKOUT,
);

export const goToPayAction = createAction(VIEW.GO_TO_PAY);

export const goToClientAction = createAction(VIEW.GO_TO_CLIENT);

export const goToClientDataAction = createAction(VIEW.GO_TO_CLIENT_DATA);

export const goToFlightSectionAction = createAction(VIEW.GO_TO_FLIGHT);

export const scrollToTouristsAction = createAction(VIEW.SCROLL_TO_TOURISTS);

export const scrollToPaymentAction = createAction(VIEW.SCROLL_TO_PAYMENT_BLOCK);

export const scrollToLogInAction = createAction(VIEW.SCROLL_TO_LOG_IN);

export const scrollToTopAction = createAction(VIEW.SCROLL_TO_TOP);

export const scrollToBooking = createAction(VIEW.SCROLL_TO_BOOKING);

export const onClientClickAction = createAction('ON_CLIENT_CLICK');
