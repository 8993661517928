import { createSelector } from '@reduxjs/toolkit';
import { getImmutableCompatible } from 'src/utils/immutableUtils';
import type { HeaderWidgetStateType } from './types';

const getParams = (state): HeaderWidgetStateType =>
  getImmutableCompatible(state, 'headerWidget');

/**
 * Виджет в шапке открыт/закрыт
 */
export const getHeaderWidgetIsOpen = createSelector(
  getParams,
  (params) => params.showPopup,
);
