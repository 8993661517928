import type { SagaReturnType } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';
import { api } from 'src/api';
import { INIT_APP } from 'src/store/view/constants';

function* checkApiAvailabilty() {
  if (!window.is_webview) return;
  const response: SagaReturnType<typeof api.getHealthCheck> =
    yield api.getHealthCheck();
  if (response.success)
    window.parent.postMessage(
      { name: 'frameDataLoadSuccess', success: true },
      '*',
    );
}

export default function* viewSaga() {
  yield takeLatest(INIT_APP, checkApiAvailabilty);
}
