import React from 'react';

import { useTranslation } from '@lt/localization';
import classnames from 'classnames';
import { useLoadStatisticWidget } from '../../hooks';

import { WIDGET_ID } from './constants';
import { Skeleton } from './Skeleton';
import styles from './styles/statisticWidget.module.css';

const StatisticsWidget = (): JSX.Element => {
  const { isLoaded } = useLoadStatisticWidget();
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.headingContainer}>
        <div className={styles.headingTitle}>
          {t('home_statistics_widget.title')}
        </div>
        <div className={styles.headingDescription}>
          {t('home_statistics_widget.description')}
        </div>
      </div>
      <div className={styles.widgetContainer}>
        {!isLoaded && <Skeleton />}
        <div
          className={classnames(styles.widgetElement, {
            [styles.widgetElementHidden]: !isLoaded,
          })}
          id={WIDGET_ID}
        />
      </div>
    </div>
  );
};

export default StatisticsWidget;
