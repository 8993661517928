import Cookies from 'js-cookie';

export const setMobileCookie = (isMobile: boolean) => {
  const value = isMobile ? 'no' : 'yes';
  const domain = window.ENV_DOMAIN ? `.${window.ENV_DOMAIN}` : 'all';

  Cookies.set('mobile', value, {
    domain,
    path: '/',
  });
};
