import { createSelector } from '@reduxjs/toolkit';

import { name } from './slice';
import type { IHomeDisplayFlags } from './types';

const getHomeDisplayFlags = (state): IHomeDisplayFlags => state[name];

export const getIsStatisticWidgetDisabled = createSelector(
  [getHomeDisplayFlags],
  (homeDisplayFlags) => homeDisplayFlags.isStatisticWidgetDisabled,
);
