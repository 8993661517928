const AUTH_TOKEN = 'auth_token';

export const clientStorage = {
  getAuthToken: (): string | null => localStorage.getItem(AUTH_TOKEN),
  saveAuthToken: (authToken: string) => {
    localStorage.setItem(AUTH_TOKEN, authToken);
  },
  resetAuthToken: (): void => {
    localStorage.removeItem(AUTH_TOKEN);
  },
};
