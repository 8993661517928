import React, { useState } from 'react';

import { SearchWidgetPreview } from '@lt/components';

import { convertDatesToStringWithEllipsis } from '@lt/widget/components/Cigarette/utils';
import { getIsFeatureHasAnOption } from 'src/utils/featureToggle';
import { SEARCH_TYPE_NAMES } from 'src/constants/search';
import { SEARCH_WIDGET_PLACE_ID } from 'src/constants/header';
import { isSSR } from 'src/helpers/ssr';
import type { SearchType } from 'src/types/search';
import classnames from 'classnames';
import HeaderSearchForm from '../HeaderSearchForm';

import type { PropsFromRedux } from './index';
import styles from './headerSearchWidgetPreview.module.css';

export const HeaderSearchWidgetPreview = ({
  showPopup,
  onOpenModal,
  onCloseModal,
  isMobile,
  isWhitelabel,
  cigaretteValues,
  className,
  pageSearchType,
  availableSearchTypes,
  initialOpenedField,
}: PropsFromRedux & { className?: string }) => {
  const hasExcursionSearch =
    !isWhitelabel && getIsFeatureHasAnOption('cigarette_excursion', 'on');

  // Хак для страницы отеля, где availableSearchTypes имеют тип строки
  const availableSearchTypesArray =
    typeof availableSearchTypes === 'string'
      ? JSON.parse(availableSearchTypes)
      : availableSearchTypes;

  const previewAvailableSearchTypes = [
    ...availableSearchTypesArray,
    ...(hasExcursionSearch ? [SEARCH_TYPE_NAMES.TRIP] : []),
  ];

  const searchType =
    pageSearchType ||
    (availableSearchTypes &&
      availableSearchTypes.length >= 1 &&
      availableSearchTypes[0]) ||
    'package';
  const [currentSearchType, setCurrentSearchType] =
    useState<SearchType>(searchType);

  if (isSSR) return null;

  const startDate = cigaretteValues?.datepicker
    ? convertDatesToStringWithEllipsis(cigaretteValues?.datepicker.left)
    : undefined;

  const endDate = cigaretteValues?.datepicker
    ? convertDatesToStringWithEllipsis(cigaretteValues?.datepicker.right)
    : undefined;

  const setCurrentSearchTypeOnModal = (searchTypeParam?: SearchType) => {
    if (
      searchTypeParam === 'package' ||
      searchTypeParam === 'hotel' ||
      searchTypeParam === 'trip'
    )
      setCurrentSearchType(searchTypeParam);
    else {
      setCurrentSearchType(searchType);
    }
    onOpenModal();
  };

  return (
    <SearchWidgetPreview
      className={classnames(styles.searchWidgetPreview, className)}
      availableSearchTypes={previewAvailableSearchTypes}
      showPopup={showPopup}
      onOpenModal={setCurrentSearchTypeOnModal}
      onCloseModal={onCloseModal}
      destinationName={cigaretteValues?.destination.label}
      startDate={startDate}
      endDate={endDate}
      personsCount={
        (cigaretteValues?.tourists?.adults || 0) +
        (cigaretteValues?.tourists?.kids.length || 0)
      }
      isMobile={isMobile}
      PopupComponent={
        <div
          className={styles.widgetWrapper}
          style={{ padding: isMobile ? '8px' : '8px 16px 16px' }}
          id={SEARCH_WIDGET_PLACE_ID}
        >
          <HeaderSearchForm
            cigaretteValues={cigaretteValues}
            pageSearchType={currentSearchType}
            isMobile={isMobile}
            availableSearchTypes={availableSearchTypes}
            inPreview
            initialOpenedField={initialOpenedField}
          />
        </div>
      }
      searchType={searchType}
    />
  );
};
