import React, { useRef, useState, useEffect } from 'react';

import ScrollButton from '../ScrollButton';

import { getNewScrollPosition } from '../../utils';

import {
  SCROLLABLE_CONTAINER_DESKTOP_TEST_ID,
  SCROLL_BUTTON_WIDTH,
} from '../../constants';
import type { IScrollableContainer, ScrollDirection } from '../../types';
import { RELINKING_VARIANTS } from '../../../../constants';
import styles from './scrollableContainerDesktop.module.css';

export const ScrollableDesktopContainer = (
  props: IScrollableContainer,
): JSX.Element => {
  const { children, variant = RELINKING_VARIANTS.DEFAULT_COUNTRIES } = props;
  const childrenRef = useRef<HTMLDivElement | null>(null);

  const [isScrollRightVisible, setIsScrollRightVisible] = useState(true);
  const [isScrollLeftVisible, setIsScrollLeftVisible] = useState(false);

  useEffect(() => {
    handleScroll();
  }, []);

  const isFirstCardBig = variant === RELINKING_VARIANTS.DEFAULT_COUNTRIES;

  const handleScrollButtonClick = (direction: ScrollDirection): void => {
    const content = childrenRef.current;
    if (!content) return;

    const newScrollPosition = getNewScrollPosition(
      content.scrollLeft,
      direction,
      isFirstCardBig,
    );
    const maxScrollPosition = content.scrollWidth - content.offsetWidth;

    content.scrollTo({
      left: Math.min(newScrollPosition, maxScrollPosition),
      behavior: 'smooth',
    });
  };

  const handleScroll = (): void => {
    const content = childrenRef.current;
    if (!content) return;

    const currentScrollPosition = content.scrollLeft;
    const maxScrollPosition =
      content.scrollWidth - content.offsetWidth - SCROLL_BUTTON_WIDTH;

    const enableScrollRight = currentScrollPosition < maxScrollPosition;
    setIsScrollRightVisible(enableScrollRight);

    const enableScrollLeft = currentScrollPosition > SCROLL_BUTTON_WIDTH;
    setIsScrollLeftVisible(enableScrollLeft);
  };

  return (
    <div data-testid={SCROLLABLE_CONTAINER_DESKTOP_TEST_ID}>
      <div className={styles.wrapper} ref={childrenRef} onScroll={handleScroll}>
        {isScrollLeftVisible && (
          <ScrollButton
            isLeft
            handleScroll={() => handleScrollButtonClick('left')}
            variant={variant}
          />
        )}
        {children}
        {isScrollRightVisible && (
          <ScrollButton
            handleScroll={() => handleScrollButtonClick('right')}
            variant={variant}
          />
        )}
      </div>
    </div>
  );
};
