import React from 'react';
import { Link } from '@lt/components';
import { i18n } from '@lt/localization';
import styles from './BottomSection.module.css';
import Logos from './Logos';

const BottomSection = () => (
  <div className={styles.bottomSection}>
    <section className={styles.legalInfo}>
      <div>
        <Logos />
        <p>{i18n.t('footer.legalInformation.levelTravel')}</p>
        <p>{i18n.t('footer.legalInformation.unigate')}</p>
        <p>{i18n.t('footer.legalInformation.priceInfo')}</p>
      </div>
      <div className={styles.rightContainer}>
        <p>{i18n.t('footer.legalInformation.infoDisclaimer')}</p>
        <div className={styles.linksSection}>
          <Link className={styles.legalLink} href="/agreements">
            {i18n.t('footer.legalInformation.furtherDetailsLink')}
          </Link>
          <Link
            className={styles.legalLink}
            href="/articles/Privacy_Policy_Level_Travel"
          >
            {i18n.t('footer.legalInformation.privacyPolicy')}
          </Link>
        </div>
      </div>
    </section>
  </div>
);

export default BottomSection;
