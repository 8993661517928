/**
 * @param {string} utmString "123123123.124214.11.1.utmcsr=tagassistant.google.com|utmccn=(organic)|utmcmd=organic|utmctr=(not provided)"
 * @returns {array} ["utmcsr=tagassistant.google.com", "utmccn=(organic)", "utmcmd=organic", "utmctr=(not provided)"]
 */
export const parseUTMParams = (utmString: string): string[] =>
  [...utmString.matchAll(/utm[a-z]+=([^|])+/g)].map((match) => match[0]);

// Трансформировать utm для диплинка
export const transformUTMKey = (utmKey: string): string => {
  switch (utmKey) {
    case 'utmcsr':
      return 'utm_source';
    case 'utmccn':
      return 'utm_campaign';
    case 'utmcmd':
      return 'utm_medium';
    case 'utmctr':
      return 'utm_term';
    case 'utmcct':
      return 'utm_content';
    default:
      return '';
  }
};
