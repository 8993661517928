import { createSelector } from '@reduxjs/toolkit';

import { LEVEL_TRAVEL_OPERATOR_ID } from 'src/constants/flights';
import {
  pluralizeAdults,
  pluralizeKids,
} from '../../../../utils/pluralizeUtils';

import { CHECKOUT_LAYOUT, PRICE_RANGES } from '../constants/package';

import type { PackageStateType } from '../package/types';
import { getRoomPopupPackageId } from '../domains/room-popup/selectors';
import { getRoomInfo as getRoomInfoSelector } from '../domains/room-info/selectors';

export const getPackage = (state): PackageStateType => state.package;

const getDatesInfo = createSelector(
  getPackage,
  (packageState) => packageState.dates_info,
);

export const getMiscData = createSelector(
  getPackage,
  (packageState) => packageState.misc_data,
);

const getExtraBed = createSelector(
  getMiscData,
  (miscData) => miscData?.extra_beds_content,
);

export const getPackageId = createSelector(
  getPackage,
  (packageState) => packageState.id,
);

export const getTourId = createSelector(
  getPackage,
  (packageState) => packageState.tour_id,
);

export const getSurcharge = createSelector(getPackage, (packageState) =>
  parseFloat(packageState.surcharge || '0'),
);

export const getOriginalRoomInfo = createSelector(
  [getRoomInfoSelector, getExtraBed],
  (info, extraBed) => {
    const name = info.name_ru;
    const altName = extraBed ? `${name} ${extraBed}` : name;

    return {
      ...info,
      altName,
    };
  },
);

export const getRoomInfo = createSelector(
  [getRoomPopupPackageId, getOriginalRoomInfo],
  (packageId, originalRoomInfo) => originalRoomInfo,
);

export const getAdultsCount = createSelector(
  getPackage,
  (packageState) => packageState.adults_count,
);

export const getKids = createSelector(
  getPackage,
  (packageState) => packageState.kids_ages,
);

export const getKidsCount = createSelector(
  getPackage,
  (packageState) => packageState.kids_count,
);

export const getStartDate = createSelector(getDatesInfo, (datesInfo) =>
  datesInfo ? datesInfo.start_date : null,
);

export const getCheckInDate = createSelector(getDatesInfo, (datesInfo) =>
  datesInfo ? datesInfo.check_in : null,
);

export const getEndDate = createSelector(getDatesInfo, (datesInfo) =>
  datesInfo ? datesInfo.end_date : null,
);

export const getCheckOutDate = createSelector(getDatesInfo, (datesInfo) =>
  datesInfo ? datesInfo.check_out : null,
);

// original_nights >= nights_count
export const getNightsCount = createSelector(getDatesInfo, (datesInfo) =>
  datesInfo ? datesInfo.original_nights : 0,
);

export const getNightsInHotel = createSelector(getDatesInfo, (datesInfo) =>
  datesInfo ? datesInfo.nights_count : 0,
);

export const getMealCode = createSelector(
  getPackage,
  (packageState) => packageState.pansion,
);

export const getMeal = createSelector(
  getPackage,
  (packageState) => packageState.pansion_description,
);

export const getMealOriginal = createSelector(
  getPackage,
  (packageState) => packageState.pansion_original,
);

export const getRegionNameRu = createSelector(
  getPackage,
  (packageState) => packageState.region_name,
);

// TODO: makeGetKids -> getKids
export const makeGetKids = getKids;

export const getTouristsText = createSelector(
  [getAdultsCount, getKidsCount],
  (adultsCount, kids) =>
    `${adultsCount} ${pluralizeAdults(adultsCount)}${
      kids ? `, ${kids} ${pluralizeKids(kids)}` : ''
    }`,
);

export const getTouristsCount = createSelector(
  [getAdultsCount, getKidsCount],
  (adultsCount, kids) => (adultsCount || 0) + (kids || 0),
);

export const getInstantConfirm = createSelector(
  getPackage,
  (packageState) => packageState.instant_confirm,
);

export const getCancellationPolicy = createSelector(
  getPackage,
  (packageState) => packageState.cancellation_policy,
);

export const getOperator = createSelector(
  getPackage,
  (packageState) => packageState.operator,
);

export const getOperatorName = createSelector(
  getOperator,
  (operator) => operator?.name,
);

export const getOperatorId = createSelector(
  [getOperator, getPackage],
  (operator, packageState) => operator?.id || packageState.operator_id,
);

export const isLTOperatorSelector = createSelector(
  getOperatorId,
  (operatorId) => operatorId === LEVEL_TRAVEL_OPERATOR_ID,
);

export const getNetPrice = createSelector(getPackage, (packageState) =>
  Number(packageState.net_price),
);

export const getPreviousPrice = createSelector(
  getPackage,
  (packageState) => parseFloat(packageState.previous_price || '') || null,
);

export const getPriceRange = createSelector(getNetPrice, (netPrice) => {
  const { ranges, maxValue } = PRICE_RANGES;
  const priceRange = ranges.find(
    ({ min, max }) => netPrice >= min && netPrice < max,
  );

  return priceRange ? priceRange.category : maxValue;
});

export const getMinimalFuelCharge = createSelector(getPackage, (packageState) =>
  Number(packageState.fuel_charge),
);

export const getTransfer = createSelector(
  getPackage,
  (packageState) => packageState.transfer,
);

export const getIsPrivateTransfer = createSelector(
  getMiscData,
  (miscData) => miscData?.transfer_type === 'private',
);

export const getMedicalInsurance = createSelector(
  getPackage,
  (packageState) => packageState.medical_insurance,
);

export const getHotelId = createSelector(
  getPackage,
  (packageState) => packageState.hotel_id,
);

export const getHotelAvailability = createSelector(
  getPackage,
  (packageState) => packageState.availability,
);

export const getLabels = createSelector(
  getPackage,
  (packageData) => packageData?.labels,
);

export const getHotelLabels = createSelector(
  getLabels,
  (labels) =>
    labels &&
    labels.filter((label) => label.checkout_layout === CHECKOUT_LAYOUT.HOTEL),
);

export const getTourLabels = createSelector(
  getLabels,
  (labels) =>
    labels &&
    labels.filter((label) => label.checkout_layout === CHECKOUT_LAYOUT.TOUR),
);

export const getConfirmabilityIndex = createSelector(
  getPackage,
  (packageState) => packageState.confirmability_index,
);

export const getRequestId = createSelector(
  getPackage,
  (packageState) => packageState.request_id,
);

export const getSearchRequest = createSelector(
  getPackage,
  (packageState) => packageState.search_request,
);

export const getNotices = createSelector(
  getPackage,
  (packageState) => packageState?.notices,
);

export const getDepartureCityId = createSelector(
  getPackage,
  (packageState) => packageState.departure_id,
);
export const getBookingUrl = createSelector(
  getPackage,
  (packageState) => packageState.booking_url,
);
export const getOperatorSearchUrl = createSelector(
  getMiscData,
  (miscData) => miscData?.op_search_url,
);

export const getIsManualPackage = createSelector(
  getPackage,
  (packageState) => packageState.manual,
);

export const getOperatorRoomName = createSelector(
  getPackage,
  (packageState) => packageState.original_room,
);

export const getArchivedAt = createSelector(
  getPackage,
  (packageState) => packageState.archived_at,
);

export const getIsFuture = createSelector(
  getStartDate,
  (startDate) => new Date(startDate || '').getTime() > new Date().getTime(),
);

export const getHot = createSelector(
  getPackage,
  (packageState) => packageState.hot,
);

export const bundleIdSelector = createSelector(
  getMiscData,
  (miscData) => miscData?.bundle_id,
);

/**
 * bundle_id от динамики имеет следующий вид:
 * "{ДВЕ ЦИФРЫ ПОСТАВЩИКА АВИА}{8 букв}|{ДВЕ ЦИФРЫ ПОСТАВЩИКА ОТЕЛЯ}{8 букв}",
 * эта утилка достает id поставщика авиа или отеля
 */
const getSupplierIdFromBundleId = (
  bundleId: string | undefined,
  type: 'avia' | 'hotel',
) => bundleId && bundleId.split('|')[type === 'avia' ? 0 : 1].slice(0, 2);

export const aviaSupplierCodeSelector = createSelector(
  bundleIdSelector,
  (bundleId) => getSupplierIdFromBundleId(bundleId, 'avia'),
);

export const hotelSupplierCodeSelector = createSelector(
  bundleIdSelector,
  (bundleId) => getSupplierIdFromBundleId(bundleId, 'hotel'),
);

export const acmComponentIdSelector = createSelector(
  getMiscData,
  (miscData) => miscData?.acm_component_id,
);

/**
 * acm_component_id - это что-то типа bundle_id для отельного поиска, формат:
 * "{ДВЕ ЦИФРЫ ПОСТАВЩИКА}-{7 БУКВ И/ИЛИ ЦИФР}"
 * Этот селектор достает id поставщика отеля из acm_component_id
 */
export const supplierFromAcmComponentIdSelector = createSelector(
  acmComponentIdSelector,
  (acmComponentId) => acmComponentId?.slice(0, 2),
);
