import { createSlice } from '@reduxjs/toolkit';

import { isSSR } from '../../helpers/ssr';
import type { HeaderMenu } from './types';

const initialState: Partial<HeaderMenu> = isSSR ? {} : window.HeaderMenu || {};

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {},
});

const { name, reducer } = menuSlice;

export { reducer as default, name };
