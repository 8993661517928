import { reportWebVitals } from 'src/helpers/reportWebVitals';
import { reportGoogleTranslate } from 'src/utils/reportGoogleTranslate/reportGoogleTranslate';
import { renderReactWithStyledComponents } from '../helpers/renderComponent';
import ModernDesktopHomeApp from '../routes/ModernDesktopHome';

import '../styles/pages/modern_desktop_home_page.scss';

export default renderReactWithStyledComponents(
  'ModernDesktopHomeApp',
  ModernDesktopHomeApp,
);

reportWebVitals();

reportGoogleTranslate();
