import { getClient, replayCanvasIntegration } from '@sentry/browser';
import type { BrowserClient } from '@sentry/browser';

import {
  IS_CANVAS_REPLAY_AVAILABLE,
  IS_PERFORMANCE_AVAILABLE,
  IS_SENTRY_SHOW_FEEDBACK_WIDGET,
  IS_SESSION_REPLAY_AVAILABLE,
  IS_SESSION_REPLAY_ON_ERROR_AVAILABLE,
} from './runTimeConfig';
import type { SentryAsyncIntegrationsType } from '../../types/sentryTypes';

export const sentryAsyncIntegrations = async ({
  isPerformanceAvailable = IS_PERFORMANCE_AVAILABLE,
  isSessionReplayAvailable = IS_SESSION_REPLAY_AVAILABLE,
  isSentryShowFeedbackWidget = IS_SENTRY_SHOW_FEEDBACK_WIDGET,
  isReplaySessionOnErrorAvailable = IS_SESSION_REPLAY_ON_ERROR_AVAILABLE,
  isCanvasReplayAvailable = IS_CANVAS_REPLAY_AVAILABLE,
}: SentryAsyncIntegrationsType): Promise<void> => {
  const client: BrowserClient | undefined = getClient();

  if (!client) return;

  /**
   * профилирует запросы к api.level.travel в сентри
   */
  if (isPerformanceAvailable) {
    const { BrowserTracing } = await import('@sentry/browser');

    client.addIntegration(
      new BrowserTracing({
        // профилируем запросы только для нашего домена
        tracePropagationTargets: [`api.${window.BASE_DOMAIN}`],
      }),
    );
  }

  /**
   * включаем Session Replay
   */
  if (isSessionReplayAvailable) {
    // eslint-disable-next-line import/no-extraneous-dependencies
    const { Replay } = await import('@sentry/replay');
    /** Будем записывать сессии пользователей6 которые не попали под выборку отправок ошибок, иначе реплаи конфликтуют */
    if (isReplaySessionOnErrorAvailable) {
      const replay = new Replay({
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false,
      });
      client.addIntegration(replay);
    }
  }

  // Запись canvas в Sentry Session Replay
  if (isSessionReplayAvailable && isCanvasReplayAvailable) {
    client.addIntegration(replayCanvasIntegration());
  }

  /**
   * Включаем виджет фидбэка
   * window.is_mobile вынужденная мера потому что фидбэк на мобилке выглядит ужасно
   */
  if (isSentryShowFeedbackWidget && !window.is_mobile) {
    const { Feedback } = await import('@sentry/browser');
    client.addIntegration(
      new Feedback({
        colorScheme: 'light',
      }),
    );
  }
};
