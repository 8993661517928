import { useCallback, useState, useEffect } from 'react';
import type { BannerVisible } from 'src/types/cookies';
import {
  getIsCookieHasAnOption,
  removeCookie,
  setCookie,
} from 'src/utils/cookiesController';

/**
 * Хук для управления поведением баннеров на основе куки
 * может скрывать баннер на expiresHiding кол-во дней
 * если передать пустой expiresHiding скрытие баннера будет на сессию (то есть до закрытия браузера)
 ** Перед использованием не забудьте зарегистрировать куку для управления баннером в файле src/types/cookies.ts
 * @param {string} [somebody=lt_banner_test_banner] key - Ключ куки, которая управляет временем скрытия баннера
 * @param {(number | undefined)} [somebody=1] expiresHiding - Целое число дней после которых кука протухнет
 */
export const useShowBannerController = <Key extends keyof BannerVisible>(
  key: Key,
  expiresHiding?: number,
) => {
  const [isShowBanner, setIsShowBanner] = useState<boolean>(
    !getIsCookieHasAnOption(key, 'hiding'),
  );

  useEffect(() => {
    if (isShowBanner && !getIsCookieHasAnOption(key, 'show')) {
      removeCookie(key);
      setCookie(key, 'show');
    }
    if (!isShowBanner && !getIsCookieHasAnOption(key, 'hiding')) {
      removeCookie(key);
      setCookie(key, 'hiding', expiresHiding);
    }
  }, [expiresHiding, isShowBanner, key]);

  const handleCloseBanner = useCallback(() => {
    setIsShowBanner(false);
  }, []);

  return {
    isShowBanner,
    handleCloseBanner,
  } as const;
};
