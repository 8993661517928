export const RELINKING_VARIANTS = {
  DEFAULT_COUNTRIES: 'default_countries',
  COUNTRIES_WITH_PRICES: 'countries_with_prices',
  METAREGIONS: 'metaregions',
  HOTELS: 'hotels',
  NARRATIVE: 'narrative',
} as const;

export const DATA_TEST_ID = 'home-relinking-blocks-container';
export const COUNTRIES_DATA_TEST_ID = 'home-relinking-countries-block';
export const COUNTRIES_WITH_PRICES_DATA_TEST_ID =
  'home-relinking-countries-with-prices-block';
export const METAREGIONS_DATA_TEST_ID = 'home-relinking-metaregions-block';
export const HOTELS_DATA_TEST_ID = 'home-relinking-hotels-block';
export const NARRATIVE_DATA_TEST_ID = 'home-relinking-narrative-cards-block';
