import { createSelector } from '@reduxjs/toolkit';

import type { TouristsState } from './slice';

import { name } from '.';

/**
 * Туристы присутствуют на
 * - отельном чекауте
 * - туровом чекауте
 * - на странице заказа
 */

/**
 * Список туристов
 */
export const touristsSelector = (state): TouristsState[] => state[name];

/**
 * Турист полученный по индексу
 */
export const touristSelector = (state, index: number) =>
  touristsSelector(state)[index];

/**
 * Список туристов, у которых не пропущенны документы
 */
export const touristsSkipDocSelector = createSelector(
  touristsSelector,
  (tourists) => {
    const isTouristsSkipDoc = tourists.find((tourist) => tourist.hideDocInputs);

    return Boolean(isTouristsSkipDoc);
  },
);

export const editedTouristsSelector = createSelector(
  touristsSelector,
  (editedTourist) => {
    editedTourist.filter(
      (tourist) =>
        tourist.name !== '' ||
        tourist.surname !== '' ||
        tourist.patronymic !== '' ||
        tourist.passport_number !== '' ||
        tourist.passport_expires_on !== '' ||
        tourist.birthday !== '',
    );
  },
);

export const touristInfoForCustomerSelector = (state, index) => {
  const touristInfo = touristSelector(state, index);

  if (!touristInfo) {
    return {
      name: null,
      surname: null,
      patronymic: null,
      passportNumber: null,
      nationalityId: null,
      address: null,
      gender: null,
      birthday: null,
      email: null,
      phone: null,
      // Подставить туриста как покупателя можно, только если у туриста паспорт РФ
      docType: 'internal_passport' as const,
    };
  }

  return {
    name: touristInfo?.name || null,
    surname: touristInfo?.surname || null,
    patronymic: touristInfo.patronymic || '',
    passportNumber: touristInfo?.passport_number || null,
    nationalityId: touristInfo?.nationality_id || null,
    address: null,
    gender: touristInfo?.gender || null,
    birthday: touristInfo?.birthday || null,
    email: null,
    phone: null,
    // Подставить туриста как покупателя можно, только если у туриста паспорт РФ
    docType: 'internal_passport' as const,
  };
};
