import type { PayloadAction, Draft } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { nestedFilters } from './constants';
import type {
  FlightsFilter,
  FlightsFilters,
  ChangeFilterPayload,
  ClearFilterPayload,
  FlightsFiltersSlice,
} from './types';

import {
  transformResponse,
  unwrapIfNestedFilter,
  filterEmptyFilters,
  getTOFiltersFromUrl,
} from './utils';

export const name = 'flightsFilters';

// Фильтры по полетам на первой странице чекаута

export const initialState: FlightsFiltersSlice = {
  isInitialFlightsFetchingDone: false,
  filters: {
    arrival_airports: { airports: [], airportCities: [] },
    backward_airlines: [],
    backward_departure_intervals: [],
    departure_airports: { airports: [], airportCity: '' },
    flight_classes: [],
    flight_types: [],
    forward_airlines: [],
    forward_departure_intervals: [],
    luggage_types: [],
    transitions_count: [],
    transitions_time: [],
    operators: [],
  },
  preselectedFilters: {
    operators: getTOFiltersFromUrl(),
  },
};

// reducer + actions
const flightsFilters = createSlice({
  name,
  initialState,
  reducers: {
    setFlightsFilters(
      state: Draft<FlightsFiltersSlice>,
      action: PayloadAction<FlightsFilters>,
    ) {
      const { payload } = action;
      state.filters = transformResponse(payload);
      return state;
    },

    setSpecificFilter(
      state,
      action: PayloadAction<{ filterName: string; filter: FlightsFilter[] }>,
    ) {
      const { filterName, filter } = action.payload;
      state.filters[filterName] = filter;
    },

    changeFlightsFilter(
      state: Draft<FlightsFiltersSlice>,
      action: PayloadAction<ChangeFilterPayload>,
    ) {
      const { path, diff } = action.payload;
      const { id } = diff;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { name: _, ...newOptionState } = diff;

      const filterDataToChange = unwrapIfNestedFilter(state.filters, path);

      const filterOptionToChange = filterDataToChange.find(
        (filterOption: FlightsFilter) => filterOption.id === id,
      );

      if (filterOptionToChange) {
        const fieldsToChange = Object.entries(newOptionState);
        fieldsToChange.forEach(([fieldName, fieldValue]) => {
          filterOptionToChange[fieldName] = fieldValue;
        });
      }

      if (state.preselectedFilters[path]) {
        state.preselectedFilters[path] = state.preselectedFilters[path].filter(
          (el) => el !== filterOptionToChange?.id,
        );
      }

      return state;
    },

    setUnfilteredFlightsCount(
      state: Draft<FlightsFiltersSlice>,
      action: PayloadAction<number>,
    ) {
      state.unfilteredFlightsCount = action.payload;
    },

    clearFlightsFilter(
      state: Draft<FlightsFiltersSlice>,
      action: PayloadAction<ClearFilterPayload>,
    ) {
      const { payload } = action;
      const filterDataToUpdate = unwrapIfNestedFilter(state.filters, payload);

      if (state.preselectedFilters[payload]) {
        state.preselectedFilters[payload] = [];
      }

      filterDataToUpdate.forEach((filterOption) => {
        filterOption.selected = false;
      });
      return state;
    },

    clearAllFligthsFilters(state: Draft<FlightsFiltersSlice>) {
      const cleanFilters = Object.fromEntries(
        Object.entries(state.filters)
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .filter(filterEmptyFilters as any)
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .map((filter: any) => {
            const [filterName] = filter;

            const filterDataToUpdate = unwrapIfNestedFilter(
              state.filters,
              filterName,
            );

            const updatedFilterOptions = filterDataToUpdate.map(
              (filterOption) => ({
                ...filterOption,
                selected: false,
              }),
            );

            if (nestedFilters.includes(filterName))
              return [
                filterName,
                { ...filterDataToUpdate, airports: updatedFilterOptions },
              ];
            return [filterName, updatedFilterOptions];
          }),
      );

      state.filters = cleanFilters;
      state.preselectedFilters = { operators: [] };
      return state;
    },

    setIsInitialFlightsFetchingDone(
      state: Draft<FlightsFiltersSlice>,
      action: PayloadAction<boolean>,
    ) {
      state.isInitialFlightsFetchingDone = action.payload;
    },
  },
});

export const {
  setFlightsFilters,
  changeFlightsFilter,
  clearFlightsFilter,
  clearAllFligthsFilters,
  setUnfilteredFlightsCount,
  setIsInitialFlightsFetchingDone,
  setSpecificFilter,
} = flightsFilters.actions;

export default flightsFilters.reducer;
