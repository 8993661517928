import CookiesLib from 'js-cookie';
import type { Cookies } from 'src/types/cookies';

export const getCookie = <Key extends keyof Cookies>(
  key: Key,
): string | undefined => {
  const value = CookiesLib.get(key);

  return value;
};

export const getIsCookieHasAnOption = <Key extends keyof Cookies>(
  cookiesName: Key,
  optionName: Cookies[Key],
): boolean => getCookie(cookiesName) === optionName;

export const setCookie = <Key extends keyof Cookies>(
  cookiesName: Key,
  optionName: Cookies[Key],
  expires?: number,
): void => {
  CookiesLib.set(cookiesName, optionName, { expires });
};

export const removeCookie = <Key extends keyof Cookies>(
  cookiesName: Key,
): void => CookiesLib.remove(cookiesName);
