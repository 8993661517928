import { createSlice } from '@reduxjs/toolkit';
import type { ICountry } from './types';

export type CountriesState = { list: ICountry[] };

const initialState: CountriesState = {
  list: [],
};

export const name = 'countries';

const countriesSlice = createSlice({
  name,
  initialState,
  reducers: {},
});

export default countriesSlice;
