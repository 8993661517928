import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { PopupSliceState } from './types';

const initialState: PopupSliceState = {
  current: 'none',
};

const popupSlice = createSlice({
  name: 'popup',
  initialState,
  reducers: {
    setPopupState: (
      state,
      action: PayloadAction<PopupSliceState['current']>,
    ) => ({
      ...state,
      current: action.payload,
    }),
    resetPopupState: () => ({
      current: 'none' as const,
    }),
  },
});

export const { setPopupState, resetPopupState } = popupSlice.actions;

export default popupSlice.reducer;
