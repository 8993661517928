export const BIG_COUNTRY_CARD_WIDTH = 330;
export const DEFAULT_COUNTRY_CARD_WIDTH = 244;
export const METAREGION_CARD_WIDTH = 248;
export const SCROLL_BUTTON_WIDTH = 56;

export const SCROLLABLE_CONTAINER_MOBILE_TEST_ID =
  'home-relinking-scrollable-container-mobile';

export const SCROLLABLE_CONTAINER_DESKTOP_TEST_ID =
  'home-relinking-scrollable-container-desktop';

export const SCROLL_BUTTON_TEST_ID = 'home-relinking-scroll-button';
