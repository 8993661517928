import {
  DEPARTURE_FOR_INIT,
  storageKeys,
} from '@lt/widget/components/Cigarette/constants';
import type { RequiredValues } from '@lt/widget/components/Cigarette/context';
import { isSSR } from 'src/helpers/ssr';
import type { EnqueueSearchParamsTo } from 'src/store/enqueue/types';

/**
 * Returns flex nights range by given nights
 * @param {Number} nights searched nights count, pass 7
 * @param {Object} options { flexCount, minNights, maxNights }
 * @return {Array[Number]} [5, 9]
 */
export const getNightsRange = (
  nights,
  options = { flexCount: 2, minNights: 1, maxNights: Infinity },
) => {
  const { flexCount, minNights, maxNights } = options;
  const nightsFrom = Math.max(nights - flexCount, minNights);
  const nightsTo = Math.min(maxNights, nights + flexCount);
  return [nightsFrom, nightsTo];
};

/**
 * Check flex nights
 * @param {Object} nights {from: 5, to: 9}
 * @returns {Boolean} true
 */
export const isFlexNights = (nights) => {
  if (!nights) return null;

  const { from, to } = nights;
  return to > from;
};

/**
 * Возвращает количество ночей одним числом
 * @param {Object} nights {from: 5, to: 9}
 * @returns {Number} 7
 * @deprecated спорная функция, которая может приводить к непоределенности в различных случаях, так что лучше не использовать. Проблема:
 * Данная функция думает, что флекс всегда две ночи, а что будет если флекс всего одна вперед ночь (те мы пользователя выкидываем за выбранные им даты)
 * Плюс данная функция бесполезна, так как у нас с бэка ночи все приходят и не понятно для чего эта функция вообще нужна
 */
export const getNightsFromObject = (nights): number | null => {
  if (!nights) return null;

  const { to } = nights;
  const FLEX_NIGHTS = 2;

  if (isFlexNights(nights)) {
    return to - FLEX_NIGHTS;
  }

  return to;
};

const defaultAdditionalCountriesParams = {
  budget: 0,
  closed: false,
  visa: '',
};

/**
 * Собираем данные destination, в зависимости от места отправления,
 * для селектора инициализации сигареты
 */
export const getDestinationParams = ({
  city,
  country,
  hotel,
  metaregion,
}: EnqueueSearchParamsTo): RequiredValues['destination'] => {
  if (metaregion) {
    return {
      groupName: 'metaregions',
      value: metaregion.id,
      label: metaregion.name_ru,
      description: '',
    };
  }
  if (hotel) {
    return {
      groupName: 'hotels',
      value: hotel.id,
      label: hotel.name,
      countryName: country?.name_ru || '',
      cityName: city?.name_ru || '',
      publicUrl: hotel.public_url,
      iso2: country?.iso2 || undefined,
    };
  }
  if (city?.id) {
    return {
      groupName: 'regions',
      value: city.id,
      label: city.name_ru,
      iso2: country?.iso2 || undefined,
      countryName: country?.name_ru || '',
      activeRegions: '',
    };
  }
  return {
    groupName: 'countries',
    value: country?.id || 0,
    label: country?.name_ru || '',
    nameEn: country?.name_en || '',
    nameRuForm3: country?.name_ru_form3 || '',
    iso2: country?.iso2 || undefined,
    ...defaultAdditionalCountriesParams,
  };
};

export const getDefaultDeparture = () => {
  const departureAfterHotelSearch = isSSR
    ? undefined
    : JSON.parse(localStorage.getItem(storageKeys.departure) || 'null');

  return departureAfterHotelSearch || DEPARTURE_FOR_INIT;
};
