import { createSelector } from '@reduxjs/toolkit';
import { HEADER_OFFSET } from 'src/constants/header';
import {
  getOperatorName,
  getBookingUrl,
  getNetPrice,
  getPreviousPrice,
  getTransfer,
  getMedicalInsurance,
  getIsPrivateTransfer,
} from '../../../../store/selectors/package';

import {
  cheapestFlightSelector,
  cheapestFlightTourIdSelector,
} from '../flights/selectors';

import { name } from './slice';

import {
  getSelectedFlightData,
  getOriginalPackageOrderBonuses,
} from '../../../../store/selectors/order';
import type { ActualizeAlternativesSlice } from './types';
import { getIsFlightsProgressBarSticky } from '../../../../store/selectors/view';
import { getIsHomePath } from '../../../../store/selectors/router';

export const getActualizeAlternativesSlice = (
  state,
): ActualizeAlternativesSlice => state[name];

export const getActualizeAlternativesStatusInfo = createSelector(
  [getActualizeAlternativesSlice],
  (slice) => slice.status_information,
);

export const getActualizeAlternativesStatus = createSelector(
  [getActualizeAlternativesStatusInfo],
  (statusInfo) => statusInfo.status,
);

export const getIsActualizeAlternativesFinished = createSelector(
  [getActualizeAlternativesStatus],
  (actualizationStatus) => actualizationStatus === 'ready',
);

export const getTotalAltToursCount = createSelector(
  [getActualizeAlternativesStatusInfo],
  (statusInfo) => statusInfo.tours_total_count,
);

export const getProcessedAltToursCount = createSelector(
  [getActualizeAlternativesStatusInfo],
  (statusInfo) => statusInfo.tours_processed_count,
);

export const getAlternativeTours = createSelector(
  [getActualizeAlternativesSlice],
  (slice) => slice.alternative_tours,
);

export const altTourAmountSelector = createSelector(
  [getAlternativeTours],
  (altTours) => altTours?.length,
);

export const getAlternativeToursIds = createSelector(
  [getAlternativeTours],
  (altTours) => altTours.map((altTour) => altTour.tour_id),
);

export const getActualizeAltStatistic = createSelector(
  [getActualizeAlternativesSlice],
  (slice) => slice.statistic,
);

export const getActualizeAltCheapestOption = createSelector(
  [getActualizeAltStatistic],
  (statistic) => statistic.cheapest_option,
);

export const getAltTourById = createSelector(
  [getAlternativeTours, (_altTours, tour_id: string | undefined) => tour_id],
  (altTours, tour_id) =>
    altTours.find((altTour) => altTour.tour_id === tour_id),
);

export const getTourOperatorNameById = createSelector(
  [(state, tour_id: string) => getAltTourById(state, tour_id), getOperatorName],
  (altTour, operatorName): string | undefined => {
    if (altTour) {
      return altTour.operator_info.name;
    }
    return operatorName;
  },
);

export const getTourOperatorLinkById = createSelector(
  [(state, tour_id: string) => getAltTourById(state, tour_id), getBookingUrl],
  (altTour, bookUrl) => {
    if (altTour) {
      return altTour.booking_url;
    }
    return bookUrl;
  },
);

export const getPackagePriceWithAlternatives = createSelector(
  [
    (state, tour_id: string | undefined) => getAltTourById(state, tour_id),
    getNetPrice,
  ],
  (altTour, netPrice: number): number =>
    altTour ? Number(altTour.price) : netPrice,
);

export const altExtrasByTourIdSelector = createSelector(
  [(state, tour_id: string) => getAltTourById(state, tour_id)],
  (altTour) => altTour?.extras,
);

export const getCheapestPackagePriceWithAlternatives = createSelector(
  [
    cheapestFlightTourIdSelector,
    (state) => (tour_id: string | undefined) =>
      getPackagePriceWithAlternatives(state, tour_id),
  ],
  (cheapestFlightTourId, getPackagePrice) =>
    getPackagePrice(cheapestFlightTourId),
);

export const getPreviousCheapestPackagePrice = createSelector(
  [getActualizeAlternativesSlice],
  (slice) => slice.previousCheapestPackagePrice,
);

/**
 * Возвращает булево значение - подтянулся ли в альтернативных турах
 * более дешевый рейс
 */
export const getIsPriceFell = createSelector(
  [cheapestFlightTourIdSelector, getAlternativeTours],
  (cheapestFlightTourId, altTours) =>
    Boolean(
      altTours.find((altTour) => altTour.tour_id === cheapestFlightTourId),
    ),
);

/**
 *  В случае, если сверху помимо хэдера есть прогрессбар,
 *  то увеличиваем отступ для сайдбара
 */
export const getSidebarTopMargin = createSelector(
  [getIsFlightsProgressBarSticky, getIsHomePath],
  (isProgressbarSticky, isHomePath) =>
    isHomePath && isProgressbarSticky ? HEADER_OFFSET + 22 : HEADER_OFFSET + 10,
);

export const selectedFlightWithAlternativesInfoSelector = createSelector(
  [
    getSelectedFlightData,
    (state) => (tour_id: string) => getAltTourById(state, tour_id),
    getOperatorName,
  ],
  (selectedFlight, getAltTour, originalOperatorName) => {
    const tourId = selectedFlight?.back[0].tour_id;
    const altTour = getAltTour(tourId || '');
    return {
      ...selectedFlight,
      is_alternative: Boolean(altTour),
      operator: altTour?.operator_info.name || originalOperatorName,
    };
  },
);

export const altActualizationSessionIdSelector = createSelector(
  [getActualizeAlternativesSlice],
  (slice) => slice.alternatives_actualization_session_id,
);

export const isAltActualizationDoneSelector = createSelector(
  getActualizeAlternativesSlice,
  (slice) => slice.isAltActualizationDone,
);

export const cheapestPreviousPriceSelector = createSelector(
  [
    cheapestFlightSelector,
    (state) => (tourId: string) => getAltTourById(state, tourId),
    getPreviousPrice,
  ],
  (cheapestFlight, getAltTour, originalPackagePreviousPrice) => {
    if (!cheapestFlight) return null;
    const altTourCandidate = getAltTour(cheapestFlight.back[0].tour_id);
    return altTourCandidate
      ? altTourCandidate.previous_price
      : originalPackagePreviousPrice;
  },
);

export const bonusesByTourIdSelector = createSelector(
  [
    (state, tour_id: string) => getAltTourById(state, tour_id),
    getOriginalPackageOrderBonuses,
  ],
  (altTour, originalOrderBonuses) => {
    if (altTour && altTour.bonuses) {
      return altTour.bonuses;
    }

    return originalOrderBonuses;
  },
);

export const discountSelector = createSelector(
  [getCheapestPackagePriceWithAlternatives, cheapestPreviousPriceSelector],
  (netPrice, previousPrice) => !!(previousPrice && previousPrice > netPrice),
);

/**
 * Возвращает состав допуслуг (страховка, трансфер), которые были улучшены
 * после выбора альтернативного пакета
 */
export const upgradedServicesSelector = createSelector(
  [getActualizeAlternativesSlice],
  (slice) => slice.upgradedServices,
);

/**
 * Возвращает массив из допуслуг (трансфер и страховка)
 * для альтернативного пакета, только если состав допуслуг лучше, чем
 * в основном пакете
 */
export const servicesToUpgradePriceInfoSelector = createSelector(
  [
    (state, tourId) => getAltTourById(state, tourId),
    getMedicalInsurance,
    getTransfer,
  ],
  (altTour, origMedInsurance, origTransfer) => {
    const hasAltMedInsurance = origMedInsurance
      ? false
      : altTour?.medical_insurance || false;

    const medInsurancePriceInfoTemplate = {
      amount: 0,
      price_id: 'medical_insurance',
      title: 'cтраховка',
    };

    const hasAltTransfer = origTransfer ? false : altTour?.transfer || false;
    const transferPriceInfoTemplate = {
      amount: 0,
      price_id: 'transfer',
      title: 'трансфер',
    };

    const upgradedServicesPriceInfo = [
      ...(hasAltMedInsurance ? [medInsurancePriceInfoTemplate] : []),
      ...(hasAltTransfer ? [transferPriceInfoTemplate] : []),
    ];
    return upgradedServicesPriceInfo;
  },
);

export const isChangingPackageSelector = createSelector(
  [getActualizeAlternativesSlice, (_state, flightPairId) => flightPairId],
  (slice, flightPairId) =>
    slice.isChangingPackage && slice.selectedFlightPairId === flightPairId,
);

/** Возвращает альтернативный тур, если он самый дешевый, иначе - undefined */
export const altTourIfCheapestSelector = createSelector(
  [
    cheapestFlightTourIdSelector,
    (state) => (tourId: string) => getAltTourById(state, tourId),
  ],
  (cheapestTourId, getAltTour) => getAltTour(cheapestTourId || '') || undefined,
);

export const сheapestPackageTransferSelector = createSelector(
  [getTransfer, altTourIfCheapestSelector],
  (origTransfer, altTour) => {
    if (altTour) {
      return altTour.transfer;
    }
    return origTransfer;
  },
);

export const cheapestPackageMedInsuranceSelector = createSelector(
  [getMedicalInsurance, altTourIfCheapestSelector],
  (origMedInsurance, altTour) => {
    if (altTour) {
      return altTour.medical_insurance;
    }
    return origMedInsurance;
  },
);

export const isPrivateCheapestTransferSelector = createSelector(
  [getIsPrivateTransfer, altTourIfCheapestSelector],
  (origIsPrivateTransfer, altTour) => {
    if (altTour) {
      return altTour.misc_data.transfer_type === 'private';
    }
    return origIsPrivateTransfer;
  },
);

export const filteredToursSelector = (state) =>
  getActualizeAlternativesSlice(state).filtered_tours;

export const failedToursSelector = (state) =>
  getActualizeAlternativesSlice(state).failed_tours;

export const initialToursSelector = (state) =>
  getActualizeAlternativesSlice(state).initial_tours;

const SEPARATOR = '|';

export const toursAnalyticsSelector = createSelector(
  [
    getAlternativeTours,
    filteredToursSelector,
    failedToursSelector,
    initialToursSelector,
  ],
  (alternativeTours, filteredTours, failedTours, initialTours) => {
    const initialToursInfo = initialTours.map((tour) => ({
      operator_id: tour.operator_info.id,
      tour_id: tour.tour_id,
    }));
    const alternativeToursInfo = alternativeTours.map((tour) => ({
      operator_id: tour.operator_info.id,
      tour_id: tour.tour_id,
    }));
    const filteredToursInfo = filteredTours.map((tour) => ({
      operator_id: tour.operator_info.id,
      tour_id: tour.tour_id,
    }));
    const failedToursInfo = failedTours.map((tour) => ({
      operator_id: tour.operator_info.id,
      tour_id: tour.tour_id,
    }));
    const notTimedOutOperatorIds = [
      ...alternativeToursInfo,
      ...filteredToursInfo,
      ...failedToursInfo,
    ];
    const timedOutToursInfo = initialToursInfo.filter(
      (el) => !notTimedOutOperatorIds.includes(el),
    );

    return {
      alternative_tours: alternativeToursInfo
        .map((el) => el.operator_id)
        .join(SEPARATOR),
      alternative_tours_tour_id: alternativeToursInfo
        .map((el) => el.tour_id)
        .join(SEPARATOR),
      filtered_tours: filteredToursInfo
        .map((el) => el.operator_id)
        .join(SEPARATOR),
      filtered_tours_tour_id: filteredToursInfo
        .map((el) => el.tour_id)
        .join(SEPARATOR),
      failed_tours: failedToursInfo.map((el) => el.operator_id).join(SEPARATOR),
      failed_tours_tour_id: failedToursInfo
        .map((el) => el.tour_id)
        .join(SEPARATOR),
      initial_tours: initialToursInfo
        .map((el) => el.operator_id)
        .join(SEPARATOR),
      initial_tours_tour_id: initialToursInfo
        .map((el) => el.tour_id)
        .join(SEPARATOR),
      timed_out_tours: timedOutToursInfo
        .map((el) => el.operator_id)
        .join(SEPARATOR),
      timed_out_tours_tour_id: timedOutToursInfo
        .map((el) => el.tour_id)
        .join(SEPARATOR),
    };
  },
);

export const previousPacakgeIdSelector = (state) =>
  getActualizeAlternativesSlice(state).previous_package_id;

export const previousOperatorIdSelector = (state) =>
  getActualizeAlternativesSlice(state).previous_operator_id;

export const altToursLabelIdsSelector = (state) =>
  getAlternativeTours(state)
    .map((altTour) => altTour.labels.map((label) => label.id).join(';'))
    .join('|');
