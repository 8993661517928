import { pluralize } from './stringUtils';

const PLURAL = {
  YEARS: ['год', 'года', 'лет'],
  MONTHS: ['месяц', 'месяца', 'месяцев'],
  DAYS: ['день', 'дня', 'дней'],
  SECONDS: ['секунду', 'секунды', 'секунд'],
  KIDS: ['ребёнок', 'ребёнка', 'детей'],
  KIDS_FORM2: ['ребёнка', 'детей', 'детей'],
  ADULTS: ['взрослый', 'взрослых', 'взрослых'],
  ADULTS_FORM2: ['взрослого', 'взрослых', 'взрослых'],
  NIGHTS: ['ночь', 'ночи', 'ночей'],
  TOURS: ['тур', 'тура', 'туров'],
  HOTELS: ['отель', 'отеля', 'отелей'],
  FLIGHTS: ['рейс', 'рейса', 'рейсов'],
  OPTIONS: ['вариант', 'варианта', 'вариантов'],
  REVIEWS: ['отзыв', 'отзыва', 'отзывов'],
  TOURISTS: ['турист', 'туриста', 'туристов'],
  TOURISTS_ACC: ['туриста', 'туристов', 'туристов'],
  HOURS_GEN: ['часе', 'часах', 'часах'],
  MINUTES_GEN: ['минуте', 'минутах', 'минутах'],
  LUGGAGE: ['место', 'места', 'мест'],
  ADDITIONAL: ['дополнительное', 'дополнительных', 'дополнительных'],
  PERSON: ['человек', 'человека', 'человек'],
  ORDERS: ['заказ', 'заказа', 'заказов'],
  COUNTRIES: ['страна', 'страны', 'стран'],
} as const;
export const pluralizeYears = (value: number) => pluralize(value, PLURAL.YEARS);
export const pluralizeMonths = (value: number) =>
  pluralize(value, PLURAL.MONTHS);
export const pluralizeDays = (value: number) => pluralize(value, PLURAL.DAYS);
export const pluralizeSeconds = (value: number) =>
  pluralize(value, PLURAL.SECONDS);
export const pluralizeKids = (value: number) => pluralize(value, PLURAL.KIDS);
export const pluralizeAdults = (value: number) =>
  pluralize(value, PLURAL.ADULTS);
export const pluralizeNights = (value: number) =>
  pluralize(value, PLURAL.NIGHTS);
export const pluralizeTours = (value: number) => pluralize(value, PLURAL.TOURS);
export const pluralizeHotels = (value: number) =>
  pluralize(value, PLURAL.HOTELS);
export const pluralizeFlights = (value: number) =>
  pluralize(value, PLURAL.FLIGHTS);
export const pluralizeOptions = (value: number) =>
  pluralize(value, PLURAL.OPTIONS);
export const pluralizeReviews = (value: number) =>
  pluralize(value, PLURAL.REVIEWS);
export const pluralizeTourists = (value: number) =>
  pluralize(value, PLURAL.TOURISTS);
export const pluralizeTouristsAcc = (value: number) =>
  pluralize(value, PLURAL.TOURISTS_ACC);
export const pluralizeHoursGen = (value: number) =>
  pluralize(value, PLURAL.HOURS_GEN);
export const pluralizeMinutesGen = (value: number) =>
  pluralize(value, PLURAL.MINUTES_GEN);
export const pluralizeLuggage = (value: number) =>
  pluralize(value, PLURAL.LUGGAGE);
export const pluralizeAdultsForm2 = (value: number) =>
  pluralize(value, PLURAL.ADULTS_FORM2);
export const pluralizeKidsForm2 = (value: number) =>
  pluralize(value, PLURAL.KIDS_FORM2);
export const pluralizeAdditional = (value: number) =>
  pluralize(value, PLURAL.ADDITIONAL);
export const pluralizePerson = (value: number) =>
  pluralize(value, PLURAL.PERSON);
export const pluralizeOrder = (value: number) =>
  pluralize(value, PLURAL.ORDERS);
export const pluralizeCountry = (value: number) =>
  pluralize(value, PLURAL.COUNTRIES);
