import React from 'react';
import styled from 'styled-components';

import { Close } from '@lt/components/icons-react';
import type { TourGuideButtonProps } from '../../../types';
import { IMAGES } from '../constants';
import { container, transition, image } from '../style';

export const TourGuideButtonDesktop = (
  props: TourGuideButtonProps,
): JSX.Element => {
  const { onClick, onClose, className, text } = props;
  return (
    <Container onClick={onClick} className={className}>
      <LoungeChairImage />
      <Text>{text}</Text>
      <BallImage />
      <BigBallImage />
      <CloseIcon onClick={onClose} />
    </Container>
  );
};

export const LoungeChairImage = styled.div`
  ${transition}
  ${image}
  position: absolute;
  left: 8px;
  top: -69px;
  height: 188px;
  width: 195px;
  background-image: url(${IMAGES.LOUNGE_CHAIR});
`;

const BallImage = styled.div`
  ${transition}
  ${image}
  position: absolute;
  width: 26px;
  height: 26px;
  top: 12px;
  right: 85px;
  transform: rotate(-30deg);
  background-image: url(${IMAGES.BALL});
`;

const BigBallImage = styled.div`
  ${transition}
  ${image}
  position: absolute;
  width: 100px;
  height: 100px;
  top: -76px;
  right: -45px;
  filter: blur(2px);
  transform: rotate(-30deg);
  background-image: url(${IMAGES.BALL});
`;

const Container = styled.div`
  ${container}
  ${transition}
  height: 40px;
  justify-content: center;
  &:hover {
    background: radial-gradient(
        378.67% 379.26% at 90.19% -196.58%,
        rgba(117, 222, 255, 0.2) 39.69%,
        rgba(0, 10, 255, 0) 100%
      ),
      radial-gradient(
        129.98% 100% at 100% 0%,
        rgba(71, 222, 255, 0.2) 51.67%,
        rgba(169, 239, 255, 0) 100%
      ),
      radial-gradient(
        173.91% 275.28% at -15.71% -22.91%,
        rgba(164, 156, 255, 0.1) 0%,
        rgba(164, 150, 255, 0.1) 66.78%,
        rgba(12, 178, 250, 0.1) 100%
      ),
      #ffffff;
  }

  &:hover ${LoungeChairImage} {
    left: 3px;
    top: -71px;
    height: 192px;
    width: 200px;
  }

  &:hover ${BallImage} {
    height: 30px;
    width: 30px;
    top: 7px;
    right: 80px;
    transform: rotate(-15deg);
  }

  &:hover ${BigBallImage} {
    width: 110px;
    height: 110px;
    right: -55px;
    transform: rotate(-15deg);
  }
`;

const Text = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  z-index: 2;
`;

const CloseIcon = styled(Close)`
  stroke: #fff;
  width: 8px;
  height: 8px;
  position: absolute;
  top: 12px;
  right: 20px;
`;
