import React from 'react';
import TopSection from './components/TopSection';

import styles from './Footer.module.css';
import MiddleSection from './components/MiddleSection';
import BottomSection from './components/BottomSection';

const Footer = () => (
  <footer className={styles.footer}>
    <div className={styles.footerContent}>
      <TopSection />
      <hr className={styles.hr} />
      <MiddleSection />
      <BottomSection />
    </div>
  </footer>
);

export default Footer;
