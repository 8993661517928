import { PageType } from 'src/components/AuthNew/types';

export const getPageType = () => {
  const path = window.location.pathname;
  if (path.includes('/client')) return PageType.Client;
  if (
    path.includes('/packages') &&
    !path.includes('/tourists') &&
    !path.includes('/services')
  )
    return PageType.Packages;
  if (path.includes('/packages') && path.includes('/tourists'))
    return PageType.Checkout;
  if (path.includes('/gift')) return PageType.Gift;
  if (path.includes('/testimonials')) return PageType.Testimonials;
  if (path.includes('/orders')) return PageType.Order;
  if (path.includes('/packages') && path.includes('/services'))
    return PageType.Services;
  return PageType.Default;
};
