import { enqueueSlice } from 'src/store/enqueue/reducers';

export const START_SEARCH = 'START_SEARCH';
export const STOP_SEARCH = 'STOP_SEARCH';
export const SET_SEARCHER_CAROUSEL_DATE = 'SET_SEARCHER_CAROUSEL_DATE';
export const UPDATE_SEARCHER_PARAMS = 'UPDATE_SEARCHER_PARAMS';

export const UPDATE_REQUEST_ID = enqueueSlice.actions.updateRequestId.type;
export const SET_SEARCHER_DATE = 'SET_SEARCHER_DATE';
export const SET_START_DATE = enqueueSlice.actions.setStartDate.type;
export const SET_KIDS = 'SET_KIDS';
export const SET_DEPARTURE = 'SET_DEPARTURE';
export const TOGGLE_INSTANT_CONFIRM = 'TOGGLE_INSTANT_CONFIRM';
export const SEARCH_PRICE_FOR_DATE = 'SEARCH_PRICE_FOR_DATE';
export const SEARCH_WITH_DEFAULT_DEPARTURE = 'SEARCH_WITH_DEFAULT_DEPARTURE';
export const SET_FILTERS = 'SET_FILTERS';
export const SET_DEFAULT_FILTERS = 'SET_DEFAULT_FILTERS';
export const CLEAR_OPERATORS_FILTER = 'CLEAR_OPERATORS_FILTER';
export const CLEAR_SEARCHER_FILTERS = 'CLEAR_SEARCHER_FILTERS';
export const SET_SEARCHER_FETCHING = 'SET_SEARCHER_FETCHING';

export const SET_NEXT_PAGE = 'SET_NEXT_PAGE';
export const SET_HOTELS_COUNT = 'SET_HOTELS_COUNT';
export const REQUEST_NEXT_PAGE = 'REQUEST_NEXT_PAGE';
export const SET_CONTINUE_FIRST_SEARCH = 'SET_CONTINUE_FIRST_SEARCH';
export const SET_SEARCHER_PARAMS = 'SET_SEARCHER_PARAMS';

export const OPEN_FILTERS_LIST = 'OPEN_FILTERS_LIST';

export const SET_EMPTY_SEARCHER = 'SET_EMPTY_SEARCHER';

export const SEARCHER_STATE = {
  INITIAL: 'initial',
  EMPTY: 'empty',
  FETCHING: 'fetching',
  COMPLETED: 'completed',
  ERROR: 'error',
};

export const MEALS_TYPES_SYMBOLS = 9;
