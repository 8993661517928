import { createSelector } from '@reduxjs/toolkit';
import { getIsHotelOnly } from 'src/store/view/selectors';
import { transformOfferFilters } from '../../utils/offersUtils';
import { getFromImmutableState } from '../../../../utils/immutableUtils';

const getSearchOffer = (state) => state.get('searchOffer');

const _getSearchOfferOperators = (state) =>
  getSearchOffer(state).get('operators');
const _getSearchOfferMeals = (state) => getSearchOffer(state).get('meals');
const _getSearchOfferLtExtras = (state) =>
  getSearchOffer(state).get('lt_extras');
const _getSearchOfferLabelFilter = (state) =>
  getSearchOffer(state).get('labels');
const getSearchOfferInstantConfirm = (state) =>
  getSearchOffer(state).get('instantConfirm');

const getSearchFreeCancel = (state) => getSearchOffer(state).get('freeCancel');
export const getSearchOfferPrice = (state) =>
  getSearchOffer(state).get('price');
export const getSearchOfferDate = (state) =>
  getSearchOffer(state).get('start_date');
export const getSearchOfferNights = (state) =>
  getSearchOffer(state).get('nights');

export const _getSearchOfferFrom = (state) =>
  getSearchOffer(state).get('from_city');

export const getSearchOfferOperators = createSelector(
  _getSearchOfferOperators,
  (operators) => transformOfferFilters(operators),
);

export const getSearchOfferMeals = createSelector(
  _getSearchOfferMeals,
  (meals) => transformOfferFilters(meals),
);

export const getSearchOfferLtExtras = createSelector(
  _getSearchOfferLtExtras,
  (ltExtras) => transformOfferFilters(ltExtras),
);

export const getSearchOfferLabelFilters = createSelector(
  _getSearchOfferLabelFilter,
  (labelFilters) => transformOfferFilters(labelFilters),
);

export const getSearchOfferFrom = getFromImmutableState(_getSearchOfferFrom);

export const getSearchOfferFilters = createSelector(
  [
    getSearchOfferLtExtras,
    getSearchOfferInstantConfirm,
    getSearchFreeCancel,
    getSearchOfferLabelFilters,
    getIsHotelOnly,
  ],
  (ltExtrasFilters, instantConfirm, freeCancel, labelFilters, isHotelOnly) => {
    const extras = Object.keys(ltExtrasFilters || {}).reduce(
      (acc, curr) => ({
        ...acc,
        [`lt_extras_${curr}`]: ltExtrasFilters[curr],
      }),
      {},
    );
    const labels = Object.keys(labelFilters || {}).reduce(
      (acc, curr) => ({
        ...acc,
        [`labels_${curr}`]: labelFilters[curr],
      }),
      {},
    );
    const freeCancelFilter = isHotelOnly ? freeCancel : null;
    return {
      ...extras,
      instantConfirm,
      freeCancel: freeCancelFilter,
      ...labels,
    };
  },
);
