import { connect } from 'react-redux';

import {
  getIsAppBannerEnabled,
  getIsMobile,
  getIsWebview,
  getIsWhitelabel,
} from 'src/store/view/selectors';
import { BannerController } from './BannerController';

const mapStateToProps = (state) => ({
  isMobile: getIsMobile(state),
  isWhiteLabel: getIsWhitelabel(state),
  isWebview: getIsWebview(state),
  isAppBannerEnabled: getIsAppBannerEnabled(state),
});

const connector = connect(mapStateToProps);

export default connector(BannerController);
