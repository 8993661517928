import { i18n } from '@lt/localization';
import { isSecondStepDone, isTouristsAvailable } from '../selectors/order';

export const BASE_CLASSNAME = 'checkout_page_new';

export const ROOT_COMPONENT_ID = 'checkout_page_root';

export const PACKAGE_HOME_PATH = '';
export const CLIENT_PATH = '/client';
/**
 * Ради того чтобы не было зацикливания данная константа была дублирована тут
 * @see client/lt-modules/src/routes/Checkout/store/selectors/order.ts
 */
export const TOURISTS_PATH = '/tourists';
export const SUCCESS_PATH = '/success';
export const PAY_PATH = '/pay';

export const GET_PAGES = (isHotelOnly: boolean) => [
  {
    path: PACKAGE_HOME_PATH,
    component: 'Package',
    nextPage: 'Tourists',
    title: i18n.t('checkout.packageHome.title', {
      context: isHotelOnly ? 'hotel' : 'package',
    }),
    menuText: i18n.t('checkout.packageHome.menu', {
      context: isHotelOnly ? 'hotel' : 'package',
    }),
    manualFlightMenuText: i18n.t('checkout.packageHome.menuwithoutFlight'),
  },
  {
    path: TOURISTS_PATH,
    component: 'Tourists',
    nextPage: 'Services',
    title: i18n.t('checkout.tourists.title'),
    menuText: i18n.t('checkout.tourists.menu'),
    transitionCondition: isTouristsAvailable,
    showNextStep: true,
  },
  {
    path: PAY_PATH,
    component: 'Services',
    title: i18n.t('checkout.services.title'),
    menuText: i18n.t('checkout.services.menu'),
    transitionCondition: isSecondStepDone,
    showNextStep: true,
  },
  {
    path: SUCCESS_PATH,
    component: 'OrderSuccess',
    title: null,
    menuText: null,
  },
];

// TODO: слишком страшно убирать (он напихан везде где можно в том числе и в redux)
export const PAGES = [
  {
    path: PACKAGE_HOME_PATH,
    component: 'Package',
    nextPage: 'Tourists',
    title: i18n.t('checkout.packageHome.title', {
      context: 'package',
    }),
    menuText: i18n.t('checkout.packageHome.menu', { context: 'package' }),
    manualFlightMenuText: i18n.t('checkout.packageHome.menuwithoutFlight'),
  },
  {
    path: TOURISTS_PATH,
    component: 'Tourists',
    nextPage: 'Services',
    title: i18n.t('checkout.tourists.title'),
    menuText: i18n.t('checkout.tourists.menu'),
    transitionCondition: isTouristsAvailable,
    showNextStep: true,
  },
  {
    path: PAY_PATH,
    component: 'Services',
    title: i18n.t('checkout.services.title'),
    menuText: i18n.t('checkout.services.menu'),
    transitionCondition: isSecondStepDone,
    showNextStep: true,
  },
  {
    path: SUCCESS_PATH,
    component: 'OrderSuccess',
    title: null,
    menuText: null,
  },
];

export const ROUTE_TRANSITION_TIME = 350;
