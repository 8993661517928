import React from 'react';
import styled from 'styled-components';

import { Close } from '@lt/components/icons-react';
import type { TourGuideButtonProps } from '../../../types';
import { IMAGES } from '../constants';
import { container, image } from '../style';

export const TourGuideButtonHomeHeader = (
  props: TourGuideButtonProps,
): JSX.Element => {
  const { onClick, onClose, className, text } = props;
  return (
    <Container onClick={onClick} className={className}>
      <Text>{text}</Text>
      <LoungeChairImage />
      <CloseIconWrapper onClick={onClose}>
        <CloseIcon />
      </CloseIconWrapper>
    </Container>
  );
};

const Container = styled.div`
  ${container}
  position: relative;
  top: -1px;
  height: 32px;
  width: 376px;
  margin-left: 20px;
  padding-left: 12px;
  color: ${({ theme }) => theme.colors.blackBase};
`;

const Text = styled.p`
  font-weight: 700;
  font-size: 14px;
  display: flex;
  align-items: center;
`;

const CloseIconWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px 12px;
`;

const CloseIcon = styled(Close)`
  stroke: #fff;
  width: 8px;
  height: 8px;
`;

const LoungeChairImage = styled.div`
  ${image}
  position: absolute;
  right: 30px;
  top: -10px;
  height: 66px;
  width: 66px;
  background-image: url(${IMAGES.LOUNGE_CHAIR});
  transform: scaleX(-1);
`;
