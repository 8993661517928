import { eventChannel } from 'redux-saga';
import { call, fork, take } from 'redux-saga/effects';

import { isSSR } from './ssr';

export const takeOnce = (pattern, saga, ...args) =>
  fork(function* () {
    const action = yield take(pattern);
    yield call(saga, ...args.concat(action));
  });

/**
 * Отслеживает клик на DOM элемент
 */
export const clickChannel = isSSR
  ? null
  : eventChannel((emitter) => {
      document.addEventListener('click', emitter);
      return () => document.removeEventListener('click', emitter);
    });
