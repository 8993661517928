/**
 * Тут собраны составные селекторы из разных полей хранилища,
 * чтобы избежать циклических зависимостей
 */

import { createSelector } from '@reduxjs/toolkit';
import { flatten, isEmpty } from 'lodash';
import { pricePerNightPerPersonCounter } from 'src/routes/Hotel/utils/pricePerNightPerPersonCounter';

import {
  getActiveFilters,
  getSearcherCarouselDate,
  getOffersForCurrentDate,
  getSearcherNights,
  getOffersForCurrentDateWithAllHotelFilters,
} from './searcher';
import { getRooms } from './rooms';
import { convertClientDateToServerDate } from '../../../../utils/dateUtils';
import { getHotelInfo } from './hotel';
import { getSearchOfferNights } from './searchOffer';
import { getAirports } from './airports';
import { getLtExtras } from './extraitems';
import {
  getOffersMinPrice,
  hasLtExtras,
  hasLabels,
  buildFilters,
  isFiltersAppliedToOffer,
} from '../../utils/offersUtils';
import {
  getSearchParamsAdults,
  getSearchParamsKidsCount,
  getSearchParamsNights,
} from '../../../../store/enqueue/selectors';
import { getFreeCancelFilter } from './filters';

/**
 * @typedef {Object} Operator
 * @property {number} id
 * @property {string} name
 * @property {number} minPrice
 * @property {number} count
 * @property {boolean} hasLtExtras
 * @return {Array<Operator>}
 */
export const getRoomsOperators = createSelector(
  [getRooms, getActiveFilters, getSearcherCarouselDate],
  (rooms, activeFilters, currentDate) => {
    if (!rooms) {
      return null;
    }
    const date = convertClientDateToServerDate(currentDate);
    const { instantConfirm, ...otherFilter } = activeFilters;

    const activeLtExtras = buildFilters(otherFilter, 'lt_extras');
    const activeLabelFilters = buildFilters(otherFilter, 'labels');
    const activeMealsFilters = buildFilters(otherFilter, 'lt_meals');
    const operatorsMap = new Map();
    let arrayOffers = [];
    rooms.forEach((room) => {
      const { offers } = room;
      const offersMeal = Object.keys(offers);
      offersMeal.forEach((mealTypeOffer) => {
        const flattenOffers = offers[mealTypeOffer].map((offer) => ({
          ...offer,
          mealType: mealTypeOffer,
        }));
        arrayOffers = [...arrayOffers, ...flattenOffers];
      });
    });

    arrayOffers.forEach((offer) => {
      const {
        operator_id,
        operator_name,
        price,
        extras: { instant_confirm } = {},
        start_date,
        lt_extras,
      } = offer;
      const existed = operatorsMap.get(operator_id);
      const isCurrentDate = date === start_date;
      const isFilteredByDate = !isCurrentDate;
      const isFilteredByInstantConfirm = instantConfirm && !instant_confirm;
      const isFiltersApplied = isFiltersAppliedToOffer(
        offer,
        activeLtExtras,
        activeLabelFilters,
        activeMealsFilters,
      );
      const isFiltered =
        isFilteredByDate || isFilteredByInstantConfirm || isFiltersApplied;
      const emptyReasonText = isFilteredByInstantConfirm
        ? 'instantConfirm'
        : 'date';

      const increment = isFiltered ? 0 : 1;
      let minPrice = isFiltered ? Infinity : price;
      let count = increment;
      let isLtExtras = !isEmpty(lt_extras);

      if (existed) {
        if (isFiltered) {
          minPrice = existed.minPrice;
        } else {
          minPrice = price < existed.minPrice ? price : existed.minPrice;
        }
        count = existed.count + increment;
        isLtExtras = existed.hasLtExtras || isLtExtras;
      }

      operatorsMap.set(operator_id, {
        id: operator_id,
        name: operator_name,
        minPrice,
        count,
        emptyReason: count === 0 ? emptyReasonText : null,
        hasLtExtras: isLtExtras,
      });
    });

    return Array.from(operatorsMap.values());
  },
);

export const getFilterOperators = createSelector(
  [getRoomsOperators],
  (operatorsMap) => [
    {
      id: 'operator_filters',
      label: 'Туроператоры',
      filters: operatorsMap,
    },
  ],
);

export const getFlattenRooms = createSelector(
  [getOffersForCurrentDate],
  (rooms) => {
    if (!rooms) return [];

    return rooms.reduce((acc, room) => {
      const offers = flatten(Object.values(room.offers));
      acc.push(...offers);
      return acc;
    }, []);
  },
);

// TODO: в searcher?
export const getLtExtrasFilter = createSelector(
  [getFlattenRooms, getLtExtras],
  (rooms, ltExtras) => {
    if (!ltExtras) return [];

    const result = ltExtras.map(({ id, name }) => {
      const offers = rooms.filter((offer) => hasLtExtras(offer, id));

      return {
        icon: 'lt_extras',
        id: `lt_extras_${id}`,
        name,
        minPrice: getOffersMinPrice(offers),
        count: offers.length,
        emptyReason: null,
      };
    });

    return result;
  },
);

export const getMealsFilter = createSelector(
  [getOffersForCurrentDate, getActiveFilters],
  (rooms, activeFilters) => {
    if (!rooms) return [];
    const mealTypes = [];
    const mealTypesId = new Set();
    rooms.forEach((room) =>
      room.meal_types.forEach((meal) => {
        if (!mealTypesId.has(meal.id)) {
          mealTypes.push(meal);
        }
        mealTypesId.add(meal.id);
      }),
    );
    const { instantConfirm, freeCancel, ...otherFilter } = activeFilters;
    const activeLtExtras = buildFilters(otherFilter, 'lt_extras');
    const activeLabelFilters = buildFilters(otherFilter, 'labels');
    const result = Array.from(mealTypes).map(({ id, description }) => {
      const mealOffer = [];
      rooms.forEach((room) => {
        room.offers[id]?.forEach((offer) => {
          const { extras: { instant_confirm } = {} } = offer;
          const isFilteredByInstantConfirm = instantConfirm && !instant_confirm;
          const isFilteredByFreeCancel =
            freeCancel && offer?.rates?.cancellation_policy.kind === 'flexible';
          const isFiltersApplied = isFiltersAppliedToOffer(
            offer,
            activeLtExtras,
            activeLabelFilters,
          );
          const isFiltered =
            isFilteredByInstantConfirm ||
            isFiltersApplied ||
            isFilteredByFreeCancel;
          if (!isFiltered) {
            mealOffer.push(offer);
          }
        });
      });
      return {
        icon: '',
        id: `lt_meals_${id}`,
        name: description,
        minPrice: getOffersMinPrice(mealOffer),
        count: mealOffer.length,
        emptyReason: mealOffer.length === 0 ? 'date' : null,
      };
    });

    return result;
  },
);

export const getLabelsFilters = createSelector([getFlattenRooms], (rooms) => {
  const paymentBenefits = [];
  const travelBenefits = [];
  const labelMap = new Map();

  rooms.forEach((offer) => {
    const { labels } = offer;

    if (isEmpty(labels)) return;
    labels.forEach((label) => {
      const { filterable, id } = label;
      if (!labelMap.has(id)) {
        switch (filterable) {
          case 1:
            paymentBenefits.push(label);
            break;
          case 2:
            travelBenefits.push(label);
            break;
          default:
            return;
        }
        labelMap.set(id, 1);
      }
    });
  });

  const buildLabelFilters = (uniqLabel) => {
    const { icon_path: iconPath, id, title, filterable } = uniqLabel;
    if (filterable === 3) return null;

    const offers = rooms.filter((offer) => hasLabels(offer, id));

    const filter = {
      icon: iconPath,
      id: `labels_${id}`,
      name: title,
      minPrice: getOffersMinPrice(offers),
      count: offers.length,
      emptyReason: null,
    };

    return filter;
  };

  const result = {};
  result.paymentBenefits = paymentBenefits.map(buildLabelFilters);
  result.travelBenefits = travelBenefits.map(buildLabelFilters);

  return result;
});

export const getInstantConfirmFilter = createSelector(
  [getOffersForCurrentDateWithAllHotelFilters],
  (rooms) => {
    const flattenRooms = rooms.reduce((acc, room) => {
      const offers = flatten(Object.values(room.offers));
      acc.push(...offers);
      return acc;
    }, []);
    const offers = flattenRooms.filter((offer) => offer.extras.instant_confirm);

    return {
      icon: 'instant_confirm',
      id: 'instantConfirm',
      name: 'Моментальное подтверждение',
      minPrice: getOffersMinPrice(offers),
      count: offers.length,
      emptyReason: null,
    };
  },
);

export const getFilters = createSelector(
  [
    getLtExtrasFilter,
    getLabelsFilters,
    getInstantConfirmFilter,
    getMealsFilter,
    getRoomsOperators,
  ],
  (
    ltExtrasFilter,
    labelFilters,
    instantConfirmFilter,
    mealsFilter,
    operatorsMap,
  ) => [
    {
      id: 'instantConfirmFilter',
      label: undefined,
      filters: [instantConfirmFilter],
    },
    {
      id: 'ltExtrasFilter',
      label: 'Дополнительные услуги',
      filters: [...ltExtrasFilter],
    },
    {
      id: 'paymentBenefits',
      label: 'Преимущества оплаты',
      filters: labelFilters.paymentBenefits,
    },
    {
      id: 'travelBenefits',
      label: 'Особенности путешествий',
      filters: labelFilters.travelBenefits,
    },
    {
      id: 'ltMealsFilter',
      label: 'Питание',
      filters: mealsFilter || [],
    },
    {
      id: 'operator_filters',
      label: 'Туроператоры',
      filters: operatorsMap,
    },
  ],
);

export const getFiltersHotel = createSelector(
  [getFreeCancelFilter, getMealsFilter, getInstantConfirmFilter],
  (freeCancelFilter, mealsFilter, instantConfirmFilter) => [
    {
      id: 'instantConfirmFilter',
      label: undefined,
      filters: [instantConfirmFilter],
    },
    {
      id: 'freeCancelFilter',
      label: undefined,
      filters: [freeCancelFilter],
    },
    {
      id: 'ltMealsFilter',
      label: 'Питание',
      filters: mealsFilter || [],
    },
  ],
);

export const getHasAboutHotel = createSelector(
  [getHotelInfo, getAirports],
  (text, airports) => !isEmpty(text || airports),
);

/**
 * Выбранное кол-во ночей на матрице на странице отеля (то что выделяется сейчас желтоватым)
 */
export const getSearchedNights = createSelector(
  [getSearchOfferNights, getSearcherNights],
  (searchOfferNights, searcherNights) =>
    Number(searchOfferNights) || Number(searcherNights),
);

/**
 * Выводим минимальную цену за выбранное кол-во ночей на матрице (то что выделяется сейчас желтым)
 */
export const getDefaultPrice = createSelector(
  [getFlattenRooms, getSearchedNights],
  (offers, nights) => {
    const [minPriceWithDefaultNights] = offers
      .filter((offer) => offer.nights_count === nights)
      .map((offer) => offer.price)
      .sort((a, b) => a - b);
    return minPriceWithDefaultNights;
  },
);

/**
 * Минимальная цена в рублях для комнаты
 */
export const minAnalyticsPriceSelector = createSelector(
  [getFlattenRooms, getSearchParamsNights],
  (offers, nights) => {
    const [minPriceWithDefaultNights] = offers
      .filter((offer) => offer.nights_count === nights)
      .map((offer) => offer.analytics_price)
      .sort((a, b) => a - b);
    return minPriceWithDefaultNights;
  },
);

export const getPrice = createSelector(
  [getDefaultPrice],
  (minPrice) => minPrice,
);

export const getPricePerNightPerPerson = createSelector(
  [
    getDefaultPrice,
    getSearchParamsAdults,
    getSearchParamsKidsCount,
    getSearchParamsNights,
  ],
  (minPrice, adultsCount, kids, nightsCount) =>
    pricePerNightPerPersonCounter(
      minPrice,
      adultsCount || 0,
      kids,
      nightsCount,
    ),
);
