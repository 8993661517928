import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import type { RootState } from 'src/routes/Home/store';
import type {
  HotPriceByCountry,
  LatestOrderType,
} from 'src/routes/Home/store/homeDynamicData/types';
import { useInterval } from 'usehooks-ts';

/**
 * Хук с переключением по таймерам для динамической информации
 */
const useDynamicInfo = () => {
  const latestOrders: LatestOrderType[] = useSelector(
    (state: RootState) => state.homeDynamicDataSlice.latestOrders,
  );
  const hotPricesByCountry: HotPriceByCountry[] = useSelector(
    (state: RootState) => state.homeDynamicDataSlice.hotPricesByCountry,
  );
  const foundToursToday: number = useSelector(
    (state: RootState) => state.homeDynamicDataSlice.foundToursToday,
  );

  const [currentLatestOrder, setCurrentLatestOrder] =
    useState<LatestOrderType | null>(null);
  const [currentHotPriceByCountry, setCurrentHotPriceByCountry] =
    useState<HotPriceByCountry | null>(null);
  const [currentFoundToursToday, setCurrentFoundToursToday] =
    useState<number>(0);

  const [indexCurrentLatestOrder, setIndexCurrentLatestOrder] =
    useState<number>(0);
  const [indexCurrentHotPriceByCountry, setIndexCurrentHotPriceByCountry] =
    useState<number>(0);

  useInterval(
    () => {
      setIndexCurrentLatestOrder((value) => (value + 1) % latestOrders.length);
    },
    latestOrders.length !== 0 ? 5000 : null,
  );
  useInterval(
    () => {
      setIndexCurrentHotPriceByCountry(
        (value) => (value + 1) % hotPricesByCountry.length,
      );
    },
    hotPricesByCountry.length !== 0 ? 6000 : null,
  );
  useInterval(
    () => {
      setCurrentFoundToursToday(
        (value) => value + Math.floor(Math.random() * 10),
      );
    },
    foundToursToday !== 0 ? 7000 : null,
  );

  useEffect(() => {
    if (latestOrders.length !== 0) {
      setCurrentLatestOrder(latestOrders[indexCurrentLatestOrder]);
    }
  }, [indexCurrentLatestOrder, setCurrentLatestOrder, latestOrders]);

  useEffect(() => {
    if (hotPricesByCountry.length !== 0) {
      setCurrentHotPriceByCountry(
        hotPricesByCountry[indexCurrentHotPriceByCountry],
      );
    }
  }, [
    indexCurrentHotPriceByCountry,
    setCurrentHotPriceByCountry,
    hotPricesByCountry,
  ]);

  useEffect(() => {
    setCurrentFoundToursToday(foundToursToday);
  }, [foundToursToday]);

  return {
    currentLatestOrder,
    currentHotPriceByCountry,
    currentFoundToursToday,
  };
};

export default useDynamicInfo;
