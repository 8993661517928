import { HEADER_OFFSET } from 'src/constants/header';

/**
 * Скролл к указанному компоненту
 * @param element
 */
export const scrollToElement = (element: HTMLElement | null) => {
  if (element) {
    const elementPosition =
      element.getBoundingClientRect().top + window.pageYOffset;
    window.scrollTo({
      top: elementPosition - HEADER_OFFSET,
      behavior: 'smooth',
    });
  }
};
