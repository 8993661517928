import { isEmpty } from 'lodash';
import { getFromImmutableState } from 'src/utils/immutableUtils';
import { createSelector } from '@reduxjs/toolkit';
import { getIsWhitelabel } from './view';

const _getExtraItems = (state) => state.get('extraItems');
export const getLtExtras = getFromImmutableState(_getExtraItems);

export const getExtraItems = createSelector(
  [_getExtraItems, getIsWhitelabel],
  (items, isWl) => (!isWl && items ? items.toJS() : null),
);

export const hasExtraItems = createSelector(
  [getExtraItems],
  (items) => !isEmpty(items),
);
