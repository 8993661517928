import { i18n } from '@lt/localization';

export const HOTEL_FACTS_TYPES = {
  AIRCON: 'aircon',
  AIRPORT: 'airport_distance',
  AQUAPARK: 'aquapark',
  BAR: 'bar',
  BEACHLINE: 'beach_line',
  BEACHSURFACE: 'beach_surface',
  BEACHTYPE: 'beach_type',
  THERMAL_FUN: 'thermal_fun',
  HEATED_POOL: 'heated_pool',
  KIDS_CLUB: 'kids_club',
  KIDS_MENU: 'kids_menu',
  KIDS_POOL: 'kids_pool',
  MASSAGE: 'massage',
  NANNY: 'nanny',
  PARKING: 'parking',
  POOL: 'pool',
  SKI_IN_SKI_OUT: 'ski_in_ski_out',
  SKI_LIFT: 'ski_lift_distance',
  WIFI: 'wi_fi',
} as const;

export const HOTEL_FACTS_VALUES = {
  SKI_IN: 'SKI_IN',
  SKI_OUT: 'SKI_OUT',
  SKI_IN_SKI_OUT: 'SKI_IN_SKI_OUT',
} as const;

const {
  AIRCON,
  AIRPORT,
  AQUAPARK,
  BAR,
  BEACHLINE,
  BEACHSURFACE,
  BEACHTYPE,
  THERMAL_FUN,
  HEATED_POOL,
  KIDS_MENU,
  KIDS_CLUB,
  KIDS_POOL,
  MASSAGE,
  NANNY,
  PARKING,
  POOL,
  SKI_LIFT,
  SKI_IN_SKI_OUT,
  WIFI,
} = HOTEL_FACTS_TYPES;

export const HOTEL_FACTS_TEXTS = {
  [WIFI]: {
    FREE: {
      title: i18n.t('hotel.facts.wifi.free.title'),
      subtitle: i18n.t('hotel.facts.wifi.free.subtitle'),
      iconName: 'wifi',
    },
    PAID: {
      title: i18n.t('hotel.facts.wifi.paid.title'),
      subtitle: i18n.t('hotel.facts.wifi.paid.subtitle'),
      iconName: 'wifi',
    },
    LOBBY_PAID: {
      title: i18n.t('hotel.facts.wifi.lobbyPaid.title'),
      subtitle: i18n.t('hotel.facts.wifi.lobbyPaid.subtitle'),
      iconName: 'wifi',
    },
    LOBBY_FREE: {
      title: i18n.t('hotel.facts.wifi.lobbyFree.title'),
      subtitle: i18n.t('hotel.facts.wifi.lobbyFree.subtitle'),
      iconName: 'wifi',
    },
    LOBBY_UNKNOWN: {
      title: i18n.t('hotel.facts.wifi.lobbyUnknown.title'),
      subtitle: i18n.t('hotel.facts.wifi.lobbyUnknown.subtitle'),
      iconName: 'wifi',
    },
  },
  [BEACHSURFACE]: {
    'SAND+PEBBLE': {
      title: i18n.t('hotel.facts.beachsurface.sandPebble.title'),
      subtitle: i18n.t('hotel.facts.beachsurface.sandPebble.subtitle'),
      iconName: 'beach-surface-sand-pebble',
    },
    PEBBLE: {
      title: i18n.t('hotel.facts.beachsurface.pebble.title'),
      subtitle: i18n.t('hotel.facts.beachsurface.pebble.subtitle'),
      iconName: 'beach-surface-pebble',
    },
    SAND: {
      title: i18n.t('hotel.facts.beachsurface.sand.title'),
      subtitle: i18n.t('hotel.facts.beachsurface.sand.subtitle'),
      iconName: 'beach-surface-sand',
    },
    PLATFORM: {
      title: i18n.t('hotel.facts.beachsurface.platform.title'),
      subtitle: i18n.t('hotel.facts.beachsurface.platform.subtitle'),
      iconName: 'beach-surface-platform',
    },
  },
  [BEACHLINE]: {
    1: {
      title: i18n.t('hotel.facts.beachline.first'),
      iconName: 'wave',
    },
    2: {
      title: i18n.t('hotel.facts.beachline.second'),
      iconName: 'wave',
    },
    3: {
      title: i18n.t('hotel.facts.beachline.third'),
      iconName: 'wave',
    },
  },
  [SKI_IN_SKI_OUT]: {
    [HOTEL_FACTS_VALUES.SKI_IN]: {
      title: 'Ski-in',
      subtitle: i18n.t('hotel.facts.skiIn'),
      iconName: 'ski-in-ski-out',
    },
    [HOTEL_FACTS_VALUES.SKI_OUT]: {
      title: 'Ski-out',
      subtitle: i18n.t('hotel.facts.skiOut'),
      iconName: 'ski-in-ski-out',
    },
    [HOTEL_FACTS_VALUES.SKI_IN_SKI_OUT]: {
      title: 'Ski-in, ski-out',
      subtitle: i18n.t('hotel.facts.skiIn_skiOut'),
      iconName: 'ski-in-ski-out',
    },
  },
  [BEACHTYPE]: {
    DESIGNATED: {
      title: i18n.t('hotel.facts.beachtype.designated.title'),
      subtitle: i18n.t('hotel.facts.beachtype.designated.subtitle'),
      iconName: 'private-beach',
    },
    PUBLIC: {
      title: i18n.t('hotel.facts.beachtype.public.title'),
      subtitle: i18n.t('hotel.facts.beachtype.public.subtitle'),
      iconName: 'private-beach',
    },
  },
  [SKI_LIFT]: {
    title: i18n.t('hotel.facts.skiLift.title'),
    subtitle: i18n.t('hotel.facts.skiLift.subtitle'),
    iconName: 'funicular',
  },
  [AIRCON]: {
    title: i18n.t('hotel.facts.aircon.title'),
    subtitle: i18n.t('hotel.facts.aircon.subtitle'),
    iconName: 'air-conditioner',
  },
  [BAR]: {
    title: i18n.t('hotel.facts.bar.title'),
    subtitle: i18n.t('hotel.facts.bar.subtitle'),
    iconName: 'bar',
  },
  [PARKING]: {
    title: i18n.t('hotel.facts.parking.title'),
    subtitle: i18n.t('hotel.facts.parking.subtitle'),
    iconName: 'parking',
  },
  [HEATED_POOL]: {
    title: i18n.t('hotel.facts.heatedPool.title'),
    subtitle: i18n.t('hotel.facts.heatedPool.subtitle'),
    iconName: 'heated-pool',
  },
  [POOL]: {
    title: i18n.t('hotel.facts.pool.title'),
    subtitle: i18n.t('hotel.facts.pool.subtitle'),
    iconName: 'swimming-pool',
  },
  [AQUAPARK]: {
    title: i18n.t('hotel.facts.aquapark.title'),
    subtitle: i18n.t('hotel.facts.aquapark.subtitle'),
    iconName: 'aquapark',
  },
  [THERMAL_FUN]: {
    title: i18n.t('hotel.facts.thermalFun.title'),
    subtitle: i18n.t('hotel.facts.thermalFun.subtitle'),
    iconName: 'sauna',
  },
  [MASSAGE]: {
    title: i18n.t('hotel.facts.massage.title'),
    subtitle: i18n.t('hotel.facts.massage.subtitle'),
    iconName: 'massage',
  },
  [NANNY]: {
    title: i18n.t('hotel.facts.nanny.title'),
    subtitle: i18n.t('hotel.facts.nanny.subtitle'),
    iconName: 'nanny',
  },
  [KIDS_MENU]: {
    title: i18n.t('hotel.facts.kidsMenu.title'),
    subtitle: i18n.t('hotel.facts.kidsMenu.subtitle'),
    iconName: 'kids-menu',
  },
  [KIDS_POOL]: {
    title: i18n.t('hotel.facts.kidsPool.title'),
    subtitle: i18n.t('hotel.facts.kidsPool.subtitle'),
    iconName: 'kids-pool',
  },
  [KIDS_CLUB]: {
    title: i18n.t('hotel.facts.kidsClub.title'),
    subtitle: i18n.t('hotel.facts.kidsClub.subtitle'),
    iconName: 'kids-club',
  },
} as const;

export const HOTEL_FACTS_ORDER = [
  BEACHLINE,
  BEACHSURFACE,
  BEACHTYPE,
  WIFI,
  AIRPORT,
  SKI_IN_SKI_OUT,
  SKI_LIFT,
  AIRCON,
  BAR,
  PARKING,
  HEATED_POOL,
  POOL,
  AQUAPARK,
  THERMAL_FUN,
  MASSAGE,
  NANNY,
  KIDS_MENU,
  KIDS_POOL,
  KIDS_CLUB,
];
