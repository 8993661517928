import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import {
  cigaretteValuesSelector,
  getSearchParamsNights,
  getSearchParamsStartDate,
} from 'src/store/enqueue/selectors';
import { getHeaderWidgetIsOpen } from 'src/store/headerWidget/selectors';
import {
  getIsMobile,
  getAvailableSearchTypes,
  searchTypeSelector,
  getInitialOpenedField,
  getIsWhitelabel,
} from 'src/store/view/selectors';

import {
  showHeaderWidgetAction,
  hideHeaderWidgetAction,
} from 'src/store/headerWidget/actions';

import { HeaderSearchWidgetPreview } from './HeaderSearchWidgetPreview';

const mapStateToProps = (state) => ({
  showPopup: getHeaderWidgetIsOpen(state),
  isMobile: getIsMobile(state),
  isWhitelabel: getIsWhitelabel(state),
  cigaretteValues: cigaretteValuesSelector(state),
  availableSearchTypes: getAvailableSearchTypes(state),
  // для отображения старого превью
  nights: getSearchParamsNights(state),
  startDateOld: getSearchParamsStartDate(state),
  pageSearchType: searchTypeSelector(state),
  initialOpenedField: getInitialOpenedField(state),
});

const mapDispatchToProps = {
  onOpenModal: showHeaderWidgetAction,
  onCloseModal: hideHeaderWidgetAction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(HeaderSearchWidgetPreview);
