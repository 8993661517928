import { enqueueSlice } from 'src/store/enqueue/reducers';

export const START_SEARCH_PROCESS =
  enqueueSlice.actions.startSearchProcess.type;
export const SEARCH_SUCCESS = 'SEARCH_SUCCESSED';

export const SEARCH_ENRICHED = 'SEARCH_ENRICHED';
export const SEARCH_TIMED_OUT = 'SEARCH_TIMED_OUT';

export const CHANGE_SEARCH_PARAMS = 'CHANGE_SEARCH_PARAMS';

export const REFRESH_EMPTY_SEARCH_PARAMS = 'REFRESH_EMPTY_SEARCH_PARAMS';
