import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import { cigaretteValuesSelector } from 'src/store/enqueue/selectors';
import {
  getIsMobile,
  getIsIframe,
  getAppName,
  searchTypeSelector,
  getIsTransparent,
  getIsWidgetShown,
  getIsHomepage,
  getIsWhitelabel,
  getInitialOpenedField,
} from 'src/store/view/selectors';

import { getOrderInfo } from 'src/store/order/selectors';

import { fetchClientInfo } from 'src/store/client/actions';

import Header from './Header';

const mapStateToProps = (state) => ({
  isMobile: getIsMobile(state),
  isIframe: getIsIframe(state),
  appName: getAppName(state),
  orderInfo: getOrderInfo(state),
  cigaretteValues: cigaretteValuesSelector(state),
  pageSearchType: searchTypeSelector(state),
  isTransparent: getIsTransparent(state),
  isWidgetShown: getIsWidgetShown(state),
  isHomepage: getIsHomepage(state),
  isWhiteLabel: getIsWhitelabel(state),
  initialOpenedField: getInitialOpenedField(state),
});

const mapDispatchToProps = {
  fetchClientInfo,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Header);
