import { isSSR } from '../helpers/ssr';

export const isDevelopment = () => {
  try {
    return process.env.NODE_ENV === 'development';
  } catch (e) {
    return false;
  }
};

export const LTApiClient = !isSSR && window.LTApi && new window.LTApi.Client();

// BrowserUtils
export const IS_IE = !isSSR
  ? navigator.userAgent.indexOf('MSIE') !== -1 ||
    navigator.appVersion.indexOf('Trident/') !== -1
  : false;

/**
 * Возвращает CSRF токен из head
 */
export const getCSRF = () => {
  if (isSSR) {
    return null;
  }

  const tag = window.document.head.querySelector('[name=csrf-token]');
  return tag ? tag.getAttribute('content') : null;
};

export const initLTApi = () => {
  window.LTApi.setup(
    {
      api_version: 3,
      env: window.env,
      api_key: window.API_KEY,
      is_admin: window.is_admin,
    },
    {
      public_host: window.BASE_DOMAIN,
      partner: window.PARTNER_INFO,
    },
  );
};

export const removePreloader = () => {
  if (isSSR) {
    return;
  }

  try {
    const preloader = document.querySelector('.lt-react-app-root .preloader');
    if (preloader) {
      preloader.remove();
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

/**
 * Преобразует пиксели в rem
 * @param {number} px размер в пикселях
 * @param {number} base базовый размер
 * @returns {string} '13rem'
 */
export const px2rem = (px, base = 13) => {
  const value = (px / base).toPrecision(4);

  return `${value}rem`;
};

/**
 * Осуществляет вибрацию устройства, если
 * это возможно в данном браузере
 * (iOS не умеет пока что работать с вибрациями)
 * @param {number | number[]} time
 */
export const vibrate = (time: number | number[]) => {
  const canVibrate = Boolean(navigator.vibrate);
  if (canVibrate) navigator.vibrate(time);
};

/**
 * Открывыает ссылку в новом окне.
 * Если действие невозможно (заблокирован поп-ап, ограницение на кол-во открытых вкладок и тд),
 * открывает ссылку в текущем окне.
 * @param {String} link открываемая ссылка
 */
export const maybeOpenInNewTab = (link, openInCurrentTab = false) => {
  if (openInCurrentTab) {
    window.location.href = link;
    return;
  }

  const newTab = !isSSR ? window.open() : null;
  if (newTab) {
    newTab.location = link;
  } else if (!isSSR) {
    window.location.href = link;
  }
};
