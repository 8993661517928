import type { getWishlistCreate } from '@lt/api/dist/methods/wishlist/types';
import type { SagaReturnType } from 'redux-saga/effects';
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';

import { api } from 'src/api';

import type { PayloadAction } from '@reduxjs/toolkit';
import { IS_ONBOARDING_SHOWED } from 'src/components/WishlistOnboarding/constants';
import { WISHLIST_ID } from './constants';

import {
  addWishlistItem,
  destroyWishlistAction,
  getWishlistDataAction,
  hideWishlistOnboarding,
  needShowWishlistOnboarding,
  removeWishlistItem,
} from './actions';
import { getWishlistIdSelector, getWishlistItemsSelector } from './selectors';
import {
  setWishlistFetched,
  showWishlistOnboarding,
  updateWishlist,
  updateWishlistWithFilters,
} from './slice';
import type { AddWishlistPayload } from './types';
import {
  wishlistAddItemAnalytics,
  wishlistDestroyAnalytics,
  wishlistRemoveItemAnalytics,
} from './analytics';

/**
 * Формирует параметры для добавления элемента
 */
function* prepareToCreateWishlist(pageDependedParams: AddWishlistPayload) {
  const wishlistId: SagaReturnType<typeof getWishlistIdSelector> = yield select(
    getWishlistIdSelector,
  );
  const { wishlistIdentifier, type, price, requestId } = pageDependedParams;

  const params: getWishlistCreate = {
    id: wishlistId || undefined,
    hotel_id: type === 'hotel' ? wishlistIdentifier : undefined,
    price,
    type,
    package_id: type === 'package' ? wishlistIdentifier : undefined,
    request_id: requestId,
  };

  return params;
}

function* doWeNeedToShowOnboarding() {
  /**
   * Если в LocalStorage нет этого элемента, значит пользователь еще смешарик
   * и не знает функции избранного в сайдбаре. Либо же он не кликал на отлично, поэтому можно
   * показать ему еще раз
   */
  if (
    localStorage.getItem(IS_ONBOARDING_SHOWED) === null ||
    localStorage.getItem(IS_ONBOARDING_SHOWED) === 'true'
  ) {
    yield put(showWishlistOnboarding(true));
  }
}

function* handleHideOnboarding() {
  yield localStorage.setItem(IS_ONBOARDING_SHOWED, JSON.stringify(false));
  yield put(showWishlistOnboarding(false));
}

function* handleCreateWishlist(action: PayloadAction<AddWishlistPayload>) {
  try {
    yield call(doWeNeedToShowOnboarding);
    const params: SagaReturnType<typeof prepareToCreateWishlist> = yield call(
      prepareToCreateWishlist,
      action.payload,
    );
    const response: SagaReturnType<typeof api.postWishlistCreate> =
      yield api.postWishlistCreate(params);
    // Находим добавленный элемент и получаем его id
    const previousItems = yield select(getWishlistItemsSelector);
    const itemId = response.wishlistItems.find(
      ({ id }) => !previousItems.find(({ prevId }) => prevId === id),
    )?.id;
    // Отправка аналитики на добавление элемента
    if (itemId)
      yield call(
        wishlistAddItemAnalytics,
        response.id,
        itemId,
        action.payload.isFromMap,
      );
    yield put(updateWishlist(response));
    localStorage.setItem(WISHLIST_ID, response.id);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

function* handleRemoveWishlistItem(action: PayloadAction<number>) {
  const wishlistId: SagaReturnType<typeof getWishlistIdSelector> = yield select(
    getWishlistIdSelector,
  );
  const wishlistItemId = action.payload;
  const params = {
    id: wishlistId || '',
    wish_list_item_id: wishlistItemId,
  };
  try {
    const response: SagaReturnType<typeof api.postWishlistRemove> =
      yield api.postWishlistRemove(params);

    yield put(updateWishlist(response));
    localStorage.setItem(WISHLIST_ID, response.id);
    yield call(wishlistRemoveItemAnalytics, {
      item: wishlistItemId,
      wishlistId: response.id,
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
}

/**
 * Удаляет вишлист
 */
function* handleDestroyWishlist() {
  const wishlistId: SagaReturnType<typeof getWishlistIdSelector> = yield select(
    getWishlistIdSelector,
  );
  try {
    const response: SagaReturnType<typeof api.postWishlistDestroy> =
      yield api.postWishlistDestroy({
        id: wishlistId || '',
      });
    yield put(updateWishlist(response));
    localStorage.setItem(WISHLIST_ID, response.id);
    yield call(wishlistDestroyAnalytics, response.id);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
}

function* mergeWishlists(
  wishlistData: SagaReturnType<typeof api.getWishlistRead> | null,
) {
  /**
   * Если в URL передан параметр wish, то нужно смерджить вишлисты
   */
  const sharedWishlistId = window.location.search.split('?wish=')[1];
  /**
   * Если в URL передан параметр wish, и у пользователя есть еще свой вишлист,
   * то нужно их смерджить
   */
  if (sharedWishlistId && wishlistData) {
    const wishlistMergedData: SagaReturnType<typeof api.postWishlistMerge> =
      yield api.postWishlistMerge({
        id: wishlistData.id,
        mergable_id: sharedWishlistId,
      });
    yield put(updateWishlistWithFilters(wishlistMergedData));
    /**
     * Если в URL передан параметр wish, но у пользователя своего вишлиста нет,
     * то подставляется вишлист "друга"
     */
  } else if (sharedWishlistId && !wishlistData) {
    const wishlistResponse: SagaReturnType<typeof api.getWishlistRead> =
      yield api.getWishlistRead({
        id: sharedWishlistId,
      });
    yield put(updateWishlistWithFilters(wishlistResponse));
    /**
     * Если просто нужно получить данные своего вишлиста
     */
  } else if (!sharedWishlistId && wishlistData)
    yield put(updateWishlistWithFilters(wishlistData));
}

function* getWishlistItems(action: ReturnType<typeof getWishlistDataAction>) {
  /**
   * Получаем wishlist id либо из LS, либо если пользователь авторизован
   */
  const wishlistId = action.payload || localStorage.getItem(WISHLIST_ID);
  try {
    let wishlistData: SagaReturnType<typeof api.getWishlistRead> | null = null;
    if (wishlistId) {
      wishlistData = yield api.getWishlistRead({
        id: wishlistId,
      });
    }
    yield call(mergeWishlists, wishlistData);
    /**
     * После ответа ручки элементы вишлиста не успевают обработаться,
     * и показывается на доли секунд пустое избранное
     */
    yield delay(200);
    yield put(setWishlistFetched());
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
}

export default function* wishlistSaga() {
  yield takeLatest(getWishlistDataAction.type, getWishlistItems);
  yield takeLatest(addWishlistItem.type, handleCreateWishlist);
  yield takeLatest(removeWishlistItem.type, handleRemoveWishlistItem);
  yield takeLatest(destroyWishlistAction.type, handleDestroyWishlist);
  yield takeLatest(needShowWishlistOnboarding.type, doWeNeedToShowOnboarding);
  yield takeLatest(hideWishlistOnboarding.type, handleHideOnboarding);
}
