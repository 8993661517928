import { isSSR } from 'src/helpers/ssr';
import { getIsFeatureHasAnOption } from 'src/utils/featureToggle';

/** ключ для подключение к sentry */
export const KEY = process.env.SENTRY_FRONTEND_DSN;

/** получаем версию приложения (на момент написания это hash последнего комита) */
export const APPLICATION_VERSION = process.env.VERSION;

/** отправлять ли ошибки в sentry
 * формируется из фичи sentry_logs_front
 * и переменной, которая прокидывается окружением
 */
export const IS_REPORT_ERROR_TO_SENTRY =
  // смотрим на фичу (можно настраивать из админки). Если не знаешь что это спроси про фичитогл
  getIsFeatureHasAnOption('sentry_logs_front', 'send') &&
  // process.env.SENTRY_ENABLE эта штука прокидывается из webpack (настраивается через teamcity) подробнее можно узнать у devops
  // process.env.STAGING если false то это прод тоже спрашивать у devops
  (process.env.SENTRY_ENABLED === 'true' || process.env.STAGING === 'false');

/** показывать ли модальное окно от сентри, где к ошибке можно прикрепить репорт пользователя */
export const IS_SHOW_REPORT_DIALOG =
  (!isSSR && getIsFeatureHasAnOption('show_error_report_dialog', 'show')) ||
  false;

/**
 * отсылать ли ошибки onerror в sentry (работать с осторожностью)
 */
export const IS_REPORT_ONERROR = getIsFeatureHasAnOption(
  'sentry_log_onerror',
  'send',
);

/**
 * включено ли профилирование запросов в сентри
 * https://docs.sentry.io/product/performance/
 */
export const IS_PERFORMANCE_AVAILABLE = getIsFeatureHasAnOption(
  'sentry_performance_front',
  'on',
);

/**
 * включены ли транзакции
 */
export const IS_TRANSACTION_AVAILABLE = getIsFeatureHasAnOption(
  'sentry_percent_transaction',
  'on',
);

/**
 * включены ли Session Replay
 * https://docs.sentry.io/product/session-replay/
 */
export const IS_SESSION_REPLAY_AVAILABLE = getIsFeatureHasAnOption(
  'sentry_session_replay',
  'on',
);

/**
 * включена ли полная запись Session Replay у пользователя
 */
export const IS_SESSION_REPLAY_FULL_SESSION = getIsFeatureHasAnOption(
  'sentry_session_replay_full_session',
  'on',
);

export const IS_SENTRY_SHOW_FEEDBACK_WIDGET = getIsFeatureHasAnOption(
  'sentry_show_feedback_widget',
  'show',
);

export const IS_SESSION_REPLAY_ON_ERROR_AVAILABLE = getIsFeatureHasAnOption(
  'sentry_session_replay_on_error',
  'on',
);

// Если понадобиться для кастомных реплаев
// export const IS_SESSION_REPLAY_MANUALLY_AVAILABLE = getIsFeatureHasAnOption(
//   'sentry_session_replay_custom',
//   'on',
// );

export const IS_CANVAS_REPLAY_AVAILABLE = getIsFeatureHasAnOption(
  'sentry_canvas_replay',
  'on',
);
