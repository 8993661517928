import { connect } from 'react-redux';

import { getPartnerLogo } from 'src/store/logo/selectors';
import { isNewYearSelector } from 'src/store/menu/selectors';
import {
  getIsFontsBlack,
  getIsMobile,
  getIsWhitelabel,
} from 'src/store/view/selectors';

import HeaderLogo from './HeaderLogo';

const mapStateToProps = (state) => ({
  partnerLogo: getPartnerLogo(state),
  isNewYear: isNewYearSelector(state),
  isMobile: getIsMobile(state),
  isBlack: getIsFontsBlack(state),
  isWhiteLabel: getIsWhitelabel(state),
});

export default connect(mapStateToProps)(HeaderLogo);
