import React from 'react';
import styled, { css } from 'styled-components';

import { useTranslation } from '@lt/localization';

import { getTouristsLabel } from '@lt/components/components/TouristsPicker/utils';

import { SEARCH_TYPE_NAMES } from 'src/constants/search';

import { FIELD_NAMES } from '@lt/widget/components/Cigarette/constants';
import type { FieldType } from '@lt/widget/components/Cigarette/types';

import {
  DeparturePickerValue,
  DestinationPickerValue,
  DurationTrip,
} from '@lt/components';
import Search from '@lt/components/icons-react/userflow/Search';
import DropdownArrow from '@lt/components/icons-react/DropdownArrow';

import DesktopFieldPreview from '../DesktopFieldPreview';

import { getDurationTripProps } from './utils';

import { CIGARETTE_DESKTOP_PREVIEW_TEST_ID } from '../LazyCigarette/constants';
import type { IDesktopCigarettePreview } from './types';

export const DesktopCigarettePreview = (props: IDesktopCigarettePreview) => {
  const {
    onFieldClick,
    cigaretteValues,
    searchType,
    variant = 'default',
  } = props;
  const { destination, datepicker, tourists, departure } =
    cigaretteValues || {};

  const { t } = useTranslation();

  const handleFieldClick = (clickedField: FieldType) => {
    if (!onFieldClick) return;

    onFieldClick(clickedField);
  };

  const durationTripProps = destination
    ? getDurationTripProps(datepicker, searchType)
    : null;
  const touristsLabel =
    tourists &&
    getTouristsLabel({
      adultsValue: tourists.adults,
      kidsValue: tourists.kids,
    });

  const isHotelOnly = searchType === SEARCH_TYPE_NAMES.HOTEL;
  const isTrip = searchType === SEARCH_TYPE_NAMES.TRIP;
  const isHotelVariant = variant === 'hotel';

  return (
    <Container
      $isHotelVariant={isHotelVariant}
      data-testid={CIGARETTE_DESKTOP_PREVIEW_TEST_ID}
    >
      {!isHotelVariant && (
        <DesktopFieldPreview
          onClick={() => handleFieldClick(FIELD_NAMES.destination)}
          variant={FIELD_NAMES.destination}
          isFilled={!!destination}
        >
          <SearchIcon $isPlaceholder={!!destination} />
          <DestinationPickerValue
            isDisabled={!!destination}
            currentValue={destination}
          >
            <span>{t('cigarettePreview.destinationPickerPlaceholder')}</span>
          </DestinationPickerValue>
        </DesktopFieldPreview>
      )}
      {!isTrip && !!durationTripProps && (
        <DesktopFieldPreview
          onClick={() => handleFieldClick(FIELD_NAMES.datepicker)}
          variant={FIELD_NAMES.datepicker}
          isHotelVariant={isHotelVariant}
          isHotelOnly={isHotelOnly}
          isFilled
        >
          <DurationTrip {...durationTripProps} variant={variant} />
        </DesktopFieldPreview>
      )}
      {!isTrip && (
        <DesktopFieldPreview
          onClick={() => handleFieldClick(FIELD_NAMES.tourists)}
          variant={FIELD_NAMES.tourists}
          isHotelVariant={isHotelVariant}
          isHotelOnly={isHotelOnly}
          isFilled
        >
          <span>
            {touristsLabel ??
              t('cigarettePreview.touristsPicker.adult', { count: 2 })}
          </span>
          <DropdownArrowIcon />
        </DesktopFieldPreview>
      )}
      {!isHotelOnly && !isTrip && (
        <DesktopFieldPreview
          onClick={() => handleFieldClick(FIELD_NAMES.departure)}
          variant={FIELD_NAMES.departure}
          isHotelVariant={isHotelVariant}
          isHotelOnly={isHotelOnly}
          isFilled={!!departure}
        >
          <DeparturePickerValue
            isDisabled={!!departure}
            currentValue={departure}
          >
            <span>{t('cigarettePreview.departurePickerPlaceholder')}</span>
          </DeparturePickerValue>
          <DropdownArrowIcon />
        </DesktopFieldPreview>
      )}
      <DesktopFieldPreview
        onClick={() => handleFieldClick(FIELD_NAMES.submit)}
        variant={FIELD_NAMES.submit}
      >
        <StyledFormButtonPreview>
          {t('explore.cigarette.findButton')}
        </StyledFormButtonPreview>
      </DesktopFieldPreview>
    </Container>
  );
};

const Container = styled.div<{ $isHotelVariant: boolean }>`
  display: flex;

  ${({ $isHotelVariant }) =>
    $isHotelVariant &&
    `
      max-width: 1200px;
  `}
`;

const StyledFormButtonPreview = styled.div`
  font-size: 12px;
  line-height: 36px;
  font-weight: 700;
  text-align: center;

  height: 38px;
  min-width: 80px;
  width: 100%;

  background: linear-gradient(rgb(255, 231, 0), rgb(255, 208, 0));
  color: #000;

  border-width: 0px 0px 2px;
  border-bottom-style: solid;
  border-bottom-color: rgb(178, 126, 1);
  border-radius: 2px;

  cursor: pointer;
  &:hover {
    background: linear-gradient(to bottom, #ffe700, #ffea00);
  }
`;

const commonIconStyles = css`
  stroke: rgb(23, 24, 26);
`;

const SearchIcon = styled(Search)<{ $isPlaceholder: boolean }>`
  ${commonIconStyles}

  width: 24px;
  height: 24px;

  margin-right: 8px;

  stroke: ${({ $isPlaceholder }) => ($isPlaceholder ? '#919499' : '#17181a')};
`;

const DropdownArrowIcon = styled(DropdownArrow)`
  ${commonIconStyles}

  width: 10px;

  position: absolute;
  right: 8px;
`;
