import { createSelector } from '@reduxjs/toolkit';

import {
  getFromImmutableStateCompatible,
  getImmutableCompatible,
} from 'src/utils/immutableUtils';

import { name } from './slice';
import type { PopularDestinationsOffersState } from './types';

const _popularDestinationOffersSelector = (state) =>
  getImmutableCompatible(state, name);

const popularDestinationOffersSelector =
  getFromImmutableStateCompatible<PopularDestinationsOffersState>(
    _popularDestinationOffersSelector,
  );

export const popularDestinationsOffersListSelector = createSelector(
  popularDestinationOffersSelector,
  (offers) => offers.list,
);

export const isDefaultPopularDestinationsOffersSelector = createSelector(
  popularDestinationOffersSelector,
  (offers) => offers.isDefault,
);

export const popularDestinationsOffersTitleSelector = createSelector(
  popularDestinationOffersSelector,
  (offers) => offers.title,
);
