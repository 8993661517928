/* eslint-disable i18next/no-literal-string */
import React from 'react';
import { Link } from '@lt/components';
import { i18n } from '@lt/localization';
import Column from '../Column';

const HotelsColumn = () => (
  <Column title={i18n.t('footer.hotelsTitle')}>
    <li>
      <Link href="/tophotels/any/Russia">Россия:&nbsp;</Link>
      <Link href="/tophotels/any/Sochi-Russia">Сочи,&nbsp;</Link>
      <Link href="/tophotels/any/Adler-Russia">Адлер,&nbsp;</Link>
      <Link href="/tophotels/any/St_Petersburg-Russia">СПб,&nbsp;</Link>
      <Link href="/tophotels/any/Moscow-Russia">Москва</Link>
    </li>
    <li>
      <Link href="/tophotels/any/Turkey">Турция:&nbsp;</Link>
      <Link href="/tophotels/any/Istanbul-Turkey">Стамбул,&nbsp;</Link>
      <Link href="/tophotels/any/Antalya-Turkey">Анталья,&nbsp;</Link>
      <Link href="/tophotels/any/Alanya-Turkey">Алания</Link>
    </li>
    <li>
      <Link href="/tophotels/any/Thailand">Таиланд:&nbsp;</Link>
      <Link href="/tophotels/any/Phuket-Thailand">Пхукет,&nbsp;</Link>
      <Link href="/tophotels/any/Pattaya-Thailand">Паттайя</Link>
    </li>
    <li>
      <Link href="/tophotels/any/Egypt">Египет:&nbsp;</Link>
      <Link href="/tophotels/any/Hurghada-Egypt">Хургада,&nbsp;</Link>
      <Link href="/tophotels/any/Sharm_el_Sheikh-Egypt">Шарм-Эль-Шейх</Link>
    </li>
    <li>
      <Link href="/tophotels/any/UAE">ОАЭ:&nbsp;</Link>
      <Link href="/tophotels/any/Dubai-UAE">Дубай,&nbsp;</Link>
      <Link href="/tophotels/any/Sharjah-UAE">Шарджа</Link>
    </li>
    <li>
      <Link href="/tophotels/any/Maldives">Мальдивы:&nbsp;</Link>
      <Link href="/tophotels/any/Male-Maldives">Мале,&nbsp;</Link>
      <Link href="/tophotels/any/Maafushi-Maldives">Маафуши</Link>
    </li>
    <li>
      <Link href="/tophotels/any/Sri_Lanka">Шри-Ланка:&nbsp;</Link>
      <Link href="/tophotels/any/Hikkaduwa-Sri_Lanka">Хиккадува</Link>
    </li>
    <li>
      <Link href="/tophotels/any/India">Индия:&nbsp;</Link>
      <Link href="/tophotels/any/Goa-India">Гоа</Link>
    </li>
  </Column>
);

export default HotelsColumn;
