import { configureScope, onLoad } from '@sentry/browser';

import { initSentry } from 'src/error/config/sentry/initSentry.config';
import { saturationScope } from 'src/error/config/sentry/sentryScope.config';
import {
  APPLICATION_VERSION,
  IS_REPORT_ERROR_TO_SENTRY,
} from '../../config/sentry/runTimeConfig';
import { ErrorBoundary } from './ErrorBoundary';
import { sentryAsyncIntegrations } from '../../config/sentry/sentryAsyncIntegrations.config';

// TODO: нужно вынести все не относящееся к ErrorBoundary либо в индекс файл
/**
 * Функция для логирования основной информации о клиенте
 * Поможет детектить версионирование приложения и поможет отлечить при релизе
 * из кэша берется бандл или это полноценный новый релиз
 */
const logInformationAboutApplication = () => {
  // eslint-disable-next-line no-console
  console.group('Информация о клиенте:');
  // eslint-disable-next-line no-console
  console.info(`Версия: ${APPLICATION_VERSION}`);
  // eslint-disable-next-line no-console
  console.info(`Включено ли логирование: ${IS_REPORT_ERROR_TO_SENTRY}`);
  // eslint-disable-next-line no-console
  console.groupEnd();
};

if (IS_REPORT_ERROR_TO_SENTRY) {
  onLoad(() => {
    initSentry({});
    configureScope(saturationScope);
    sentryAsyncIntegrations({});
  });
}
if (process.env.NODE_ENV !== 'test') {
  logInformationAboutApplication();
}

export default ErrorBoundary;
