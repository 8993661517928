import React, { useRef } from 'react';
import classnames from 'classnames';
import flagSprite from '@lt/components/components/Flags';

import { useIntersectionObserver } from 'usehooks-ts';

import styled from 'styled-components';
import type { ICountry } from './types';
import styles from './countriesList.module.css';

interface ICountriesList {
  className?: string;
  isMobile?: boolean;
  countries: ICountry[];
}

export const CountriesList = (props: ICountriesList) => {
  const { className, countries = [], isMobile = false } = props;

  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, {
    freezeOnceVisible: true,
  });
  const isVisible = !!entry?.isIntersecting;

  if (countries.length === 0) return null;

  return (
    <StyledCountriesList
      className={classnames(className, styles.container, {
        [styles.containerMobile]: isMobile,
        [styles.containerDesktop]: !isMobile,
      })}
      ref={ref}
      $isVisible={isVisible}
    >
      {countries.map((item) => {
        const { link, text, iso2 } = item;

        return (
          <a
            key={iso2}
            href={link}
            className={classnames(styles.countryLink, {
              [styles.countryLinkMobile]: isMobile,
            })}
          >
            {isVisible && (
              <div
                className={classnames(
                  `lt-flag lt-flag-${iso2?.toLowerCase() || 'us'}`,
                  styles.flag,
                )}
              />
            )}

            {text}
          </a>
        );
      })}
    </StyledCountriesList>
  );
};

const StyledCountriesList = styled.div<{ $isVisible: boolean }>`
  ${({ $isVisible }) => ($isVisible ? flagSprite() : '')}
`;
