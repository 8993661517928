export const EXTRA_SCOPES = {
  FLIGHT: 'flight',
  TOUR: 'tour',
  TOURIST: 'tourist',
  LEVEL_TRAVEL: 'leveltravel',
} as const;

export const EXTRAS = {
  FLIGHT_LOCK: 'lock_flight',
  INFANT_BOARDING_PASS: 'infant',
  VISA_RISK: 'visa_risk',
  NEW_YEAR_DINNER: 'new_year_dinner',
  CHRISTMAS_DINNER: 'christmas_dinner',
} as const;
