import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import { showPopup } from 'src/store/feedback/slices';
import { getIsHideFeedback, getMenuContacts } from 'src/store/menu/selectors';

import { getShortLink } from 'src/store/appBanners/selectors';
import { getDeeplinkAction } from 'src/store/appBanners/slice';
import {
  getIsWebview,
  getMobilePlatform,
  getIsAppBannerEnabled,
} from 'src/store/view/selectors';
import { getWishlistItemsCounterSelector } from 'src/store/wishlist/selectors';
import { SideNavMenu } from './SideNavMenu';

const mapStateToProps = (state) => ({
  isHideFeedback: getIsHideFeedback(state),
  contacts: getMenuContacts(state),
  deviceType: getMobilePlatform(state),
  wishlistCount: getWishlistItemsCounterSelector(state),
  appLink: getShortLink(state),
  isWebview: getIsWebview(state),
  isAppBannerEnabled: getIsAppBannerEnabled(state),
});

const mapDispatchToProps = {
  onOpenWriteUsPopup: showPopup,
  getDeeplink: getDeeplinkAction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SideNavMenu);
