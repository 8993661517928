import { getUrlParams } from '../../../../../../utils/uriUtils';
import type { FlightsFilter, FlightsFilters } from './types';
import { nestedFilters, optionsIdArray } from './constants';
import { OPERATOR_QUERY_PARAM_KEY } from '../../../../../../constants/filters';

/**
 * Добавляем поле selected и min_price ко всем опциям фильтра
 */
const addSelectedFieldToFilterOptions = (filterValuesData: any) =>
  filterValuesData.map((filterOption: FlightsFilter) => {
    if (filterOption.id) {
      return filterOption;
    }
    // Проверяем какое из полей может стать ID опции
    const optionToBeId = optionsIdArray.find(
      (possibleOptionName) => filterOption[possibleOptionName] !== undefined,
    );

    if (optionToBeId) filterOption.id = filterOption[optionToBeId];

    return filterOption;
  });

/**
 * Превращаем ответ апи в фильтры, аналогичные фильтрам на поиске
 */
export const transformResponse = (filtersFromServer: any): FlightsFilters =>
  Object.fromEntries(
    Object.entries(filtersFromServer).map(
      ([filterName, filterValues]: any[]) => {
        const filterValuesData = nestedFilters.includes(filterName)
          ? filterValues.airports
          : filterValues;
        const newFilterValues =
          addSelectedFieldToFilterOptions(filterValuesData);
        if (nestedFilters.includes(filterName))
          return [filterName, { ...filterValues, airports: newFilterValues }];
        return [filterName, newFilterValues];
      },
    ),
  );

/**
 * Возвращаем данные фильтра, если вложенный фильтр - то поле airports
 */
export const unwrapIfNestedFilter = (
  state,
  filterName: string,
): FlightsFilter[] =>
  nestedFilters.includes(filterName)
    ? state[filterName].airports
    : state[filterName];

/**
 * Фильтрует пустые фильтры (те, которые не должны отображаться)
 */
export const filterEmptyFilters = ([, filterValue]: any[]) => {
  const unwrappedFilterValue = filterValue?.airports || filterValue;
  return unwrappedFilterValue?.length > 0;
};

/**
 * Получить инфу по фильтрам ТО из урл и привести к массиву чисел
 */
export const getTOFiltersFromUrl = (): number[] => {
  const urlParams = getUrlParams();
  const [operatorsIdsFromUrl] = Object.keys(urlParams)
    .filter((paramName) => paramName.startsWith(OPERATOR_QUERY_PARAM_KEY))
    .map((key) => urlParams[key]);

  if (!operatorsIdsFromUrl) return [];

  return operatorsIdsFromUrl.split(',').map(Number);
};
