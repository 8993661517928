import { createSelector } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';

import { getImmutableCompatible } from '../../utils/immutableUtils';
import { PAYMENT_METHOD_CARD } from './constants';
import type { PaymentStateType } from './types';
import { isPaylate } from './utils';

// selectors
export const getPayment = (state): PaymentStateType =>
  getImmutableCompatible(state, 'payment');

/**
 * If payment succeeded;
 */
export const getPaymentIsSucceeded = createSelector(
  getPayment,
  (payment) => payment.isPaid,
);

/**
 * Payment amount
 */
export const getPaymentAmount = createSelector(
  getPayment,
  (payment) => payment.paymentAmount,
);

export const select3dsData = (state) => getPayment(state).data3ds;

/**
 * Order info
 */
export const getOrderId = createSelector(
  getPayment,
  (payment) => payment.orderId,
);
export const getOrderPin = createSelector(
  getPayment,
  (payment) => payment.orderPin,
);

/**
 * Uniteller payment id
 */
export const selectPaymentId = createSelector(
  getPayment,
  (payment) => payment.paymentId,
);

/**
 * Order is paid
 */
export const getOrderIsPaid = createSelector(
  getPayment,
  (payment) => payment.isPaid,
);

/**
 * Card data
 */
export const getCardData = createSelector(
  getPayment,
  (payment) => payment.cardData,
);
export const getCardIsValid = createSelector(
  getPayment,
  (payment) => payment.cardIsValid,
);

/**
 * Payment method:
 */
export const getPaymentMethod = createSelector(
  getPayment,
  (payment) => payment.paymentMethod,
);
export const getPaymentMethodList = createSelector(getPayment, (payment) =>
  payment.paymentMethodList?.filter((method) => method.enable),
);

export const selectIsSbpIncluded = createSelector(
  getPaymentMethodList,
  (methods) => Boolean(methods.find((method) => method.type === 'sbp')),
);

export const getDefaultPaymentMethod = createSelector(
  getPaymentMethodList,
  (paymentMethods: unknown[]) => {
    if (
      !(
        paymentMethods &&
        Array.isArray(paymentMethods) &&
        paymentMethods.length > 0
      )
    ) {
      return PAYMENT_METHOD_CARD;
    }
    const paymentMethod = paymentMethods[0];
    if (
      !(
        paymentMethod &&
        typeof paymentMethod === 'object' &&
        'type' in paymentMethod &&
        paymentMethod.type === 'string'
      )
    ) {
      return PAYMENT_METHOD_CARD;
    }
    return paymentMethod.type;
  },
);
export const hasPaymentMethod = createSelector(
  getPayment,
  (paymentMethods) => !isEmpty(paymentMethods.paymentMethodList),
);
export const getPaymentData = createSelector(
  [getPaymentMethodList, getPaymentMethod],
  (paymentMethods = [], orderPaymentMethod) =>
    paymentMethods.find((item) => item.type === orderPaymentMethod),
);

export const selectPaylateData = createSelector(
  getPayment,
  (payment) => payment.paylateData,
);

/**
 * Возвращает `true` если метод оплаты Paylate
 */
export const getIsPaylate = createSelector(getPaymentMethod, isPaylate);

export const selectPay2meURL = (state) => getPayment(state).pay2meURL;

export const selectTariffs = (state) => getPayment(state).tbankCredit.tariffs;
export const selectCurrentTariffID = (state) =>
  getPayment(state).tbankCredit.selectedTariffID;
