import { createSelector } from '@reduxjs/toolkit';
import { name } from './slice';
import type { PrebookStateType } from './types';

export const getPrebookInfo = (state): PrebookStateType => state[name];

export const getPrebookTryCount = createSelector(
  getPrebookInfo,
  (state) => state.tryCount,
);

export const getIsPrebookInProgress = createSelector(
  getPrebookInfo,
  (state) => state.inProgress,
);

export const getIsPrebookSuccess = createSelector(
  getPrebookInfo,
  (state) => state.success,
);

export const getBookingId = createSelector(
  getPrebookInfo,
  (state) => state.bookingId || undefined,
);

export const getIsPrebookPending = createSelector(
  getPrebookInfo,
  (state) => state.pending,
);
