import React from 'react';

import type {
  IPopularDestinationsOffer,
  IPopularDestinationsOfferDefault,
  IPopularDestinationsOfferWithPrice,
} from 'src/routes/Home/store/popularDestinationsOffers/types';

import type { PropsFromRedux } from '.';

import PopularCountries from './components/PopularCountries';
import CountriesWithPrices from './components/CountriesWithPrices';

interface IPopularDestinationsOffers extends PropsFromRedux {
  isDefault?: boolean;
  isMobile: boolean;
  places: IPopularDestinationsOffer[];
}

export const PopularDestinationsOffers = (
  props: IPopularDestinationsOffers,
) => {
  const { isDefault = false, isMobile, places, title } = props;

  return isDefault ? (
    <PopularCountries
      places={places as IPopularDestinationsOfferDefault[]}
      title={title}
      isMobile={isMobile}
    />
  ) : (
    <CountriesWithPrices
      places={places as IPopularDestinationsOfferWithPrice[]}
      title={title}
      isMobile={isMobile}
    />
  );
};
