import React from 'react';
import { Copy, Done } from '@lt/components/icons-react';
import { i18n } from '@lt/localization';
import styles from '../../../mobileAppPopup.module.css';
import { DONE_ICON_TEST_ID, COPY_ICON_TEST_ID } from '../../../constants';

interface CopyIconProps {
  copied: boolean;
  onClick: () => void;
}

const CopyIcon: React.FC<CopyIconProps> = ({ copied, onClick }) => (
  <>
    {copied ? (
      <Done className={styles.doneIcon} data-testid={DONE_ICON_TEST_ID} />
    ) : (
      <Copy
        className={styles.copyIcon}
        onClick={onClick}
        aria-label={i18n.t('mobile_apps_banner.copyPromoCode')}
        data-testid={COPY_ICON_TEST_ID}
      />
    )}
  </>
);

export default CopyIcon;
