import React from 'react';
import { AuthFromWishlistDesktop } from './AuthFromWishlistDesktop/AuthFromWishlistDesktop';
import { AuthFromWishlistMobile } from './AuthFromWishlistMobile/AuthFromWishlistMobile';
import { pushWishlistAuth } from './analytics';
import type {
  AuthFromWishlistCommonProps,
  DesktopProps,
  MobileProps,
} from './types';

type AuthFromWishlistProps = AuthFromWishlistCommonProps &
  (MobileProps | DesktopProps);

const AuthFromWishlist: React.FC<AuthFromWishlistProps> = (props) => {
  const { isMobile, setShowAuthFromWishlist, onShowAuthPopup } = props;

  const handleOpenLoginForm = () => {
    setShowAuthFromWishlist(true);
    onShowAuthPopup();
    pushWishlistAuth();
  };

  if (isMobile) {
    const {
      lastWishlistItem,
      wishlistId,
      addToWishlist,
      removeFromWishlist,
      isContainedInWishlist,
    } = props;

    return (
      <AuthFromWishlistMobile
        lastWishlistItem={lastWishlistItem}
        wishlistId={wishlistId}
        addToWishlist={addToWishlist}
        removeFromWishlist={removeFromWishlist}
        isContainedInWishlist={isContainedInWishlist}
        handleOpenLoginForm={handleOpenLoginForm}
      />
    );
  }

  return <AuthFromWishlistDesktop handleOpenLoginForm={handleOpenLoginForm} />;
};

export default AuthFromWishlist;
