import type { SagaReturnType } from 'redux-saga/effects';
import { select, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';

import { api } from 'src/api';

import { takeOnce } from 'src/helpers/effects';
import { clientStorage } from 'src/api/storage';
import { goToFlightSectionAction } from 'src/routes/Checkout/store/actions/view';
import { PageType } from 'src/components/AuthNew/types';

import { MODULE_NAME } from 'src/constants';
import { isSSR } from 'src/helpers/ssr';
import { INIT_APP } from '../view/constants';
import { ON_LOAD, FETCH_CLIENT_INFO } from './constants';
import {
  hideAuthAction,
  showAuthAction,
  fetchClientInfo,
  setClientInfo,
  logOutAction,
  setClientAuthToken,
  showLogOutAction,
  confirmClientAction,
  logInAction,
  setClientConfirmPhone,
  signUpAction,
  resetClientAction,
} from './actions';
import { getShowAuthPopup, getIsLoggedIn, getClientPhone } from './selectors';
import { getPageType } from './helpers/getPageType';
import { getAppName } from '../view/selectors';
import { getWishlistDataAction } from '../wishlist/actions';
import { setWishlistId } from '../wishlist/slice';

function getResetPasswordToken(): string | null {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get('reset_password_token');
}

function getConfirmationToken(): string | null {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get('confirmation_token');
}

function getShowLoginFormParam(): string | null {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get('show_login_form');
}

function getSessionExpiredParam(): string | null {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get('session_expired');
}

function* handleClosePopupAuth(action: unknown) {
  const isAuthPopupVisible: SagaReturnType<typeof getShowAuthPopup> =
    yield select(getShowAuthPopup);
  const isConfirmedPhone: SagaReturnType<typeof getClientPhone> = yield select(
    getClientPhone,
  );

  if (
    isAuthPopupVisible &&
    isConfirmedPhone &&
    action &&
    typeof action === 'object' &&
    'payload' in action &&
    typeof action.payload === 'object' &&
    action.payload &&
    (('name' in action.payload && action?.payload?.name) ||
      ('shouldHideAuthPopup' in action.payload &&
        action?.payload?.shouldHideAuthPopup))
  ) {
    yield put(hideAuthAction());
  }
}

function* handleGetClientInfo() {
  try {
    const authToken = clientStorage.getAuthToken();
    if (!authToken) {
      yield put(getWishlistDataAction());
      return;
    }
    const { client }: SagaReturnType<typeof api.getClientShow> =
      yield api.getClientShow({ auth_token: authToken });
    clientStorage.saveAuthToken(client.auth_token);
    yield put(
      setClientInfo({
        id: client.id,
        email: client.email,
        avatar: client.avatar,
        unconfirmed_email: client.unconfirmed_email || undefined,
        confirmed_at: undefined,
        unconfirmed_phone: client.unconfirmed_phone || undefined,
        name: client.name || undefined,
        surname: client.surname || undefined,
        phone: client.phone || undefined,
        bonus_score: client.bonus_score,
        coefficient_for_room_upgrade: undefined,
        has_password: client.has_password,
        auth_token: client.auth_token,
        name_en: client.name_en,
        surname_en: client.surname_en,
      }),
    );
    if (client.wishlist_id) yield put(setWishlistId(client.wishlist_id));
    yield put(getWishlistDataAction(client.wishlist_id || undefined));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
}

function* onClientLogOut() {
  const resetPasswordToken = getResetPasswordToken();
  const sessionExpiredParam = getSessionExpiredParam();
  const showLoginFormParam = getShowLoginFormParam();
  const appName: SagaReturnType<typeof getAppName> = yield select(getAppName);
  try {
    const authToken = clientStorage.getAuthToken();
    // произведения искусства
    const success = yield axios
      .get(`${window.location.origin}/client/logout`)
      .then((res) => res.data.success);
    if (success) {
      if (!authToken) {
        return;
      }
      yield api.postClientLogout({
        auth_token: authToken,
        use_cookies: true,
      });

      // сбрасываем инфу о клиенте на странице сертификата
      if (appName === MODULE_NAME.GIFT_CERT)
        yield put(confirmClientAction(null));

      // сбрасываем инфу о клиенте на чекауте
      if (appName === MODULE_NAME.PACKAGE_CHECKOUT)
        yield put(resetClientAction());

      // если логаут был на странице оплаты, то
      // редиректим обратно на выбор перелета
      if (getPageType() === PageType.Services) {
        yield put(goToFlightSectionAction());
      }
      if (!resetPasswordToken && !sessionExpiredParam && !showLoginFormParam) {
        yield put(showLogOutAction());
      }
      yield put(showAuthAction());
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
}

/**
 * Обрабатываем кейс, когда при загрузке страницы window.current_client === null (т.е. на бэке клиент не авторизован),
 * но в локалсторадже по какой-то причине auth_token присутствует. В таком случае удаляем auth_token из LS
 */
function checkAuthTokenWithWindowData() {
  const authToken = clientStorage.getAuthToken();

  if (!isSSR && !window.current_client && authToken) {
    clientStorage.resetAuthToken();
  }
}
function* onInitApp() {
  checkAuthTokenWithWindowData();

  yield put(fetchClientInfo());
}

function* handleCheckLoginFormParam() {
  const showLoginFormParam = getShowLoginFormParam();
  const isLoggedIn: SagaReturnType<typeof getIsLoggedIn> = yield select(
    getIsLoggedIn,
  );

  if (showLoginFormParam && !isLoggedIn) yield put(showAuthAction());
}

function* handleCheckClientInfo() {
  yield* handleGetClientInfo();
}

function* handleCheckURLParams() {
  const confirmationToken = getConfirmationToken();
  const resetPasswordToken = getResetPasswordToken();
  const sessionExpiredParam = getSessionExpiredParam();
  const showLoginFormParam = getShowLoginFormParam();

  if (confirmationToken && !resetPasswordToken) {
    yield put(showAuthAction());
  } else if (resetPasswordToken || sessionExpiredParam || showLoginFormParam) {
    yield put(logOutAction());
    yield put(showAuthAction());
  }
}

function* handleLoadAuthToken() {
  const isLoggedIn: SagaReturnType<typeof getIsLoggedIn> = yield select(
    getIsLoggedIn,
  );
  const storageToken = clientStorage.getAuthToken();
  const windowToken = window?.current_client?.auth_token;

  // если в локалсторадже нет auth_token, но есть в window, то берем его из window.current_client
  if (!storageToken && windowToken) {
    yield clientStorage.saveAuthToken(windowToken);
    yield put(setClientAuthToken(windowToken));
    return;
  }

  // если токена по какой-то причине нет ни в localStorage, ни в window, но при этом в сторе есть инфа о клиенте, то логаутим для очистки стора
  if (!storageToken && !windowToken && isLoggedIn) {
    yield put(logOutAction());
  }
}

export default function* clientSaga() {
  yield takeLatest(INIT_APP, onInitApp);
  yield takeLatest(logInAction.type, handleClosePopupAuth);
  yield takeLatest(setClientConfirmPhone.type, handleClosePopupAuth);
  yield takeLatest(logInAction.type, handleGetClientInfo);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  yield takeLatest(logInAction.type, (action: any) => {
    clientStorage.saveAuthToken(action?.payload?.auth_token);
    // поскольку страница заказа - легаси, то необходимо обновлять страницу,
    // чтобы подтянулись/обновились некоторые данные.
    // TODO: убрать после переноса страницы заказа на React
    if (getPageType() === PageType.Order) window.location.reload();
  });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  yield takeOnce(signUpAction.type, (action: any) => {
    clientStorage.saveAuthToken(action?.payload?.auth_token);
  });
  yield takeLatest(logOutAction.type, onClientLogOut);
  yield takeLatest(logOutAction.type, () => {
    clientStorage.resetAuthToken();
    if (!isSSR) window.current_client = null;
  });
  yield takeLatest(FETCH_CLIENT_INFO, handleGetClientInfo);
  yield takeOnce(ON_LOAD, handleCheckClientInfo);
  yield takeOnce(ON_LOAD, handleCheckLoginFormParam);
  yield takeOnce(ON_LOAD, handleCheckURLParams);
  yield takeOnce(ON_LOAD, handleLoadAuthToken);
}
