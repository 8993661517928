import React, { useCallback, useEffect, useState, lazy, Suspense } from 'react';
import UserflowTheme from '@lt/components/themes/userflow';
import { useSearchParams } from 'src/components/AuthNew/hooks/useResetPasswordToken';
import { getClientType } from 'src/components/AuthNew/utils/getClientType';
import type { ConfirmPhoneConfig } from 'src/components/AuthNew/types';
import { AuthFormType } from 'src/components/AuthNew/types';
import type { CLIENT } from '@lt/api/dist/types/client';
import { css } from 'styled-components';
import Modal from 'react-responsive-modal';
import { removeURLParam } from 'src/components/AuthNew/utils';
import ErrorBoundary from 'src/error/components/ErrorBoundary';
import { useSelector } from 'react-redux';
import { getShowLogOutPopup } from 'src/store/client/selectors';

const AuthNew = lazy(
  () =>
    import(/* webpackChunkName: "AuthNew" */ 'src/components/AuthNew/AuthNew'),
);

type Props = {
  isOpen: boolean;
  isWhiteLabel?: boolean;
  onCloseAuthPopup?: () => void;
  clientName: string | null;
  clientUnconfirmedPhone: string | null;
  isConfirmedClientEmail: string | null;
  logIn: (client: CLIENT) => void;
  signUp: (client: CLIENT) => void;
  logOut: (shouldShowAuthPopup?: boolean) => void;
  hideLogOutPopup: () => void;
  setConfirmedPhone?: (confirmPhoneConfig: ConfirmPhoneConfig) => void;
};

const authLayoutCSS = {
  [AuthFormType.Login]: {
    loginFormCSS: css`
      background: #fff;
    `,
  },
  [AuthFormType.Registration]: {
    wrapperCSS: css`
      width: 100%;
    `,
  },
  [AuthFormType.CreatePassword]: {
    wrapperCSS: css`
      width: 100%;
    `,
  },
};

const Auth = ({
  isOpen = false,
  isWhiteLabel = false,
  onCloseAuthPopup = () => null,
  clientName,
  clientUnconfirmedPhone,
  isConfirmedClientEmail,
  logIn,
  signUp,
  logOut,
  hideLogOutPopup,
  setConfirmedPhone = () => null,
}: Props) => {
  const [open, setOpen] = useState(isOpen);

  const { reset_password_token } = useSearchParams('reset_password_token');
  const { show_login_form } = useSearchParams('show_login_form');

  const isShownLogOutForm = useSelector(getShowLogOutPopup);

  useEffect(() => {
    if (reset_password_token || show_login_form) {
      setOpen(true);
    }
  }, [reset_password_token, show_login_form]);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const onGetClientType = getClientType(
    clientName,
    isConfirmedClientEmail,
    clientUnconfirmedPhone,
  );

  const onCloseModal = () => {
    setOpen(false);
    if (isShownLogOutForm) hideLogOutPopup();
    onCloseAuthPopup();
    removeURLParam('redirect_to');
    removeURLParam('show_login_form');
    removeURLParam('reset_password_token');
    removeURLParam('confirmation_token');
    removeURLParam('session_expired');
    removeURLParam('email');
  };

  const onSignIn = useCallback(
    (client) => {
      if (!clientName)
        setTimeout(() => {
          logIn(client);
        }, 2000);
      else
        setTimeout(() => {
          onCloseModal();
        }, 2000);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clientName, logIn],
  );

  const onSignUp = useCallback(
    (client) => {
      setTimeout(() => {
        signUp(client);
      }, 2000);
    },
    [signUp],
  );

  const onLogOut = (shouldShowAuthPopup?: boolean) => {
    if (clientName) logOut();
    if (shouldShowAuthPopup) {
      setOpen(true);
    }
  };

  const handleSetConfirmedPhone = useCallback(
    (phone) => {
      setTimeout(() => {
        setConfirmedPhone(phone);
      }, 2000);
    },
    [setConfirmedPhone],
  );

  const modal = {
    height: '100%',
    width: '100%',
    background: '#F5F3F2',
  };

  const overlay = {
    padding: 0,
    zIndex: 10000,
  };

  return (
    <ErrorBoundary>
      <UserflowTheme>
        {/** TODO: @see https://tracker.yandex.ru/LT-39577 */}
        {/** @ts-ignore */}
        <Modal
          open={open}
          onClose={onCloseModal}
          showCloseIcon={false}
          styles={{ modal, overlay }}
          center
        >
          <Suspense fallback={null}>
            <AuthNew
              isMobile
              isWhiteLabel={isWhiteLabel}
              authLayoutCSS={authLayoutCSS}
              onSignIn={onSignIn}
              onSignUp={onSignUp}
              onLogOut={onLogOut}
              setConfirmedPhone={handleSetConfirmedPhone}
              onClose={onCloseModal}
              clientType={onGetClientType}
              showCloseButton
            />
          </Suspense>
        </Modal>
      </UserflowTheme>
    </ErrorBoundary>
  );
};

export default Auth;
