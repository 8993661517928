import { logInAction } from 'src/store/client/actions';
import { connect } from 'react-redux';
import { getIsLoggedIn } from 'src/store/client/selectors';

import { LazySeamlessTBankAuth } from './LazySeamlessTBankAuth';

const mapStateToProps = (state) => ({
  isLoggedIn: getIsLoggedIn(state),
});

const mapDispatchToProps = {
  logIn: logInAction,
};

// JS файл, так как logInAction типизирован странно и по всему проекту вызывается через dispatch, либо без типизации(желатильно исправить при написании новой авторизации)
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LazySeamlessTBankAuth);
