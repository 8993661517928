import React from 'react';
import styled from 'styled-components';

import { Close } from '@lt/components/icons-react';
import type { TourGuideButtonProps } from '../../../types';
import { IMAGES } from '../constants';
import { container, image } from '../style';

export const TourGuideButtonMobile = (
  props: TourGuideButtonProps,
): JSX.Element => {
  const { onClick, onClose, className, text, mode } = props;
  return (
    <Container onClick={onClick} className={className}>
      <Text $mode={mode}>{text}</Text>
      <LoungeChairImage />
      <CloseIconWrapper onClick={onClose}>
        <CloseIcon />
      </CloseIconWrapper>
    </Container>
  );
};

const Container = styled.div`
  ${container}
  height: 60px;
`;

const Text = styled.p<{ $mode: 'payment' | 'default' }>`
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: center;
  margin-left: 8px;
  width: ${({ $mode }) => ($mode === 'default' ? `168px` : `100%`)};
`;

const CloseIconWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px 12px;
`;

const CloseIcon = styled(Close)`
  stroke: #fff;
  width: 8px;
  height: 8px;
`;

const LoungeChairImage = styled.div`
  ${image}
  position: absolute;
  right: -38px;
  top: -34px;
  height: 158px;
  width: 164px;
  background-image: url(${IMAGES.LOUNGE_CHAIR_SMILE});
`;
