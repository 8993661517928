import { formatDate } from '@lt/components/utils/date';

import type { DateYMDString } from '@lt/api/dist/types/date';

/**
 * Получить дату в формате
 * 2020-04-25 -> 25 апр
 */
export const getFormattedDate = (date: Date | DateYMDString) =>
  formatDate(date, 'dd MMM');
