import { connect } from 'react-redux';

import { popularDestinationsOffersTitleSelector } from 'src/routes/Home/store/popularDestinationsOffers/selectors';

import { PopularDestinationsOffers } from './PopularDestinationsOffers';

const mapStateToProps = (state) => ({
  title: popularDestinationsOffersTitleSelector(state),
});

const connector = connect(mapStateToProps);
export type PropsFromRedux = ReturnType<typeof mapStateToProps>;

export default connector(PopularDestinationsOffers);
