import { createSelector } from '@reduxjs/toolkit';
import { headerSelector } from '../menu/selectors';

/**
 * Возвращает лого партнера
 */
export const getPartnerLogo = createSelector(headerSelector, (header) => {
  const logo = header?.logo;
  return typeof logo === 'string' ? null : logo;
});
