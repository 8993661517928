import { TABS_STYLES, WL_TABS_STYLES } from './constants';

import type { TabsVariant } from './types';

export const getStyles = (
  component: 'TAB' | 'ICON',
  variant: TabsVariant,
  isActive?: boolean,
): string => {
  const { page, mobile, collapsed, wl } = variant;

  const tabStyles = wl ? WL_TABS_STYLES : TABS_STYLES;

  let styles;

  switch (page) {
    case 'HOTEL':
      styles = tabStyles[mobile ? 'MOBILE' : 'DESKTOP'].HOTEL;
      break;
    case 'MAIN':
      styles = mobile
        ? tabStyles.MOBILE.MAIN_PAGE.NEW
        : tabStyles.DESKTOP.MAIN_PAGE;
      break;
    case 'OLD_MAIN':
      styles = tabStyles.MOBILE.MAIN_PAGE.OLD;
      break;
    case 'SEARCH':
      styles = mobile
        ? tabStyles.MOBILE.SEARCH[collapsed ? 'COLLAPSED' : 'EXPANDED']
        : tabStyles.DESKTOP.SEARCH;
      break;
    default:
      return '';
  }

  return styles[isActive ? 'ENABLED' : 'DISABLED'][component];
};
