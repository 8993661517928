import { createSelector } from '@reduxjs/toolkit';
import { flatten } from 'lodash';
import { i18n } from '@lt/localization';
import { getImmutableCompatible } from '../../../../utils/immutableUtils';
import { getOffersForCurrentDateWithAllHotelFilters } from './searcher';
import type { UpdateHotelFiltersType } from '../reducers/filters';
import { getOffersMinPrice } from '../../utils/offersUtils';

export const getFilters = (state): UpdateHotelFiltersType =>
  getImmutableCompatible(state, 'filters');

export const getFreeCancelFilter = createSelector(
  [getOffersForCurrentDateWithAllHotelFilters],
  (rooms) => {
    const flattenRooms = rooms.reduce((acc, room) => {
      const offers = flatten(Object.values(room.offers));
      acc.push(...offers);
      return acc;
    }, []);
    const offers = flattenRooms.filter(
      (offer) => offer?.rates?.cancellation_policy.kind === 'flexible',
    );

    return {
      icon: 'free_cancel',
      id: 'freeCancel',
      name: i18n.t('hotel.filters.freeCancelFilter'),
      minPrice: getOffersMinPrice(offers),
      count: offers.length,
      emptyReason: offers.length === 0 ? 'freeCancel' : null,
    };
  },
);
