import React from 'react';
import classnames from 'classnames';
import { Image } from '@lt/components';
import type { IPopularMetaregionsOffer } from 'src/routes/Home/store/popularMetaregionsOffers/types';
import stylesCard from '../../styles/card.module.css';
import CardHeading from '../../CardHeading';
import CardButton from '../../CardButton';

import styles from './popularMetaregion.module.css';
import stylesImage from '../../styles/cardImages.module.css';
import stylesContentContainer from '../../styles/contentContainer.module.css';

interface IPopularMetaregion extends IPopularMetaregionsOffer {
  isMobile: boolean;
}

export const PopularMetaregion = (props: IPopularMetaregion) => {
  const { imageUrl, title, link, regions, isMobile } = props;

  return (
    <div
      className={classnames(
        stylesCard.card,
        stylesCard.cardMetaregionsVariant,
        stylesCard.shadow,
        {
          [stylesCard.desktop]: !isMobile,
          [stylesCard.cardMetaregionsVariantMobile]: isMobile,
        },
      )}
    >
      <Image
        className={stylesImage.image}
        src={imageUrl}
        fallback={imageUrl}
        loading="lazy"
      />
      <div
        className={classnames(
          stylesContentContainer.contentContainer,
          stylesContentContainer.contentContainerMetaregions,
        )}
      >
        <CardHeading text={title} url={link} isMobile={isMobile} isBlack />
        <div className={styles.buttonsContainer}>
          {regions.map((region) => (
            <CardButton
              text={region.title}
              isMobile={isMobile}
              url={region.link}
              isBlue
              key={`${title}_region_${region.title}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
