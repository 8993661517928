export const TEST_ID_FLIGHT_CHANGE_WARNING = 'flight-change-warning';

export const TEST_ID_FLIGHT_CHANGE_WARNING_CHECKBOX =
  'flight-change-warning-checkbox';

/*
  Константы для обработки события нажатия на чекбокс
*/
export const OPERATORS = 'operators';
export const BIBLIO_GLOBUS_ID = 7;

/*
  Константа смещения от верхней границы при скролле
*/
export const FLIGHT_OFFSET = 120;
