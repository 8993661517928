import React from 'react';

import type { IPopularDestinationsOfferRegionWithPrice } from 'src/routes/Home/store/popularDestinationsOffers/types';

import { RegionLabel } from './RegionLabel';
import styles from './styles/cardLabels.module.css';

interface ICardLabels {
  regions: IPopularDestinationsOfferRegionWithPrice[];
}

export const CardLabels = (props: ICardLabels) => {
  const { regions } = props;

  return (
    <div className={styles.cardLabelsContainer}>
      {regions.map((regionProps) => (
        <RegionLabel
          {...regionProps}
          key={`relinking_new_year_card_region_${regionProps.place}`}
        />
      ))}
    </div>
  );
};
