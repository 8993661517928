export const GO_MOBILE_APP_POPUP = 'GO_MOBILE_APP_POPUP';
export const MOVE_TO_APP_SHOWN = 'MOVE_TO_APP_SHOWN';
export const MOVE_TO_APP_OPENED_APP = 'MOVE_TO_APP_OPENED_APP';
export const MOVE_TO_APP_CONTINUED_WEB = 'MOVE_TO_APP_CONTINUED_WEB';
export const GET_DEEPLINK = 'GET_DEEPLINK';
export const PARTNER_KEY_COOKIE = 'lt_ref';
export const UTM_KEY_COOKIE = '__utmz';
export const AFFMARKET_PARAMS = 'affmarker';
export const utm_marker = new Map([
  ['utmcsr', 'utm_source'],
  ['utmccn', 'utm_campaign'],
  ['utmcmd', 'utm_medium'],
  ['utmctr', 'utm_keyword'],
  ['utmcct', 'utm_content'],
]);
