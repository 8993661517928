import { createAction } from '@reduxjs/toolkit';

import {
  CLEAR_PREVIOUS_PACKAGE_DATA,
  CREATE_PACKAGE_SUCCESS,
  UPDATE_PACKAGE_ID,
} from '../constants/package';

import type { PackageStateType } from '../package/types';

export const createPackageSuccess = createAction<Partial<PackageStateType>>(
  CREATE_PACKAGE_SUCCESS,
);

export const updatePackageId = createAction<number>(UPDATE_PACKAGE_ID);

export const clearPreviousPackageDataAction = createAction(
  CLEAR_PREVIOUS_PACKAGE_DATA,
);
