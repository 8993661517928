import React from 'react';
import { useTranslation } from '@lt/localization';
import { AuthFromWishlistIcon } from '@lt/components/icons-react/auth';
import { ButtonTs } from '@lt/components';
import styles from '../authFromWishlist.module.css';
import type { AuthFromWishlistDesktopProps } from '../types';
import { AUTH_FROM_WISHLIST_DESKTOP } from '../constants';

export const AuthFromWishlistDesktop = ({
  handleOpenLoginForm,
}: AuthFromWishlistDesktopProps) => {
  const { t } = useTranslation();

  const authText = t('wishlist.authText', { ns: 'wishlist' });
  const login = t('wishlist.login', { ns: 'wishlist' });

  return (
    <div
      className={styles.containerDesktop}
      data-testid={AUTH_FROM_WISHLIST_DESKTOP}
    >
      <AuthFromWishlistIcon width="101px" height="101px" />
      <pre className={styles.text}>{authText}</pre>
      <ButtonTs
        onClick={handleOpenLoginForm}
        variant="primary"
        className={styles.buttonDesktop}
        size="large"
      >
        {login}
      </ButtonTs>
    </div>
  );
};
