import React from 'react';

import ContainerHeading from '../ContainerHeading';
import ScrollableContainer from '../ScrollableContainer';
import { PopularMetaregion } from './components/PopularMetaregion';

import { RELINKING_VARIANTS } from '../../constants';

import type { IPopularMetaregions } from './types';

export const PopularMetaregions = (props: IPopularMetaregions) => {
  const { isMobile, places } = props;

  return (
    <>
      <ContainerHeading
        variant={RELINKING_VARIANTS.METAREGIONS}
        isMobile={isMobile}
      />
      <ScrollableContainer
        variant={RELINKING_VARIANTS.METAREGIONS}
        isMobile={isMobile}
      >
        {places.map((metaregionCardProps) => (
          <PopularMetaregion
            {...metaregionCardProps}
            key={`country_card_${metaregionCardProps.title}`}
            isMobile={isMobile}
          />
        ))}
      </ScrollableContainer>
    </>
  );
};
