import React, { Suspense, lazy, useEffect, useRef } from 'react';
import ErrorBoundary from 'src/error/components/ErrorBoundary';
import type { CLIENT } from '@lt/api/dist/types/client';
import { isSSR } from 'src/helpers/ssr';
import { isSeamlessTBankWl } from './utils';

interface Props {
  logIn(clientData: CLIENT): void;
  isLoggedIn: boolean;
}

const SeamlessTBankAuth = lazy(
  () =>
    import(/* webpackChunkName: "SeamlessTBankAuth" */ './SeamlessTBankAuth'),
);

export const LazySeamlessTBankAuth = ({ logIn, isLoggedIn }: Props) => {
  const isFirstRender = useRef(true);

  useEffect(() => {
    isFirstRender.current = false;
  }, []);
  return (
    <>
      {!isSSR && (
        <ErrorBoundary showDialog={false}>
          <Suspense fallback={null}>
            {isSeamlessTBankWl() && !isLoggedIn && isFirstRender.current && (
              <SeamlessTBankAuth logIn={logIn} />
            )}
          </Suspense>
        </ErrorBoundary>
      )}
    </>
  );
};
