import { logErrorToSentry } from '../utils/logErrorToSentry';

export class DTOError extends Error {
  // поле которое принимает ожидаемое значение
  private _resultingValue: string;

  // тут легальный any так как объект с DTO может прийти любой
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(message: string, resultingValue: any) {
    super(message);
    try {
      // TODO: аккуратно необходимое зло к сожалению
      logErrorToSentry(new Error(message), resultingValue);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
    this.name = 'DTOError';
    this._resultingValue = JSON.stringify(resultingValue);
  }

  get resultingValue(): string {
    return this._resultingValue;
  }
}
