import React from 'react';
import { Link } from '@lt/components';
import VkLogoIcon from '@lt/components/icons-react/footerLogos/VkLogo';
import TgLogoIcon from '@lt/components/icons-react/footerLogos/TgLogo';
import YoutubeLogoIcon from '@lt/components/icons-react/footerLogos/YoutubeLogo';
import { i18n } from '@lt/localization';
import styles from './SocialLink.module.css';

const SocialLinks = () => (
  <div>
    <h3 className={styles.title}>{i18n.t('footer.beWithUs')}</h3>
    <div className={styles.linksContainer}>
      <Link
        href="https://vk.com/level.travel"
        className={`${styles.linkWrapper} ${styles.vkLink}`}
      >
        <VkLogoIcon width="21" height="12" />
      </Link>
      <Link
        href="https://t.me/s/leveltravel"
        className={`${styles.linkWrapper} ${styles.tgLink}`}
      >
        <TgLogoIcon width="20" height="16" />
      </Link>
      <Link
        href="https://youtube.com/channel/UC2zY7LXiFHeiMfEnNU0wY1w"
        className={`${styles.linkWrapper} ${styles.youtubeLink}`}
      >
        <YoutubeLogoIcon width="24" height="18" />
      </Link>
    </div>
  </div>
);

export default SocialLinks;
