export const isSeamlessTBankWl = (): boolean => {
  const host = window.location.hostname;
  return (
    Boolean(window.location.search.indexOf('auth_token')) &&
    (host === 'tbank.level.travel' ||
      host === 'tbank-tours.level.travel' ||
      host === 'tbank-hotels.level.travel' ||
      host === 'staging-epsilon.level.travel')
  );
};
