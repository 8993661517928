import { createSlice } from '@reduxjs/toolkit';
import type { INarrativeCard } from './types';

export type NarrativeCardsState = { list: INarrativeCard[] };

const initialState: NarrativeCardsState = {
  list: [],
};

export const name = 'narrativeCards';

const narrativeCardsSlice = createSlice({
  name,
  initialState,
  reducers: {},
});

export default narrativeCardsSlice;
