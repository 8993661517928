import React, { useState } from 'react';
import classnames from 'classnames';

import type { Values } from '@lt/widget/components/Cigarette/context';
import type {
  TrackSubmitParams,
  FieldType,
} from '@lt/widget/components/Cigarette/types';

import SearchTypeTabs from 'src/components/SearchTypeTabs';
import LazyCigarette from 'src/components/Cigarette/components/LazyCigarette';

import type { SearchType } from 'src/types/search';

import Logo from '../HeaderLogo';
import Menu from '../HeaderMenu';

import styles from './headerSearchForm.module.css';

interface HeaderSearchFormProps {
  cigaretteValues?: Values;
  pageSearchType: SearchType;
  isMobile?: boolean;
  availableSearchTypes?: SearchType[];
  inPreview?: boolean;
  submitCigarette?: ({ values, searchType }: TrackSubmitParams) => void;
  initialOpenedField?: FieldType;
}

const HeaderSearchForm = ({
  cigaretteValues,
  pageSearchType,
  isMobile,
  availableSearchTypes,
  inPreview = false,
  submitCigarette,
  initialOpenedField,
}: HeaderSearchFormProps) => {
  const [searchType, setSearchType] = useState<SearchType>(
    pageSearchType ||
      (availableSearchTypes &&
        availableSearchTypes.length >= 1 &&
        availableSearchTypes[0]) ||
      'package',
  );

  const SearchTabs = (
    <SearchTypeTabs
      className={classnames(styles.searchTypeTabs, {
        [styles.mobileSearchTypeTabs]: isMobile,
      })}
      selectedTab={searchType}
      setSelectedTab={setSearchType}
      variant={{
        mobile: isMobile || false,
        wl: false,
        page: 'SEARCH',
        collapsed: false,
      }}
    />
  );

  return (
    <>
      <div
        className={classnames(styles.menuWrapper, {
          [styles.mobileMenuWrapper]: isMobile,
        })}
      >
        <Logo className={styles.logo} inWidget />
        {!isMobile ? SearchTabs : null}
        <Menu className={styles.menu} isCollapsed={false} />
      </div>

      <div>
        {isMobile ? SearchTabs : null}
        <LazyCigarette
          isMobile={isMobile}
          searchType={searchType}
          cigaretteValues={cigaretteValues}
          trackSubmit={submitCigarette}
          allowPreview={!inPreview}
          inHeader
          initialOpenedField={initialOpenedField}
        />
      </div>
    </>
  );
};

export default HeaderSearchForm;
