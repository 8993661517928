import React from 'react';

import ContainerHeading from '../../../ContainerHeading';
import ScrollableContainer from '../../../ScrollableContainer';
import { PopularCountry } from './components/PopularCountry';

import { RELINKING_VARIANTS } from '../../../../constants';

import type { IPopularCountries } from './types';

export const PopularCountries = (props: IPopularCountries) => {
  const { isMobile, places, title } = props;

  return (
    <>
      <ContainerHeading
        variant={RELINKING_VARIANTS.DEFAULT_COUNTRIES}
        title={title}
        isMobile={isMobile}
      />
      <ScrollableContainer isMobile={isMobile}>
        {places.map((countryCardProps, i) => (
          <PopularCountry
            {...countryCardProps}
            key={`country_card_${countryCardProps.place}`}
            isFirst={i === 0}
            isMobile={isMobile}
          />
        ))}
      </ScrollableContainer>
    </>
  );
};
