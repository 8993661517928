import React from 'react';
import styled, { css } from 'styled-components';

import { FIELD_NAMES } from '@lt/widget/components/Cigarette/constants';
import type { FieldType } from '@lt/widget/components/Cigarette/types';

import type { IFieldPreview } from './types';
import { CIGARETTE_CONTROL_PREVIEW_TEST_IDS } from '../LazyCigarette/constants';

export const DesktopFieldPreview = (props: IFieldPreview) => {
  const {
    variant,
    onClick,
    children,
    isFilled = false,
    isHotelVariant = false,
    isHotelOnly = false,
  } = props;

  const dataTestId = CIGARETTE_CONTROL_PREVIEW_TEST_IDS[variant];

  return (
    <FieldPreviewContainer
      $variant={variant}
      $isFilled={isFilled}
      $isHotelVariant={isHotelVariant}
      $isHotelOnly={isHotelOnly}
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex={0}
      data-testid={dataTestId}
    >
      {children}
    </FieldPreviewContainer>
  );
};

const FieldPreviewContainer = styled.div<{
  $variant: FieldType;
  $isFilled: boolean;
  $isHotelVariant: boolean;
  $isHotelOnly: boolean;
}>`
  box-sizing: border-box;
  height: 56px;

  font-size: 14px;
  line-height: 16px;

  display: flex;
  align-items: center;

  position: relative;
  padding: 8px;

  background: #fff;
  color: ${({ $isFilled }) =>
    $isFilled ? 'rgb(23, 24, 26)' : 'rgb(145, 148, 153)'};

  border-radius: ${({ $variant }) =>
    FIELD_PREVIEW_BORDER_RADIUS[$variant] ?? 0};
  border: none;

  ${({ $variant }) => FIELD_CUSTOM_STYLES_BY_VARIANT[$variant] ?? ''}
  ${({ $isFilled }) => $isFilled && `font-weight: bold;`}
`;

const verticalLineStyles = css`
  &:before {
    content: '';
    position: absolute;
    width: 1px;
    height: 40px;
    background-color: rgb(230, 233, 238);
    left: 0px;
    top: 0px;
    bottom: 0px;
    margin: auto;
    z-index: 1;
  }
`;

const destinationFieldStyles = css`
  flex: 1 1 0%;
  min-width: 200px;
  cursor: text;
`;

const departureFieldStyles = css<{
  $isHotelVariant: boolean;
}>`
  width: ${({ $isHotelVariant }) => ($isHotelVariant ? '300px' : '29.5%')};
  min-width: 150px;

  padding: 10px;

  cursor: text;

  ${verticalLineStyles};
`;

const touristsFieldStyles = css<{
  $isHotelVariant: boolean;
  $isHotelOnly: boolean;
}>`
  min-width: 110px;
  max-width: 350px;
  color: #000;
  cursor: pointer;

  ${({ $isHotelVariant, $isHotelOnly }) => {
    if (!$isHotelVariant) return '';
    const width = $isHotelOnly ? 400 : 300;

    return `width: ${width}px;`;
  }}

  ${verticalLineStyles}
`;

const datepickerFieldStyles = css<{
  $isHotelVariant: boolean;
  $isHotelOnly: boolean;
}>`
  width: 350px;
  padding: 0;

  & > div {
    width: 100%;
  }

  ${({ $isHotelVariant, $isHotelOnly }) => {
    if (!$isHotelVariant) return '';
    const width = $isHotelOnly ? 800 : 600;

    return `width: ${width}px;`;
  }}
`;

const submitButtonStyles = css`
  display: flex;
`;

const FIELD_CUSTOM_STYLES_BY_VARIANT = {
  [FIELD_NAMES.destination]: destinationFieldStyles,
  [FIELD_NAMES.datepicker]: datepickerFieldStyles,
  [FIELD_NAMES.departure]: departureFieldStyles,
  [FIELD_NAMES.tourists]: touristsFieldStyles,
  [FIELD_NAMES.submit]: submitButtonStyles,
};

const FIELD_PREVIEW_BORDER_RADIUS = {
  [FIELD_NAMES.destination]: '4px 0 0 4px',
  [FIELD_NAMES.submit]: '0 4px 4px 0',
};
