import { connect } from 'react-redux';
import { HeaderMenuFeedback } from '@lt/components/components/HeaderMenu';

import { getIsMobile } from 'src/store/view/selectors';
import { getFeedbackPopup } from '../../../../store/feedback/selectors';

import { showPopup, hidePopup } from '../../../../store/feedback/slices';

const mapStateToProps = (state) => ({
  showPopup: getFeedbackPopup(state),
  isMobile: getIsMobile(state),
});

const mapDispatchToProps = (dispatch) => ({
  onOpenModal: () => dispatch(showPopup()),
  onCloseModal: () => dispatch(hidePopup()),
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderMenuFeedback);
