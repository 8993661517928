import React from 'react';

import { Burger, Logo } from '@lt/components/icons-react';

import { PartnerLogo } from '@lt/components/components/Logo';
import { isSSR } from 'src/helpers/ssr';
import type { HeaderProps } from './types';
import styles from './header.module.css';

const Header = ({
  redirectLogoLink = '/',
  onBurgerClick,
  partnerLogo,
  isWhitelabel,
}: HeaderProps): JSX.Element | null => {
  if (isSSR && isWhitelabel) return null;
  return (
    <div className={styles.header}>
      {partnerLogo ? (
        <PartnerLogo src={partnerLogo.src} srcSet={partnerLogo.srcset} />
      ) : (
        <a className={styles.link} href={redirectLogoLink}>
          <Logo className={styles.logo} />
        </a>
      )}

      <button className={styles.button} type="button" onClick={onBurgerClick}>
        <Burger className={styles.burger} />
      </button>
    </div>
  );
};

export default Header;
