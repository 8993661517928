import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { isSSR } from 'src/helpers/ssr';
import type { ClientStateType } from './types';
import { clientDTO } from './clientDTO';

const initialState: ClientStateType = {
  auth_token: null,
  avatar: null,
  email: null,
  id: null,
  name: null,
  surname: null,
  phone: null,
  readable_phone: null,
  unconfirmed_phone: null,
  has_password: null,
  bonus_score: null,
  unconfirmed_email: null,
  confirmed_at: null,
  coefficient_for_room_upgrade: 0,
  name_en: null,
  surname_en: null,
  advertising_mail_accepted: false,
  showAuthPopup: false,
  showLogOutPopup: false,
  isAdmin: false,
};
export const clientSlice = createSlice({
  name: 'client',
  initialState: !isSSR
    ? clientDTO(window?.current_client, window?.current_user)
    : initialState,
  reducers: {
    logInAction: (
      state,
      action: PayloadAction<{
        id: number;
        email?: string;
        avatar?: string;
        unconfirmed_email?: string;
        confirmed_at?: string;
        unconfirmed_phone?: string;
        name?: string;
        surname?: string;
        phone?: string;
        bonus_score?: number;
        coefficient_for_room_upgrade?: number;
        has_password?: boolean;
        auth_token?: string;
        name_en?: string;
        surname_en?: string;
      }>,
    ) => {
      state.id = action.payload.id;
      state.email = action.payload.email || null;
      state.avatar = action.payload.avatar || null;
      state.unconfirmed_email = action.payload.unconfirmed_email || null;
      state.confirmed_at = action.payload.confirmed_at || null;
      state.unconfirmed_phone = action.payload.unconfirmed_phone || null;
      state.name = action.payload.name || null;
      state.surname = action.payload.surname || null;
      state.phone = action.payload.phone || null;
      state.bonus_score = action.payload.bonus_score || null;
      state.coefficient_for_room_upgrade =
        action.payload.coefficient_for_room_upgrade || 0;
      state.has_password = action.payload.has_password || null;
      state.auth_token = action.payload.auth_token || null;
      state.name_en = action.payload.name_en || null;
      state.surname_en = action.payload.surname_en || null;
    },
    signUpAction: (
      state,
      action: PayloadAction<{
        id: number;
        email?: string;
        avatar?: string;
        unconfirmed_email?: string;
        confirmed_at?: string;
        unconfirmed_phone?: string;
        name?: string;
        surname?: string;
        phone?: string;
        bonus_score?: number;
        coefficient_for_room_upgrade?: number;
        has_password?: boolean;
        auth_token?: string;
        name_en?: string;
        surname_en?: string;
        advertising_mail_accepted: boolean;
      }>,
    ) => {
      state.id = action.payload.id;
      state.email = action.payload.email || null;
      state.avatar = action.payload.avatar || null;
      state.unconfirmed_email = action.payload.unconfirmed_email || null;
      state.confirmed_at = action.payload.confirmed_at || null;
      state.unconfirmed_phone = action.payload.unconfirmed_phone || null;
      state.name = action.payload.name || null;
      state.surname = action.payload.surname || null;
      state.phone = action.payload.phone || null;
      state.bonus_score = action.payload.bonus_score || null;
      state.coefficient_for_room_upgrade =
        action.payload.coefficient_for_room_upgrade || 0;
      state.has_password = action.payload.has_password || null;
      state.auth_token = action.payload.auth_token || null;
      state.name_en = action.payload.name_en || null;
      state.surname_en = action.payload.surname_en || null;
      state.advertising_mail_accepted =
        action.payload.advertising_mail_accepted;
    },
    setClientInfo: (
      state,
      action: PayloadAction<{
        id: number;
        email?: string;
        avatar?: string;
        unconfirmed_email?: string;
        confirmed_at?: string;
        unconfirmed_phone?: string;
        name?: string;
        surname?: string;
        phone?: string;
        bonus_score?: number;
        coefficient_for_room_upgrade?: number;
        has_password?: boolean;
        auth_token?: string;
        name_en?: string;
        surname_en?: string;
      }>,
    ) => {
      state.id = action.payload.id;
      state.email = action.payload.email || null;
      state.avatar = action.payload.avatar || null;
      state.unconfirmed_email = action.payload.unconfirmed_email || null;
      state.confirmed_at = action.payload.confirmed_at || null;
      state.unconfirmed_phone = action.payload.unconfirmed_phone || null;
      state.name = action.payload.name || null;
      state.surname = action.payload.surname || null;
      state.phone = action.payload.phone || null;
      state.bonus_score = action.payload.bonus_score || null;
      state.coefficient_for_room_upgrade =
        action.payload.coefficient_for_room_upgrade ||
        state.coefficient_for_room_upgrade;
      state.has_password = action.payload.has_password || null;
      state.auth_token = action.payload.auth_token || null;
      state.name_en = action.payload.name_en || null;
      state.surname_en = action.payload.surname_en || null;
    },
    setClientBonusScore: (
      state,
      action: PayloadAction<{ bonus_score?: number }>,
    ) => {
      state.bonus_score = action.payload.bonus_score || null;
    },
    setClientConfirmPhone: (
      state,
      action: PayloadAction<{ confirmedPhone?: string }>,
    ) => {
      state.phone = action.payload.confirmedPhone || null;
      state.unconfirmed_phone = null;
    },
    logOutAction: (): ClientStateType => ({ ...initialState }),
    setClientAuthToken(state, action: PayloadAction<string | undefined>) {
      state.auth_token = action.payload || null;
    },
    showAuthAction: (state) => {
      state.showAuthPopup = true;
    },
    hideAuthAction: (state) => {
      state.showAuthPopup = false;
    },
    showLogOutAction: (state) => {
      state.showLogOutPopup = true;
    },
    hideLogOutAction: (state) => {
      state.showLogOutPopup = false;
    },
  },
});
