import { connect } from 'react-redux';
import { getShortLink } from 'src/store/appBanners/selectors';
import { getDeeplinkAction } from 'src/store/appBanners/slice';
import FooterAppBanners from './FooterAppBanners';

const mapStateToProps = (state) => ({
  appLink: getShortLink(state),
});

const mapDispatchToProps = {
  getShortLink: getDeeplinkAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(FooterAppBanners);
