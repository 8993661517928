import React from 'react';
import styled from 'styled-components';
import type { TourGuideItem } from '../../../../types';

interface MobileItemProps extends TourGuideItem {
  onClickButton: () => void;
}

export const TourGuideMobileModal = ({
  image,
  text,
  title,
  buttonText,
  onClickButton,
}: MobileItemProps) => (
  <Content>
    <ImageWrapper>
      <Image src={image.src} />
    </ImageWrapper>
    <Title>{title}</Title>
    <Text>{text}</Text>
    <Button onClick={onClickButton}>{buttonText}</Button>
  </Content>
);

const Content = styled.section`
  display: flex;
  flex-direction: column;
  height: calc(80vh - 16px);
  margin-bottom: 20px;
`;

const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`;

const Image = styled.img`
  width: 100%;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  padding-left: 4px;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.colors.blackBase};
`;

const Text = styled.div`
  font-size: 14px;
  line-height: 16px;
  padding: 0 20px 0 4px;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.blackLight};
`;

export const Button = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.blue};
  color: ${({ theme }) => theme.colors.blue};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 16px 0;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  cursor: pointer;
`;
