import React, { useState } from 'react';
import { useUpdateEffect } from 'usehooks-ts';
import styled from 'styled-components';

import { Tabs } from '@lt/components';

import { getIsFeatureHasAnOption } from 'src/utils/featureToggle';
import { SEARCH_TYPES, SEARCH_TYPE_NAMES } from 'src/constants/search';

import { SearchTypeTab } from './SearchTypeTab';

import type { SearchType } from '../../types/search';
import type { TabsVariant } from './types';

interface SwitchTabsProps {
  previousSearchType: SearchType;
  searchType: SearchType;
  url: string;
  cigaretteType: 'MAIN' | 'SEARCH' | 'HOTEL' | 'OLD_MAIN';
  isMobile: boolean;
}

interface SearchTypeTabsProps {
  selectedTab: SearchType;
  variant: TabsVariant;
  setSelectedTab:
    | React.Dispatch<React.SetStateAction<SearchType>>
    | React.Dispatch<SearchType>;
  className?: string;
  availableSearchTypes?: string[];
  isMobile: boolean;
  isWhitelabel: boolean;
  switchTab?: (params: SwitchTabsProps) => void;
}

const SearchTypeTabs = (props: SearchTypeTabsProps): JSX.Element => {
  const {
    selectedTab = SEARCH_TYPE_NAMES.PACKAGE,
    setSelectedTab,
    variant,
    className,
    availableSearchTypes,
    isMobile,
    isWhitelabel,
    switchTab,
  } = props;

  const [previousSelectedTab, setPreviousSelectedTab] = useState(selectedTab);

  const hasExcursionSearch =
    !isWhitelabel && getIsFeatureHasAnOption('cigarette_excursion', 'on');

  const searchTypesArray = availableSearchTypes
    ? SEARCH_TYPES.filter(
        ({ name }) =>
          availableSearchTypes.includes(name) ||
          (hasExcursionSearch &&
            name === SEARCH_TYPE_NAMES.TRIP &&
            variant.page !== 'HOTEL'),
      )
    : SEARCH_TYPES;

  const handleTabsChange = (newSelectedTab: SearchType) => {
    setPreviousSelectedTab(selectedTab);
    setSelectedTab(newSelectedTab);
  };

  // При инициализации не должен вызываться switchTab, только при смене тип поиска
  useUpdateEffect(
    () =>
      switchTab?.({
        previousSearchType: previousSelectedTab,
        searchType: selectedTab,
        url: window.location.href,
        cigaretteType: variant.page,
        isMobile,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isMobile, selectedTab, switchTab, variant.page],
  );

  return (
    <StyledTabs
      functional
      onChange={handleTabsChange}
      $variant={variant}
      className={className}
    >
      {searchTypesArray.map((searchTypeInfo) => (
        <SearchTypeTab
          key={searchTypeInfo.name}
          isActive={selectedTab === searchTypeInfo.name}
          {...props}
          {...searchTypeInfo}
        />
      ))}
    </StyledTabs>
  );
};

const StyledTabs = styled(Tabs)<{
  $variant: TabsVariant;
}>`
  display: flex;
  justify-content: ${({ $variant: { mobile, page } }) =>
    mobile && page === 'OLD_MAIN' ? 'center' : 'flex-start'};
  height: ${({ $variant: { mobile, collapsed } }) => {
    if (!mobile) return '48';
    return collapsed ? '20' : '40';
  }}px;
`;

export default SearchTypeTabs;
