import React from 'react';
import { useTranslation } from '@lt/localization';
import { AuthFromWishlistIcon } from '@lt/components/icons-react/auth';
import { ButtonTs } from '@lt/components';
import WishlistCard from 'src/routes/Wishlist/components/WishlistPage/components/WishlistCard/WishlistCard';
import styles from '../authFromWishlist.module.css';
import type { AuthFromWishlistMobileProps } from '../types';
import { AUTH_FROM_WISHLIST_MOBILE } from '../constants';

export const AuthFromWishlistMobile = ({
  lastWishlistItem,
  wishlistId,
  addToWishlist,
  removeFromWishlist,
  isContainedInWishlist,
  handleOpenLoginForm,
}: AuthFromWishlistMobileProps) => {
  const { t } = useTranslation();

  const authText = t('wishlist.authText', { ns: 'wishlist' });
  const login = t('wishlist.login', { ns: 'wishlist' });
  const title = t('wishlist.title', { ns: 'wishlist' });

  return (
    <div
      className={styles.containerMobile}
      data-testid={AUTH_FROM_WISHLIST_MOBILE}
    >
      <div className={styles.wrapper}>
        <div className={styles.title}>{title}</div>
        <AuthFromWishlistIcon width="101px" height="101px" />
        <pre className={styles.text}>{authText}</pre>
      </div>
      <div className={styles.cardWithButtonWrapper}>
        <div className={styles.childrenContainer}>
          {lastWishlistItem && (
            <WishlistCard
              key={lastWishlistItem.id}
              wishlistId={wishlistId}
              wishlistItem={lastWishlistItem}
              addToWishlist={addToWishlist}
              removeFromWishlist={removeFromWishlist}
              isMobile
              isContainedInWishlist={isContainedInWishlist}
              wishlistItemId={lastWishlistItem.id}
            />
          )}
        </div>
        <ButtonTs
          onClick={handleOpenLoginForm}
          variant="primary"
          className={styles.buttonMobile}
          size="large"
        >
          {login}
        </ButtonTs>
      </div>
    </div>
  );
};
