import { createGlobalStyle, css } from 'styled-components';

// класс который должен добавляться к body, когда необходимо убрать скролл заднего фона у модалки
export const freezeBody = css`
  body.modal-open,
  html.modal-open {
    height: 100% !important;
    overflow: hidden !important;
    width: 100% !important;
    position: fixed !important;
  }
`;

export const GlobalStyle = createGlobalStyle`
  ${freezeBody}
`;
