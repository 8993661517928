import React from 'react';

import classnames from 'classnames';
import PopularDestinationsOffers from './components/PopularDestinationsOffers';
import PopularMetaregions from './components/PopularMetaregions';
import HotelsRelinking from './components/HotelsRelinking';
import NarrativeCards from './components/NarrativeCards';

import type { IRelinking } from './types';
import {
  COUNTRIES_DATA_TEST_ID,
  DATA_TEST_ID,
  HOTELS_DATA_TEST_ID,
  METAREGIONS_DATA_TEST_ID,
  NARRATIVE_DATA_TEST_ID,
  COUNTRIES_WITH_PRICES_DATA_TEST_ID,
} from './constants';
import styles from './relinking.module.css';

export const Relinking = (props: IRelinking): JSX.Element | null => {
  const {
    isMobile,
    isDefaultCountriesLinks,
    linkedHotels,
    narrativeCards,
    popularDestinationsOffers,
    popularMetaregionsOffers,
  } = props;

  const isPopularDestinationsOffers = !!popularDestinationsOffers?.length;
  const isPopularMetaregions = !!popularMetaregionsOffers?.length;
  const isHotelsRelinking = !!linkedHotels?.length;
  const isNarrativeCards = !!narrativeCards?.length;

  const isRelinkingDisabled =
    !isPopularDestinationsOffers &&
    !isPopularMetaregions &&
    !isHotelsRelinking &&
    !isNarrativeCards;

  if (isRelinkingDisabled) return null;

  return (
    <div
      className={classnames({ [styles.relinkingWrapperMobile]: isMobile })}
      data-testid={DATA_TEST_ID}
    >
      {isPopularDestinationsOffers && (
        <div
          className={styles.container}
          data-testid={
            isDefaultCountriesLinks
              ? COUNTRIES_DATA_TEST_ID
              : COUNTRIES_WITH_PRICES_DATA_TEST_ID
          }
        >
          <PopularDestinationsOffers
            isMobile={isMobile}
            isDefault={isDefaultCountriesLinks}
            places={popularDestinationsOffers}
          />
        </div>
      )}
      {isPopularMetaregions && (
        <div
          className={styles.container}
          data-testid={METAREGIONS_DATA_TEST_ID}
        >
          <PopularMetaregions
            isMobile={isMobile}
            places={popularMetaregionsOffers}
          />
        </div>
      )}
      {isHotelsRelinking && (
        <div className={styles.container} data-testid={HOTELS_DATA_TEST_ID}>
          <HotelsRelinking isMobile={isMobile} linkedHotels={linkedHotels} />
        </div>
      )}
      {isNarrativeCards && (
        <div className={styles.container} data-testid={NARRATIVE_DATA_TEST_ID}>
          <NarrativeCards
            isMobile={isMobile}
            narrativeCardsList={narrativeCards}
          />
        </div>
      )}
    </div>
  );
};
