import { api } from 'src/api';
import { getUserId } from 'src/utils/getUserId';
import { getEffectiveType } from 'src/utils/networkInformation';
import type { Metric } from 'web-vitals';
import { onCLS, onFCP, onINP, onLCP, onTTFB } from 'web-vitals';
import { isSSR } from './ssr';

const sendWebVitals = async (data: Metric) => {
  try {
    await api.postStatsWebVitals({
      uuid: getUserId(),
      network_speed: getEffectiveType(),
      metric_name: data.name,
      metric_value: data.value,
      metric_rating: data.rating,
      delta: data.delta,
      id: data.id,
      navigation_type: data.navigationType,
      url: window.location.href,
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
};

export const reportWebVitals = () => {
  if (isSSR) {
    return;
  }

  onCLS(sendWebVitals);
  onLCP(sendWebVitals);
  onINP(sendWebVitals);
  onFCP(sendWebVitals);
  onTTFB(sendWebVitals);
};
