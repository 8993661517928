/**
 * Аналитика баннера "как купить тур"
 * Все эвенты описаны в документе
 * @see https://docs.google.com/spreadsheets/d/1ZJftJ2drwj6rNFlf2tjmUkHM7QpfX4T6e0kQwTAiMng/edit#gid=0
 */

import { dataLayerPush } from 'src/helpers/gtm';
import type { TourGuideMode } from './types';

export const pushBannerClosed = () => {
  dataLayerPush({ event: 'onboarding_pay_banner_closed' });
};

export const pushBannerClicked = () => {
  dataLayerPush({ event: 'onboarding_pay_banner_clicked' });
};

export const pushBannerClickedAfterPay = () => {
  dataLayerPush({ event: 'onboarding_pay_after_pay_clicked' });
};

export const pushBannerViewed = (mode: TourGuideMode) => {
  const event =
    mode === 'payment'
      ? 'onboarding_pay_stories_viewed_3'
      : 'onboarding_pay_stories_viewed_1';

  dataLayerPush({ event });
};

export const pushBannerViewed2 = (mode: TourGuideMode, nextItem: number) => {
  const eventName = 'onboarding_pay_stories_viewed';
  const count = mode === 'payment' ? nextItem + 3 : nextItem + 1;
  const event = `${eventName}_${count}`;

  dataLayerPush({ event });
};
