import { createSelector } from '@reduxjs/toolkit';
import { range, pickBy } from 'lodash';

import {
  convertServerDateToClientDate,
  getFlexDates,
  formatServerDate,
  getDateRangeString,
} from 'src/utils/dateUtils';
import {
  getFromImmutableStateCompatible,
  getImmutableCompatible,
} from 'src/utils/immutableUtils';
import { getNightsFromObject, isFlexNights } from 'src/utils/searchParamsUtils';
import { addDays } from 'date-fns';
import { getIsHotelOnly } from 'src/store/view/selectors';
import {
  pluralizeAdultsForm2,
  pluralizeKidsForm2,
  pluralizeNights,
} from 'src/utils/pluralizeUtils';
import {
  getSearchParams,
  getSearchParamsRequestId,
} from 'src/store/enqueue/selectors';

export const getParams = getSearchParams;

export const getRequestId = getSearchParamsRequestId;

const _enqueueSelector = (state) => getImmutableCompatible(state, 'enqueue');
const enqueueSelector = getFromImmutableStateCompatible(_enqueueSelector);
export const isEnqueueAvailable = createSelector(enqueueSelector, (enqueue) =>
  Boolean(enqueue),
);

export const getDeparture = (state) => getSearchParams(state)?.from;
export const getDepartureCity = (state) => getDeparture(state)?.city;
export const getDepartureCityId = (state) => getDepartureCity(state)?.id;

export const getDestination = (state) => getParams(state)?.to;
export const getDestinationCity = (state) => getDestination(state)?.city;
export const getDestinationCountry = (state) => getDestination(state)?.country;
export const getDestinationId = (state) =>
  getDestinationCity(state)?.id || getDestinationCountry(state)?.id;
export const getDestinationCountryName = (state) =>
  getDestinationCountry(state)?.name_ru;
export const getDestinationCountryNameForm2 = (state) =>
  getDestinationCountry(state)?.name_ru_form2;

export const getAdultsCount = (state) => getParams(state)?.adults || 0;
export const getKidsCount = (state) => getParams(state)?.kids.count || 0;
export const getKidsAges = (state) => getParams(state)?.kids.ages;
export const getTouristsCount = createSelector(
  [getAdultsCount, getKidsCount],
  (adults, kids) => adults + kids,
);

const getNights = (state) => getParams(state)?.nights;
export const getDuration = getFromImmutableStateCompatible(getNights);

export const getNightsRange = (state) => {
  const from = getNights(state)?.from;
  const to = getNights(state)?.to;
  return from === to ? from : `${from}-${to}`;
};
export const getSearchQuery = (state) => getParams(state)?.query;
export const getSearchStartDate = (state) => getParams(state)?.start_date;

/** Определяем флексы на поиске
 * @return flex_dates = true -> 2
 * @return flex_dates = false -> 0
 * @return flex_dates = 1 | 2
 */
const isFlexDatesOldSelector = (state): number => {
  const flexDate = getParams(state)?.options.flex_dates;
  if (flexDate === true) return 2;
  if (!flexDate) return 0;
  return flexDate;
};

/* TODO: Удалить когда мобильная сига докатится доконца */
const isFlexNightsOldSelector = createSelector([getDuration], (nights) =>
  isFlexNights(nights),
);

export const isFlexDatesSelector = createSelector(
  [isFlexDatesOldSelector, isFlexNightsOldSelector],
  (isFlexDates, isFlexNight) => !!(isFlexDates || isFlexNight),
);

export const getSearchDestination = (state) => {
  const destinationCity = getDestinationCity(state);
  return destinationCity?.id ? destinationCity : getDestinationCountry(state);
};

export const getSearchDestinationTitle = (state) =>
  getSearchDestination(state)?.name_ru;

export const getSearchTitle = (state) => {
  const destination = getSearchDestination(state);
  const isHotelOnly = getIsHotelOnly(state);
  const destinationName = isHotelOnly
    ? destination?.name_ru_form2
    : destination?.name_ru_form3;
  const startDate = getSearchStartDate(state);
  const nightsFrom = getNights(state)?.from;
  const adults = getAdultsCount(state);
  const kidsCount = getKidsCount(state);
  const offersTitle = isHotelOnly ? 'Отели' : 'Туры';
  return `${offersTitle} ${destinationName} с ${convertServerDateToClientDate(
    startDate,
  )} на ${nightsFrom} ${pluralizeNights(
    nightsFrom ?? 0,
  )} для ${adults} ${pluralizeAdultsForm2(adults ?? 0)} ${
    kidsCount ? `и ${kidsCount} ${pluralizeKidsForm2(kidsCount)}` : ''
  }`;
};

export const fullDestinationTextSelector = (state) => {
  const destinationCity = getDestinationCity(state);
  const searchByRegion = Boolean(destinationCity?.id);
  const destination = searchByRegion
    ? destinationCity
    : getDestinationCountry(state);
  const isHotelOnly = getIsHotelOnly(state);
  const destinationName = isHotelOnly
    ? destination?.name_ru_form2
    : destination?.name_ru_form3;
  const countryName = getDestinationCountryName(state);
  return `${destinationName}${searchByRegion ? `, ${countryName}` : ''}`;
};

/**
 * Параметры для pricewatch подписки
 */
export const getParamsForSubscribtion = createSelector(
  [
    getSearchStartDate,
    getDestinationId,
    isFlexDatesSelector,
    getDepartureCityId,
    getSearchParams,
  ],
  (startDate, destinationId, hasFlexDates, departureId, searchParams) => {
    if (!(searchParams && searchParams.nights && startDate)) {
      return null;
    }
    const { nights, kids, adults } = searchParams;
    const nightsRange = isFlexNights(searchParams?.nights)
      ? range(nights.from, nights.to + 1)
      : [nights.from];
    const flexDates = getFlexDates(new Date(startDate));

    // убираем пустые параметры
    return pickBy({
      adults,
      nights: nightsRange,
      kids: kids.ages.length ? kids.ages : null,
      place_ids: [destinationId],
      departure_ids: [departureId],
      start_date_from: hasFlexDates
        ? formatServerDate(flexDates.from)
        : startDate,
      start_date_to: hasFlexDates ? formatServerDate(flexDates.to) : startDate,
    });
  },
);

/**
 * Параметры для отображения pricewatch подписки
 */
export const getViewParamsForSubscribtion = createSelector(
  [getSearchDestination, isFlexDatesSelector, getSearchParams],
  (countryRu, flexDates, searchParams) => {
    if (!searchParams) {
      return null;
    }
    const { nights, start_date: startDate } = searchParams;

    return {
      startDate,
      hasFlexDates: Boolean(flexDates),
      hasFlexNights: isFlexNights(nights),
      nightCount: getNightsFromObject(nights),
      countryRu: countryRu?.name_ru_form3,
    };
  },
);

const nightsSelector = createSelector(
  getDuration,
  (nights) => getNightsFromObject(nights) as number,
);
export const endDateSelector = createSelector(
  [getSearchStartDate, nightsSelector],
  (startDate, nights) => startDate && addDays(new Date(startDate), nights),
);

export const startDateRangeSelector = createSelector(
  [getSearchStartDate, isFlexDatesOldSelector],
  (startDate, flexDates) =>
    startDate && getDateRangeString(new Date(startDate), flexDates),
);
export const endDateRangeSelector = createSelector(
  [endDateSelector, isFlexDatesOldSelector],
  (endDate, flexDates) => endDate && getDateRangeString(endDate, flexDates),
);
