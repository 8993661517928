import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { NpsStore } from './types';
import { NpsState } from '../../components/NpsForm/NpsForm';

const initialState: NpsStore = {
  componentState: NpsState.Hide,
  score: null,
  isAlreadyRated: false,
};

const npsSlice = createSlice({
  name: 'nps',
  initialState,
  reducers: {
    updateComponentState: (
      state,
      action: PayloadAction<NpsStore['componentState']>,
    ) => {
      state.componentState = action.payload;
    },
    setToken: (
      state,
      action: PayloadAction<NonNullable<NpsStore['token']>>,
    ) => {
      state.token = action.payload;
    },
    setAlreadyRated: (state) => {
      state.isAlreadyRated = true;
    },
    setErrorOccured: (state) => {
      state.errorOccured = true;
    },
    setScore: (state, action: PayloadAction<NpsStore['score']>) => {
      state.score = action.payload;
    },
  },
});

export const {
  updateComponentState,
  setToken,
  setAlreadyRated,
  setErrorOccured,
  setScore,
} = npsSlice.actions;
export const { name } = npsSlice;
export default npsSlice.reducer;
