import { createSelector } from '@reduxjs/toolkit';

import { getImmutableCompatible } from 'src/utils/immutableUtils';
import type { HeaderMenu } from './types';
import { name } from './slice';

const getMenuData = (state): HeaderMenu => getImmutableCompatible(state, name);

export const menuSelector = createSelector(getMenuData, (data) => data.menu);

export const headerSelector = createSelector(
  getMenuData,
  (data) => data.header,
);

export const menuSettingsSelector = createSelector(
  getMenuData,
  (data) => data.settings,
);

export const menuPhonesSelector = createSelector(
  getMenuData,
  (data) => data.phones,
);

export const isNewYearSelector = createSelector(
  menuSettingsSelector,
  (settings) => settings?.new_year || false,
);

export const getIsWhitelabel = createSelector(
  menuSettingsSelector,
  (settings) => settings.wl,
);

export const getIsHideFeedback = createSelector(
  menuSettingsSelector,
  (settings) => settings.hide_feedback,
);

export const getPartnerContactPhone = createSelector(
  [menuPhonesSelector, getIsWhitelabel],
  (phones, isWL) => (isWL ? phones.name : phones.phone || phones.alt_phone),
);

export const getPartnerPromoTitle = createSelector(
  [headerSelector, getIsWhitelabel],
  (header, isWL) => (isWL ? header.promo_head : null),
);

export const getMenuContacts = createSelector(menuSelector, (menu) =>
  menu.help?.items?.find((helpItem) => helpItem.slug === 'contacts'),
);

export const getIsWishlistAvailable = createSelector(
  menuSelector,
  (menu) => menu && !!menu.wishlist,
);

export const getHeaderBackground = createSelector(
  headerSelector,
  (header) => header?.header_background,
);
