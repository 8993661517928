import React from 'react';

import ScrollableDesktopContainer from './components/ScrollableContainerDesktop';
import ScrollableMobileContainer from './components/ScrollableContainerMobile';

import type { IScrollableContainer } from './types';
import { RELINKING_VARIANTS } from '../../constants';

export const ScrollableContainer = (
  props: IScrollableContainer,
): JSX.Element => {
  const {
    children,
    isMobile = false,
    variant = RELINKING_VARIANTS.DEFAULT_COUNTRIES,
  } = props;

  const Container = isMobile
    ? ScrollableMobileContainer
    : ScrollableDesktopContainer;

  return <Container variant={variant}>{children}</Container>;
};
