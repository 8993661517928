import React from 'react';
import classnames from 'classnames';

import { Image } from '@lt/components';

import {
  Info,
  Target,
  Checkmark,
  Update,
  Settings,
  Support,
  Card,
} from '@lt/components/icons-react/home_lt_promo';

import seaviewPng from 'src/images/home_lt_promo/seaview.png';
import seaviewWebp from 'src/images/home_lt_promo/seaview.webp';

import promoPng from 'src/images/home_lt_promo/promo.png';
import promoWebp from 'src/images/home_lt_promo/promo.webp';

import historyPng from 'src/images/home_lt_promo/history.png';
import historyWebp from 'src/images/home_lt_promo/history.webp';

import crownPng from 'src/images/home_lt_promo/crown.png';
import crownWebp from 'src/images/home_lt_promo/crown.webp';

import messangerPng from 'src/images/home_lt_promo/messanger.png';
import messangerWebp from 'src/images/home_lt_promo/messanger.webp';

import { useTranslation } from '@lt/localization';
import styles from './homeLtBottomPromo.module.css';

const HomeLtBottomPromo = ({ isMobile = false }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div
        className={classnames(styles.aboutContainer, {
          [styles.aboutContainerMobile]: isMobile,
        })}
      >
        <div
          className={classnames({ [styles.aboutInnerContainer]: !isMobile })}
        >
          <div
            className={classnames(styles.heading, {
              [styles.aboutHeadingDesktop]: !isMobile,
            })}
          >
            <Info className={styles.headingIcon} />
            {t('home_lt_bottom_promo.company.heading')}
          </div>
          <div className={styles.description}>
            {t('home_lt_bottom_promo.company.description')}
          </div>
        </div>

        <div
          className={classnames({ [styles.aboutInnerContainer]: !isMobile })}
        >
          <div
            className={classnames(styles.heading, {
              [styles.aboutHeadingDesktop]: !isMobile,
            })}
          >
            <Target className={styles.headingIcon} />
            {t('home_lt_bottom_promo.mission.heading')}
          </div>
          <div className={styles.description}>
            {t('home_lt_bottom_promo.mission.description')}
          </div>
        </div>
      </div>

      <div className={styles.delimeter} />

      <div className={styles.heading}>
        <Checkmark className={styles.headingIcon} />
        {t('home_lt_bottom_promo.transparency.heading')}
      </div>
      <div
        className={classnames(styles.middleDescription, {
          [styles.middleDescriptionDesktop]: !isMobile,
        })}
      >
        {t('home_lt_bottom_promo.transparency.description')}
      </div>

      <div
        className={classnames(styles.featureHeading, {
          [styles.featureHeadingDesktop]: !isMobile,
        })}
      >
        {t('home_lt_bottom_promo.features.heading')}
      </div>

      <div className={classnames({ [styles.featureContainer]: !isMobile })}>
        <div
          className={classnames(styles.feature, {
            [styles.featureDesktop]: !isMobile,
          })}
        >
          <Update className={styles.featureIcon} />
          {t('home_lt_bottom_promo.features.items.integration')}
        </div>
        <div
          className={classnames(styles.feature, {
            [styles.featureDesktop]: !isMobile,
          })}
        >
          <Settings className={styles.featureIcon} />
          {t('home_lt_bottom_promo.features.items.filters')}
        </div>
      </div>
      <div className={classnames({ [styles.featureContainer]: !isMobile })}>
        <div
          className={classnames(styles.feature, {
            [styles.featureDesktop]: !isMobile,
          })}
        >
          <Support className={styles.featureIcon} />
          {t('home_lt_bottom_promo.features.items.support')}
        </div>
        <div
          className={classnames(styles.feature, {
            [styles.featureDesktop]: !isMobile,
          })}
        >
          <Card className={styles.featureIcon} />
          {t('home_lt_bottom_promo.features.items.payments')}
        </div>
      </div>

      <div
        className={classnames(styles.featureHeading, {
          [styles.featureHeadingDesktop]: !isMobile,
        })}
      >
        {t('home_lt_bottom_promo.get_instruction.still_thinking')}
        <br />
        {t('home_lt_bottom_promo.get_instruction.we_do')}{' '}
        <a className={styles.instructionLink} href="https://promo.level.travel">
          {t('home_lt_bottom_promo.get_instruction.link')}
        </a>
        {'. '}
        {t('home_lt_bottom_promo.get_instruction.hurry_up')}
      </div>

      <div className={styles.delimeter} />

      <div className={styles.heading}>
        {t('home_lt_bottom_promo.articles_links.heading')}
      </div>
      <div className={classnames({ [styles.articlesContainer]: !isMobile })}>
        <a
          className={classnames(styles.articleLink, {
            [styles.articleLinkMobile]: isMobile,
          })}
          href="https://promo.level.travel/how-to-buy"
          target="_blank"
          rel="noreferrer"
        >
          <Image
            className={classnames(styles.articleImage, {
              [styles.articleImageMobile]: isMobile,
            })}
            src={seaviewWebp}
            fallback={seaviewPng}
            alt="how to buy"
          />
          <div
            className={classnames(styles.articleContent, {
              [styles.articleContentMobile]: isMobile,
            })}
          >
            {t('home_lt_bottom_promo.articles_links.how_to_buy')}
          </div>
        </a>
        <a
          className={classnames(styles.articleLink, {
            [styles.articleLinkMobile]: isMobile,
          })}
          href="https://promo.level.travel/how-to-find"
          target="_blank"
          rel="noreferrer"
        >
          <Image
            className={classnames(styles.articleImage, {
              [styles.articleImageMobile]: isMobile,
            })}
            src={promoWebp}
            fallback={promoPng}
            alt="how to find"
          />
          <div
            className={classnames(styles.articleContent, {
              [styles.articleContentMobile]: isMobile,
            })}
          >
            {t('home_lt_bottom_promo.articles_links.how_to_find')}
          </div>
        </a>
        <a
          className={classnames(styles.articleLink, {
            [styles.articleLinkMobile]: isMobile,
          })}
          href="https://promo.level.travel/paylate"
          target="_blank"
          rel="noreferrer"
        >
          <Image
            className={classnames(styles.articleImage, {
              [styles.articleImageMobile]: isMobile,
            })}
            src={historyWebp}
            fallback={historyPng}
            alt="paylate"
          />
          <div
            className={classnames(styles.articleContent, {
              [styles.articleContentMobile]: isMobile,
            })}
          >
            {t('home_lt_bottom_promo.articles_links.paylate')}
          </div>
        </a>
      </div>
      <div className={classnames({ [styles.articlesContainer]: !isMobile })}>
        <a
          className={classnames(styles.articleLink, {
            [styles.articleLinkMobile]: isMobile,
          })}
          href="https://promo.level.travel/vip"
          target="_blank"
          rel="noreferrer"
        >
          <Image
            className={classnames(styles.articleImage, {
              [styles.articleImageMobile]: isMobile,
            })}
            src={crownWebp}
            fallback={crownPng}
            alt="vip"
          />
          <div
            className={classnames(styles.articleContent, {
              [styles.articleContentMobile]: isMobile,
            })}
          >
            {t('home_lt_bottom_promo.articles_links.vip')}
          </div>
        </a>
        <a
          className={classnames(styles.articleLink, {
            [styles.articleLinkMobile]: isMobile,
          })}
          href="https://level.travel/media/category/inspiration"
          target="_blank"
          rel="noreferrer"
        >
          <Image
            className={classnames(styles.articleImage, {
              [styles.articleImageMobile]: isMobile,
            })}
            src={messangerPng}
            fallback={messangerWebp}
            alt="inspiration"
          />
          <div
            className={classnames(styles.articleContent, {
              [styles.articleContentMobile]: isMobile,
            })}
          >
            {t('home_lt_bottom_promo.articles_links.inspiration')}
          </div>
        </a>
      </div>
      <div className={styles.delimeter} />
    </div>
  );
};

export default HomeLtBottomPromo;
