import { dataLayerPush } from 'src/helpers/gtm';
import type { OpenHotelInWishlist, OpenPackageInWishlist } from './types';

/**
 * Событие открытия страницы избранного
 */
export const pushWishlistOpen = () => {
  dataLayerPush({ event: 'wishlist_open' });
};

/**
 * Событие нажатия на кнопку "Поделиться избранным"
 */
export const pushWishlistShare = (wishlistId: string) => {
  dataLayerPush({ event: 'share_wishlist', custom_params: { wishlistId } });
};

/**
 * Событие открытия страницы отеля из избранного
 */
export const pushWishlistOpenHotel = (params: OpenHotelInWishlist) => {
  dataLayerPush({ event: 'open_wishlist_hotel', custom_params: params });
};

/**
 * Событие открытия страницы пакета из избранного
 */
export const pushWishlistOpenPackage = (params: OpenPackageInWishlist) => {
  dataLayerPush({ event: 'open_wishlist_package', custom_params: params });
};
