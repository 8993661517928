import { dataLayerPush } from 'src/helpers/gtm';

export const pushNpsShown = () => {
  dataLayerPush({ event: 'nps_shown' });
};

export const pushNpsClosed = () => {
  dataLayerPush({ event: 'nps_dismiss' });
};

export const pushNpsSent = () => {
  dataLayerPush({ event: 'nps_submit' });
};
