/** TODO: перевести на TypeScript @link {https://leveltravel.atlassian.net/browse/LT-24567} */

import {
  isEmpty,
  mapValues,
  intersectionBy,
  isObject,
  memoize,
  get,
  omit,
  uniqBy,
  concat,
  sortBy,
  every,
  difference,
  values,
  flatten,
  minBy,
  forIn,
} from 'lodash';
import { isWithinInterval } from 'date-fns';

import {
  convertClientDateToServerDate,
  getFlexDates,
} from '../../../utils/dateUtils';

// TODO: change id to name
const getOfferMeal = (name, mealTypes) =>
  mealTypes.find((item) => item.id === name);

const getOfferMealMemoized = memoize(getOfferMeal);

/**
 *
 * @param {Object} offer {lt_extras: [{id: 13}, {id: 14}}
 * @param {Object} activeFilters {lt_extras_13: true}
 * @returns {Boolean}
 */
export const isFilteredByExtras = (offer, activeFilters) => {
  // {13: true, 14: false} -> [13]
  const activeFiltersIds = Object.keys(activeFilters).filter(
    (item) => activeFilters[item] === true,
  );

  const offerLtExtrasIds =
    offer.lt_extras && offer.lt_extras.map((item) => `lt_extras_${item.id}`);

  const diff = difference(activeFiltersIds, offerLtExtrasIds);
  const hasFiltered = isEmpty(diff);

  return hasFiltered;
};

/**
 *
 * @param {Object} offer {labels: [{id: 13}, {id: 14}}
 * @param {Object} activeFilters {labels_13: true}
 * @returns {Boolean}
 */
export const isFilteredByLabels = (offer, activeFilters) => {
  // {labels_13: true, labels_14: false} -> [labels_13]

  const activeFiltersIds = Object.keys(activeFilters).filter(
    (item) => activeFilters[item] === true,
  );

  // [{id: 14, ...}, {id: 16, ...}] => [labels_14, labels_16];
  const offerLtExtrasIds =
    offer.labels && offer.labels.map((item) => `labels_${item.id}`);

  const diff = difference(activeFiltersIds, offerLtExtrasIds);
  const hasFiltered = isEmpty(diff);

  return hasFiltered;
};

/**
 *
 * @param {Object} offer {mealTypes: "RO"}
 * @param {Object} activeFilters {lt_meals_RO: true}
 * @returns {Boolean}
 */
export const isFilteredByMeals = (offer, activeFilters) => {
  const activeFiltersIds = Object.keys(activeFilters).filter(
    (item) => activeFilters[item] === true,
  );
  const mealType = activeFiltersIds.map((meal) => meal.slice(9));
  if (mealType.length === 0) return true;
  return offer.mealType && mealType.includes(offer.mealType);
};

/**
 *
 * @param {Object} offer {operator_id: 76}
 * @param {Object} activeFilters {lt_meals_RO: true}
 * @returns {Boolean}
 */
export const isFilteredByOperators = (offer, activeFilters) => {
  const activeFiltersIds = Object.keys(activeFilters).filter(
    (item) => activeFilters[item] === true,
  );
  return offer.operator_id && activeFiltersIds.includes(offer.operator_id);
};

export const isFiltersAppliedToOffer = (
  offer,
  extrasFilters,
  labelsFilters,
  mealsFilters,
) => {
  const isFilteredMeals =
    mealsFilters && !isFilteredByMeals(offer, mealsFilters);
  const isFilteredExtras =
    extrasFilters && !isFilteredByExtras(offer, extrasFilters);
  const isFilteredLabels =
    labelsFilters && !isFilteredByLabels(offer, labelsFilters);
  return isFilteredExtras || isFilteredLabels || isFilteredMeals;
};

/**
 * building filter object:
 * @param {array} filters array of filters
 * @param {string} prefix prefix of filters
 */
export const buildFilters = (filters, prefix = '') =>
  Object.keys(filters || {})
    ?.filter((key) => key.includes(prefix))
    .reduce((prev, curr) => {
      const filter = filters || {};
      // eslint-disable-next-line no-param-reassign
      prev[curr] = filter[curr];
      return prev;
    }, {});

/**
 * @param {Object} offers - All offers as got from hotel_rooms request
 * @param date {String} date in server format, ie '2019-09-15';
 * @param filters {Object} filter data by this object
 * @param cashbackReduction {boolean} reduces offers' prices by a user's cashback
 * @param isHotelOnly {boolean} hotel search or not
 *
 * @returns offers object for specified date.
 * Filters out meal types which are not present for the day
 */
export const getOffersForDate = (allOffers, date, isHotelOnly, filters = {}) =>
  allOffers
    .map((roomOffer) => {
      const { operators, instantConfirm, ltExtras: activeFilters } = filters;

      const offers = mapValues(roomOffer.offers, (mealOffers) =>
        mealOffers
          .filter((offer) => {
            const { start_date, extras, operator_id, lt_extras, labels } =
              offer;

            const extrasFilters = buildFilters(activeFilters, 'lt_extras');
            const labelFilters = buildFilters(activeFilters, 'labels');

            return (
              start_date === date &&
              (instantConfirm
                ? extras.instant_confirm === instantConfirm
                : true) &&
              (!operators || operators[operator_id]) &&
              (!extrasFilters ||
                isFilteredByExtras({ lt_extras }, extrasFilters)) &&
              (!labelFilters || isFilteredByLabels({ labels }, labelFilters))
            );
          })
          .map((offer) => ({
            ...offer,
            price: offer.price_minus_score ?? offer.price,
          })),
      );

      const mealTypes = intersectionBy(
        roomOffer.meal_types,
        Object.keys(offers)
          .filter((mealTypeId) => !isEmpty(offers[mealTypeId]))
          .map((id) => ({ id })),
        'id',
      );

      const uniqueLabels = [];
      let allNumberOffers = [];
      const labelMap = new Map();
      // Собираем все офферы в текущем номере:
      mealTypes.forEach((type) => {
        allNumberOffers = allNumberOffers.concat(offers[type.id]);
      });

      allNumberOffers.forEach((offer) => {
        // Делаем счетчик лейблов + собираем уникальные лейблы:
        const { labels } = offer;
        if (isEmpty(labels)) return;

        labels.forEach((label) => {
          if (label.context.hotel !== 2) return;

          if (labelMap.has(label.id)) {
            // Счетчик лейблов
            const counter = labelMap.get(label.id);
            labelMap.set(label.id, counter + 1);
          } else {
            const counter = 1;
            labelMap.set(label.id, counter);

            // Уникальные лейблы
            uniqueLabels.push(label);
          }
        });
      });

      // Собираем лейблы для текущего номера:
      const labels = [];
      labelMap.forEach((val, key) => {
        if (val === allNumberOffers.length) {
          const label = uniqueLabels.find((uniqLabel) => uniqLabel.id === key);
          if (label.context.hotel === 2) labels.push(label);
        }
      });

      const offerItems = Object.values(offers).flat();
      const roomTypeForSearchCountNights = offerItems.find(
        (item) => item.price === roomOffer.searchCountNightsMin,
      );

      const currentBonusCountForPackage =
        roomTypeForSearchCountNights?.bonus_count;

      const currentBonusCountForHotel = roomOffer?.bonus_count;

      const currentBonusCount = isHotelOnly
        ? currentBonusCountForHotel
        : currentBonusCountForPackage;

      return {
        ...roomOffer,
        bonus_count: currentBonusCount ?? 0,
        labels,
        offers,
        meal_types: mealTypes,
      };
    })
    .filter((roomOffer) => !isEmpty(roomOffer.meal_types));

/**
 * Returns all offers
 * @param {*} rooms
 * @summary используется дла аналитики
 */
export const getAllOffers = (rooms, hotelId) => {
  const parsedOffers = [];

  rooms.forEach((room) => {
    // Обходим комнаты.
    if (!isObject(room) || !isObject(room.offers)) {
      return;
    }

    const roomId = String(room.ID);
    const code = `${hotelId}-${roomId}`;
    const roomName = room.room.name_ru;

    Object.keys(room.offers).forEach((mealType) => {
      const mealTypeOffers = room.offers[mealType];

      mealTypeOffers.forEach((packageItem) => {
        const {
          extras: { instant_confirm, analytics_surcharge: surcharge } = {},
          analytics_price: price,
          operator_id,
          operator_name,
          availability,
          labels,
          confirmability_index,
          id,
          nights_count,
          start_date,
          bundle_id,
          acm_component_id,
        } = packageItem;

        // на самом деле искать мы должны по name
        const offerMeal = getOfferMealMemoized(mealType, room.meal_types);
        const filteredRoomOffers = mealTypeOffers.filter(
          (offer) =>
            offer.start_date === start_date &&
            offer.nights_count === nights_count,
        );
        const onTop = Math.min(
          ...filteredRoomOffers.map((offer) => offer.price),
        );

        parsedOffers.push({
          code,
          instant_confirm,
          // TODO: раньше отправлялся id, теперь тут name, например, "BB"
          meal_id: get(offerMeal, 'id'),
          meal_name: get(offerMeal, 'description'),
          meal_type: mealType,
          price,
          surcharge,
          operator_id,
          operator_name,
          room_id: `${roomId} `, // TODO: узнать, зачем был пробел в конце id
          room_name: roomName,
          // FIXME: нет такого поля, оно и до сих пор пустое отправлялось в большинстве случаев
          room_type: room.room.name_en,
          start_date: start_date || undefined,
          accommodation_availability: availability.hotel,
          flight_seats: availability.flight,
          labels: labels ? labels.map((label) => label.id).join(';') : null,
          confirmability_index,
          tour_id: id,
          nights: nights_count,
          on_top: onTop === price,
          room_order: rooms.indexOf(room) + 1,
          bundle_id,
          acm_component_id,
        });
      });
    });
  });
  return parsedOffers;
};

/**
 * Deep merge of rooms and offers
 * @typedef Offer
 * @property {string} id
 * @typedef Room
 * @property {string} ID
 * @property {Object.<string, Offer>} offers
 * @param {Array<Room>} rooms
 * @param {Array<Room>} nextRooms
 * @param {number} searchCountNights
 * @param {string} carouselDate
 * @returns {Array<Room>}
 */
export const mergeRoomsOffers = (
  rooms,
  nextRooms,
  searchCountNights,
  carouselDate,
) => {
  const existedRoomsIdsCache = {};

  // насыщаем существующие комнаты предложениями
  const existedRooms = rooms.map((room) => {
    existedRoomsIdsCache[room.ID] = true;

    const existedMealTypes = Object.keys(room.offers);
    const payloadRoom = nextRooms.find((item) => item.ID === room.ID);

    if (!payloadRoom) {
      return room;
    }

    // новые типы питания с предложениями
    const newMealTypesOffers = omit(payloadRoom.offers, existedMealTypes);

    // новые предложения для существующих типов питания
    const nextOffers = existedMealTypes.reduce(
      (acc, mealType) => {
        const mealTypeOffers = room.offers[mealType];
        const offers = sortBy(
          uniqBy(
            concat(mealTypeOffers, payloadRoom.offers[mealType] || []),
            'id',
          ),
          ['price'],
        );

        return {
          ...acc,
          [mealType]: offers,
        };
      },
      { ...newMealTypesOffers },
    );

    const allOffers = flatten(values(nextOffers)).concat(
      flatten(values(room.offers)),
    );
    const filterOffers = allOffers
      .filter(
        ({ nights_count, start_date }) =>
          nights_count === searchCountNights && start_date === carouselDate,
      )
      .map((offer) => offer.price);

    const minBonusCount = filterOffers.find(
      (el) => payloadRoom.min_price === el,
    )
      ? payloadRoom.bonus_count
      : room.bonus_count;

    const roomMinPrices = [];

    return {
      ...room,
      offers: nextOffers,
      meal_types: room.meal_types.map((item) => {
        const min_price = get(nextOffers, [item.id, '0', 'price']);
        roomMinPrices.push(min_price);
        return {
          ...item,
          min_price,
        };
      }),
      min_price: Math.min(...roomMinPrices),
      bonus_count: minBonusCount,
    };
  });

  // новые комнаты
  const newRooms = nextRooms.filter(
    (item) => !existedRoomsIdsCache[String(item.ID)],
  );

  const result = [...existedRooms, ...newRooms].map((res) => {
    const array = [];
    forIn(res.offers, (offer) => {
      const currentDateOffer = offer.filter(
        ({ nights_count, start_date }) =>
          nights_count === searchCountNights && start_date === carouselDate,
      );
      array.push(minBy(currentDateOffer, 'price'));
    });
    res.searchCountNightsMin = minBy(array, 'price')?.price;
    return res;
  });
  return sortBy(result, ['searchCountNightsMin']);
};

/**
 * Находится ли дата в промежутке
 * @param {{centerDate: string, date: string}} dateInfo
 * Обе даты должны быть в формате DD.MM.YYYY
 * @returns {boolean}
 */
export const isDateBetweenRange = ({ centerDate, date }) => {
  try {
    const { from, to } = getFlexDates(new Date(centerDate));

    const parseDate = convertClientDateToServerDate(date);

    return isWithinInterval(new Date(parseDate), {
      start: from,
      end: to,
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('isDateBetweenRange', error);
    return false;
  }
};

/**
 * Возвращает поля для фильтрации, если на сайте применен фильтр
 * @param {Object} filter объект для фильтрации офферов
 */
export const passFilterData = (filter) => {
  const showAll = isEmpty(filter) || every(filter, (v) => v === false);
  return showAll ? undefined : filter;
};

/**
 * Найти минимальную цену среди офферов
 * @param {Array} offers
 */
export const getOffersMinPrice = (offers) =>
  Math.min.apply(
    null,
    offers.map((offer) => offer.price),
  );

/**
 * Проверяет, есть ли среди предложений id искомой доп. услуги
 * @param {Object} offer
 * @param {number} id ID услуги
 */
export const hasLtExtras = (offer, id) =>
  offer.lt_extras && offer.lt_extras.some((extras) => extras.id === id);

/**
 * Проверяет, есть ли среди предложений id искомого лейбла
 * @param {Object} offer
 * @param {number} id ID услуги
 */
export const hasLabels = (offer, id) =>
  offer.labels && offer.labels.some((label) => label.id === id);

/**
 * Преобразует get параметры в объект
 * @param {string} params "6, 7"
 * @returns {Object} { 6: true, 7: true }
 */
export const transformOfferFilters = (params) => {
  if (!params) {
    return null;
  }

  return params.split(',').reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: true,
    }),
    {},
  );
};
