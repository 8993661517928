import Immutable, { Iterable } from 'immutable';
import { createSelector } from '@reduxjs/toolkit';

// eslint-disable-next-line import/prefer-default-export
export const keyIn = ([...keys]) => {
  const keySet = Immutable.Set(keys);

  return function (v, k) {
    return keySet.has(k);
  };
};

/**
 * Статичный редюсер
 * @param {Immutable.Map} state
 * @param {object} action
 */
export function staticMap(state = Immutable.Map(), action) {
  switch (action.type) {
    default:
      return state;
  }
}

/**
 * Возвращает безопасно непримитивное свойство из имьютабл стейта
 * @param {Function} statePropGetter
 */
export const getFromImmutableState = <T = any>(statePropGetter) =>
  createSelector(
    statePropGetter,
    (props) => props && (props as any).toJS(),
  ) as any as (state: any) => T;

/**
 * Позволяет использовать селектор как к immutable стейту так и к обычному
 * @param {object} state
 */
export const getImmutableCompatible = (state, key) =>
  typeof state.get === 'function' ? state.get(key) : state[key];

/**
 * Возвращает безопасно непримитивное свойство из имьютабл или обычного стейта
 * @param {Function} statePropGetter
 */
export const getFromImmutableStateCompatible = <T = any>(statePropGetter) =>
  createSelector(
    statePropGetter,
    (props) =>
      props && (Iterable.isIterable(props) ? (props as any).toJS() : props),
  ) as any as (state: any) => T;
