import { all } from 'redux-saga/effects/';

// global store
import client from 'src/store/client/sagas';
import payment from 'src/store/payment/sagas';
import globalView from 'src/store/view/sagas';
import globalAnalytics from 'src/store/analytics/sagas';
import mindboxAnalytics from 'src/store/mindbox/sagas';
import wishlist from 'src/store/wishlist/sagas';
import bannerAppData from 'src/store/appBanners/sagas';
import appBannersSagaWatcher from 'src/store/appBannerStats/saga';
import view from '../store/view/sagas';
import nps from '../store/nps/sagas';
import { homeDynamicDataWatcher } from './homeDynamicData';

function* sagas() {
  yield all([
    client(),
    view(),
    payment(),
    globalView(),
    globalAnalytics(),
    mindboxAnalytics(),
    wishlist(),
    nps(),
    //
    homeDynamicDataWatcher(),
    bannerAppData(),
    appBannersSagaWatcher(),
  ]);
}

export default sagas;
