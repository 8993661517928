import { createSelector } from '@reduxjs/toolkit';
import type { RoomInfoStateType } from './types';

export const getRoomInfo = (state): RoomInfoStateType => state.roomInfo;

export const getIsRoomInfoAvailable = createSelector(getRoomInfo, (roomInfo) =>
  Boolean(roomInfo.description || roomInfo.images),
);

export const getRoomInfoNameRu = createSelector(
  getRoomInfo,
  (roomInfo) => roomInfo.name_ru,
);
