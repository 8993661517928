import { createSelector } from '@reduxjs/toolkit';
import { name } from './slice';
import type { NpsStore } from './types';

const getState = (state): NpsStore => state[name];

export const isAlreadyRatedSelector = createSelector(
  getState,
  (state) => state.isAlreadyRated,
);

export const npsTokenSelector = createSelector(
  getState,
  (state) => state.token,
);

export const npsRatingSelector = createSelector(
  getState,
  (state) => state.score,
);

export const npsComponentStateSelector = createSelector(
  getState,
  (state) => state.componentState,
);

export const npsErrorSelector = createSelector(
  getState,
  (state) => state.errorOccured,
);
