import type { MobilePlatforms } from './types';

export const MOBILE_PLATFORMS: MobilePlatforms = {
  ANDROID: 'android',
  IOS: 'ios',
  RUSTORE: 'rustore',
  APPGALLERY: 'appgallery',
};

/**
 * Константы для тестирования
 */
export const WRAPPER_TEST_ID = 'WRAPPER_TEST_ID';
export const NEW_SEARCH_TEST_ID = 'NEW_SEARCH_TEST_ID';
export const ABOUT_US_TEST_ID = 'ABOUT_US_TEST_ID';
export const CAREER_TEST_ID = 'CAREER_TEST_ID';
export const WRITE_US_TEST_ID = 'WRITE_US_TEST_ID';
export const CALL_CENTER_TEST_ID = 'CALL_CENTER_TEST_ID';
export const GIFTS_TEST_ID = 'GIFTS_TEST_ID';
export const LOG_IN_TEST_ID = 'LOG_IN_TEST_ID';
export const LOG_OUT_TEST_ID = 'LOG_OUT_TEST_ID';
export const PROFILE_TEST_ID = 'PROFILE_TEST_ID';
export const WISHLIST_TEST_ID = 'WISHLIST_TEST_ID';
