import { createSlice } from '@reduxjs/toolkit';

import type { PopularDestinationsOffersState } from './types';

const initialState: PopularDestinationsOffersState = {
  list: [],
  isDefault: true,
};

export const name = 'popularDestinationsOffers';

const popularDestinationsOffersSlice = createSlice({
  name,
  initialState,
  reducers: {},
});

export default popularDestinationsOffersSlice;
