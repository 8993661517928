import { DTOError } from 'src/error/errors/DTOError';
import type {
  EnqueueSearchParams,
  EnqueueSearchParamsCity,
  EnqueueSearchParamsCountry,
  EnqueueSearchParamsFrom,
  EnqueueSearchParamsKids,
  EnqueueSearchParamsNights,
  EnqueueSearchParamsOptions,
  EnqueueSearchParamsTo,
  EnqueueSearchParamsToHotel,
  EnqueueSearchParamsToMetaregion,
  EnqueueStateType,
} from './types';

export const searchParamsKidsDTO = (data: unknown): EnqueueSearchParamsKids => {
  if (
    data &&
    typeof data === 'object' &&
    'count' in data &&
    typeof data.count === 'number' &&
    'ages' in data &&
    Array.isArray(data.ages)
  ) {
    return {
      count: data.count,
      ages: data.ages.map((age) => {
        if (typeof age === 'number') {
          return age;
        }
        throw new DTOError('searchParamsKidsDTO', data);
      }),
    };
  }
  throw new DTOError('searchParamsKidsDTO', data);
};

export const searchParamsOptionsDTO = (
  data: unknown,
): EnqueueSearchParamsOptions => {
  if (
    data &&
    typeof data === 'object' &&
    'flex_dates' in data &&
    (typeof data.flex_dates === 'boolean' ||
      typeof data.flex_dates === 'number')
  ) {
    return {
      flex_dates: data.flex_dates,
    };
  }
  throw new DTOError('searchParamsOptionsDTO', data);
};

export const searchParamsNightsDTO = (
  data: unknown,
): EnqueueSearchParamsNights | null => {
  if (
    data &&
    typeof data === 'object' &&
    'from' in data &&
    typeof data.from === 'number' &&
    'to' in data &&
    typeof data.to === 'number'
  ) {
    return {
      from: data.from,
      to: data.to,
    };
  }
  return null;
};

export const searchParamsCountryDTO = (
  data: unknown,
): EnqueueSearchParamsCountry => {
  if (
    data &&
    typeof data === 'object' &&
    'id' in data &&
    typeof data.id === 'number' &&
    'name_ru' in data &&
    typeof data.name_ru === 'string'
  ) {
    return {
      id: data.id,
      name_ru: data.name_ru,
      name_en:
        'name_en' in data && typeof data.name_en === 'string'
          ? data.name_en
          : null,
      iso2: 'iso2' in data && typeof data.iso2 === 'string' ? data.iso2 : null,
      name_ru_form2:
        'name_ru_form2' in data && typeof data.name_ru_form2 === 'string'
          ? data.name_ru_form2
          : null,
      name_ru_form3:
        'name_ru_form3' in data && typeof data.name_ru_form3 === 'string'
          ? data.name_ru_form3
          : null,
    };
  }
  throw new DTOError('searchParamsToCountryDTO', data);
};

export const searchParamsCityDTO = (data: unknown): EnqueueSearchParamsCity => {
  if (
    data &&
    typeof data === 'object' &&
    'id' in data &&
    typeof data.id === 'number' &&
    'name_ru' in data &&
    typeof data.name_ru === 'string'
  ) {
    return {
      id: data.id,
      name_ru: data.name_ru,
      name_en:
        'name_en' in data && typeof data.name_en === 'string'
          ? data.name_en
          : null,
      name_ru_form2:
        'name_ru_form2' in data && typeof data.name_ru_form2 === 'string'
          ? data.name_ru_form2
          : null,
      name_ru_form3:
        'name_ru_form3' in data && typeof data.name_ru_form3 === 'string'
          ? data.name_ru_form3
          : null,
      iso2: 'iso2' in data && typeof data.iso2 === 'string' ? data.iso2 : null,
    };
  }
  throw new DTOError('searchParamsCityDTO', data);
};

export const searchParamsToMetaregionDTO = (
  data: unknown,
): EnqueueSearchParamsToMetaregion => {
  if (
    data &&
    typeof data === 'object' &&
    'id' in data &&
    typeof data.id === 'number' &&
    'name_ru' in data &&
    typeof data.name_ru === 'string' &&
    'name_en' in data &&
    typeof data.name_en === 'string'
  ) {
    return {
      id: data.id,
      name_ru: data.name_ru,
      name_en: data.name_en,
    };
  }
  throw new DTOError('searchParamsToMetaregionDTO', data);
};

export const searchParamsToHotelDTO = (
  data: unknown,
): EnqueueSearchParamsToHotel => {
  if (
    data &&
    typeof data === 'object' &&
    'id' in data &&
    typeof data.id === 'number' &&
    'name' in data &&
    typeof data.name === 'string' &&
    'public_url' in data &&
    typeof data.public_url === 'string'
  ) {
    return {
      id: data.id,
      name: data.name,
      public_url: data.public_url,
    };
  }
  throw new DTOError('searchParamsToHotelDTO', data);
};

export const searchParamsToDTO = (data: unknown): EnqueueSearchParamsTo => {
  if (data && typeof data === 'object') {
    return {
      city: 'city' in data ? searchParamsCityDTO(data.city) : null,
      country: 'country' in data ? searchParamsCountryDTO(data.country) : null,
      hotel: 'hotel' in data ? searchParamsToHotelDTO(data.hotel) : null,
      metaregion:
        'metaregion' in data
          ? searchParamsToMetaregionDTO(data.metaregion)
          : null,
    };
  }
  throw new DTOError('searchParamsTo', data);
};

export const searchParamsFromDTO = (data: unknown): EnqueueSearchParamsFrom => {
  if (data && typeof data === 'object' && 'city' in data && 'country' in data) {
    return {
      city: searchParamsCityDTO(data.city),
      country: searchParamsCountryDTO(data.country),
    };
  }
  throw new DTOError('searchParamsFromDTO', data);
};

export const searchParamsDTO = (data: unknown): EnqueueSearchParams | null => {
  try {
    if (
      data &&
      typeof data === 'object' &&
      'adults' in data &&
      typeof data.adults === 'number' &&
      'from' in data &&
      'to' in data &&
      'kids' in data &&
      'options' in data
    ) {
      return {
        from: searchParamsFromDTO(data.from),
        to: searchParamsToDTO(data.to),
        query:
          'query' in data && typeof data.query === 'string' ? data.query : '',
        start_date_primary:
          'start_date_primary' in data &&
          typeof data.start_date_primary === 'string'
            ? data.start_date_primary
            : null,
        start_date:
          'start_date' in data && typeof data.start_date === 'string'
            ? data.start_date
            : null,
        nights: 'nights' in data ? searchParamsNightsDTO(data.nights) : null,
        adults: data.adults,
        kids: searchParamsKidsDTO(data.kids),
        options: searchParamsOptionsDTO(data.options),
      };
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    return null;
  }
  return null;
};

export const enqueueDTO = (data: unknown): EnqueueStateType => {
  if (
    data &&
    typeof data === 'object' &&
    'request_id' in data &&
    typeof data.request_id === 'string' &&
    'search_params' in data &&
    typeof data.search_params === 'object'
  ) {
    return {
      request_id: data.request_id,
      search_params: searchParamsDTO(data.search_params),
    };
  }
  throw new DTOError('enqueueDTO', data);
};
