import React, { useState, useEffect } from 'react';
import { shuffle } from 'lodash';

import { useTranslation } from '@lt/localization';
import UserflowTheme from '@lt/components/themes/userflow';

import ContainerHeading from '../ContainerHeading';
import ScrollableContainer from '../ScrollableContainer';
import HotelRelinkingBlock from './components/HotelRelinkingBlock';

import { RELINKING_VARIANTS } from '../../constants';
import type { IHotelsRelinking } from './types';
import styles from './hotelsRelinking.module.css';

export const HotelsRelinking = (props: IHotelsRelinking) => {
  const { linkedHotels, isMobile } = props;
  const [shuffledLinkedHotels, setShuffledLinkedHotels] =
    useState(linkedHotels);

  useEffect(() => {
    setShuffledLinkedHotels(shuffle(linkedHotels));
  }, [linkedHotels]);

  const { t } = useTranslation();

  return (
    <UserflowTheme>
      <ContainerHeading
        isMobile={isMobile}
        variant={RELINKING_VARIANTS.HOTELS}
      />
      <div className={styles.subheading}>
        {t('homeRelinking.subheading', { context: RELINKING_VARIANTS.HOTELS })}
      </div>
      <ScrollableContainer
        isMobile={isMobile}
        variant={RELINKING_VARIANTS.HOTELS}
      >
        {shuffledLinkedHotels.map((hotelCardProps) => (
          <HotelRelinkingBlock
            {...hotelCardProps}
            key={`hotel_card_${hotelCardProps.name}`}
            isMobile={isMobile}
          />
        ))}
      </ScrollableContainer>
    </UserflowTheme>
  );
};
