import { logger } from 'src/error';
import { notEmpty } from '../../utils/notEmpty';

import type {
  PopularDestinationsOffersState,
  IPopularDestinationsOffer,
  IPopularDestinationsOfferRegion,
} from './types';

export const popularDestinationsOffersDTO = (
  data: unknown,
): PopularDestinationsOffersState => {
  if (
    !data ||
    typeof data !== 'object' ||
    !('offers' in data) ||
    !Array.isArray(data.offers) ||
    !data.offers.length ||
    !('default' in data) ||
    typeof data.default !== 'boolean'
  )
    return { list: [], isDefault: true };

  return {
    list: data.offers
      .map((destination) => popularDestinationOfferDTO(destination))
      .filter(notEmpty),
    isDefault: data.default,
    title:
      'title' in data && typeof data.title === 'string'
        ? data.title
        : undefined,
  };
};

const popularDestinationOfferDTO = (
  data: unknown,
): IPopularDestinationsOffer | null => {
  if (
    !data ||
    typeof data !== 'object' ||
    !('link' in data) ||
    typeof data.link !== 'string' ||
    !('place' in data) ||
    typeof data.place !== 'string' ||
    !('image_url' in data) ||
    typeof data.image_url !== 'string' ||
    !('regions' in data)
  ) {
    logger('popularDestinationOfferDTO', data);
    return null;
  }

  return {
    adults:
      'adults' in data && typeof data.adults === 'number'
        ? data.adults
        : undefined,
    link: data.link,
    minPrice:
      'min_price' in data && typeof data.min_price === 'number'
        ? data.min_price
        : undefined,
    nights:
      'nights' in data && typeof data.nights === 'string'
        ? data.nights
        : undefined,
    place: data.place,
    imageUrl: data.image_url,
    regions: popularDestinationOfferRegionsDTO(data.regions),
  };
};

const popularDestinationOfferRegionsDTO = (
  data: unknown,
): IPopularDestinationsOfferRegion[] => {
  if (!data || !Array.isArray(data)) {
    logger('popularDestinationOfferRegionsDTO', data);
    return [];
  }

  return data
    .map((region) => popularDestinationOfferRegionDTO(region))
    .filter(notEmpty);
};

const popularDestinationOfferRegionDTO = (
  data: unknown,
): IPopularDestinationsOfferRegion | null => {
  if (
    !data ||
    typeof data !== 'object' ||
    !('place' in data) ||
    typeof data.place !== 'string' ||
    !('link' in data)
  ) {
    logger('popularDestinationOfferRegionDTO', data);
    return null;
  }

  return {
    place: data.place,
    minPrice:
      'min_price' in data && typeof data.min_price === 'number'
        ? data.min_price
        : undefined,
    link: typeof data.link === 'string' ? data.link : '',
  };
};
