import { createSelector } from '@reduxjs/toolkit';
import { getIsMobile } from 'src/store/view/selectors';
import { HOTEL_MOBILE_PAGES } from '../../constants';

const getView = (state) => state.get('view');
const getBrowser = (state) => state.get('browser');

export const getIsShortInfoClamped = (state) =>
  getView(state).get('isShortInfoClamped');
export const getIsWhitelabel = (state) => getView(state).get('isWhitelabel');
export const getSearchType = (state) => getView(state).get('searchType');
export const getHotelCurrentPage = (state) => getView(state).get('page');

export const getAvailableSearchTypes = (state) =>
  getView(state).get('availableSearchTypes');

export const getIsWebGlSupported = (state) =>
  getView(state).get('isWebGlSupported');

export const isMapViewSelector = createSelector(
  getHotelCurrentPage,
  (currentPage) => currentPage === HOTEL_MOBILE_PAGES.MAP,
);

/** how many nights are visible in offers table */
export const getVisibleNightsColumnsCount = createSelector(
  [getIsMobile, getBrowser],
  (isMobile, browser) => {
    if (isMobile || browser.greaterThan.large) {
      return 5;
    }
    if (browser.is.large) {
      return 4;
    }
    return 3;
  },
);
