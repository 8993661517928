import { createSelector } from '@reduxjs/toolkit';
import type { MobileAppDataProps } from 'src/components/Banners/MobileAppPopup/types';
import { getImmutableCompatible } from 'src/utils/immutableUtils';
import { name } from './slice';

export const getBannersAppData = (state): MobileAppDataProps =>
  getImmutableCompatible(state, name);

export const getShortLink = createSelector(
  [getBannersAppData],
  (data) => data.shortLink,
);
