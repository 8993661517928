import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { NewErrorPayload, ErrorStateType } from './types';

const initialState: ErrorStateType = {
  type: null,
  title: null,
  message: null,
  action: null,
};

const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    setError: (_, action: PayloadAction<NewErrorPayload>) => ({
      type: action.payload.type || null,
      action: action.payload.action || null,
      title: action.payload.title || null,
      message: action.payload.message,
    }),
  },
});

export const { setError } = errorSlice.actions;

export default errorSlice.reducer;
