import { upperCase } from 'lodash';
import React, { Suspense, lazy } from 'react';
import styled from 'styled-components';
import type { DeviceType } from '../Header/components/HeaderMenu/components/SideNavMenu/types';

const LazyRustore = lazy(
  () =>
    import(
      /* webpackChunkName: "ToRustore" */ '@lt/components/icons-react/commonPages/goToStore/ToRuStore'
    ),
);

const LazyAppgallery = lazy(
  () =>
    import(
      /* webpackChunkName: "ToAppgallery" */ '@lt/components/icons-react/commonPages/goToStore/ToAppGallery'
    ),
);

const LazyAndroid = lazy(
  () => import(/* webpackChunkName: "AndroidIcon" */ './AndroidIcon'),
);

const LazyIos = lazy(
  () => import(/* webpackChunkName: "IosIcon" */ './IosIcon'),
);

const ICONS = {
  IOS: <LazyIos />,
  ANDROID: <LazyAndroid />,
  RUSTORE: <LazyRustore />,
  APPGALLERY: <LazyAppgallery />,
};

type Props = {
  deviceType: DeviceType;
  sidebarMenu?: boolean;
  appLink: string;
};

const MobileAppBanner = ({ deviceType, sidebarMenu, appLink }: Props) => {
  if (!deviceType || !appLink) {
    return null;
  }

  return (
    <StyledContainer sidebarMenu={sidebarMenu}>
      <StyledLink href={appLink} target="_blank" rel="noreferrer">
        <Suspense fallback={null}>{ICONS[upperCase(deviceType)]}</Suspense>
      </StyledLink>
    </StyledContainer>
  );
};

const StyledContainer = styled.div<{ sidebarMenu: boolean | undefined }>`
  width: 150px;
  height: 47px;
  display: flex;
  ${({ sidebarMenu }) =>
    sidebarMenu &&
    ` 
      justify-content: center;
      & > a > svg {
        width: 150px;
        height: auto;
      }
  `}
`;

const StyledLink = styled.a`
  height: 100%;
  & > svg {
    height: 100%;
  }
`;

export default MobileAppBanner;
