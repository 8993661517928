import React from 'react';

import { Link, Image } from '@lt/components';

import classnames from 'classnames';
import stylesCard from '../../../styles/card.module.css';
import stylesImage from '../../../styles/cardImages.module.css';

import type { INarrativeCard } from '../../types';
import styles from './narrativeCard.module.css';
import cardHeadingStyles from '../../../CardHeading/cardHeading.module.css';

export const NarrativeCard = (props: INarrativeCard) => {
  const { title, text, redirect_url: redirectUrl, image, isMobile } = props;
  const { x240x150: src, webp_x240x150: fallback } = image;

  const url = new URL(redirectUrl);
  url.searchParams.set('ref_content', 'main_page');

  return (
    <div
      className={classnames(stylesCard.card, {
        [stylesCard.desktop]: !isMobile,
      })}
    >
      <Link className={styles.link} href={url.href}>
        <Image
          className={classnames(stylesImage.image, styles.cardImage)}
          src={src}
          fallback={fallback}
        />
        <p
          className={classnames(
            cardHeadingStyles.cardHeadingText,
            cardHeadingStyles.cardHeadingTextBlack,
            {
              [cardHeadingStyles.cardHeadingTextMobile]: isMobile,
            },
          )}
        >
          {title}
        </p>
        <p className={styles.cardText}>{text}</p>
      </Link>
    </div>
  );
};
