import { i18n } from '@lt/localization';

export const VALIDATE_TOURIST_DATA = 'VALIDATE_TOURIST_DATA';
export const VALIDATE_TOURIST_ON_SERVER = 'VALIDATE_TOURIST_ON_SERVER';
export const VALIDATE_TOURIST_FIELD = 'VALIDATE_TOURIST_FIELD';
export const SUBMIT_TOURISTS = 'SUBMIT_TOURISTS';
export const SUBMIT_TOURIST = 'SUBMIT_TOURIST';
export const SUBMIT_TOURIST_SIMPLE_CHECKOUT = 'SUBMIT_TOURIST_SIMPLE_CHECKOUT';
export const SET_TOURIST_VALIDATED = 'SET_TOURIST_VALIDATED';
export const UPDATE_MOCK_TOURIST = 'UPDATE_MOCK_TOURIST';
export const RESET_TOURIST_CONFIRMED = 'RESET_TOURIST_CONFIRMED';

export const TOURISTS_COMPONENT_ID = 'checkout_tourists';
export const LOG_IN_ID = 'log_in_component';
export const CLIENT_DATA_ID = 'client_data_component';
export const PAYMENT_COMPONENT = 'payment_component';

export const GENDER = {
  MALE: 'M',
  FEMALE: 'F',
};

export const INPUT_PATTERN = {
  LATIN: /^[A-Za-z'\s-]+$/,
  CYRILLIC: /^[ЁёА-я\s-]+$/,
  EXCLUDE_SCRIPT: /^[^<>;]*$/,
};

export const TOURIST_FIELDS = {
  DOC_TYPE: 'docType',
  GENDER: 'gender',
  CITIZENSHIP: 'nationality_id',
  NAME: 'name',
  SURNAME: 'surname',
  PATROMYMIC: 'patronymic',
  BIRTHDAY: 'birthday',
  DOC_NUMBER: 'passport_number',
  DOC_EXPIRES_DATE: 'passport_expires_on',
  VISA_SUPPORT: 'visa_service',
} as const;

export const WRONG_FORMAT_MESSAGES = {
  [TOURIST_FIELDS.NAME]: i18n.t('checkout.touristForm.name.fields'),
  [TOURIST_FIELDS.SURNAME]: i18n.t('checkout.touristForm.surname.fields'),
  [TOURIST_FIELDS.PATROMYMIC]: i18n.t('checkout.touristForm.patronymic.fields'),
};

const DOCUMENT_DATE_FIELDS = {
  RUSSIAN: null,
  INTERNATIONAL: {
    id: TOURIST_FIELDS.DOC_EXPIRES_DATE,
    name: i18n.t('checkout.touristForm.document.dateFields'),
  },
};

export const BIRTH_CERTIFICATE_MASK =
  /^([XVILDMХМ]{1,4})-?([ЁёА-яOMKTAXYHBEPC]{2})\s?(\d{6})$/;

export const DOC_TYPES = {
  internal_passport: {
    id: 'internal_passport',
    name: i18n.t('checkout.touristForm.document.passportRu'),
    pattern: INPUT_PATTERN.CYRILLIC,
    dateField: DOCUMENT_DATE_FIELDS.RUSSIAN,
    numberField: {
      mask: '9999 999999',
      placeholder: '0000 000000',
      pattern: /\d{4}\s?\d{6}/,
    },
  },
  birth_certificate: {
    id: 'birth_certificate',
    name: i18n.t('checkout.touristForm.document.bithCertificate'),
    pattern: INPUT_PATTERN.CYRILLIC,
    dateField: DOCUMENT_DATE_FIELDS.RUSSIAN,
    numberField: {
      placeholder: 'II-АА 000000',
      pattern: BIRTH_CERTIFICATE_MASK,
    },
  },
  international_passport: {
    id: 'international_passport',
    name: i18n.t('checkout.touristForm.document.passport'),
    pattern: INPUT_PATTERN.LATIN,
    dateField: DOCUMENT_DATE_FIELDS.INTERNATIONAL,
    numberField: {
      mask: '99 9999999',
      placeholder: '00 0000000',
      pattern: /\d{2}\s?\d{7}/,
    },
  },
};
