import { createSelector } from '@reduxjs/toolkit';

import {
  getDefaultDeparture,
  getDestinationParams,
  getNightsFromObject,
  isFlexNights,
} from 'src/utils/searchParamsUtils';
import type { InitialValues } from '@lt/widget/components/Cigarette/context';
import { getDateCell } from 'src/utils/cigaretteUtils';
import {
  getImmutableCompatible,
  getFromImmutableStateCompatible,
} from 'src/utils/immutableUtils';
import { addDays } from 'date-fns';
import { MODULE_NAME } from 'src/constants';
import { getAppName } from '../view/selectors';
import type { EnqueueStateType } from './types';

const _getParams = (state) => getImmutableCompatible(state, 'enqueue');
const getParams = getFromImmutableStateCompatible<EnqueueStateType>(_getParams);

/**
 * Возвращает request_id
 */
export const getSearchParamsRequestId = createSelector(
  getParams,
  (params) => params?.request_id,
);

/**
 * Возвращает объект поисковых параметров
 */
export const getSearchParams = createSelector(
  getParams,
  (params) => params?.search_params,
);

/**
 * Дата для поиска YYYY-MM-DD
 */
export const getSearchParamsStartDate = createSelector(
  getSearchParams,
  (searchParams) => searchParams?.start_date,
);

/**
 * Первоначальная дата для поиска YYYY-MM-DD (при флексовом поиске)
 */
export const searchParamsPrimaryStartDateSelector = createSelector(
  getSearchParams,
  (searchParams) => searchParams?.start_date_primary,
);

// НОЧИ
/**
 * Возвращает объект с количеством ночей
 * @returns {object} {from: 7, to: 7}
 */
export const getSearchParamsNightsObject = createSelector(
  [getSearchParams],
  (searchParams) => searchParams?.nights,
);

/**
 * Возвращает количество ночей одним числом
 */
export const getSearchParamsNights = createSelector(
  [getSearchParamsNightsObject],
  (nightsObject) => getNightsFromObject(nightsObject),
);

/**
 * Возвращает количество минимальное количество флексовых ночей
 */
export const nightsFromSelector = createSelector(
  [getSearchParamsNightsObject],
  (nights) => nights?.from,
);

/**
 * Возвращает количество максимальное количество флексовых ночей
 */
export const nightsToSelector = createSelector(
  [getSearchParamsNightsObject],
  (nights) => nights?.to,
);

/**
 * Ранг ночей
 */
export const rangeNightsSelector = createSelector(
  [nightsFromSelector, nightsToSelector],
  (nightsFrom, nightsTo) =>
    nightsTo === nightsFrom ? nightsFrom : `${nightsFrom}...${nightsTo}`,
);

// ТУРИСТЫ
/**
 * Объект возраст детей
 */
export const getSearchParamsKidsAges = createSelector(
  getSearchParams,
  (searchParams) => searchParams?.kids.ages,
);

/**
 * Возраст детей через запятую "2,3,5"
 */
export const getSearchParamsKidsAgesText = createSelector(
  getSearchParamsKidsAges,
  (kidsAges) => (kidsAges ? kidsAges.join(',') : ''),
);

/**
 * Количество детей
 */
export const getSearchParamsKidsCount = createSelector(
  [getSearchParams],
  (searchParams) => searchParams?.kids.count,
);

/**
 * Количество взрослых туристов
 */
export const getSearchParamsAdults = createSelector(
  [getSearchParams],
  (searchParams) => searchParams?.adults,
);

/**
 * Общее количество туристов
 * дети + взрослые
 */
export const getSearchParamsTouristsCount = createSelector(
  [getSearchParamsAdults, getSearchParamsKidsCount],
  (adults, kids) => (adults && kids ? adults + kids : 0),
);

// НАПРАВЛЕНИЕ
/**
 * Объект направление
 */
export const getSearchParamsTo = createSelector(
  [getSearchParams],
  (searchParams) => searchParams?.to,
);

/**
 * Возвращает город, если он указан в поисковом запросе или страну
 */
export const getSearchParamsDestination = createSelector(
  [getSearchParamsTo],
  (destination) => {
    if (!destination) {
      return null;
    }

    const { city, country } = destination;
    return city?.id ? city.name_ru : country?.name_ru;
  },
);

/**
 * Определение что имя города
 * отличное от 'Any'
 */
export const isSearchParamsRegionSelector = createSelector(
  getSearchParams,
  (searchParams) => searchParams?.to?.city?.name_en !== 'Any',
);

/**
 * Объект города прилета
 */
export const getSearchParamsToCity = createSelector(
  getSearchParams,
  (searchParams) => searchParams?.to?.city,
);

/**
 * Город прилета en
 */
export const getSearchParamsToCityEn = createSelector(
  getSearchParams,
  (searchParams) => searchParams?.to?.city?.name_en,
);

/**
 * Город прилета ru
 */
export const getSearchParamsToCityRu = createSelector(
  getSearchParams,
  (searchParams) => searchParams?.to?.city?.name_ru,
);

/**
 * Страна прилета ISO2
 */
export const getSearchParamsToCountry = createSelector(
  getSearchParams,
  (searchParams) => searchParams?.to?.country?.iso2,
);

/**
 * Страна прилета id
 */
export const getSearchParamsToCountryId = createSelector(
  getSearchParams,
  (searchParams) => searchParams?.to?.country?.id,
);

/**
 * Страна прилета en
 */
export const getSearchParamsToCountryNameEn = createSelector(
  getSearchParams,
  (searchParams) => searchParams?.to?.country?.name_en,
);

/**
 * Страна прилета ru
 */
export const getSearchParamsToCountryNameRu = createSelector(
  getSearchParams,
  (searchParams) => searchParams?.to?.country?.name_ru,
);

// ВЫЛЕТ ИЗ
/**
 * Город вылета en
 */
export const getSearchParamsFromCity = createSelector(
  getSearchParams,
  (searchParams) => searchParams?.from?.city?.name_en,
);

/**
 * Страна вылета ru
 */
export const getSearchParamsFromCountryNameRu = createSelector(
  getSearchParams,
  (searchParams) => searchParams?.from?.country?.name_ru,
);

/**
 * Город вылета id
 */
export const getSearchParamsFromCityId = createSelector(
  getSearchParams,
  (searchParams) => searchParams?.from?.city?.id,
);

/**
 * Город вылета name_ru_form2
 * @example из Перьми
 */
export const destinationCityNameForm2Selector = createSelector(
  getSearchParams,
  (searchParams) => {
    const nameForm2 = searchParams?.from?.city?.name_ru_form2;
    return `из ${nameForm2 || ''}`;
  },
);

/**
 * Страна вылета ISO2
 */
export const getSearchParamsFromCountry = createSelector(
  getSearchParams,
  (searchParams) => searchParams?.from?.country?.iso2,
);

/**
 * Страна вылета id
 */
export const getSearchParamsFromCountryId = createSelector(
  getSearchParams,
  (searchParams) => searchParams?.from?.country?.id,
);

// ПАРАМЕТРЫ
/**
 * Гибкие дни
 */
export const getSearchParamsFlexDates = createSelector(
  [getSearchParams],
  (searchParams) => {
    const flexDates = searchParams?.options.flex_dates;
    return Boolean(flexDates);
  },
);
/**
 * Гибкие ночи
 */
export const getSearchParamsFlexNights = createSelector(
  [getSearchParamsNights],
  (nights) => isFlexNights(nights),
);

/**
 * инициализация дат новой сигареты
 */
export const cigaretteDatepickerSelector = createSelector(
  [getAppName, getSearchParams],
  (appName, searchParams): InitialValues['datepicker'] => {
    // на эксплоре мы не подставляем дату, потому что точные даты лучше не передавать
    if (
      !searchParams ||
      !searchParams.start_date ||
      !searchParams.nights ||
      appName === MODULE_NAME.SEO
    )
      return undefined;
    const { options, nights, start_date } = searchParams;
    const calcFlexDates = () => {
      if (!options) {
        return 0;
      }
      if (typeof options.flex_dates === 'number') {
        return options.flex_dates;
      }
      if (options.flex_dates) {
        return 2;
      }
      return 0;
    };
    const flexDates = calcFlexDates();
    const rightDate = nights
      ? addDays(new Date(start_date), nights.from)
      : null;

    const leftDateCell = getDateCell(start_date);
    const rightDateCell = getDateCell(rightDate);

    const datepicker: InitialValues['datepicker'] = {
      left: leftDateCell,
      right: rightDateCell,
      inputFocuses: {
        isStartDateFocus: false,
        isEndDateFocus: false,
      },
      shift: flexDates,
      buttonsClicked: [],
      initialTab: 'calendar',
    };

    return datepicker;
  },
);

/**
 * инициализация новой сигареты
 */
export const cigaretteValuesSelector = createSelector(
  [getSearchParams, cigaretteDatepickerSelector],
  (
    searchParams,
    datepicker?: InitialValues['datepicker'],
  ): InitialValues | undefined => {
    if (!(searchParams && searchParams.to)) return undefined;

    const destination = getDestinationParams(searchParams.to);

    const tourists: InitialValues['tourists'] = {
      adults: searchParams?.adults || 0,
      kids: searchParams.kids.ages,
    };

    const departure: InitialValues['departure'] =
      searchParams?.from?.city?.name_en === 'Any'
        ? getDefaultDeparture()
        : {
            id: searchParams.from.city?.id,
            iso2: searchParams.from.city?.iso2,
            label: searchParams.from.city?.name_ru,
            value: searchParams.from.city?.name_en,
            selectedLabel: searchParams.from.city?.name_ru_form2,
          };

    return { destination, datepicker, tourists, departure };
  },
);
