import { createSelector } from '@reduxjs/toolkit';

import type { ViewStateType } from 'checkout/store/view/types';
import {
  getFromImmutableStateCompatible,
  getImmutableCompatible,
} from 'src/utils/immutableUtils';
import { FLIGHT_CLASSES } from '../../CheckoutRoutes/PackageCheckout/store/flights/constants';

const _getView = (state) => getImmutableCompatible(state, 'view');
const getView = getFromImmutableStateCompatible<ViewStateType>(_getView);

export const getIsExpanded = createSelector(getView, (view) =>
  Boolean(view.isMapExpanded),
);

export const getFlightClass = createSelector(
  getView,
  (view) => view.selectedClass || FLIGHT_CLASSES.ECONOMY,
);

export const getIsWhitelabel = createSelector(
  getView,
  (view) => view.isWhitelabel,
);

export const getIsWebGlSupported = createSelector(getView, (view) =>
  Boolean(view.isWebGlSupported),
);

export const getIsRouteTransition = createSelector(
  getView,
  (view) => view.isRouteTransition,
);

export const getActiveTouristFormIdx = createSelector(
  getView,
  (view) => view.activeTouristForm,
);

export const getIsTouristFormActive = createSelector(
  [getActiveTouristFormIdx, (_: ViewStateType, id: number) => id],
  (activeTouristForm, id) => activeTouristForm === id,
);

export const makeGetIsTouristFormActive = () => getIsTouristFormActive;

export const getIsTopbarExpanded = createSelector(
  getView,
  (view) => view.isTopbarExpanded,
);

export const getTopbarIndex = createSelector(
  getView,
  (view) => view.topbarIndex,
);
export const getShowFiltersModal = createSelector(getView, (view) =>
  Boolean(view.showFiltersModal),
);

export const getIsFlightsProgressBarSticky = createSelector(
  getView,
  (view) => view.flightsProgressBarIsSticky,
);

export const getShowSummary = createSelector(getView, (view) =>
  Boolean(view.showSummary),
);
