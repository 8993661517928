import React from 'react';
import { isSSR } from 'src/helpers/ssr';
import styles from './MiddleSection.module.css';
import DownloadAppSection from './DownloadAppSection';
import SocialLinks from './SocialLinks';

const MiddleSection = () => (
  <div className={styles.middleSection}>
    {!isSSR && <DownloadAppSection />}
    <SocialLinks />
  </div>
);

export default MiddleSection;
