import { connect } from 'react-redux';

import { getIsWhitelabel } from 'src/store/view/selectors';

import { getHeaderBackground } from 'src/store/menu/selectors';

import BackgroundImage from './BackgroundImage';

const mapStateToProps = (state) => ({
  isShowTitle: getIsWhitelabel(state),
  backgroundImage: getHeaderBackground(state),
});

export default connect(mapStateToProps)(BackgroundImage);
