// кука ответственная за управление отображением банера
export const KEY_COOKIE = 'lt_cookies_banner';
// id для тестирования через testing-library
export const DATA_TEST_ID = 'cookies-banner';
// id для тестирования кнопки закрытия
export const BUTTON_DATA_TEST_ID = 'cookies-banner-button';

// ссылка на соглашение на обработку данных и использование Cookie
export const COOKIE_AGREEMENT =
  'https://level.travel/articles/cookies_agreement';
// ссылка на соглашение применения рекомендательных технологий
export const RECOMENDATION_TECHNOLOGIES_AGREEMENT =
  'https://level.travel/articles/recommendation_technologies_agreement';
