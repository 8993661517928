import type { Values } from '@lt/widget/components/Cigarette/context';

import { EMPTY_DATE } from 'src/utils/cigaretteUtils';
import type { SearchType } from 'src/types/search';

export const getDurationTripProps = (
  datepicker: Values['datepicker'],
  searchType: SearchType,
) => {
  const left = datepicker?.left ?? EMPTY_DATE;
  const right = datepicker?.right ?? EMPTY_DATE;

  return {
    leftValue: left,
    rightValue: right,
    shift: datepicker?.shift ?? 0,
    openCalendar: () => null,
    searchType,
    isStartDateFocus: false,
    isEndDateFocus: false,
  };
};
