import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice, createAction } from '@reduxjs/toolkit';
import type { PrebookStateType } from './types';

const initialState: PrebookStateType = {
  success: false,
  tryCount: 0,
  bookingId: null,
  error: null,
  inProgress: false,
  pending: false,
};

export const name = 'packagePrebook';

// reducer
const packagePrebook = createSlice({
  name,
  initialState,
  reducers: {
    incrementTryCount(state) {
      state.tryCount += 1;
    },
    clearTryCount(state) {
      state.tryCount = 0;
    },
    clear() {
      return initialState;
    },
    setSuccess(state, action: PayloadAction<boolean>) {
      state.success = action.payload;
    },
    setBookingId(state, action: PayloadAction<string | null>) {
      state.bookingId = action.payload;
    },
    setInProgress(state, action: PayloadAction<boolean>) {
      state.inProgress = action.payload;
    },
    setPending(state, action: PayloadAction<boolean>) {
      state.pending = action.payload;
    },
  },
});

// actions
export const {
  incrementTryCount,
  clear,
  setSuccess,
  setBookingId,
  clearTryCount,
  setInProgress,
  setPending,
} = packagePrebook.actions;

export const onPrebookFailed = createAction(`${name}/onPrebookFailed`);
export const onTryPrebook = createAction(`${name}/onTryPrebook`);
export const onPrebookSuccess = createAction(`${name}/onPrebookSuccess`);
export const onPrebookTryFailed = createAction(`${name}/onPrebookTryFailed`);
export const onPrebookSkip = createAction(`${name}/onPrebookSkip`);

export default packagePrebook.reducer;
