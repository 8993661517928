/* eslint-disable i18next/no-literal-string */
import React from 'react';
import { Link } from '@lt/components';
import { i18n } from '@lt/localization';
import Column from '../Column';

const OperatorsColumn = () => (
  <Column title={i18n.t('footer.toursTitle')}>
    <li>
      <Link href="/explore/Moscow-Russia/any/anex_tour">Anex Tour</Link>
    </li>
    <li>
      <Link href="/explore/Moscow-Russia/any/biblio_globus">Biblio Globus</Link>
    </li>
    <li>
      <Link href="/explore/Moscow-Russia/any/coral_travel">Coral Travel</Link>
    </li>
    <li>
      <Link href="/explore/Moscow-Russia/any/leveltravel">Level.Travel</Link>
    </li>
    <li>
      <Link href="/explore/Moscow-Russia/any/pegas">Pegas Touristik</Link>
    </li>
    <li>
      <Link href="/explore/Moscow-Russia/any/funsun">Fun&Sun</Link>
    </li>
    <li>
      <Link href="/explore/Moscow-Russia/any/sunmar">Sunmar</Link>
    </li>
    <li>
      <Link href="/explore/Moscow-Russia/any/tez_tour">Tez Tour</Link>
    </li>
    <li>
      <Link href="/explore/Moscow-Russia/any/alean">Алеан</Link>
    </li>
  </Column>
);

export default OperatorsColumn;
