import {
  BIG_COUNTRY_CARD_WIDTH,
  DEFAULT_COUNTRY_CARD_WIDTH,
  SCROLL_BUTTON_WIDTH,
  METAREGION_CARD_WIDTH,
} from './constants';
import type { ScrollDirection } from './types';

/**
 * Функция для получения величины скролла в блоке стран на 2 карточки.
 *
 * В блоке стран первая карточка шире остальных, поэтому нужно учесть
 * кейс, когда эта карточка отображается, на основе текущей позиции
 * скролла.
 */
const getScrolledCountryCardsWidth = (curPosition: number) => {
  const isFirstCardVisible =
    curPosition < BIG_COUNTRY_CARD_WIDTH + SCROLL_BUTTON_WIDTH;

  return isFirstCardVisible
    ? BIG_COUNTRY_CARD_WIDTH + DEFAULT_COUNTRY_CARD_WIDTH
    : DEFAULT_COUNTRY_CARD_WIDTH * 2;
};

const getScrolledCardsWidth = (curPosition: number, isMetaregion: boolean) =>
  isMetaregion
    ? METAREGION_CARD_WIDTH * 3
    : getScrolledCountryCardsWidth(curPosition);

const getScrollLeftAmount = (curPosition: number, scrolledCardsWidth: number) =>
  Math.max(0, curPosition - scrolledCardsWidth - SCROLL_BUTTON_WIDTH * 2);

export const getNewScrollPosition = (
  curPosition: number,
  direction: ScrollDirection,
  isMetaregion: boolean,
): number => {
  const scrolledCardsWidth = getScrolledCardsWidth(curPosition, isMetaregion);

  const newScrollPosition =
    direction === 'left'
      ? getScrollLeftAmount(curPosition, scrolledCardsWidth)
      : curPosition + scrolledCardsWidth;

  return newScrollPosition;
};
