import { createSelector } from '@reduxjs/toolkit';
import type { ExtraItem } from 'src/routes/Checkout/types/extras';
import { mapValues, minBy, uniq } from 'lodash';
import { getIsMobile } from 'src/store/view/selectors';
import { BIBLIO_GLOBUS_ID } from 'checkout/CheckoutRoutes/PackageCheckout/components/Flights/FlightChangeWarning/constants';
import { name } from './slice';

import { loadingStateSelector } from '../flights/selectors';
import { getNetPrice } from '../../../../store/selectors/package';
import { getIsHomePath } from '../../../../store/selectors/router';
// eslint-disable-next-line import/no-cycle
import { getMandatoryExtras } from '../../../../store/selectors/services';
import { getPackagePriceWithAlternatives } from '../actualize-alternatives/selectors';
import { MIN_NUM_OF_FLIGHTS_TO_SHOW_FILTERS, nestedFilters } from './constants';
import type { FlightsFilter, FlightsFiltersSlice } from './types';
import { LOADING_STATE } from '../flights/constants';

/**
 * Возвращает объект - всю информацию внутри слайса фильтров авиа
 */
export const getFlightsFiltersSlice = (state) => state[name];

/**
 * Возвращает объект - информацию по фильтрам авиа
 */
export const getRawFlightsFiltersData = (state) =>
  getFlightsFiltersSlice(state)?.filters;

/**
 * Возвращает объект - информацию по конкретному фильтру авиа
 */
export const getRawFlightsFilterData = (state, filterName: string) =>
  getRawFlightsFiltersData(state)[filterName];

/**
 * Селектор возвращающий объект фильтров без вложенной логики, все фильтры преобразуются в массивы
 */
export const getFlightsFiltersData = (state) =>
  Object.fromEntries(
    Object.entries(getRawFlightsFiltersData(state)).map(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ([filterName, filterValues]: any[]) => {
        if (nestedFilters.includes(filterName)) {
          return [filterName, filterValues.airports];
        }
        return [filterName, filterValues];
      },
    ),
  );

/**
 * Возвращает массив опций фильтров
 */
export const getFlightsFiltersValuesArray = (state) =>
  Object.values(getFlightsFiltersData(state));

/**
 * Возвращает опции фильтра по его имени
 */
export const getFlightsFilterData = (state, filterName: string) =>
  getFlightsFiltersData(state)[filterName];

/**
 * Возвращает булевую переменнную - есть ли примененные фильтры
 */
export const getHasActiveFilters = (state) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getFlightsFiltersValuesArray(state).some((filterOptions: any) =>
    filterOptions.some((filterOption) => filterOption.selected),
  );

/**
 * Возвращает цену перелета с учетом сбора за топливо
 */
export const getMinPriceForFlight = (state, filterOptionData: FlightsFilter) =>
  filterOptionData.min_fuel_charge + getNetPrice(state);

/**
 * Возвращает аэропорт/аэропорты вылета/прилета
 */
export const getAirportCitiesForFilter = (state, filterName: string) => {
  const filterData = getRawFlightsFilterData(state, filterName);
  const { city, cities } = filterData;
  return city || cities;
};

/**
 * Возвращает количество полетов без примененных фильтров
 */
export const getUnfilteredFlightsCount = (state) =>
  getFlightsFiltersSlice(state).unfilteredFlightsCount || 0;

/**
 * Возвращает булевую переменнную - нужно ли показывать элемент "Не были найдены полеты с такими фильтрами"
 */
export const getShowEmptyFlightsSkeleton = (state) =>
  !getIsMobile(state) &&
  loadingStateSelector(state) === LOADING_STATE.EMPTY_FILTRATION;

/**
 * Возвращает количество выбранных фильтров
 */
export const getSelectedFiltersCount = (state) =>
  Object.entries(getFlightsFiltersData(state)).filter(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ([, filterValue]: [string, any]) =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      filterValue.some((option: any) => option.selected),
  ).length;

/*
  Загрузка списка рейсов происходит, либо если мы загружаем рейсы,
  либо если происходит процесс актуализации, либо если применены фильтры,
  
*/
export const isFlightListLoading = createSelector(
  [loadingStateSelector],
  (loadingState) => loadingState === LOADING_STATE.IS_LOADING,
);

export const isInitialFlightsFetchingDoneSelector = createSelector(
  [getFlightsFiltersSlice],
  (slice): boolean => slice.isInitialFlightsFetchingDone,
);

/**
 * Возвращает булевую переменнную - нужно ли показывать фильтры в сайдбаре
 */
export const showFiltersSelector = createSelector(
  [
    getUnfilteredFlightsCount,
    isInitialFlightsFetchingDoneSelector,
    getIsHomePath,
  ],
  (flightsCount, isInitialFlightsFetchingDone, isHomePath) =>
    flightsCount >= MIN_NUM_OF_FLIGHTS_TO_SHOW_FILTERS &&
    isInitialFlightsFetchingDone &&
    isHomePath,
);

export const preselectedFiltersSelector = createSelector(
  [getFlightsFiltersSlice],
  (slice: FlightsFiltersSlice) => slice.preselectedFilters,
);

/**
 * Возвращает данные по фильтрам для запроса к серверу в формате объекта с полями формата filterName: [ChosenFilterValue]
 */
export const flightsFiltersDataForRequestSelector = createSelector(
  [getFlightsFiltersData, preselectedFiltersSelector],
  (filtersData, preselectedFilters) =>
    mapValues(filtersData, (filterOptions, filterName) => {
      const selectedOptionIds = filterOptions
        .filter((filterOption) => filterOption.selected)
        .map((option) => option.id);

      const preselectedOptionIds = preselectedFilters[filterName] || [];
      const allSelectedIds = [...selectedOptionIds, ...preselectedOptionIds];
      const uniqueOptionIds = uniq(allSelectedIds);

      return uniqueOptionIds;
    }),
);

/*
    Узнать используется ли фильтрация по БиблиоГлобусу
*/
export const getBiblioGlobusFilterSelector = createSelector(
  getFlightsFiltersData,
  (filtersData): FlightsFilter => {
    const bgFilter = filtersData.operators.find(
      (filt) => filt.id === BIBLIO_GLOBUS_ID,
    );
    return bgFilter;
  },
);
/**
 * Минимальная цена для отображения на фильтрах вместе с соседними рейсами
 */
export const minPriceForFilterByTourIdSelector = createSelector(
  [
    (state) => (tourId: string) =>
      getPackagePriceWithAlternatives(state, tourId),
    (_state, filterOptionData) => filterOptionData,
    (state) => (tourId: string) => getMandatoryExtras(state, tourId),
  ],
  (
    getPackagePriceByTourId,
    filterOptionData: FlightsFilter,
    getMandatoryExtrasByTourId: (tourId) => ExtraItem[],
  ) => {
    const minEntry = minBy(
      Object.entries(filterOptionData.min_fuel_charge_by_tours),
      ([currentTourId, currentMinFuelCharge]) =>
        Number(currentMinFuelCharge) +
        getPackagePriceByTourId(currentTourId) +
        getMandatoryExtrasByTourId(currentTourId).reduce(
          (res, extra) => res + Number(extra?.price || 0),
          0,
        ),
    );
    if (!minEntry) return undefined;

    const [minTourId, minFuelCharge] = minEntry;
    return (
      Number(minFuelCharge) +
      getPackagePriceByTourId(minTourId) +
      getMandatoryExtrasByTourId(minTourId).reduce(
        (res, extra) => res + Number(extra?.price || 0),
        0,
      )
    );
  },
);
