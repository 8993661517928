import { createAction } from '@reduxjs/toolkit';
import { name } from './slice';
import type { PaymentFailPayload } from './types';

export const initPayment = createAction(`${name}/initPayment`);
export const payUniteller = createAction(`${name}/payUniteller`);
export const confirmPayment = createAction(`${name}/confirmPayment`);
export const submitCres = createAction<string>(`${name}/submitCres`);
export const submitPares = createAction<string>(`${name}/submitPares`);

export const paymentFailed = createAction<PaymentFailPayload>(
  `${name}/paymentFailed`,
);
