import { createSlice } from '@reduxjs/toolkit';

import type { IHomeDisplayFlags } from './types';

export const initialState: IHomeDisplayFlags = {
  isStatisticWidgetDisabled: false,
};

const homeDisplayFlagsSlice = createSlice({
  name: 'homeDisplayFlags',
  initialState,
  reducers: {},
});

const { name, reducer } = homeDisplayFlagsSlice;

export { reducer as default, name };
