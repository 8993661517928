import { api } from 'src/api';
import { getUserId } from 'src/utils/getUserId';
import { APPLICATION_VERSION } from 'src/error/config/sentry/runTimeConfig';

type EventType = {
  GOOGLE_TRANSLATE: 'google_translate';
};

export const sendCommonEvents = async (
  eventName: EventType[keyof EventType],
  value: string,
) => {
  try {
    const clientData = { uuid: getUserId() };
    if (!APPLICATION_VERSION)
      throw new Error('No application version provided!');
    const searchType = window.SEARCH_PARAMS && window.SEARCH_PARAMS.search_type;
    await api.postStatsEvent({
      url: window.location.href,
      event: eventName,
      client: clientData,
      is_mobile: window.is_mobile,
      release_version: APPLICATION_VERSION,
      search_type: searchType,
      value,
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
};
