import { createSelector } from '@reduxjs/toolkit';

import { getImmutableCompatible } from 'src/utils/immutableUtils';
import { WISHLIST_COUNTER_LIMIT } from './constants';
import { name } from './slice';
import type { WishlistStore } from './types';

export const getWishlistState = (state): WishlistStore =>
  getImmutableCompatible(state, name);

export const getWishlistItemsSelector = createSelector(
  [getWishlistState],
  (wishlist) => wishlist.list,
);

export const getWishlistItemsCounterSelector = createSelector(
  [getWishlistState],
  (wishlist) => {
    const count = wishlist?.list?.length;

    if (!count) {
      return null;
    }

    const counter =
      count > WISHLIST_COUNTER_LIMIT ? WISHLIST_COUNTER_LIMIT : count;

    return counter;
  },
);

export const getWishlistIdSelector = createSelector(
  [getWishlistState],
  (wishlist) => wishlist.id,
);

export const isActiveSelector = createSelector(
  [getWishlistItemsSelector, (_state, identifier: number) => identifier],
  (wishlistItems, identifier) =>
    Boolean(
      wishlistItems.find((item) =>
        item.hotelId
          ? identifier === item.hotelId
          : identifier === item.packageId,
      ),
    ),
);

export const activeItemIdSelector = createSelector(
  [getWishlistItemsSelector, (_state, identifier: number) => identifier],
  (wishlistItems, identifier): number | undefined =>
    wishlistItems.find((item) =>
      item.hotelId
        ? identifier === item.hotelId
        : identifier === item.packageId,
    )?.id,
);

export const isSearchInWishlistSelector = createSelector(
  [getWishlistItemsSelector],
  (wishlistItems) =>
    Boolean(
      wishlistItems.find(
        (item) =>
          item.type === 'search' && item.link === window.location.pathname,
      ),
    ),
);

export const getWishlistItemByIdSelector = createSelector(
  [getWishlistItemsSelector, (_state, id: number) => id],
  (items, id) => items.find((item) => item.id === id),
);

export const wishlistFiltersSelector = createSelector(
  [getWishlistState],
  (state) => state.filters,
);

export const isWishlistFetchedSelector = createSelector(
  [getWishlistState],
  (state) => state.isWishlistFetched,
);

export const isOnboardingShowedSelector = createSelector(
  [getWishlistState],
  (state) => state?.showOnboarding,
);
