import {
  PAYMENT_METHOD_PAYLATE,
  PAYMENT_METHOD_PAYLATE_120,
  PAYMENT_GROUPS,
} from './constants';

export const getPaymentGroup = (paymentType) => {
  switch (paymentType) {
    case PAYMENT_METHOD_PAYLATE:
    case PAYMENT_METHOD_PAYLATE_120:
      return PAYMENT_GROUPS.PAYLATE;
    default:
      return PAYMENT_GROUPS.CARD;
  }
};

export const isPaylate = (paymentType) =>
  getPaymentGroup(paymentType) === PAYMENT_GROUPS.PAYLATE;
