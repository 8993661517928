import { FlightSkeletonLine } from '@lt/components/icons-react/userflow/flights/skeleton';
import React from 'react';
import { fadeAnimation } from 'src/components/animations/SkeletonFadeInAndOut';
import styled, { css } from 'styled-components';
import { NumbersGrid, StyledNumber } from './ChooseRating';
import { NPS_SKELETON_TEST_ID } from '../constants';

type Props = {
  isMobile: boolean;
};

const NpsSkeleton = (props: Props) => {
  const { isMobile } = props;
  return (
    <StyledContainer $isMobile={isMobile} data-testid={NPS_SKELETON_TEST_ID}>
      <StyledFirstLine width={260} $isMobile={isMobile} viewBox="0 0 260 1" />
      {isMobile && (
        <>
          <StyledFirstLine
            width={260}
            $isMobile={isMobile}
            viewBox="0 0 260 1"
          />
          <StyledFirstLine
            width={260}
            $isMobile={isMobile}
            viewBox="0 0 260 1"
          />
        </>
      )}
      <StyledSkeletonLine width={180} viewBox="0 0 180 1" />
      <div>
        {Array.from({ length: 10 }).map((_, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={idx}>
            <StyledNumber />
          </div>
        ))}
      </div>
    </StyledContainer>
  );
};

const StyledContainer = styled.div<{ $isMobile: boolean }>`
  ${({ $isMobile }) =>
    $isMobile
      ? css`
          padding: 24px 0 16px;
          & > div:last-child {
            height: 222px;
          }
        `
      : css`
          padding: 24px;
          height: 228px;
        `};
  display: flex;
  flex-direction: column;
  align-items: center;
  ${fadeAnimation}
  & > div:last-child {
    display: flex;
    ${NumbersGrid}
  }
`;

const StyledSkeletonLine = styled(FlightSkeletonLine)`
  > path {
    stroke: #e6e6e6;
    d: path('M260 0.5H0');
  }
  padding-bottom: 32px;
`;

const StyledFirstLine = styled(StyledSkeletonLine)<{ $isMobile: boolean }>`
  padding: ${({ $isMobile }) => ($isMobile ? `0 0 24px` : `16px 0 24px`)};
`;

export default NpsSkeleton;
