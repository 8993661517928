import type { EnqueueSearchParams } from 'src/store/enqueue/types';
import { createAction } from '@reduxjs/toolkit';
import * as searchConstants from '../constants/search';

export const startSearchAction = (payload?: StartSearchActionPayload) => ({
  type: searchConstants.START_SEARCH_PROCESS,
  payload,
});

export const searchSuccessAction = (payload) => ({
  type: searchConstants.SEARCH_SUCCESS,
  payload,
});

export const searchEnrichedtAction = () => ({
  type: searchConstants.SEARCH_ENRICHED,
});

export const searchTimedOutAction = () => ({
  type: searchConstants.SEARCH_TIMED_OUT,
});

export const changeSearchParamsAction = createAction<unknown>(
  searchConstants.CHANGE_SEARCH_PARAMS,
);

export const refreshEmptySearchParamsAction =
  (): RefreshEmptySearchParamsAction => ({
    type: searchConstants.REFRESH_EMPTY_SEARCH_PARAMS,
  });

export type RefreshEmptySearchParamsAction = {
  type: typeof searchConstants.REFRESH_EMPTY_SEARCH_PARAMS;
};

export type StartSearchActionPayload = {
  requestId: string | null;
  searchParams?: EnqueueSearchParams | null;
  errorsCount?: number;
};
