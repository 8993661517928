import { createSlice } from '@reduxjs/toolkit';

interface IFeedbackStore {
  showPopup: boolean;
}

const initialState: IFeedbackStore = {
  showPopup: false,
};

const feedbackSlice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    showPopup: (state) => {
      state.showPopup = true;
    },
    hidePopup: (state) => {
      state.showPopup = false;
    },
  },
});

const { reducer, actions, name } = feedbackSlice;

export const { showPopup, hidePopup } = actions;

export { reducer as default, actions, name, IFeedbackStore };
