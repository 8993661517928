/**
 * Времення функция, маппит массив строк с услугами в объекты с рандомным параметром best
 * @param {String[]} facilities
 * @param {Boolean} setRandomBest
 * @returns {Object[]}
 */

const best = (setRandomBest, length) =>
  length > 5 && setRandomBest ? Math.round(Math.random() * 10) > 5 : false;

function transformAnyFacilities({
  items,
  setRandomBest,
  filterComparator,
  mapper,
}) {
  if (!items) {
    return null;
  }

  const { length } = items;

  // сервер возвращает массив с пустыми строками для незаполненного блока удобств
  // отфильтруем их
  const filteredItems = items.filter(filterComparator);

  if (!filteredItems.length) {
    return null;
  }

  return filteredItems.map((item) => ({
    ...mapper(item),
    best: best(setRandomBest, length),
  }));
}

function transformFacilities(items, setRandomBest = false) {
  return transformAnyFacilities({
    items,
    setRandomBest,
    filterComparator: (item) => Boolean(item),
    mapper: (item) => ({
      text: item,
    }),
  });
}

function transformFacilitiesWithIcons(items, setRandomBest = false) {
  return transformAnyFacilities({
    items,
    setRandomBest,
    filterComparator: (item) => Boolean(item.name),
    mapper: (item) => ({
      text: item.name,
      icon: item.group_identifier,
    }),
  });
}

export { transformFacilities, transformFacilitiesWithIcons };
