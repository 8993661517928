import { formatDateToDayShort } from './dateUtils';
import { pluralizeAdults, pluralizeNights } from './pluralizeUtils';

// TODO Описать типы для Order
// https://leveltravel.atlassian.net/browse/LT-22315
export const getOrderHeaderLabel = (order: any) => `Заказ №${order?.id}`;

export const getOrderDescription = (order: any) => {
  const nights = order?.package?.nights_count;
  const startDate = order?.package?.dates_info?.start_date || new Date();
  const adults = order?.package?.adults_count;

  return `${nights} ${pluralizeNights(nights)} с ${formatDateToDayShort(
    startDate,
  )} / ${adults} ${pluralizeAdults(adults)}`;
};
