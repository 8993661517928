import { connect } from 'react-redux';

import { switchTabAction } from 'src/store/analytics/slice';
import {
  getAvailableSearchTypes,
  getIsMobile,
  getIsWhitelabel,
} from 'src/store/view/selectors';
import SearchTypeTabs from './SearchTypeTabs';

const mapStateToProps = (state) => ({
  availableSearchTypes: getAvailableSearchTypes(state),
  isMobile: getIsMobile(state),
  isWhitelabel: getIsWhitelabel(state),
});

const mapDispatchToProps = {
  switchTab: switchTabAction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(SearchTypeTabs);
