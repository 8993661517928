import { connect } from 'react-redux';

import {
  getClientName,
  getShowAuthPopup,
  getAvatar,
  getClientBonusScore,
  getClientUnconfirmedPhone,
  getConfirmedAt,
} from 'src/store/client/selectors';

import {
  logInAction,
  onLoadAction,
  signUpAction,
  logOutAction,
  setClientConfirmPhone,
  hideLogOutAction,
  hideAuthAction,
} from 'src/store/client/actions';

import { getIsWhitelabel } from 'src/store/view/selectors';
import Auth from './Auth';

const mapStateToProps = () => (state) => ({
  isOpen: getShowAuthPopup(state),
  clientName: getClientName(state),
  clientAvatar: getAvatar(state),
  clientUnconfirmedPhone: getClientUnconfirmedPhone(state),
  isConfirmedClientEmail: getConfirmedAt(state),
  bonusScore: getClientBonusScore(state),
  isWhiteLabel: getIsWhitelabel(state),
});

const mapDispatchToProps = (dispatch) => ({
  onLoad: () => dispatch(onLoadAction()),
  logIn: (data) => dispatch(logInAction(data)),
  signUp: (data) => dispatch(signUpAction(data)),
  logOut: () => dispatch(logOutAction()),
  onCloseAuthPopup: () => dispatch(hideAuthAction()),
  hideLogOutPopup: () => dispatch(hideLogOutAction()),
  setConfirmedPhone: (data) => dispatch(setClientConfirmPhone(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
