import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import sagas from '../sagas';
import rootReducer from './rootReducer';

import { initialState } from './homeDisplayFlags/slice';

import { popularDestinationsOffersDTO } from './popularDestinationsOffers/popularDestinationsOffersDTO';
import { popularMetaregionsOffersDTO } from './popularMetaregionsOffers/popularMetaregionsOffersDTO';

import type { ICountry } from './countries/types';
import type { IHomeDisplayFlags } from './homeDisplayFlags/types';
import type { ILinkedHotel } from './linkedHotels/types';
import type { INarrativeCard } from './narrativeCards/types';

const sagaMiddleware = createSagaMiddleware();

interface IProps {
  isMobile: boolean;
  isWhitelabel?: boolean;
  countries?: ICountry[];
  homeDisplayFlags?: IHomeDisplayFlags;
  linkedHotels?: ILinkedHotel[] | null;
  narrativeCards?: INarrativeCard[] | null;
  popularDestinationsOffers?: unknown;
  popularMetaregionsOffers?: unknown;
  isAppBannerEnabled: boolean;
}

export const getStore = ({
  isMobile,
  isWhitelabel,
  countries = [],
  homeDisplayFlags = initialState,
  linkedHotels = [],
  narrativeCards = [],
  popularDestinationsOffers = [],
  popularMetaregionsOffers = [],
  isAppBannerEnabled,
}: IProps) => {
  // TODO: Разобраться, почему не соответствуют типы
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const preloadedState: any = {
    view: {
      isMobile,
      isWhitelabel,
      isAppBannerEnabled,
    },
    countries: {
      list: countries || [],
    },
    homeDisplayFlags,
    linkedHotels: {
      list: linkedHotels || [],
    },
    narrativeCards: {
      list: narrativeCards || [],
    },
    popularDestinationsOffers: popularDestinationsOffersDTO(
      popularDestinationsOffers,
    ),
    popularMetaregionsOffers: popularMetaregionsOffersDTO(
      popularMetaregionsOffers,
    ),
  };

  const store = configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        // нужно, чтобы выключить проверку стора на сериалайз,
        // поскольку в некоторых редюсерах initial state обёрнут в immutable, а в некоторых нет
        // (как уйдем с immutable, можно будет убрать)
        serializableCheck: false,
      }).concat(sagaMiddleware),
  });

  sagaMiddleware.run(sagas);

  return store;
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = ReturnType<typeof getStore>['dispatch'];
