import React from 'react';
import { Link } from '@lt/components';
import { i18n } from '@lt/localization';
import Column from '../Column';

const HelpColumn = () => (
  <Column title={i18n.t('footer.help.title')}>
    <li>
      <Link href="https://promo.level.travel/how-to-buy">
        {i18n.t('footer.help.howToBookTour')}
      </Link>
    </li>
    <li>
      <Link href="https://customer.level.travel/countries">
        {i18n.t('footer.help.rulesAndVisa')}
      </Link>
    </li>
    <li>
      <Link href="https://help.level.travel/">{i18n.t('footer.help.Q&A')}</Link>
    </li>
    <li>
      <Link href="https://promo.level.travel/canceled">
        {i18n.t('footer.help.depositTours')}
      </Link>
    </li>
    <li>
      <Link href="https://promo.level.travel/">
        {i18n.t('footer.help.promo')}
      </Link>
    </li>
  </Column>
);

export default HelpColumn;
