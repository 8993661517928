import React from 'react';
import styles from './styles/skeleton.module.css';

export const Skeleton = () => (
  <div className={styles.container}>
    <div className={styles.switchers}>
      <div className={styles.departureSwitcher} />
      <div className={styles.firstSwitcher} />
      <div className={styles.switcher} />
      <div className={styles.switcher} />
      <div className={styles.lastSwitcher} />
    </div>

    <div className={styles.chartWrapper}>
      <div className={styles.tabs}>
        <div className={styles.activeTab} />
        <div className={styles.tab} />
        <div className={styles.tab} />
        <div className={styles.tab} />
        <div className={styles.tab} />
        <div className={styles.tab} />
      </div>
      <div className={styles.chart}>
        <div className={styles.preloader} />
      </div>
    </div>
  </div>
);
