import { css } from 'styled-components';

export const container = css`
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 100%;
  border-radius: 4px;
  display: flex;
  align-items: center;

  background: radial-gradient(
      378.67% 379.26% at 90.19% -196.58%,
      rgba(117, 222, 255, 0.1) 39.69%,
      rgba(0, 10, 255, 0) 100%
    ),
    radial-gradient(
      129.98% 100% at 100% 0%,
      rgba(71, 222, 255, 0.1) 51.67%,
      rgba(169, 239, 255, 0) 100%
    ),
    radial-gradient(
      173.91% 275.28% at -15.71% -22.91%,
      rgba(164, 156, 255, 0.1) 0%,
      rgba(164, 150, 255, 0.1) 66.78%,
      rgba(12, 178, 250, 0.1) 100%
    ),
    #ffffff;
  box-shadow: 0px 1px 2px rgba(210, 208, 197, 0.9),
    0px 10px 15px rgba(216, 214, 204, 0.9);
`;

export const transition = css`
  transition-property: all;
  transition-duration: 100ms;
  transition-timing-function: linear;
`;

export const image = css`
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;
