import { nanoid } from 'nanoid';
import { isSSR } from 'src/helpers/ssr';
import { ERROR_MESSAGE_SSR, LOCAL_STORAGE_KEY_USER_ID } from './constants';

/**
 * Функция, которая отдает уникальный id для вашего клиента, данная функция сохраняет в localStorage созданный userID
 * и в будущем если вы захотите получить userId пользователя, то воз возьмется значение из localStorage
 */
export const getUserId = (): string => {
  // userId на бэкенде будет генериться каждый раз новый + на бэкенде нет localStorage и это вызовет развал страницы
  if (isSSR) {
    throw new Error(ERROR_MESSAGE_SSR);
  }

  // получаем userId из localStorage, если в хранилище есть ключ, то мы его возвращаем
  const localStorageValue = localStorage.getItem(LOCAL_STORAGE_KEY_USER_ID);
  if (localStorageValue) {
    return localStorageValue;
  }

  // если в localStorage нет ключа генерим уникальный ключ
  const userId = nanoid(50);

  // сохраняем сгенерированный ключ в localStorage
  localStorage.setItem(LOCAL_STORAGE_KEY_USER_ID, userId);

  return userId;
};
