import { isSSR } from 'src/helpers/ssr';
import { isDevelopment } from '../commonUtils';

export const getFeatureOptionByName = <Key extends keyof Features>(
  featureName: Key,
): Window[Key] | string | undefined => {
  if (isSSR) return undefined;

  const feature = window[featureName];

  if (!feature && isDevelopment()) {
    // eslint-disable-next-line no-console
    console.error(
      `Не обнаружено фичи с названием ${featureName}. Пожалуйста обновите локальную базу данных при помощи команды lt download-table features`,
    );
  }

  return feature;
};

export const getIsFeatureHasAnOption = <Key extends keyof Features>(
  featureName: Key,
  optionName: Window[Key],
): boolean => getFeatureOptionByName(featureName) === optionName;
