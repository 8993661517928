import { useTranslation } from '@lt/localization';
import React from 'react';
import styled, { css } from 'styled-components';
import { NPS_CHOOSE_RATING_TEST_ID } from '../constants';

type Props = {
  selectRating: (value: number) => void;
  isMobile: boolean;
};

const ChooseRating = (props: Props) => {
  const { selectRating, isMobile } = props;
  const { t } = useTranslation();

  return (
    <StyledContainer
      $isMobile={isMobile}
      data-testid={NPS_CHOOSE_RATING_TEST_ID}
    >
      <StyledTitle>{t('nps.chooseRatingTitle')}</StyledTitle>
      <div>
        {isMobile && (
          <StyledWorstRating>{t('nps.worstRatingMessage')}</StyledWorstRating>
        )}
        <StyledRating $isMobile={isMobile}>
          {Array.from({ length: 10 }).map((_, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={idx}>
              <StyledNumber
                onClick={() => selectRating(idx + 1)}
                data-testid={`NPS_RATING_${idx}`}
              >
                {idx + 1}
              </StyledNumber>
            </div>
          ))}
        </StyledRating>
        <StyledExplanations $isMobile={isMobile}>
          {!isMobile && (
            <StyledWorstRating>{t('nps.worstRatingMessage')}</StyledWorstRating>
          )}
          <StyledBestRating>{t('nps.bestRatingMessage')}</StyledBestRating>
        </StyledExplanations>
      </div>
    </StyledContainer>
  );
};

const StyledContainer = styled.div<{ $isMobile: boolean }>`
  display: flex;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  & > div + div {
    margin-top: 24px;
  }
  & > div > div + div {
    margin-top: 24px;
  }
  ${({ $isMobile }) =>
    $isMobile
      ? css`
          padding: 16px 0 16px;
          display: flex;
          flex-direction: column;
          width: 100%;
          align-items: center;
        `
      : css`
          width: 632px;
        `}
`;

const StyledTitle = styled.div`
  color: #17181a;
  font-family: Backpack;

  text-align: center;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
`;

export const NumbersGrid = css<{ $isMobile: boolean }>`
  ${({ $isMobile }) =>
    $isMobile
      ? css`
          display: grid;
          grid-template-columns: repeat(5, 56px);
          row-gap: 8px;
          width: 280px;
          & > div {
            display: flex;
            justify-content: flex-end;
          }
          & > div:nth-child(-n + 5) {
            justify-content: flex-start;
            align-items: flex-end;
          }
        `
      : css`
          * + * {
            margin-left: 8px;
          }
        `}
`;

const StyledRating = styled.div`
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  cursor: pointer;

  ${NumbersGrid}
`;

export const StyledNumber = styled.span`
  width: 48px;
  height: 48px;
  padding: 12px 0 14px;
  border-radius: 40px;
  background: #f5f3f2;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #4d4d4d;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  transition: background 0.3s linear;
  :hover {
    background: #f8e71c;
  }
  :active {
    background: #ffd600;
  }
`;

const StyledExplanations = styled.div<{ $isMobile: boolean }>`
  display: flex;
  justify-content: ${({ $isMobile }) =>
    $isMobile ? `flex-end` : `space-between`};
  align-items: flex-start;
  align-self: stretch;
`;

const StyledWorstRating = styled.div`
  color: #ac6c6c;
  width: 100px;

  font-size: 15px;
  line-height: 18px;
`;

const StyledBestRating = styled.div`
  color: #6f9055;
  text-align: right;

  font-size: 15px;
  line-height: 18px;
  width: 100px;
`;

export { ChooseRating };
