import { connect } from 'react-redux';
import {
  getIsMobile,
  getIsWebview,
  getIsWhitelabel,
  getIsAppBannerEnabled,
} from 'src/store/view/selectors';

import { getShortLink } from 'src/store/appBanners/selectors';
import { getDeeplinkAction } from 'src/store/appBanners/slice';
import { DynamicDataWrapper } from './DynamicDataWrapper';
import { fetchLiveInfoPromo } from '../../../../store/homeDynamicData/actions';

const mapStateToProps = (state) => ({
  liveInfoPromoRequest: state.homeDynamicDataSlice.liveInfoPromoRequest,
  isWhitelabel: getIsWhitelabel(state),
  isMobile: getIsMobile(state),
  appLink: getShortLink(state),
  isWebview: getIsWebview(state),
  isAppBannerEnabled: getIsAppBannerEnabled(state),
});

const mapDispatchToProps = {
  fetchLiveInfoPromo,
  getDeeplink: getDeeplinkAction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(DynamicDataWrapper);
