export const GO_TO_PATH = 'GO_TO_PATH';
export const GO_TO_TOURISTS = 'GO_TO_TOURISTS';
export const GO_TO_TOURISTS_HOTEL_CHECKOUT = 'GO_TO_TOURISTS_HOTEL_CHECKOUT';
export const GO_TO_PAY = 'GO_TO_PAY';
export const GO_TO_CLIENT = 'GO_TO_CLIENT';
export const GO_TO_CLIENT_DATA = 'GO_TO_CLIENT_DATA';
export const GO_TO_HOTEL = 'GO_TO_HOTEL';
export const GO_TO_FLIGHT = 'GO_TO_FLIGHT';
export const SCROLL_TO_TOURISTS = 'SCROLL_TO_TOURISTS';
export const SCROLL_TO_TOP = 'SCROLL_TO_TOP';
export const SCROLL_TO_BOOKING = 'SCROLL_TO_BOOKING';
export const SCROLL_TO_LOG_IN = 'SCROLL_TO_LOG_IN';
export const SCROLL_TO_PAYMENT_BLOCK = 'SCROLL_TO_PAYMENT_BLOCK';

// time in ms to redirect to order page
export const REDIRECT_TO_ORDER_DELAY = 3000;
