/**
 * Поисковые параметры
 */

import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { RequestId } from '@lt/api/dist/types/searchParams';

import { isSSR } from '../../helpers/ssr';
import type {
  EnqueueSearchParams,
  EnqueueSearchParamsKids,
  EnqueueStateType,
} from './types';
import { searchParamsDTO } from './enqueueDTO';

const initialState: EnqueueStateType = isSSR
  ? {
      request_id: null,
      search_params: null,
    }
  : {
      request_id:
        typeof window.REQUEST_ID === 'string' ? window.REQUEST_ID : null,
      search_params: window.SEARCH_PARAMS
        ? searchParamsDTO(window.SEARCH_PARAMS)
        : null,
    };

export const enqueueSlice = createSlice({
  name: 'enqueue',
  initialState,
  reducers: {
    startSearchProcess(
      state,
      action: PayloadAction<{
        requestId: RequestId | null;
        searchParams: EnqueueSearchParams | null;
      }>,
    ) {
      const { requestId, searchParams } = action.payload || {};

      return {
        ...state,
        ...(!!requestId && { request_id: requestId }),
        ...(!!requestId && { search_params: searchParams }),
      };
    },
    updateRequestId(state, action: PayloadAction<string>) {
      return {
        // @ts-ignore
        ...(typeof state.toJS === 'function' ? state.toJS() : state),
        request_id: action.payload,
      };
    },
    commitSearchParams(state, action: PayloadAction<EnqueueSearchParams>) {
      return {
        // @ts-ignore
        ...(typeof state.toJS === 'function' ? state.toJS() : state),
        search_params: action.payload,
      };
    },
    clearStartDate(state) {
      return {
        ...state,
        ...(!!state.search_params?.start_date && {
          search_params: {
            ...state.search_params,
            start_date: null,
          },
        }),
      };
    },
    clearTourists(state) {
      return {
        ...state,
        ...(!!state.search_params?.adults && {
          search_params: {
            ...state.search_params,
            adults: 2,
          },
        }),
      };
    },
    clearKids(state) {
      return {
        ...state,
        ...(!!state.search_params?.kids && {
          search_params: {
            ...state.search_params,
            kids: {
              count: 0,
              ages: [],
            },
          },
        }),
      };
    },
    setStartDate(state, action: PayloadAction<string>) {
      return {
        ...state,
        ...(!!state.search_params?.start_date && {
          search_params: {
            ...state.search_params,
            start_date: action.payload,
          },
        }),
      };
    },
    setAdultsParams(state, action: PayloadAction<number>) {
      return {
        ...state,
        ...(!!state.search_params?.adults && {
          search_params: {
            ...state.search_params,
            adults: action.payload,
          },
        }),
      };
    },
    setKidsParams(state, action: PayloadAction<EnqueueSearchParamsKids>) {
      return {
        ...state,
        ...(!!state.search_params?.kids && {
          search_params: {
            ...state.search_params,
            kids: action.payload,
          },
        }),
      };
    },
  },
});

export default enqueueSlice.reducer;
