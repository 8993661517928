/* eslint-disable no-param-reassign */
import type { PaymentMethod } from '@lt/api/dist/methods/orders/types';
import type { PaylateSuccess } from '@lt/api/dist/methods/payment/types';
import type { PaymentType } from '@lt/api/dist/types/payment';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { PaymentStateType, ThreeDSData } from './types';

const initialState: PaymentStateType = {
  cardInfo: {
    number: '',
    expMonth: '',
    expYear: '',
    cvv: '',
  },
  isCardValid: false,
  paymentMethodList: [],
  paymentMethod: null,
  paymentAmount: null,
  orderId: null,
  orderPin: null,
  cardData: null,
  cardIsValid: null,
  paymentId: null,
  isPaid: false,
  paylateData: null,
  tbankCredit: {
    tariffs: [],
  },
};
export const name = 'payment';

const payment = createSlice({
  name,
  initialState,
  reducers: {
    setPaymentAmount(state, action: PayloadAction<number | null>) {
      state.paymentAmount = action.payload;
    },

    setPaymentMethod(state, action: PayloadAction<PaymentType>) {
      state.paymentMethod = action.payload;
    },
    setPaymentMethodList(state, action: PayloadAction<PaymentMethod[]>) {
      state.paymentMethodList = action.payload;
    },

    setOrderInfo(
      state,
      action: PayloadAction<{
        orderId: number | null;
        pin: string | null;
      }>,
    ) {
      const {
        payload: { orderId, pin },
      } = action;
      state.orderId = orderId;
      state.orderPin = pin;
    },

    setCardDataAction(
      state,
      action: PayloadAction<{
        number: string;
        expMonth: string;
        expYear: string;
        cvv: string;
      }>,
    ) {
      state.cardData = action.payload;
    },
    setCardValidityAction(state, action: PayloadAction<boolean>) {
      state.cardIsValid = action.payload;
    },

    set3dsDataV1(state, action: PayloadAction<ThreeDSData<'v1'>>) {
      state.data3ds = action.payload;
    },
    set3dsDataV2(state, action: PayloadAction<ThreeDSData<'v2'>>) {
      state.data3ds = action.payload;
    },

    openPay2meFrame(state, action: PayloadAction<string>) {
      state.pay2meURL = action.payload;
    },

    setPaylateData(state, action: PayloadAction<PaylateSuccess>) {
      state.paylateData = action.payload;
    },

    payCardSuccessed(state, action: PayloadAction<number>) {
      state.paymentId = action.payload;
    },

    finishPayment(state) {
      state.isPaid = true;
    },

    setCurrentTariff(state, action: PayloadAction<string>) {
      state.tbankCredit.selectedTariffID = action.payload;
    },
  },
});

export const {
  setPaymentAmount,
  setPaymentMethod,
  setPaymentMethodList,
  setOrderInfo,
  setCardDataAction,
  setCardValidityAction,
  set3dsDataV1,
  set3dsDataV2,
  payCardSuccessed,
  setPaylateData,
  finishPayment,
  openPay2meFrame,
  setCurrentTariff,
} = payment.actions;

export default payment.reducer;
