import Cookies from 'js-cookie';
import type { MouseEvent } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import { useBoolean } from 'usehooks-ts';

import { isSSR } from 'src/helpers/ssr';
import styled from 'styled-components';
import { useTranslation } from '@lt/localization';
import type { TourGuideProps } from './types';

import { getComponent } from './components/TourGuideButton';
import { TourGuideModal } from './components/TourGuideModal';

import { pushBannerClicked, pushBannerClosed } from './analytics';
import { KEY_COOKIE } from './constants';

export const TourGuide = (props: TourGuideProps): JSX.Element | null => {
  const {
    mode = 'default',
    isMobile = false,
    isHomeHeader = false,
    isHotelOnly = false,
    className,
  } = props;

  const { value: isActive, setValue: setIsActive } = useBoolean(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { t } = useTranslation();

  const handleHideModal = () => {
    setIsModalVisible(false);
  };

  const handleShowModal = () => {
    setIsModalVisible(true);
  };

  const onInitialShowFiredRef = useRef(false);

  if (!onInitialShowFiredRef.current && isActive) {
    onInitialShowFiredRef.current = true;
  }

  useEffect(() => {
    const cookieValue = Cookies.get(KEY_COOKIE);
    const value = cookieValue ? Boolean(JSON.parse(cookieValue)) : true;
    setIsActive(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // обходим скачки контента для поисковиков те улучшаем CLS
  if (isSSR) {
    return <TourGuidePreview $isMobile={isMobile} />;
  }

  if (!isActive) {
    return null;
  }

  const handleClose = (e: MouseEvent) => {
    e.stopPropagation();
    pushBannerClosed();
    setIsActive(false);
    Cookies.set(KEY_COOKIE, JSON.stringify(false));
  };

  const handleClick = () => {
    handleShowModal();
    pushBannerClicked();
  };
  const tourGuideTitle =
    mode === 'default'
      ? t('howBuy.title')
      : t('checkout.payment.whatWillHappenAfter');

  const TourGuideButton = getComponent({ isMobile, isHomeHeader });

  return (
    <>
      <TourGuideButton
        onClick={handleClick}
        onClose={handleClose}
        className={className}
        text={tourGuideTitle}
        mode={mode}
      />

      <TourGuideModal
        mode={mode}
        isMobile={isMobile}
        isVisible={isModalVisible}
        onClose={handleHideModal}
        isHotelOnly={isHotelOnly}
      />
    </>
  );
};

const TourGuidePreview = styled.div<{ $isMobile: boolean }>`
  height: ${({ $isMobile }) => ($isMobile ? '60px' : '40px')};
`;
