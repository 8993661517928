import React from 'react';
import classnames from 'classnames';

import ArrowRight from '@lt/components/icons-react/ArrowRight';

import { SCROLL_BUTTON_TEST_ID } from '../../constants';
import { RELINKING_VARIANTS } from '../../../../constants';

import type { IScrollButton } from '../../types';
import styles from './scrollButton.module.css';

export const ScrollButton = (props: IScrollButton): JSX.Element => {
  const { isLeft = false, handleScroll, variant } = props;

  const ArrowIcon = isLeft ? (
    <ArrowRight className={styles.arrowLeftIcon} />
  ) : (
    <ArrowRight className={styles.arrowRightIcon} />
  );

  return (
    <div
      className={classnames(styles.containerShadow, {
        [styles.containerShadowRight]: !isLeft,
        [styles.containerShadowLeft]: isLeft,
        [styles.containerShadowCountries]:
          variant === RELINKING_VARIANTS.DEFAULT_COUNTRIES ||
          variant === RELINKING_VARIANTS.COUNTRIES_WITH_PRICES,
        [styles.containerShadowNarrative]:
          variant === RELINKING_VARIANTS.NARRATIVE,
        [styles.containerShadowCountriesWithPrices]:
          variant === RELINKING_VARIANTS.COUNTRIES_WITH_PRICES,
      })}
      data-testid={SCROLL_BUTTON_TEST_ID}
    >
      <button
        type="button"
        className={styles.circleButton}
        onClick={handleScroll}
      >
        {ArrowIcon}
      </button>
    </div>
  );
};
