import { dynamic } from 'src/helpers/dynamic';
import type { StoreType } from 'src/store/appBanners/types';

export const getStoreTypesByPlatform = (
  isIOS: boolean,
  isAndroid: boolean,
): StoreType[] => {
  if (isIOS) return ['appStore'];
  if (isAndroid) return ['googlePlay', 'ruStore', 'appGallery'];
  return ['googlePlay', 'appStore', 'ruStore', 'appGallery'];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ICONS: Record<StoreType, (props: any) => JSX.Element> = {
  appStore: dynamic(
    () =>
      import(
        /* webpackChunkName: "toAppStore" */ 'src/components/MobileAppBanner/IosIcon'
      ),
  ),
  googlePlay: dynamic(
    () =>
      import(
        /* webpackChunkName: "toGooglePlay" */ 'src/components/MobileAppBanner/AndroidIcon'
      ),
  ),
  appGallery: dynamic(
    /* webpackChunkName: "toAppGallery" */ () =>
      import('@lt/components/icons-react/commonPages/goToStore/ToAppGallery'),
  ),
  ruStore: dynamic(
    /* webpackChunkName: "toRuStore" */ () =>
      import('@lt/components/icons-react/commonPages/goToStore/ToRuStore'),
  ),
};
