import React from 'react';
import styled from 'styled-components';

import Search from '@lt/components/icons-react/userflow/Search';
import { FIELD_NAMES } from '@lt/widget/components/Cigarette/constants';
import type { FieldType } from '@lt/widget/components/Cigarette/types';
import { useTranslation } from '@lt/localization';

import { CIGARETTE_WIZARD_PREVIEW_TEST_ID } from '../LazyCigarette/constants';

interface IWizardCigarettePreview {
  onFieldClick: (clickedPreviewField: FieldType) => void;
}

export const WizardCigarettePreview = (props: IWizardCigarettePreview) => {
  const { onFieldClick } = props;

  const { t } = useTranslation();

  const handleClick = () => {
    onFieldClick(FIELD_NAMES.destination);
  };

  return (
    <PlaceholderWrapper
      onClick={handleClick}
      onKeyDown={handleClick}
      role="button"
      tabIndex={0}
      data-testid={CIGARETTE_WIZARD_PREVIEW_TEST_ID}
    >
      <SearchIcon />
      <StyledPlaceholderText>
        {t('cigarettePreview.destinationPickerPlaceholder')}
      </StyledPlaceholderText>
    </PlaceholderWrapper>
  );
};

const PlaceholderWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  height: 56px;
  padding: 16px 16px 16px 8px;

  border: none;
  border-radius: 4px;

  font-size: 16px;
  line-height: 20px;

  background: #fff;
  color: rgb(194, 197, 204);
`;

const SearchIcon = styled(Search)`
  width: 24px;
  height: 24px;
  margin-right: 4px;
  stroke: rgb(194, 197, 204);
`;

const StyledPlaceholderText = styled.div`
  padding: 2px 8px 2px 4px;
`;
