import { TourGuideButtonDesktop } from './components/TourGuideButtonDesktop';
import { TourGuideButtonMobile } from './components/TourGuideBannerMobile';
import { TourGuideButtonHomeHeader } from './components/TourGuideButtonHomeHeader';

export const getComponent = ({ isMobile, isHomeHeader }) => {
  if (isHomeHeader) return TourGuideButtonHomeHeader;

  if (isMobile) return TourGuideButtonMobile;

  return TourGuideButtonDesktop;
};
