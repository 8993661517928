import React from 'react';

import { useTranslation } from '@lt/localization';

import { CurrencyFormat, HotelStars, Link } from '@lt/components';
import { MOON } from '@lt/components/constants/symbols';

import classnames from 'classnames';
import stylesCard from '../../../styles/card.module.css';
import HotelImage from '../HotelImage';

import { getFormattedDate } from '../../utils';

import type { IHotelRelinkingBlock } from './types';
import styles from './hotelRelinkingBlock.module.css';
import stylesContentContainer from '../../../styles/contentContainer.module.css';

export const HotelRelinkingBlock = (props: IHotelRelinkingBlock) => {
  const { isMobile, image, stars, rating, name, place, country, offer } = props;
  const {
    price,
    link,
    start_date: startDate,
    end_date: endDate,
    nights,
    adults,
  } = offer;

  const { t } = useTranslation();

  const startDateString = getFormattedDate(startDate);
  const endDateString = getFormattedDate(endDate);

  const hotelUrl = `${link}&ref_content=main_page`;

  return (
    <div
      className={classnames(
        stylesCard.card,
        stylesCard.cardHotelsVariant,
        stylesCard.shadow,
        {
          [stylesCard.desktop]: !isMobile,
          [stylesCard.cardHotelsVariantMobile]: isMobile,
        },
      )}
    >
      <Link className={styles.link} href={hotelUrl}>
        <HotelImage image={image} rating={rating} country={country} />
        <div
          className={classnames(
            stylesContentContainer.contentContainer,
            stylesContentContainer.contentContainerHotels,
          )}
        >
          <div>
            <HotelStars value={stars} size={8} />
            <p className={styles.hotelName}>{name}</p>
            <p className={styles.hotelPlace}>
              {place}, {country}
            </p>
          </div>

          <div>
            <p className={styles.hotelPrice}>
              {t('filters.prices.from')}
              <CurrencyFormat
                className={styles.currencyFormat}
                value={price}
                currencyCode="RUB"
                prefix=" "
              />
            </p>
            <p className={styles.searchParamsText}>
              {startDateString} — {endDateString}, {nights}
              {MOON} {t('homeRelinking.hotels.adults', { count: adults })}
            </p>
          </div>
        </div>
      </Link>
    </div>
  );
};
