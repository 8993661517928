import { Flamingo } from '@lt/components/icons-react';
import { useTranslation } from '@lt/localization';
import React from 'react';
import styled, { css } from 'styled-components';
import type { NpsProps } from '../NpsForm';
import { NPS_GOODBYE_MESSAGE_TEST_ID } from '../constants';

type Props = Pick<NpsProps, 'isAlreadyRated' | 'isMobile'> & {
  rating: number;
};

const GoodbyeMessage = (props: Props) => {
  const { isAlreadyRated, isMobile, rating } = props;
  const { t } = useTranslation();

  return (
    <StyledContainer
      $isMobile={isMobile}
      data-testid={NPS_GOODBYE_MESSAGE_TEST_ID}
    >
      <Flamingo width={166} />
      <StyledText>
        {isAlreadyRated
          ? t('nps.success.alreadyRated', { rating })
          : t('nps.success.thanksForRating')}
      </StyledText>
    </StyledContainer>
  );
};

const StyledContainer = styled.div<{ $isMobile: boolean }>`
  ${({ $isMobile }) =>
    $isMobile
      ? css`
          padding: 28px 16px 16px;
        `
      : css`
          padding: 36px 0 24px;
        `}

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledText = styled.div`
  color: #17181a;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  margin-top: 16px;
`;

export { GoodbyeMessage };
