import { isSSR } from 'src/helpers/ssr';
import { QUERY_MOBILE_APP_ROOM_OFFERS } from 'src/routes/Hotel/store/constants/view';
import { HOTEL_MOBILE_PAGES } from 'src/routes/HotelReviewsPage/constants';

export const getViewInitialState = () => {
  if (isSSR) {
    return {
      cashbackIcon: undefined,
      isWhitelabel: false,
    };
  }
  const currentPageURL = new URL(window.location.href);
  const pageValue = currentPageURL.searchParams.get(
    QUERY_MOBILE_APP_ROOM_OFFERS,
  );
  return {
    cashbackIcon: window.customCashback || undefined,
    isWhitelabel: window.is_wl || false,
    isWebview: window.is_webview || false,
    page: pageValue === 'true' ? HOTEL_MOBILE_PAGES.SEARCHER : null,
  };
};
