import { createAction } from '@reduxjs/toolkit';

import {
  CONFIRM_CLIENT,
  RESET_CLIENT,
  ON_LOAD,
  FETCH_CLIENT_INFO,
} from './constants';
import { clientSlice } from './slice';

export const {
  logInAction,
  logOutAction,
  signUpAction,
  showAuthAction,
  hideAuthAction,
  showLogOutAction,
  hideLogOutAction,
  setClientConfirmPhone,
  setClientInfo,
  setClientBonusScore,
  setClientAuthToken,
} = clientSlice.actions;

export const confirmClientAction = createAction<boolean | null>(CONFIRM_CLIENT);
export const resetClientAction = createAction(RESET_CLIENT);

export const onLoadAction = () => ({
  type: ON_LOAD,
});

export const fetchClientInfo = createAction<undefined>(FETCH_CLIENT_INFO);
