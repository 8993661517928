import { put, takeLatest } from 'redux-saga/effects';

import { api } from 'src/api';

import {
  liveInfoPromoSetFailResponseStatus,
  liveInfoPromoSetSendingStatus,
  liveInfoPromoSetSuccessResponseStatus,
  parseFoundToursToday,
  parseHotPricesByCountry,
  parseLatestOrders,
} from '../store/homeDynamicData/actions';

import { FETCH_LIVE_INFO_PROMO } from '../store/homeDynamicData/constants';

/**
 * Получаем текста для текстов под сигаретой
 */
function* fetchLiveInfoPromoWorker() {
  try {
    yield put(liveInfoPromoSetSendingStatus());

    const data = yield api.getReferencesLiveInfoPromo();
    yield data.latest_orders && put(parseLatestOrders(data.latest_orders));
    yield data.hot_prices_by_country &&
      put(parseHotPricesByCountry(data.hot_prices_by_country));
    yield put(parseFoundToursToday(data.found_tours_today));

    yield put(liveInfoPromoSetSuccessResponseStatus());
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    yield put(liveInfoPromoSetFailResponseStatus());
  }
}

export function* homeDynamicDataWatcher() {
  yield takeLatest(FETCH_LIVE_INFO_PROMO, fetchLiveInfoPromoWorker);
}
