import type { FC, ReactNode } from 'react';
import React from 'react';

import styles from './Column.module.css';

type ColumnProps = {
  title: ReactNode;
  children: ReactNode[] | ReactNode;
};

const Column: FC<ColumnProps> = ({ title, children }) => (
  <section>
    <h3 className={styles.title}>{title}</h3>
    <ul className={styles.list}>{children}</ul>
  </section>
);

export default Column;
