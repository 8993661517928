import classnames from 'classnames';
import React from 'react';
import styles from './cardButton.module.css';

import type { ICardButton } from './types';

export const CardButton = (props: ICardButton) => {
  const { text, isMobile, isBlue = false, url } = props;

  const href = `${url}?ref_content=main_page`;

  return (
    <a
      className={classnames(styles.cardButtonWrapper, {
        [styles.cardButtonWrapperBlue]: isBlue,
        [styles.cardButtonWrapperMobile]: isMobile,
      })}
      href={href}
    >
      {text}
    </a>
  );
};
