import { createAction } from '@reduxjs/toolkit';

import homeDynamicDataSlice from './slice';

import { FETCH_LIVE_INFO_PROMO } from './constants';

export const {
  parseLatestOrders,
  parseHotPricesByCountry,
  parseFoundToursToday,
  liveInfoPromoSetFailResponseStatus,
  liveInfoPromoSetSuccessResponseStatus,
  liveInfoPromoSetSendingStatus,
} = homeDynamicDataSlice.actions;

export const fetchLiveInfoPromo = createAction(FETCH_LIVE_INFO_PROMO);
