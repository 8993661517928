import { createSelector } from '@reduxjs/toolkit';
import {
  getImmutableCompatible,
  getFromImmutableStateCompatible,
} from 'src/utils/immutableUtils';
import type { IFeedbackStore } from './slices';
import { name } from './slices';

const _getFeedback = (state) => getImmutableCompatible(state, name);

export const getFeedback =
  getFromImmutableStateCompatible<IFeedbackStore>(_getFeedback);

export const getFeedbackPopup = createSelector(
  getFeedback,
  (feedback) => feedback.showPopup,
);
