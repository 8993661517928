import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import {
  getAvailableSearchTypes,
  getIsAppBannerEnabled,
  getIsMobile,
  getIsWhitelabel,
} from 'src/store/view/selectors';
import { submitCigaretteAction as submitCigarette } from 'src/store/analytics/slice';

import type { RootState } from '../../store';

import SearchForm from './SearchForm';

const mapStateToProps = (state: RootState) => ({
  isMobile: getIsMobile(state),
  isWhitelabel: getIsWhitelabel(state),
  availableSearchTypes: getAvailableSearchTypes(state),
  isAppBannerEnabled: getIsAppBannerEnabled(state),
});

const mapDispatchToProps = {
  submitCigarette,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SearchForm);
