import { createSelector } from '@reduxjs/toolkit';
import { getUserId } from 'src/utils/getUserId';
import { getImmutableCompatible } from 'src/utils/immutableUtils';
import type { ClientStateType } from './types';

export const getClient = (state): ClientStateType =>
  getImmutableCompatible(state, 'client');

export const getShowAuthPopup = createSelector(
  getClient,
  (state) => state.showAuthPopup,
);
export const getShowLogOutPopup = createSelector(
  getClient,
  (state) => state.showLogOutPopup,
);
export const getClientAuthToken = createSelector(
  getClient,
  (state) => state.auth_token,
);
export const getClientId = createSelector(getClient, (state) => state.id);
export const getClientName = createSelector(getClient, (state) => state.name);
export const getClientSurname = createSelector(
  getClient,
  (state) => state.surname,
);
export const getClientNameEn = createSelector(
  getClient,
  (state) => state.name_en,
);
export const getClientSurnameEn = createSelector(
  getClient,
  (state) => state.surname_en,
);
export const getClientPhone = createSelector(getClient, (state) => state.phone);
export const getClientUnconfirmedPhone = createSelector(
  getClient,
  (state) => state.unconfirmed_phone,
);
export const getClientEmail = createSelector(getClient, (state) => state.email);
export const getClientUnconfirmedEmail = createSelector(
  getClient,
  (state) => state.unconfirmed_email,
);
export const getConfirmedAt = createSelector(
  getClient,
  (state) => state.confirmed_at,
);
export const getAvatar = createSelector(getClient, (state) => state.avatar);

export const getClientIsSubscribedMail = createSelector(
  getClient,
  (state) => state.advertising_mail_accepted,
);

export const getIsLoggedIn = createSelector(getClientId, (id) => Boolean(id));

export const isPhoneConfirmedSelector = createSelector(
  [getClientUnconfirmedPhone],
  (unconfirmedPhone) => !unconfirmedPhone,
);

export const getClientData = (state) =>
  getClientId(state)
    ? {
        id: getClientId(state),
        name: getClientName(state),
        surname: getClientSurname(state),
        phone: getClientPhone(state) || getClientUnconfirmedPhone(state),
        email: getClientEmail(state) || getClientUnconfirmedEmail(state),
      }
    : null;

/**
 * Бонусы накопленные клиентом
 */
export const getClientBonusScore = createSelector(
  getClient,
  (state) => state.bonus_score || 0,
);

export const getIsAdmin = createSelector(getClient, (state) => state.isAdmin);

/**
 * Данные клиента для фронтовой аналитики
 */
export const getClientStatsData = createSelector(
  [getClientId, getClientEmail, getClientPhone],
  (id, email, phone) => ({
    id: id ?? undefined,
    email: email ?? undefined,
    phone: phone ?? undefined,
    uuid: getUserId(),
  }),
);
