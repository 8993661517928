import { useEffect, useState } from 'react';
import { useIsMounted } from 'usehooks-ts';

import { initLTApi } from 'src/utils/commonUtils';
import { WIDGET_CONFIG } from '../components/StatisticsWidget/constants';

const DELAY = 1000;
const TIMEOUT = 10 * DELAY;

const LT_API_BASE_URL = 'https://jsapi.cdn.level.travel/5.0';

const LT_API_URLS = {
  staging: `${LT_API_BASE_URL}-edge/open_api.js`,
  production: `${LT_API_BASE_URL}/open_api.js`,
  development: `${LT_API_BASE_URL}-dev/open_api.dev.js`,
};

const getLTApiUrl = () => LT_API_URLS[window.env || 'production'];

/**
 * Скачиваем open_api
 */
const loadOpenApi = (handleLoaded) => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.src = getLTApiUrl();

  const node = document.getElementsByTagName('script')[0];
  node.parentNode?.insertBefore(script, node);

  script.onload = () => initWidget(handleLoaded);
};

/**
 * Инициализируем виджет статистики в дереве элеменотв по id виджета
 */

const initStatistic = () => new window.LTApi.Widgets.Statistic(WIDGET_CONFIG);

/**
 * Ждем пока window.LTApi станет доступен и инициализируем партрена
 */
const initWidget = (handleLoaded) => {
  const startTime = Date.now();

  setTimeout(function tryInit() {
    try {
      // если API подгрузилось в window
      if (window.LTApi) {
        initLTApi();
        initStatistic();
        handleLoaded();
        return;
      }

      if (Date.now() - startTime > TIMEOUT) {
        throw new Error('Невозможно загрузить window.LTApi');
      }

      setTimeout(tryInit, DELAY);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Ошибка инициализации LTApi', error);
      setTimeout(tryInit, DELAY);
    }
  }, DELAY);
};

export const useLoadStatisticWidget = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const isMounted = useIsMounted();

  useEffect(() => {
    loadOpenApi(() => isMounted() && setIsLoaded(true));
  }, [isMounted]);

  return {
    isLoaded,
  };
};
