import { connect } from 'react-redux';
import type { ConnectedProps } from 'react-redux';

import { getIsWhitelabel } from 'src/store/view/selectors';
import { getIsStatisticWidgetDisabled } from '../../store/homeDisplayFlags/selectors';

import Desktop from './Desktop';
import { npsComponentStateSelector } from '../../store/nps/selectors';

const mapStateToProps = (state) => ({
  isWhitelabel: getIsWhitelabel(state),
  isStatisticWidgetDisabled: getIsStatisticWidgetDisabled(state),
  npsState: npsComponentStateSelector(state),
});

const connector = connect(mapStateToProps);
export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Desktop);
