import { isNil, negate, isEqual } from 'lodash';
import type { CLIENT, FullClientData } from '@lt/api/dist/types/client';
import { flow, get } from 'lodash/fp';
import type { PostClientAuthResponse } from '@lt/api/dist/methods/client/types';
import type { SetFormType, SharedState } from '../types';
import { AuthFormType, AuthProvider } from '../types';

/** @desc привести номер телефона к виду 79001112233 */
export const resetPhoneFormat = (value?: string | null) =>
  `${value}`.replace(/\D/g, '');

/** @desc привести номер телефона к читаемому виду
 * в соответствии с передаваемой маской
 * @params mask - маска номера телефона
 * @params phone - номер телефона, который нужно отформатировать
 * @example
 * setPhoneFormat('+. ... ...-..-..', '+7 (123) 456-7890') => '+7 123 456-78-90'
 * */
export const setPhoneFormat = (mask: string, phone: string) => {
  const resetPhone = phone.replace(/\D/g, '');
  let newPhone = mask;
  if ((mask.match(/\./g) || []).length === resetPhone.length) {
    for (let i = 0; i < resetPhone.length; i += 1) {
      newPhone = newPhone.replace('.', resetPhone[i]);
    }
    return newPhone;
  }
  return phone;
};

/** @desc заполнить маску номера цифрами для плейсхолдера
 * @params mask - маска номера телефона
 * @params countryCode - префикс номера телефона соответствующей страны
 * @example setPhoneForPlaceholder('+. (...) ...-..-..', '7') => '+7 (999) 999-99-99'
 */
export const setPhoneForPlaceholder = (mask: string, countryCode: string) => {
  let newMask = mask;
  for (let i = 0; i < countryCode.length; i += 1) {
    newMask = newMask.replace('.', countryCode[i]);
  }
  return newMask.replace(/\./g, '9');
};

/** @desc проверить является ли строка номером телефона */
export const isPhoneNumber = (text: string) => /^\d{11}$/.test(text);

/** @desc привести номер телефона к виду +7 (900) 111-22-33 */
export const setReadablePhoneFormat = (value?: string | null) =>
  `${value}`
    .replace(/\D/g, '')
    .replace(/^\d(\d{3})(\d{3})(\d{2})(\d{2})$/, '+7 ($1) $2-$3-$4');

/** @desc привести к нижнему регистру и удалить пробелы из имени/фамилии/почты */
export const resetPersonDataFormat = (value?: string | null) =>
  `${value}`.toLowerCase().trim();

export const containsAtLeastOneDigit = (text: string) => /[0-9]/.test(text);

export const containsAtLeastOneCharacter = (text: string) =>
  /[a-zA-Z]/.test(text);

export const isSignInProcess = (sharedState: SharedState) =>
  isNil(sharedState?.signUpType);

export const removeURLParam = (param: string) => {
  const url = new URL(window.location.href);
  const { searchParams } = url;
  searchParams.delete(param);

  url.search = String(searchParams);
  window.history.pushState(null, document.title, String(url));
};

export const removeRedirectParams = (
  redirect_to: string | undefined,
  show_login_form: string | undefined,
) => {
  if (redirect_to) removeURLParam('redirect_to');
  if (show_login_form) removeURLParam('show_login_form');
};

export const isSignupProcess = (sharedState: SharedState) =>
  negate(isSignInProcess)(sharedState);

export const isSignupByPhone = (sharedState: SharedState) =>
  sharedState?.signUpType === 'phone';
export const isSignInByPhone = (sharedState: SharedState) =>
  !isNil(sharedState?.phone);
export const isSignUpByAuthProvider = (sharedState: SharedState) =>
  sharedState?.signUpType === AuthProvider.VK ||
  sharedState?.signUpType === AuthProvider.OK ||
  sharedState?.signUpType === AuthProvider.Yandex ||
  sharedState?.signUpType === AuthProvider.TBank ||
  sharedState?.signUpType === AuthProvider.Github ||
  sharedState?.signUpType === AuthProvider.Apple;

export const isPhoneConfirm = (response: { client?: FullClientData }) =>
  isNil(response?.client?.unconfirmed_phone) &&
  negate(isNil)(response?.client?.phone);

export const isPhoneNotConfirm = (response: { client?: FullClientData }) =>
  negate(isNil)(response?.client?.unconfirmed_phone) &&
  isNil(response?.client?.phone);

export const isPasswordSet = (response: { client?: FullClientData }) =>
  Boolean(response?.client?.has_password);

export const isPasswordNotSet = (response: { client?: FullClientData }) =>
  negate(Boolean)(response?.client?.has_password);

export const isPasswordLeaked = (response: { client? }) =>
  Boolean(response?.client?.has_password_leaked);

export const isEmailNotConfirm = (response: { client?: FullClientData }) =>
  negate(isNil)(response?.client?.unconfirmed_email) &&
  isNil(response?.client?.email);

export const isLoginWithConfirmedEmail = (
  response: {
    client?: FullClientData;
  },
  email: string | undefined,
) =>
  isEqual(response?.client?.email, email) &&
  negate(isEqual)(response?.client?.unconfirmed_email, response?.client?.email);

export const isLoginWithUnconfirmedEmail = (
  response: {
    client?: FullClientData;
  },
  email: string | undefined,
) => isEqual(response?.client?.unconfirmed_email, email);

export const openLoginWithPassword = (setFormType: SetFormType) => () => {
  setFormType(AuthFormType.LoginWithPassword);
};

export const openLoginWithSMSCode = (setFormType: SetFormType) => () => {
  setFormType(AuthFormType.LoginWithSMSCode);
};

export const openConfirmPhoneWithSMSCode = (setFormType: SetFormType) => () => {
  setFormType(AuthFormType.ConfirmPhoneWithSMSCode);
};

export const openConfirmAndLoginPhoneWithSMSCode =
  (setFormType: SetFormType) => () => {
    setFormType(AuthFormType.ConfirmAndLoginPhoneWithSMSCode);
  };

export const openSetPhoneForConfirm = (setFormType: SetFormType) => () => {
  setFormType(AuthFormType.SetPhoneForConfirm);
};

export const mapSharedStateToClient = (sharedState: SharedState): CLIENT => ({
  email: sharedState.email || sharedState.unconfirmed_email || '',
  id: sharedState.id as number,
  name: sharedState.name,
  surname: sharedState.surname,
  phone: resetPhoneFormat(sharedState.phone || sharedState.unconfirmed_phone),
});

export const mergeFullClientDataWithSharedState =
  (client?: FullClientData) =>
  (prev: SharedState): SharedState => ({
    ...prev,
    id: client?.id,
    name: client?.name || prev.name,
    surname: client?.surname || prev.surname,
    email: client?.email || prev.email,
    unconfirmed_email: client?.unconfirmed_email || prev.unconfirmed_email,
    phone: client?.phone || prev.phone,
    unconfirmed_phone: client?.unconfirmed_phone || prev.unconfirmed_phone,
  });

export const isPhoneExist = flow<
  [PostClientAuthResponse],
  string | null,
  boolean
>(get(['client', 'phone']), negate(isNil));
