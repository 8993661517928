import { useState } from 'react';
/**
 * Нужен для управления сайдбаром
 * @returns {boolean} isSidebarOpened
 * @returns {function} closeSidebar
 * @returns {function} openSidebar
 */
export const useSidebarOpener = () => {
  const [isSidebarOpened, setIsSidebarOpened] = useState(false);
  const closeSidebar = () => setIsSidebarOpened(false);
  const openSidebar = () => setIsSidebarOpened(true);
  return {
    isSidebarOpened,
    closeSidebar,
    openSidebar,
  };
};
