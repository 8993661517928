import React from 'react';

import { Image } from '@lt/components';

import classnames from 'classnames';
import commonStyles from '../styles.module.css';
import styles from './countryBackgroundImage.module.css';

interface ICountryBackgroundImage {
  src: string;
}

export const CountryBackgroundImage = (props: ICountryBackgroundImage) => {
  const { src } = props;

  return (
    <>
      <Image
        className={styles.backgroundImage}
        src={src}
        fallback={src}
        type="image/jpeg"
        loading="lazy"
      />
      <div
        className={classnames(
          commonStyles.absoluteDivStyles,
          styles.imageOverlay,
        )}
      />
    </>
  );
};
