import Client from '@lt/api';

import { isSSR } from 'src/helpers/ssr';

import { logErrorToSentry } from 'src/error/utils/logErrorToSentry';
import makeServer, { isMockActive } from './mockServer';

const mockKey = '00000000000';
const safetyPartnerKey = isSSR ? mockKey : window.API_KEY;

/**
 * Формирует урл для доступа к апи
 * @example https://api.level.travel | https://api.staging-alpha.level.travel | https://api.leveltravel.dev
 */
const baseUrl = (function getBaseUrl() {
  if (isSSR) return '';

  const [subdomains] = window.location.host.split(window.ENV_DOMAIN);
  const stagging = subdomains
    .split('.')
    .filter((subdomain) => /staging/.test(subdomain))
    .toString();

  const result = ['https://api', stagging, window.ENV_DOMAIN]
    .filter(Boolean)
    .join('.');

  return result;
})();

/**
 * запросы к api v3
 */
export const api = new Client({
  key: safetyPartnerKey,
  baseURL: baseUrl,
  onHandlingThreeHundredthStatusErrors: (url, status, request) => {
    logErrorToSentry(
      new Error(`Упала ручка ${url} со статусом ${status}`),
      request,
    );
  },
  api_version: 3.7,
});

/**
 * отдельный инстанс апи для /client, /payment запросов
 * @summary отличается baseUrl
 */
export const axiosInstance = new Client({
  key: safetyPartnerKey,
  baseURL: '',
  api_version: 3.7,
});

/**
 * мок-сервер для подмены запросов в локальном окружении
 */
if (process.env.NODE_ENV === 'development' && !isSSR && isMockActive()) {
  makeServer();
}
