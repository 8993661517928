import { connect } from 'react-redux';

import { logOutAction, showAuthAction } from 'src/store/client/actions';
import { getIsLoggedIn } from 'src/store/client/selectors';
import { getPartnerLogo } from 'src/store/logo/selectors';
import { getIsWebview, getIsWhitelabel } from 'src/store/view/selectors';
import { getWishlistIdSelector } from 'src/store/wishlist/selectors';
import MobileSearchBlock from './MobileSearchBlock';
import { npsComponentStateSelector } from '../../store/nps/selectors';

const mapStateToProps = (state) => ({
  isWhitelabel: getIsWhitelabel(state),
  isWebView: getIsWebview(state),
  partnerLogo: getPartnerLogo(state),
  isLoggedIn: getIsLoggedIn(state),
  wishlistId: getWishlistIdSelector(state),
  npsState: npsComponentStateSelector(state),
});

const mapDispatchToProps = {
  onShowAuthPopup: showAuthAction,
  onLogOut: logOutAction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(MobileSearchBlock);
