import React from 'react';
import styled from 'styled-components';
import RoomType from '@lt/components/icons-react/wishlist/RoomType';
import type { WishlistItem } from '@lt/api/dist/methods/wishlist/types';
import { useTranslation } from '@lt/localization';

type Props = Pick<WishlistItem, 'roomType'>;

const HotelRoom = ({ roomType }: Props) => {
  const { t } = useTranslation();
  const room = t('wishlist.card.room', { ns: 'wishlist' });
  return (
    <StyledRoomContainer>
      <RoomType width={16} height={16} />
      <StyledRoomText>
        {room} {roomType}
      </StyledRoomText>
    </StyledRoomContainer>
  );
};

const StyledRoomContainer = styled.div`
  display: flex;
  padding: 4px 0px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  background: #f3f2e8;
`;
const StyledRoomText = styled.div`
  color: #17181a;
  height: 18px;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export default HotelRoom;
