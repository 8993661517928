import React from 'react';

import type { IPopularDestinationsOfferWithPrice } from 'src/routes/Home/store/popularDestinationsOffers/types';

import ContainerHeading from '../../../ContainerHeading';
import ScrollableContainer from '../../../ScrollableContainer';
import { CountryWithPrices } from './components/CountryWithPrices';

import { RELINKING_VARIANTS } from '../../../../constants';

interface ICountriesWithPrices {
  places: IPopularDestinationsOfferWithPrice[];
  title?: string;
  isMobile: boolean;
}

export const CountriesWithPrices = (props: ICountriesWithPrices) => {
  const { isMobile, places, title } = props;

  return (
    <>
      <ContainerHeading
        variant={RELINKING_VARIANTS.COUNTRIES_WITH_PRICES}
        title={title}
        isMobile={isMobile}
      />
      <ScrollableContainer
        isMobile={isMobile}
        variant={RELINKING_VARIANTS.COUNTRIES_WITH_PRICES}
      >
        {places.map((placeProps) => (
          <CountryWithPrices
            {...placeProps}
            isMobile={isMobile}
            key={`country_with_prices_card_${placeProps.place}`}
          />
        ))}
      </ScrollableContainer>
    </>
  );
};
