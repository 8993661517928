import type { WishlistItem } from '@lt/api/dist/methods/wishlist/types';
import { HotelStars } from '@lt/components';
import { i18n } from '@lt/localization';
import React, { useMemo } from 'react';
import { formatDateToDayShort, getPackageEndDate } from 'src/utils/dateUtils';
import { formatCurrency } from 'src/utils/stringUtils';
import styled from 'styled-components';
import { WISHLIST_INFO_BLOCK_TEST_ID } from '../constants';

type Props = Pick<
  WishlistItem,
  | 'stars'
  | 'hotelTitle'
  | 'date'
  | 'nights'
  | 'tourists'
  | 'price'
  | 'searchType'
  | 'city'
  | 'country'
>;

const SearchType = {
  package: i18n.t('wishlist.card.tour', { ns: 'wishlist' }),
  hotel: i18n.t('wishlist.card.hotel', { ns: 'wishlist' }),
};

const WishlistInfoBlock = (props: Props) => {
  const {
    hotelTitle,
    city,
    country,
    stars,
    date,
    nights,
    tourists,
    searchType,
    price,
  } = props;

  /**
   * Текст формата "от 100000₽ тур с перелетом"
   */
  const priceText = `${i18n.t('commonPages.from')} ${formatCurrency(price)} ${
    SearchType[searchType]
  }`;

  const dateFrom = useMemo(() => formatDateToDayShort(date), [date]);
  const dateTo = useMemo(
    () => formatDateToDayShort(getPackageEndDate(date, nights.from)),
    [date, nights],
  );
  const on = i18n.t('commonPages.on');
  const people =
    tourists < 8 ? i18n.t('checkout.people', { context: tourists }) : tourists;
  /**
   * Текст формата "15 сен - 30 сен, 7☾ на двоих"
   */
  const dateInfo = `${dateFrom} – ${dateTo}, ${nights.from}☾ ${on} ${people}`;
  return (
    <StyledInfoContainer data-testid={WISHLIST_INFO_BLOCK_TEST_ID}>
      <StyledMainInfoBlock>
        <HotelStars value={stars} size={12} />
        <StyledHotelTitle>{hotelTitle}</StyledHotelTitle>
        <StyledHotelPlace>
          {country}, {city}
        </StyledHotelPlace>
      </StyledMainInfoBlock>
      <div>
        <StyledPrice>{priceText}</StyledPrice>
        <StyledNights>{dateInfo}</StyledNights>
      </div>
    </StyledInfoContainer>
  );
};
const StyledInfoContainer = styled.div`
  display: flex;
  padding: 8px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  margin-left: 8px;
  width: 100%;
`;
const StyledMainInfoBlock = styled.div`
  display: flex;
  height: 60px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;
const StyledHotelTitle = styled.div`
  color: #17181a;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
`;
const StyledHotelPlace = styled.div`
  color: #4d4d4d;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
`;
const StyledPrice = styled.div`
  color: #17181a;

  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
`;
const StyledNights = styled.div`
  color: #4d4d4d;
  font-size: 12px;
`;
export default WishlistInfoBlock;
