import React from 'react';

import ContainerHeading from '../ContainerHeading';
import ScrollableContainer from '../ScrollableContainer';
import NarrativeCard from './components/NarrativeCard';

import { RELINKING_VARIANTS } from '../../constants';

import type { INarrativeCards } from './types';

export const NarrativeCards = (props: INarrativeCards) => {
  const { isMobile, narrativeCardsList } = props;

  return (
    <>
      <ContainerHeading
        variant={RELINKING_VARIANTS.NARRATIVE}
        isMobile={isMobile}
      />
      <ScrollableContainer
        variant={RELINKING_VARIANTS.NARRATIVE}
        isMobile={isMobile}
      >
        {narrativeCardsList.map((narrativeCardProps) => (
          <NarrativeCard
            {...narrativeCardProps}
            key={`narrative_card_${narrativeCardProps.title}`}
            isMobile={isMobile}
          />
        ))}
      </ScrollableContainer>
    </>
  );
};
