import React from 'react';
import { getIsCookieHasAnOption } from 'src/utils/cookiesController';
import CookiesBanner from './CookiesBanner';
import { KEY_COOKIE as mobileBannerCookie } from './MobileAppPopup/constants';
import { KEY_COOKIE as desktopBannerCookie } from './DesktopAppBanner/constants';
import MobileAppPopupContainer from './MobileAppPopup';
import DynamicDesktopAppBanner from './DesktopAppBanner/DynamicDesktopAppBanner';

interface BannerControllerProps {
  isMobile: boolean;
  isWhiteLabel: boolean;
  isWebview: boolean;
  isAppBannerEnabled: boolean;
  isCookieBannerDisabled?: boolean;
}

// показыаем куки баннер после баннеров в прил-ки, если они есть
export const BannerController = ({
  isMobile,
  isWhiteLabel,
  isWebview,
  isAppBannerEnabled,
  isCookieBannerDisabled,
}: BannerControllerProps) => {
  const mobileBannerHiding = getIsCookieHasAnOption(
    mobileBannerCookie,
    'hiding',
  );
  const desktopBannerHiding = getIsCookieHasAnOption(
    desktopBannerCookie,
    'hiding',
  );

  const showCookiesBanner =
    (mobileBannerHiding && isMobile) || (desktopBannerHiding && !isMobile);

  const shouldShowCookiesBanner =
    showCookiesBanner && !isCookieBannerDisabled && !isWhiteLabel;

  return (
    <>
      {shouldShowCookiesBanner && <CookiesBanner />}
      {isAppBannerEnabled && isMobile && !isWebview ? (
        <MobileAppPopupContainer />
      ) : null}
      {isAppBannerEnabled && !isMobile ? <DynamicDesktopAppBanner /> : null}
    </>
  );
};
