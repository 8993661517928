import { takeLatest, takeEvery } from 'redux-saga/effects';

import { setMobileCookie } from 'src/utils/mobileUtils';
import { clickChannel } from '../../helpers/effects';
import { isSSR } from '../../helpers/ssr';

import { INIT_APP } from './constants';
import { removePreloader } from '../../utils/commonUtils';

/**
 * Переход к мобильной версии сайта
 * по клику на ссылку в футере
 */
function handleGoToMobile(event: { target: HTMLInputElement }) {
  if (isSSR) return;

  const footerMobileLink = document.querySelector(
    'a[data-action="#go_mobile"]',
  );

  if (event.target !== footerMobileLink) {
    return;
  }

  setMobileCookie(false);

  window.location.reload();
}

function* handleInitApp() {
  removePreloader();

  if (clickChannel) {
    yield takeEvery(clickChannel, handleGoToMobile);
  }
}

export default function* viewSaga() {
  yield takeLatest(INIT_APP, handleInitApp);
}
