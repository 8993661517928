import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { WriteUsPopup } from '@lt/components/components/HeaderMenu/';

import { getIsMobile } from 'src/store/view/selectors';
import { getFeedbackPopup } from 'src/store/feedback/selectors';

import { showPopup, hidePopup } from 'src/store/feedback/slices';

const mapStateToProps = (state) => ({
  showPopup: getFeedbackPopup(state),
  isMobile: getIsMobile(state),
});

const mapDispatchToProps = (dispatch) => ({
  onOpenModal: () => dispatch(showPopup()),
  onCloseModal: () => dispatch(hidePopup()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(WriteUsPopup);
