import React from 'react';

import { HotelRatingSquare, Image } from '@lt/components';

import type { IHotelImage } from './types';
import styles from './hotelImage.module.css';
import stylesImages from '../../../styles/cardImages.module.css';

export const HotelImage = (props: IHotelImage) => {
  const { rating, image, country } = props;
  const { x290x135: src, webp_x290x135: fallback } = image;

  return (
    <div>
      <div className={styles.hotelCountry}>{country}</div>
      <HotelRatingSquare
        className={styles.hotelRating}
        value={rating}
        count={rating}
        showMeta={false}
      />
      <Image
        className={stylesImages.image}
        src={src}
        fallback={fallback}
        loading="lazy"
      />
    </div>
  );
};
