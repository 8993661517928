import { createAction } from '@reduxjs/toolkit';

import {
  SEND_MINDBOX_AUTH,
  SEND_MINDBOX_REGISTRATION,
  SEND_MINDBOX_AUTH_ON_CHECKOUT,
  SEND_MINDBOX_FILL_TOURIST_DATA,
  SEND_MINDBOX_PAGEVIEW_CHECKOUT,
  SEND_MINDBOX_PAGEVIEW_HOTEL,
  SEND_MINDBOX_PAGEVIEW_EXPLORE,
} from './constants';

export const sendMindboxAuthAction = createAction(SEND_MINDBOX_AUTH);
export const sendMindboxRegistrationAction = createAction(
  SEND_MINDBOX_REGISTRATION,
);
export const sendMindboxAuthOnCheckoutAction = createAction(
  SEND_MINDBOX_AUTH_ON_CHECKOUT,
);
export const sendMindboxFillTouristDataAction = createAction(
  SEND_MINDBOX_FILL_TOURIST_DATA,
);

export const sendMindboxPageviewCheckoutAction = createAction(
  SEND_MINDBOX_PAGEVIEW_CHECKOUT,
);
export const sendMindboxPageviewHotelAction = createAction(
  SEND_MINDBOX_PAGEVIEW_HOTEL,
);
export const sendMindboxPageviewExploreAction = createAction(
  SEND_MINDBOX_PAGEVIEW_EXPLORE,
);
