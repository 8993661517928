import type { SagaReturnType } from 'redux-saga/effects';
import { put, select, takeLatest } from 'redux-saga/effects';
import { api } from 'src/api';
import { INIT_APP } from 'src/store/view/constants';
import { getIsMobile } from 'src/store/view/selectors';
import { NpsState } from '../../components/NpsForm/NpsForm';
import { pushNpsShown } from '../../components/NpsForm/analytics';
import { retryAction, submitNpsAction, submitNpsOnExitAction } from './actions';
import { npsTokenSelector } from './selectors';
import {
  setAlreadyRated,
  setErrorOccured,
  setScore,
  setToken,
  updateComponentState,
} from './slice';
import type { NpsResponse } from './types';

export function* checkNps() {
  try {
    const queryParams = new URLSearchParams(window.location.search);
    if (!queryParams.get('show_nps_popup')) return;
    yield put(updateComponentState(NpsState.Loading));
    const token = queryParams.get('token');
    if (!token) {
      yield put(updateComponentState(NpsState.Error));
      return;
    }
    const response: SagaReturnType<typeof api.getNps> = yield api.getNps({
      token,
    });
    const { success, score } = response;
    if (!success) {
      yield put(updateComponentState(NpsState.Error));
      return;
    }
    /**
     * Костыль, пока не исправим аналитику
     * https://tracker.yandex.ru/LT-38205
     */
    setTimeout(() => {
      pushNpsShown();
    }, 2000);

    yield put(setToken(token));
    if (score === null) yield put(updateComponentState(NpsState.ChooseRating));
    else {
      yield put(setScore(score));
      yield put(updateComponentState(NpsState.GoodbyeMessage));
      yield put(setAlreadyRated());
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('GET NPS error: ', e);
    yield put(updateComponentState(NpsState.Error));
  }
}

function* putNps(
  payload: ReturnType<typeof submitNpsOnExitAction>['payload'],
): Generator<
  ReturnType<typeof select> | Promise<NpsResponse>,
  NpsResponse | null,
  /**
   * Добавил any, потому что 3 тип в этом дженерике это тип значения, которое отправляется
   * в генератор, т. е. тип токена, флага isMobile, ответ ручки. Это невозможно типизировать,
   * потому что middleware может вернуть в генератор множество разных типов: string, boolean, NpsResponse
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any
> {
  const { score, comment } = payload;
  const token: SagaReturnType<typeof npsTokenSelector> = yield select(
    npsTokenSelector,
  );
  const isMobile: SagaReturnType<typeof getIsMobile> = yield select(
    getIsMobile,
  );
  if (!token) return null;
  const response: NpsResponse = yield api.putNps({
    token,
    score,
    comment,
    source: isMobile ? 'mobile' : 'web',
  });
  return response;
}

export function* sendNps(action: ReturnType<typeof submitNpsAction>) {
  try {
    const response = yield* putNps(action.payload);
    if (!response) return;

    if (response.success) {
      yield put(updateComponentState(NpsState.GoodbyeMessage));
    } else yield put(setErrorOccured());
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('PUT NPS error: ', e);
    yield put(setErrorOccured());
  }
}

// При закрытии попапа отправляем NPS
function* sendNpsOnExit(action: ReturnType<typeof submitNpsOnExitAction>) {
  yield* putNps(action.payload);
}

export default function* npsSagas() {
  yield takeLatest([INIT_APP, retryAction.type], checkNps);
  yield takeLatest(submitNpsAction.type, sendNps);
  yield takeLatest(submitNpsOnExitAction.type, sendNpsOnExit);
}
