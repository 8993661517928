import React from 'react';
import { Link } from '@lt/components';

import { Skolkovo, SmallLogo } from '@lt/components/icons-react/footerLogos';
import styles from './Logos.module.css';

const Logos = () => (
  <div className={styles.footerLogos}>
    <Link href="/">
      <SmallLogo width={50} height={42} />
    </Link>

    <Link href="https://sk.ru">
      <Skolkovo className={styles.footerLogo} />
    </Link>
  </div>
);

export default Logos;
