import { i18n } from '@lt/localization';
import type { TourGuideItem } from './types';

const IMAGE_BASE = 'https://jsapi.cdn.level.travel/tour-guide';

export const ITEMS = (isHotelOnly?: boolean): TourGuideItem[] => [
  {
    image: {
      src: `${IMAGE_BASE}/1.png`,
      align: 'right',
    },
    title: i18n.t('howBuy.tourSelection.title'),
    text: i18n.t('howBuy.tourSelection.text'),
    buttonText: i18n.t('howBuy.tourSelection.buttonText'),
    desktopStyles: {
      width: 220,
      top: -30,
    },
  },
  {
    image: {
      src: `${IMAGE_BASE}/2.png`,
      align: 'left',
    },
    title: i18n.t('howBuy.payment.title'),
    text: i18n.t('howBuy.payment.text'),
    buttonText: i18n.t('howBuy.payment.buttonText'),
    desktopStyles: {
      width: 200,
      top: -30,
    },
  },
  {
    image: {
      src: `${IMAGE_BASE}/3.png`,
      align: 'right',
    },
    title: i18n.t('checkout.payment.after.title'),
    text: i18n.t('checkout.payment.after.text', {
      context: isHotelOnly ? 'hotel' : 'package',
    }),
    buttonText: i18n.t('checkout.payment.after.button'),
    desktopStyles: {
      width: 200,
      top: -20,
    },
  },
  {
    image: {
      src: `${IMAGE_BASE}/4.png`,
      align: 'left',
    },
    title: i18n.t('checkout.payment.document.title'),
    text: i18n.t('checkout.payment.document.text', {
      context: isHotelOnly ? 'hotel' : 'package',
    }),
    buttonText: i18n.t('checkout.payment.document.button'),
    desktopStyles: {
      width: 200,
      top: -15,
    },
  },
  {
    image: {
      src: `${IMAGE_BASE}/5.png`,
      align: 'right',
    },
    title: i18n.t('checkout.payment.support.title'),
    text: i18n.t('checkout.payment.support.text', {
      context: isHotelOnly ? 'hotel' : 'package',
    }),
    buttonText: i18n.t('checkout.payment.support.button'),
    desktopStyles: {
      width: 190,
      top: -5,
    },
  },
];
